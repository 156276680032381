import {Modal} from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean,
  onConfirm: () => void,
  onClose: () => void,
}

export const DeleteContactModal = ({
    isOpen,
    onConfirm,
    onClose
}: Props) => {

  const { t } = useTranslation();

  return (
      <Modal
          okText={t("common.confirm")}
          onOk={onConfirm}
          okButtonProps={{
            block: false,
            size: "large",
            style: {
              backgroundColor: "#0950A8",
              fontSize: 12,
              fontWeight: 600,
              lineHeight: "18px",
              letterSpacing: "0.06px",
              padding: 10,
            },
          }}
          cancelText={t("common.cancel")}
          onCancel={onClose}
          cancelButtonProps={{
              block: false,
              size: "large",
              style: {
                  backgroundColor: "#eeeeee",
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "18px",
                  letterSpacing: "0.06px",
                  padding: 10,
              },
          }}
          width={"600px"}
          title={
            <div className="text-blue-400 text-[16px] font-semibold leading-6 tracking-[0.06px]">
              {t("contacts.deleteContactModal.title")}
            </div>
          }
          open={isOpen}
      >
        <div className="mt-4">
          <p className={"text-neutral-300 text-sm leading-4 tracking-[0.05px]"}>{t("contacts.deleteContactModal.message")}</p>
        </div>
      </Modal>
  );
};
