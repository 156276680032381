import { FC } from "react";
import { IconPropsType } from "./types";

export const Search: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      {...props}
      {...fill}
    >
      <path
        d="M20.7104 19.2899L17.0004 15.6099C18.4405 13.8143 19.1379 11.5352 18.9492 9.2412C18.7605 6.94721 17.7001 4.81269 15.9859 3.27655C14.2718 1.74041 12.0342 0.919414 9.73332 0.982375C7.43243 1.04534 5.24311 1.98747 3.61553 3.61505C1.98795 5.24263 1.04582 7.43194 0.982863 9.73283C0.919903 12.0337 1.7409 14.2713 3.27704 15.9854C4.81318 17.6996 6.94769 18.76 9.24169 18.9487C11.5357 19.1374 13.8148 18.44 15.6104 16.9999L19.2904 20.6799C19.3834 20.7736 19.494 20.848 19.6158 20.8988C19.7377 20.9496 19.8684 20.9757 20.0004 20.9757C20.1324 20.9757 20.2631 20.9496 20.385 20.8988C20.5068 20.848 20.6174 20.7736 20.7104 20.6799C20.8906 20.4934 20.9914 20.2442 20.9914 19.9849C20.9914 19.7256 20.8906 19.4764 20.7104 19.2899ZM10.0004 16.9999C8.61592 16.9999 7.26255 16.5894 6.1114 15.8202C4.96026 15.051 4.06305 13.9578 3.53324 12.6787C3.00342 11.3996 2.8648 9.99214 3.1349 8.63427C3.40499 7.27641 4.07168 6.02912 5.05065 5.05016C6.02961 4.07119 7.27689 3.4045 8.63476 3.13441C9.99263 2.86431 11.4001 3.00293 12.6792 3.53275C13.9583 4.06256 15.0515 4.95977 15.8207 6.11091C16.5899 7.26206 17.0004 8.61544 17.0004 9.9999C17.0004 11.8564 16.2629 13.6369 14.9501 14.9497C13.6374 16.2624 11.8569 16.9999 10.0004 16.9999Z"
        fill={fill}
      />
    </svg>
  );
};
