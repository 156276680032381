import { Button, Checkbox, Col, Form, FormInstance, Input, Row, Select } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AddressBaseDto } from "../../../../api";
import { AuthContext } from "../../../../contexts/authContext";
import { useBreakpoint } from "../../../../utils/hooks/useBreakpoint";
import { FieldType } from "./DeliveryAddress";

interface PropsAddAddress {
    onFinish: (values: any) => void;
    form: FormInstance<any> | undefined;
    valuesToEdit?: AddressBaseDto;
    userDefaultAddressId?: number
  }

export const AddAddress = ({ onFinish, form, valuesToEdit, userDefaultAddressId }: PropsAddAddress) => {
    const { t } = useTranslation();
    const { isMobile } = useBreakpoint();
    const span = !isMobile ? 11 : 24
    const offset = !isMobile ? 2 : 0

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Select.Option value="39">+39</Select.Option>
          </Select>
        </Form.Item>
      );
  
    return (
      <div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          scrollToFirstError
          form={form}
          initialValues={{
            nuovoIndirizzo: valuesToEdit?.alias,
            nome: valuesToEdit?.name,
            cognome: valuesToEdit?.surname,
            indirizzo: valuesToEdit?.street,
            civico: valuesToEdit?.streetNumber,
            codicePostale: valuesToEdit?.zipCode,
            citta: valuesToEdit?.city,
            provincia: valuesToEdit?.province,
            nazione: valuesToEdit?.country,
            email: valuesToEdit?.email,
            numero: valuesToEdit?.phone,
            predefinito: userDefaultAddressId === valuesToEdit?.id
          }}
        >
          <Form.Item<FieldType>
            label={t("delivery.newAddress")}
            name="nuovoIndirizzo"
            style={{
              fontWeight: 600,
            }}
            rules={[
              {
                required: true,
                message: t("delivery.errors.enterNewAddress"),
              },
            ]}
          >
            <Input placeholder={t("delivery.newAddress")} />
          </Form.Item>
          <Row>
            <Col span={span}>
              <Form.Item<FieldType>
                label={t("common.name")}
                name="nome"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("registration.errors.enterName"),
                  },
                ]}
              >
                <Input placeholder={t("common.name")} />
              </Form.Item>
            </Col>
            <Col
              span={span}
              offset={offset}
            >
              <Form.Item<FieldType>
                label={t("common.surname")}
                name="cognome"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("registration.errors.enterSurname"),
                  },
                ]}
              >
                <Input placeholder={t("common.surname")} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={span}>
              <Form.Item<FieldType>
                label={t("delivery.address")}
                name="indirizzo"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("delivery.errors.enterAddress"),
                  },
                ]}
              >
                <Input placeholder={t("delivery.address")} />
              </Form.Item>
            </Col>
            <Col
              span={span}
              offset={offset}
            >
              <Form.Item<FieldType>
                label={t("delivery.streetNumber")}
                name="civico"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("delivery.errors.enterStreetNumber"),
                  },
                ]}
              >
                <Input placeholder={t("delivery.streetNumber")} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={span}>
              <Form.Item<FieldType>
                label={t("delivery.zipCode")}
                name="codicePostale"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("delivery.errors.enterZipCode"),
                  },
                ]}
              >
                <Input placeholder={t("delivery.zipCode")} />
              </Form.Item>
            </Col>
            <Col
              span={span}
              offset={offset}
            >
              <Form.Item<FieldType>
                label={t("delivery.city")}
                name="citta"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  { required: true, message: t("delivery.errors.enterCity") },
                ]}
              >
                <Input placeholder={t("delivery.city")} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={span}>
              <Form.Item<FieldType>
                label={t("delivery.province")}
                name="provincia"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("delivery.errors.enterProvince"),
                  },
                ]}
              >
                <Input placeholder={t("delivery.province")} />
              </Form.Item>
            </Col>
            <Col
              span={span}
              offset={offset}
            >
              <Form.Item<FieldType>
                label={t("delivery.country")}
                name="nazione"
                style={{
                  fontWeight: 600,
                }}
                rules={[
                  {
                    required: true,
                    message: t("delivery.errors.enterCountry"),
                  },
                ]}
              >
                <Input placeholder="Nazione" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item<FieldType>
            label={t("common.email")}
            name="email"
            style={{
              fontWeight: 600,
            }}
            rules={[
              {
                required: true,
                message: t("registration.errors.enterEmail"),
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item<FieldType>
            label={t("common.phone")}
            name="numero"
            style={{
              fontWeight: 600,
            }}
            rules={[
              {
                required: true,
                message: t("registration.errors.enterPhone"),
              },
            ]}
          >
            <Input addonBefore={prefixSelector} placeholder={t("common.phone")} />
          </Form.Item>
          <Form.Item name="predefinito" 
          valuePropName="checked" 
          noStyle>
            <Checkbox>{t("delivery.saveAsDefaultAddress")}</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="default"
              htmlType="submit"
              style={{
                marginTop: 10,
                borderRadius: "6px",
                border: "1px solid var(--blue-400, #0950A8)",
                color: "var(--blue-400, #0950A8)",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                letterSpacing: "0.07px",
              }}
            >
              {t("delivery.confirmAddress")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };