import { FC } from "react";
import { IconPropsType } from "./types";

export const Check: FC<IconPropsType> = (props) => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.9837 20.4C51.8503 19.2667 50.1503 19.2667 49.017 20.4L27.767 41.65L18.9837 32.8667C17.8503 31.7334 16.1503 31.7334 15.017 32.8667C13.8837 34.0001 13.8837 35.7001 15.017 36.8334L25.7837 47.6C26.3503 48.1667 26.917 48.4501 27.767 48.4501C28.617 48.4501 29.1837 48.1667 29.7503 47.6L52.9837 24.3667C54.117 23.2334 54.117 21.5334 52.9837 20.4Z"
        fill="#3DA172"
      />
    </svg>
  );
};
