import { FC } from "react";
import { IconPropsType } from "./types";

export const ChevronDown: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1481 7.60198C16.7901 7.244 16.2097 7.244 15.8517 7.60198L10.9999 12.4538L6.1481 7.60198C5.79012 7.244 5.20972 7.244 4.85174 7.60198C4.49376 7.95996 4.49376 8.54036 4.85174 8.89834L10.6758 14.7224C10.8548 14.9014 11.145 14.9014 11.324 14.7224L17.1481 8.89834C17.5061 8.54036 17.5061 7.95996 17.1481 7.60198Z"
        fill={fill}
  
      />
    </svg>
  );
};
