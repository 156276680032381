import { FC } from "react";
import { IconPropsType } from "./types";

export const Scan: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...fill}
    >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 5C5.44772 5 5 5.44772 5 6V8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8V6C3 4.34315 4.34315 3 6 3H9C9.55228 3 10 3.44772 10 4C10 4.55228 9.55228 5 9 5H6Z" fill="#F04438"/>
<path d="M18 5C18.5523 5 19 5.44772 19 6V8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8V6C21 4.34315 19.6569 3 18 3H15C14.4477 3 14 3.44772 14 4C14 4.55228 14.4477 5 15 5H18Z" fill="#F04438"/>
<path d="M5 18C5 18.5523 5.44772 19 6 19H9C9.55228 19 10 19.4477 10 20C10 20.5523 9.55228 21 9 21H6C4.34315 21 3 19.6569 3 18V16C3 15.4477 3.44772 15 4 15C4.55228 15 5 15.4477 5 16V18Z" fill="#F04438"/>
<path d="M18 19C18.5523 19 19 18.5523 19 18V16C19 15.4477 19.4477 15 20 15C20.5523 15 21 15.4477 21 16V18C21 19.6569 19.6569 21 18 21H15C14.4477 21 14 20.5523 14 20C14 19.4477 14.4477 19 15 19H18Z" fill="#F04438"/>
<path d="M4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H4Z" fill="#F04438"/>
</svg>

    </svg>
  );
};
