import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { AdminLayout } from "../Components/AdminLayout";
import { UserLayout } from "../Components/UserLayout";
import { Dashboard } from "../pages/admin/dashboard/Dashboard";
import { CartSummary } from "../pages/user/Cart/CartSummary";
import { Login } from "../pages/guest/Login/Login";
import { Order } from "../pages/user/Order/Order";
import { Product } from "../pages/guest/Products/Product";
import { Products } from "../pages/guest/Products/Products";
import { Contacts } from "../pages/user/Profile/Contacts";
import { FAQ } from "../pages/user/Profile/Faq";
import { OrderDetails } from "../pages/user/Profile/OrderDetails";
import { Orders } from "../pages/user/Profile/Orders";
import { Privacy } from "../pages/user/Profile/Privacy";
import { Profile } from "../pages/user/Profile/Profile";
import { ReturnCreated } from "../pages/user/Profile/ReturnCreated";
import { ReturnDetails } from "../pages/user/Profile/ReturnDetails";
import { Returns } from "../pages/user/Profile/Returns";
import { ReturnSteps } from "../pages/user/Profile/ReturnSteps";
import { ReturnSummary } from "../pages/user/Profile/ReturnSummary";
import { RecoveryPassword } from "../pages/guest/RecoveryPassword/RecoveryPassword";
import { Register } from "../pages/guest/Sign-up/Register";
import { ProtectedRoute } from "./ProtectedRoute";
import { Products as AdminProducts } from "../pages/admin/products/Products";
import { Clients } from "../pages/admin/clients/Clients";
import { Orders as AdminOrders } from "../pages/admin/orders/Orders";
import { Transitions } from "../pages/admin/transitions/Transitions";
import { Users } from "../pages/admin/settings/users/Users";
import { Editor } from "../pages/admin/editor/Editor";
import { CMS } from "../pages/admin/settings/CMS";
import { Home } from "../pages/guest/Home/Home";
import ScrollToTop from "../utils/ScrollToTop";
import { AdminCategories } from "../pages/admin/settings/Categories/Categories";
import { Tickets as AdminTickets } from "../pages/admin/tickets/Tickets";

import { Addresses } from "../pages/user/Profile/Addresses";
import { Favorites } from "../pages/user/Profile/Favorites";
import { Languages } from "../pages/admin/settings/Languages";
import { Translations } from "../pages/admin/settings/Translations/Translations";
import { Tickets } from "../pages/user/Profile/Tickets";
import {TicketDetails} from "../Components/TicketDetails";
import {Transactions} from "../pages/user/Profile/Transactions";
import { ResetPassword } from "../pages/guest/ResetPassword/ResetPassword";


export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Home/>
      },
      {
        path: "products",
        children: [
          {
            path: "",
            element: (
              <>
                <ScrollToTop />
                <Products />
              </>
            ),
          },
          {
            path: ":id",
            element: (
              <>
                <ScrollToTop />
                <Product />
              </>
            ),
          },
        ],
      },
      {
        path: "order",
        element: (
          <>
            <ScrollToTop />
            <Order />
          </>
        ),
      },
      {
        path: "cart",
        element: (
          <>
            <ScrollToTop />
            <CartSummary />
          </>
        ),
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "recovery",
    element: <RecoveryPassword />,
  },
  {
    path: "recovery/:token",
    element: <ResetPassword />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "profile",
    element: (
      <>
        <ScrollToTop />
        <ProtectedRoute>
          <UserLayout>
            <Outlet />
          </UserLayout>
        </ProtectedRoute>
      </>
    ),
    children: [
      {
        path: "",
        element: <Profile />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "orders",
        element: <Outlet />,
        children: [
          {
            path: ":id",
            element: <OrderDetails />,
          },
          {
            path: ":id/return",
            element: <ReturnSteps />,
          },
          {
            path: ":id/return-summary",
            element: <ReturnSummary />,
          },
          {
            path: ":id/return-created",
            element: <ReturnCreated />,
          },
        ],
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      // {
      //   path: "returns",
      //   element: <Returns />,
      // },
      // {
      //   path: "returns",
      //   element: <Outlet />,
      //   children: [
      //     {
      //       path: ":id",
      //       element: <ReturnDetails />,
      //     },
      //   ],
      // },
      {
        path: "favorites",
        element: <Favorites />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "tickets",
        element: <Outlet />,
        children: [
          {
            path: ":id",
            element: <TicketDetails />,
          },
        ],
      },
      {
        path: "addresses",
        element: <Addresses />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "contacts",
        element: <Contacts />,
      },
      {
        path: "FAQ",
        element: <FAQ />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <>
        <ScrollToTop />
        <ProtectedRoute>
          <AdminLayout>
            <Outlet />
          </AdminLayout>
        </ProtectedRoute>
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to={"dashboard"} />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "products",
        element: <AdminProducts />,
      },
      {
        path: "tickets",
        element: <AdminTickets />,
      },
      {
        path: "tickets",
        element: <Outlet />,
        children: [
          {
            path: ":id",
            element: <TicketDetails />,
          },
        ],
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "orders",
        element: <AdminOrders />,
      },
      {
        path: "transitions",
        element: <Transitions />,
      },
      {
        path: "editor",
        element: <Editor />,
      },
      {
        path: "settings",
        element: <Outlet />,
        children: [
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "categories",
            element: <AdminCategories></AdminCategories>,
          },
          {
            path: "languages",
            element: <Languages></Languages>,
          },
          {
            path: "languages",
            element: <Outlet/>,
            children: [
              {
                path: ":languageId/translations",
                element: <Translations />,
              },
            ],
          },
          {
            path: "cms",
            element: <CMS />,
          },
        ],
      },
    ],
  },
]);
