import { Breadcrumb, Button, Col, Divider, Row, Steps } from "antd";
import { useTranslation } from "react-i18next";
import { CartCard } from "../../../Components/CartCard";
import { ChevronRight } from "../../../Components/icons/ChevronRight";
import { Cube } from "../../../Components/icons/Cube";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

export const ReturnCreated = () => {
  const { t } = useTranslation()
  const {isMobile} = useBreakpoint()
  const items = [
    {
      title: (
        <span className=" text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          {t('return.chooseArticles')}
        </span>
      ),
    },
    {
      title: (
        <span className=" text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          {t('return.return')}
        </span>
      ),
    },
    {
      className: "last-point",
      title: (
        <span className=" text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          {t('return.confirm')}
        </span>
      ),
    },
  ];
  return (
    <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
      <Breadcrumb
        separator={<ChevronRight style={{ marginTop: 3}} fill="#A3A9B6"/> }
        items={[
          {
            title: t('menu.account'),
          },
          {
            title: t('menu.orders'),
          },
          {
            title: "numero ordine", //TODO: inserire id dell'ordine
          },
          {
            title: t('return.returnArticles'),
          },
        ]}
      />
      <div className="mt-8 pl-44 pr-44">
        <Steps
          className="steps-custom-return-created"
          current={3}
          labelPlacement="vertical"
          items={items}
        />
      </div>
      <div className="flex justify-center mt-8">
        <Cube
          fill="#0950A8"
          style={{
            width: "42px",
            height: "42px",
          }}
        />
      </div>
      <div className="text-xl font-bold leading-8 tracking-[0.12px] flex justify-center text-center">
        {t('return.returnCreated')}.<br></br>
        {t('return.sendEmailTo')} nome.azienda@gmail.com<br></br>
        {t('return.whitAllInfo')}
      </div>
      <Row>
        <Col span={12} className="mt-8">
          <div className="text-blue-400 text-sm font-semibold leading-5 tracking-[0.07px]">
          {t('return.summaryReturnArticles')}
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
           

            <Divider style={{ margin: "8px" }} />
            <CartCard
            id={1}
              showColor={false}
              showQuantity={false}
              showSize={false}
              showTrashButton={false}
              name={"Copripiumino due piazze"}
              brand="Iclub"
              colors={[]}
              size="XL"
              price={230}
              quantity={20}
              changeImageSpan={4}
            productId={0}
            />
          </div>
        </Col>
        <Col span={11} offset={1} className="mt-8">
          <div className="text-blue-400 text-sm font-semibold leading-5 tracking-[0.07px]">
            Consegna del pacco
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
            <Row>
              <Col span={12}>
                <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
                 {t('return.pickUpDate')}
                </div>
                <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
                  Martedi 21.11.2023
                </div>

                <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px] mt-8">
                {t('delivery.address')}
                </div>
                <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
                  Via Santa Chiara 32,<br></br> 61029 Urbino,<br></br>Italia
                </div>
              </Col>
              <Col span={12}>
                <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
                {t('return.courier')}
                </div>
                <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
                  BRT bartolini
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
            <Button block type="primary" className="bg-blue-400 text-xs font-semibold leading-5 tracking-[0.06px]">Visualizza resi</Button>
            <Button block type="text" className="mt-2 text-blue-400 font-semibold leading-4 tracking-[0.06px]">Vai alla home</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
