/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderBaseDto } from './OrderBaseDto';
import type { VariantBaseDto } from './VariantBaseDto';
export type PatchOrderRowResponseDto = {
    id?: number;
    uuid?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    qty: number;
    price: number;
    total: number;
    vat: number;
    variant: VariantBaseDto;
    order: OrderBaseDto;
    status: PatchOrderRowResponseDto.status;
    discount: number;
    discountRate: number;
};
export namespace PatchOrderRowResponseDto {
    export enum status {
        CONFIRMED = 'CONFIRMED',
        RETURNED = 'RETURNED',
        REFUND_FOR_NO_AVAILABILITY = 'REFUND_FOR_NO_AVAILABILITY',
        REFUND_FOR_RETURN_REQUEST = 'REFUND_FOR_RETURN_REQUEST',
    }
}

