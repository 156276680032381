import { Breadcrumb, Button, Checkbox, Col, Divider, Row, Steps } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartCard } from "../../../Components/CartCard";
import { Cube } from "../../../Components/icons/Cube";
import { MyProductSummary } from "../../../Components/MyProductSummary";
import "../../../styles/antd.css";

export const ReturnSteps = () => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const styleStepPassed =
    "w-[36px] h-[36px] bg-blue-100 rounded-full text-center";
  const stylestepNotPassed =
    "w-[36px] h-[36px] rounded-full text-center border-neutral-200 bg-white border-[1px] border-solid";

  const items = [
    {
      title: (
        <span className="ml-6 text-blue-400 text-xs font-medium leading-4 tracking-[0.06px]">
          Scegli articoli
        </span>
      ),
      icon: (
        <div className={styleStepPassed}>
          <Cube fill={"#0950A8"} style={{ marginTop: "8px" }} />
        </div>
      ),
    },
    {
      title: (
        <span className="ml-6 text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          Restituisci
        </span>
      ),
      icon: (
        <div className={stylestepNotPassed}>
          <Cube fill={"#DBDBDB"} style={{ marginTop: "8px" }} />
        </div>
      ),
    },
    {
      title: (
        <span className="ml-6  text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          Conferma
        </span>
      ),
      icon: (
        <div className={stylestepNotPassed}>
          <Cube fill={"#DBDBDB"} style={{ marginTop: "8px" }} />
        </div>
      ),
    },
  ];

  const elements = [
    {
      id: 1,
      name: "Copripiumino due piazze",
      brand: "Iclub",
      colors: [],
      size: "XL",
      price: "230,00",
      quantity: 20,
      changeImageSpan: 4,
    },
    {
      id: 2,
      name: "Copripiumino una piazza",
      brand: "Iclub",
      colors: [],
      size: "S",
      price: "30,00",
      quantity: 10,
      changeImageSpan: 4,
    },
  ];
  return (
    <div className="p-7 ml-14 mr-14">
      <Breadcrumb
        separator=">"
        items={[
          {
            title: t("menu.account"),
          },
          {
            title: t("menu.orders"),
          },
          {
            title: "numero ordine", //TODO: inserire id dell'ordine
          },
          {
            title: t("return.returnArticles"),
          },
        ]}
      />
      <div className="text-blue-400 text-xl font-bold leading-[30px] tracking-[0.1px] mt-4">
        {t("return.returnArticles")}
      </div>
      <div className="mt-4 pl-44 pr-44">
        <Steps
          className="steps-custom"
          current={1}
          size="small"
          labelPlacement="vertical"
          items={items}
        />
      </div>
      <div
        style={{
          boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
          borderRadius: "4px",
          border: "1px solid var(--neutral-200, #DBDBDB)",
          padding: "16px",
        }}
        className="mt-8"
      >
        <Row>
          <Col span={6}>
            <div className="font-semibold text-neutral-300 text-xs leading-4 tracking-[0.06px]">
             {t('return.orderNumber')}
            </div>
            <div className="font-bold text-neutral-500 text-2xl leading-8 tracking-[0.12px]">
              12345
            </div>
          </Col>
          <Col span={6}>
            <div className="text-neutral-500 font-semibold text-xs leading-4 tracking-[0.06px]">
            {t('return.orderDate')}
            </div>
            <div className="mt-2 text-neutral-500 text-xs leading-[16px] tracking-[0.06px]">
              Giovedi 27.09.23
            </div>
          </Col>
          <Col span={6}>
            <div className="text-neutral-500 font-semibold text-xs leading-4 tracking-[0.06px]">
            {t('common.paymentMethod')}
            </div>
            <div className="mt-2 text-neutral-500 text-xs leading-[16px] tracking-[0.06px]">
              Fido
            </div>
          </Col>
          <Col span={6}>
            <div className="text-neutral-500 font-semibold text-xs leading-4 tracking-[0.06px]">
            {t('common.total')}
            </div>
            <div className="mt-2 text-neutral-500 text-xs leading-[16px] tracking-[0.06px]">
              1008,90
            </div>
          </Col>
        </Row>
      </div>
      <div className="mt-8">
        <div className="text-blue-400 font-bold text-lg leading-7 tracking-[0.09px]">
         {t('return.selectArticlesToReturn')}
        </div>
        <Row className="mt-4">
          <Col span={11}>
            <div
              style={{
                boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
                borderRadius: "4px",
                border: "1px solid var(--neutral-200, #DBDBDB)",
                padding: "16px",
              }}
            >
              <Row>
                {/* TODO: inserire lista di articoli */}
                {elements.map((res) => {
                  return (
                    <div key={res.id}>
                      <Col span={2}>
                        <Checkbox
                          className="mt-5"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedItems([...selectedItems, res]);
                            }
                          }}
                        />
                      </Col>
                      <Col span={22}>
                        <CartCard
                          id={res.id}
                          showColor={false}
                          showQuantity={false}
                          showSize={false}
                          showTrashButton={false}
                          name={res.name}
                          brand={res.brand}
                          colors={res.colors}
                          size={res.size}
                          price={Number(res.price)}
                          quantity={res.quantity}
                          changeImageSpan={4}
                          productId={0}
                        />
                      </Col>
                      <Divider style={{ margin: "8px" }} />
                    </div>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col span={12} offset={1}>
            <div
              style={{
                boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
                borderRadius: "4px",
                border: "1px solid var(--neutral-200, #DBDBDB)",
                padding: "16px",
              }}
            >
              <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
               {t('return.howManyItems')}
              </div>
              <div className="text-neutral-500 text-xs leading-[16px] tracking-[0.06px]">
              {t('return.articlesSelected')}: 3
              </div>
              <div
                style={{
                  marginTop: 16,
                }}
              >
                <Button
                  className="bg-blue-400 text-xs font-semibold"
                  type="primary"
                  block
                  onClick={() => navigate("/profile/orders/1/return-summary")}
                >
                  {t('return.proceed')}
                </Button>
                <Button
                  className="mt-4 text-blue-400 font-semibold text-xs"
                  block
                  type="text"
                >
                   {t('common.cancel')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
