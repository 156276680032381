/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderRowBaseDto } from './OrderRowBaseDto';
import type { PaymentMethodBaseDto } from './PaymentMethodBaseDto';
import type { TransactionBaseDto } from './TransactionBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type UpdateOrderRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    status: UpdateOrderRequestDto.status;
    deliveredDate: string | null;
    shippingDate: string | null;
    shippingCost: number | null;
    shippingAddress: Record<string, any>;
    total: number;
    discount: number;
    credit: number;
    user: (UserBaseDto | number);
    rows: Array<(number | OrderRowBaseDto)>;
    transaction: (TransactionBaseDto | number);
    paymentMethod: (PaymentMethodBaseDto | number);
};
export namespace UpdateOrderRequestDto {
    export enum status {
        PENDING = 'PENDING',
        PAID = 'PAID',
        PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
        CONFIRMED = 'CONFIRMED',
        PROCESSING = 'PROCESSING',
        SHIPPED = 'SHIPPED',
        DELIVERED = 'DELIVERED',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
    }
}

