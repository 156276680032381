import { Button, Col, Divider, Flex, Row } from "antd";
import {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CartRowBaseDto } from "../../../../api";
import { numberWithCommasAndFixedDecimals } from "../../../../utils/numberWithCommasAndFixedDecimals";
import {ca} from "date-fns/locale";

export type CartData = {
  gross: number,
  taxable: number,
  shippingCosts: number,
  discount: number
}

interface Props {
  cartRows: CartRowBaseDto[];
  cartData: CartData
}

export const CartFooter = ({ cartRows, cartData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
      <div style={{boxShadow: "0px -3px 20px 0px rgba(180, 180, 180, 0.25)",}} className="bg-white pb-10 md:pb-5 py-5 px-10">
        <div className={"flex flex-col space-y-1"}>
          <div className={"w-full flex justify-between items-center"}>
            <div className="font-semibold text-xs text-neutral-500">
              {t("cart.products")}
            </div>
            <div className="text-xs text-neutral-500">
              {numberWithCommasAndFixedDecimals(cartData.taxable + cartData.discount)} €
            </div>
          </div>
          <div className={"w-full flex justify-between items-center"}>
            <div className="font-semibold text-xs text-neutral-500">
              {t("cart.shipping")}
            </div>
            <div className="text-xs text-neutral-500">
              {numberWithCommasAndFixedDecimals(cartData.shippingCosts)} €
            </div>
          </div>
        </div>
        <Divider/>
        <div className={"flex flex-col space-y-1"}>
          {cartData.discount > 0 && (
            <div className={"w-full flex justify-between items-center"}>
              <div className="font-semibold text-xs text-neutral-300 leading-4">
                {t("cart.totalDiscount")}
              </div>
              <div className="font-semibold text-xs text-neutral-300 leading-4">
                - {numberWithCommasAndFixedDecimals(cartData.discount)} €
              </div>
            </div>
          )}
          <div className={"w-full flex justify-between items-center"}>
            <div className="font-semibold text-xs text-neutral-300 leading-4">
              {t("cart.totalVAT")}
            </div>
            <div className="font-semibold text-xs text-neutral-300 leading-4">
              {numberWithCommasAndFixedDecimals(cartData.gross - cartData.taxable + (cartData.shippingCosts * 0.22))} €
            </div>
          </div>
          <div className={"w-full flex justify-between items-center"}>
            <div className="font-bold text-md">
              {t("common.total")}
            </div>
            <div className="text-md">
              {numberWithCommasAndFixedDecimals(cartData.gross + (cartData.shippingCosts * 1.22))} €
            </div>
          </div>
        </div>
        {cartRows.length > 0 && (
          <>
            <Divider/>
            <Button className="w-full bg-blue-400 text-xs font-semibold" type="primary"
                    onClick={() => navigate("/cart")}>
              {t("cart.summary")}
            </Button>
          </>
        )}
      </div>
  );
};
