import { Col, Row, Steps, Tag } from "antd";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderBaseDto } from "../api";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";
import { tagColorOrderStatus } from "../utils/tagColorOrderStatus";

interface Props {
  status: any;
  date: Dayjs;
}

export const OrderStatus = ({ status, date }: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (status === OrderBaseDto.status.PENDING || status === OrderBaseDto.status.CONFIRMED || status === OrderBaseDto.status.PAID || status === OrderBaseDto.status.PAYMENT_AUTHORIZED ) {
      setCurrentStep(1);
    } else if (status === OrderBaseDto.status.PROCESSING) {
      setCurrentStep(2);
    } else if (status === OrderBaseDto.status.SHIPPED) {
      setCurrentStep(3);
    } else if (status === OrderBaseDto.status.DELIVERED) {
      setCurrentStep(4);
    }
  }, []);
  return (
    <div
      style={{
        boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
        borderRadius: "4px",
        border: "1px solid var(--neutral-200, #DBDBDB)",
        padding: "16px",
      }}
    >
      <Row>
        <Col span={12}>
          <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
            {t("order.orderStatus")}
          </div>
          <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px] capitalize">
            {status?.toLocaleLowerCase()}
          </div>
        </Col>
        <Col>
          <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
            {t("order.deliveryDate")}
          </div>
          <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
            {date?.format("DD-MM-YYYY")}
          </div>
        </Col>
      </Row>
      <div className="mt-2">
        {status !== OrderBaseDto.status.DELIVERED ||
        status !== OrderBaseDto.status.CANCELLED ? (
          <Steps
            className={isMobile ? "" : "status-steps -ml-10"}
            labelPlacement="vertical"
            current={currentStep}
            items={[
              {
                title: t("order.status.create"),
              },
              {
                title: t("order.status.working"),
              },
              {
                title: t("order.status.shipped"),
              },
              {
                className: currentStep === 4 ? "last-point" : " ",
                title: t("order.status.delivered"),
              },
            ]}
          />
        ) : (
          <Tag
            color={tagColorOrderStatus(status)?.tag}
            style={{
              borderRadius: 50,
              borderWidth: 0,
              fontWeight: "bold",
              color: tagColorOrderStatus(status)?.text,
            }}
            key={status}
          >
            <div className="capitalize">{status?.toLocaleLowerCase()}</div>
          </Tag>
        )}
      </div>
    </div>
  );
};
