import { Menu, MenuProps } from "antd";
import { t } from "i18next";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExternalLink } from "./icons/ExternalLink";
import { UserMenuItem } from "./UserMenuItem";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";

interface AdminMenu {
  label: string;
  icon: ReactNode;
  id: string;
  url: string;
  children:
    | {
        label: string;
        url: string;
        visible: boolean;
      }[]
    | [];
  notifications: number | undefined;
  visible?: boolean;
}

interface Props {
  adminMenu: AdminMenu[];
  selectedItem: string;
  setSelectedItem: (value: string) => void;
  setSelectedKeys: (value: string[]) => void;
  openKeys: string[];
  selectedKeys: string[];
  onOpenChange: (value: string[]) => void;
  items: any[];
  openMenuDrawer?: boolean;
}

export const SideBarAdminMenu = ({
  adminMenu,
  selectedItem,
  setSelectedItem,
  setSelectedKeys,
  openKeys,
  onOpenChange,
  selectedKeys,
  items,
  openMenuDrawer,
}: Props) => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const {isMobile} = useBreakpoint()

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    // Aggiungi un listener per l'evento di ridimensionamento della finestra
    window.addEventListener("resize", handleResize);

    // Pulisci il listener quando il componente viene smontato
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filterVisibleItems = (items: any) => {
    const filteredItems: any = [];
    items.forEach((item: any) => {
      if (item.children && item.children.length > 0) {
        const visibleChildren = filterVisibleItems(item.children);
        if (visibleChildren.length > 0) {
          filteredItems.push({ ...item, children: visibleChildren });
        }
      } else if (item.visible !== false) {
        filteredItems.push(item);
      }
    });
    return filteredItems;
  };

  return (
    <div>
      <div className={`${windowSize.width < 1024 ? "pl-2" : "pl-0"} mt-4 p-2`}>
        {isMobile && <div className="flex justify-center">
          <Logo></Logo>
        </div>}
        <div className="mt-4">
          {adminMenu.map((res) => {
            if (
              res.id !== "impostazioni" &&
              res.id !== "supporto" &&
              res.visible
            )
              return (
                <div key={res.id}>
                  <UserMenuItem
                    item={res}
                    selectedItem={selectedItem}
                    setSelectedItem={(value) => setSelectedItem(value)}
                    setSelectedKeys={(value) => setSelectedKeys(value)}
                    openMenuDrawer={openMenuDrawer}
                  />
                </div>
              );
          })}
          {adminMenu.map((res) => {
            if (res.id === "impostazioni") {
              return (
                <Menu
                  key={res.id}
                  mode="inline"
                  className="custom-menu"
                  openKeys={openKeys}
                  selectedKeys={selectedKeys}
                  onOpenChange={(value) => onOpenChange(value)}
                  onClick={(e) => {
                    if (e.key) {
                      setSelectedKeys([e.key]);
                      setSelectedItem("");
                      const settings = adminMenu?.find(
                        (item) => item?.id === "impostazioni"
                      );
                      if (settings) {
                        settings.children.map((res) => {
                          if (res.label === e.key && res.visible) {
                            navigate(res.url);
                          }
                        });
                      }
                    }
                  }}
                  style={{
                    width:
                      windowSize.width < 1024 && !openMenuDrawer
                        ? "50%"
                        : "100%",
                    marginTop: -8,
                  }}
                  items={filterVisibleItems(items)}
                />
              );
            }
          })}
        </div>
      </div>
      <div className="flex justify-start absolute bottom-0 mb-10">
        <div className="text-[#0950A8] text-sm font-semibold leading-5 tracking-[0.07px]">
          unibusiness.com
        </div>
        <div>
          <ExternalLink />
        </div>
      </div>
    </div>
  );
};
