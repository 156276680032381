import { Col, DatePicker, Row, Segmented } from "antd";
import { CashChecked } from "../../../Components/icons/CashChecked";
import { Cart } from "../../../Components/icons/Cart";
import { Scan } from "../../../Components/icons/Scan";
import { SalesProcess } from "./components/SalesProgress";
import { Statistics } from "./components/Statistics";
import { TopSelling } from "./components/TopSelling";
import { SalesByLocation } from "./components/SalesByLocation";
import { RecentOrders } from "./components/RecentOrders";
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const { t } = useTranslation()
  const { RangePicker } = DatePicker;
  const segmentedOptions = [
    t('dashboard.timeOptions.allTime'),
    t('dashboard.timeOptions.12Months'),
    t('dashboard.timeOptions.30Days'),
    t('dashboard.timeOptions.7Days'),
    t('dashboard.timeOptions.24Hours'),
  ];

  const totalInfo = [
    {
      icon: <CashChecked></CashChecked>,
      color: ["#EFEFFD", "#DEDEFA"],
      label: t('dashboard.info.totalRevenue'),
      value: "$12,343",
      percentage: "10",
    },
    {
      icon: <Cart fill="#0D894F"></Cart>,
      color: ["#E7F4EE", "#CFE7DC"],
      label:  t('dashboard.info.totalSales'),
      value: "31,678",
      percentage: "15",
    },
    {
      icon: <Scan></Scan>,
      color: ["#FEEDEC", "#FCDAD7"],
      label: t('dashboard.info.productSku'),
      value: "247",
      percentage: "0",
    },
    {
      icon: <Scan></Scan>,
      color: ["#FDF1E8", "#FAE1CF"],
      label: t('dashboard.info.balance'),
      value: "24,500",
      percentage: "-25",
    },
  ];

  return (
    <div>
      <Row>
        <Col span={12}>
          <Segmented className="segmented-custom" options={segmentedOptions} />
        </Col>
        <Col span={12}>
          <div className="flex justify-end">
            <RangePicker />
          </div>
        </Col>
      </Row>
      <Row className="mt-3" justify={'space-between'}>
        {totalInfo.map((item) => {
          return (
            <Col
              key={item.value}
              span={5}
              style={{
                borderRadius: "8px",
                border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
                background: "var(--neutral-white, #FFF)",
                boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
                padding: 24,
              }}
            >
              <div
                className="w-10 h-10 rounded-full flex relative justify-around"
                style={{
                  backgroundColor: item.color[0],
                }}
              >
                <div
                  className="w-8 h-8 rounded-full m-auto"
                  style={{
                    backgroundColor: item.color[1],
                  }}
                >
                  <div className="flex justify-center w-8 h-8">
                    <div className="m-auto">{item.icon}</div>
                  </div>
                </div>
              </div>
              <div className="mt-4 text-[#667085] font-['Inter'] font-medium text-base leading-6 tracking-[0.08px]">
                {item.label}
              </div>
              <div className="flex row justify-center content-center">
                <div className=" text-[#333843] font-['Inter'] font-medium text-2xl leading-8 tracking-[0.12px]">
                  {item.value}
                </div>
                <div
                  className={
                    "w-auto h-6 rounded-full p-1 pl-2 pr-2 text-center m-auto"
                  }
                  style={percentageStyle(item.percentage)}
                >
                  <div className="m-auto font-xs leading-4 font-medium font-['Inter'] tracking-[0.06px]">
                    {item.percentage}%
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row className="mt-8" gutter={[16, 8]}>
        <Col span={12}>
          <SalesProcess></SalesProcess>
        </Col>
        <Col span={12}>
          <Statistics></Statistics>
        </Col>
      </Row>
      <Row className="mt-8" gutter={[16, 8]}>
        <Col span={16}>
          <TopSelling />
        </Col>
        <Col span={8}>
          <SalesByLocation />
        </Col>
      </Row>

      <div className="mt-8">
        <RecentOrders></RecentOrders>
      </div>
    </div>
  );
};

export const percentageStyle = (value: any) => {
  const numberValue = Number(value);
  if (numberValue > 0) {
    return {
      backgroundColor: "#E7F4EE",
      color: "#0D894F",
    };
  } else if (numberValue < 0) {
    return {
      backgroundColor: "#FEEDEC",
      color: "#F04438",
    };
  } else {
    return {
      backgroundColor: "#F0F1F3",
      color: "#667085",
    };
  }
};
