import { Button, Col, Row, Tooltip } from "antd";
import { ProductBaseDto, VariantBaseDto } from "../../../../api";
import { numberWithCommasAndFixedDecimals } from "../../../../utils/numberWithCommasAndFixedDecimals";
import { useTranslation } from "react-i18next";

interface Props {
  productVariant: VariantBaseDto;
  product: ProductBaseDto;
}

export const ProductDetails = ({ productVariant, product }: Props) => {
//  const [favorite, setFavorite] = useState(false); //TODO: creare hook per lista dei favoriti
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col span={22}>
          <p
            className="text-neutral-300 text-xs tracking-[0.07px]"
            style={{
              fontFamily: "Roboto Mono",
            }}
          >
            {t("common.code")}: {product?.code}
          </p>
        </Col>
        {/* <Col span={2}>
          <Button
            type="default"
            style={{
              borderColor: "#0950A8",
              background: "white",
            }}
            onClick={() => {}}
            icon={
              // <HeartOutlined style={{ color: "#1C4FA7", fontSize: 20 }} />
              <>
                {!favorite ? (
                  <HeartOutlined
                    onClick={() => setFavorite(!favorite)}
                    style={{ color: "#1C4FA7", fontSize: 20 }}
                  />
                ) : (
                  <HeartFilled
                    onClick={() => setFavorite(!favorite)}
                    style={{ color: "#002655", fontSize: 20 }}
                  />
                )}
              </>
            }
          ></Button> 
        </Col>*/}
      </Row>
      <p className="text-2xl font-bold mb-0 leading-8 tracking-[0.12px] capitalize">
        {productVariant?.name.toLowerCase()}
      </p>
      <span className="text-neutral-300 text-xs leading-4 font-normal tracking-[0.06px]">
        {product?.brand?.name}
      </span>
      <p className="text-blue-400 text-lg font-semibold mt-4 leading-7 tracking-[0.09px]">
        {productVariant?.price ? (
          <>
            {numberWithCommasAndFixedDecimals(productVariant?.price)}
            € {t("product.each")}
          </>
        ) : (
          <Tooltip title={t("common.loginToViewPrice")}>**</Tooltip>
        )}
      </p>
    </div>
  );
};
