import { trimEnd } from "lodash";
import { useContext, useEffect, useState } from "react";
import {
  CategoryBaseDto,
  CategoryService,
  GetProductResponseDto,
  ProductBaseDto,
  ProductService,
  SliderResponseGroupedResponseDto,
  SlideService,
} from "../../../api";
import { Layout } from "../../../Components/Layout/Layout";
import { RelatedProducts } from "../../../Components/RelatedProducts";
import { toFilter, toIncludes } from "../../../utils/request";
import { Categories } from "./components/Categories";
import { GridSlider } from "./components/GridSlider";
import { HomeCarousel } from "./components/HomeCarousel";

export const Home = () => {
  const [evidenceProducts, setEvidenceProducts] = useState<
    SliderResponseGroupedResponseDto[]
  >([]);
  const [gridProducts, setGridProducts] = useState<
    SliderResponseGroupedResponseDto
  >();
  const [sliderProducts, setSliderProducts] = useState<
    SliderResponseGroupedResponseDto
  >();
  const [listProductsGrid, setListProductsGrid] = useState<ProductBaseDto[]>(
    []
  );
  const [listProductsSlider, setListProductsSlider] = useState<
    ProductBaseDto[]
  >([]);
  const [categories, setCategories] = useState<
    { name: string; image: string; id: number; translations: any[] }[]
  >([]);

  const userLanguage = localStorage.getItem("languageId");

  const getProductsById = async (array: []) => {
    let productList: ProductBaseDto[] = [];

    try {
      const res = await ProductService.findAllProductController(
        undefined,
        1000,
        toFilter<GetProductResponseDto>({
          id: { $in: array as number[] },
        }),
        undefined,
        undefined,
        toIncludes<ProductBaseDto>({
          variants: {
            media: true,
          },
          brand: true,
          media: true
        })
      );

      if (res) {
        productList = res.data;
      }
    } catch (error) {
      console.error("Errore durante il recupero dei prodotti:", error);
    }

    return productList;
  };

  useEffect(() => {
    SlideService.getHomeSlideController().then((res) => {
      setEvidenceProducts(res.evidenceSlider);
      setGridProducts(res.grid);
      setSliderProducts(res.products);

      if (res?.grid?.products) {
        getProductsById(res?.grid?.products as []).then((res) => {
          setListProductsGrid(res);
        });
      }
      if (res?.products?.products) {
        getProductsById(res?.products?.products as []).then((res) => {
          setListProductsSlider(res);
        });
      }
    });

    CategoryService.findAllCategoryController(
      undefined,
      100,
      undefined,
      undefined,
      undefined,
      toIncludes<CategoryBaseDto>({
        father: true,
        media: true,
        translations: {
          translation: {
            language: true,
          },
        },
      })
    ).then((res) => {
      const categoriesList: {
        name: string;
        image: string;
        id: number;
        translations: any[];
      }[] = [];
      res.data.forEach((item: CategoryBaseDto) => {
        if (!item.father) {
          categoriesList.push({
            id: item.id ?? 0,
            name: item.name,
            image: item?.media?.path,
            translations: item?.translations,
          });
        }
      });
      setCategories(categoriesList);
    });
  }, []);

  return (
    <Layout noPaddingPage>
      <HomeCarousel evidenceProducts={evidenceProducts}></HomeCarousel>
      <div className="xl:p-28 sm:p-2 sm:pt-8 md:p-16 lg:p-20 pt-5">
        <RelatedProducts
          sliderProducts={sliderProducts}
          listProductsSlider={listProductsSlider}
        ></RelatedProducts>
      </div>
      <Categories categories={categories}></Categories>
      <div className="p-4 md:p-16">
        <GridSlider
          gridProducts={gridProducts}
          listProductsGrid={listProductsGrid}
        ></GridSlider>
      </div>
    </Layout>
  );
};
