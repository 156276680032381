import { FC } from "react";
import { IconPropsType } from "./types";

export const Fido: FC<IconPropsType> = (props) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9 13.0575C14.5005 13.6366 13.9708 14.1141 13.3535 14.4514C12.7361 14.7888 12.0482 14.9767 11.345 15C9.335 15 7.595 13.5 6.845 11.25H11C11.1989 11.25 11.3897 11.171 11.5303 11.0303C11.671 10.8897 11.75 10.6989 11.75 10.5C11.75 10.3011 11.671 10.1103 11.5303 9.96967C11.3897 9.82902 11.1989 9.75 11 9.75H6.5375C6.5375 9.5025 6.5 9.2475 6.5 9C6.5 8.7525 6.5 8.4975 6.5375 8.25H11C11.1989 8.25 11.3897 8.17098 11.5303 8.03033C11.671 7.88968 11.75 7.69891 11.75 7.5C11.75 7.30109 11.671 7.11032 11.5303 6.96967C11.3897 6.82902 11.1989 6.75 11 6.75H6.8525C7.6025 4.5 9.335 3 11.3525 3C12.0544 3.0245 12.7408 3.21294 13.3568 3.55024C13.9728 3.88755 14.5013 4.36435 14.9 4.9425C14.9529 5.03364 15.0245 5.1126 15.11 5.17423C15.1955 5.23587 15.293 5.27879 15.3962 5.3002C15.4994 5.32162 15.6059 5.32104 15.7089 5.29851C15.8118 5.27598 15.9089 5.232 15.9937 5.16945C16.0785 5.10689 16.1492 5.02716 16.2012 4.93545C16.2531 4.84375 16.2851 4.74212 16.2952 4.63721C16.3052 4.53229 16.293 4.42644 16.2594 4.32655C16.2257 4.22667 16.1714 4.13499 16.1 4.0575C15.5658 3.28589 14.8564 2.65183 14.0299 2.20728C13.2034 1.76273 12.2833 1.52035 11.345 1.5C8.525 1.5 6.095 3.63 5.2925 6.75H3.5C3.30109 6.75 3.11032 6.82902 2.96967 6.96967C2.82902 7.11032 2.75 7.30109 2.75 7.5C2.75 7.69891 2.82902 7.88968 2.96967 8.03033C3.11032 8.17098 3.30109 8.25 3.5 8.25H5.0375C5.0375 8.4975 5.0375 8.7525 5.0375 9C5.0375 9.2475 5.0375 9.5025 5.0375 9.75H3.5C3.30109 9.75 3.11032 9.82902 2.96967 9.96967C2.82902 10.1103 2.75 10.3011 2.75 10.5C2.75 10.6989 2.82902 10.8897 2.96967 11.0303C3.11032 11.171 3.30109 11.25 3.5 11.25H5.2925C6.11 14.37 8.525 16.5 11.345 16.5C12.2833 16.4796 13.2034 16.2373 14.0299 15.7927C14.8564 15.3482 15.5658 14.7141 16.1 13.9425C16.1928 13.7827 16.2233 13.5944 16.1858 13.4135C16.1483 13.2326 16.0453 13.0719 15.8966 12.9622C15.7479 12.8526 15.564 12.8017 15.3801 12.8192C15.1962 12.8368 15.0252 12.9217 14.9 13.0575Z"
        fill="#0A7548"
      />
    </svg>
  );
};
