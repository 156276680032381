import React, {useContext, useState} from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  Layout as LayoutComponent,
  Row,
  Space,
  Typography,
} from "antd";

import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { Instagram } from "./icons/Instagram";
import { Facebook } from "./icons/Facebook";
import { Linkedin } from "./icons/Linkedin";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../contexts/authContext";

const { Text } = Typography;

const { Footer } = LayoutComponent;

export const FooterComponent = () => {
  const {currentUser} = useContext(AuthContext);

  const history = useNavigate();
  const styleIconFooter = {
    fontSize: 8,
    marginRight: 2,
  };
  const { isMobile } = useBreakpoint();
  const [email, setEmail] = useState("");
  const navigate = useNavigate()
  return (
    <Footer className={`bg-slate-100 ${isMobile ? "p-5" : "p-20"}`}>
      <Row>
        <Col span={isMobile ? 12 : 6}>
          <Text
            style={{ fontFamily: "Roboto Mono" }}
            className="text-neutral-500 text-xs font-normal leading-5 tracking-[0.36px]"
          >
            {t('footer.company')}
          </Text>
          <div className="mt-2">
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px]">
            {t('footer.mission')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.workWithUs')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.outlets')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.aboutUs')}
            </div>
          </div>
        </Col>
        <Col span={isMobile ? 12 : 6}>
          <Text
            style={{ fontFamily: "Roboto Mono" }}
            className="text-neutral-500 text-xs font-normal leading-5 tracking-[0.36px]"
          >
             {t('footer.help')}
          </Text>
          <div className="mt-2">
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px]">
            {t('footer.FAQ')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.shipments')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.returns')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.orders')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
            {t('footer.payments')}
            </div>
          </div>
        </Col>
        <Col span={isMobile ? 12 : 6}>
          <Text
            style={{ fontFamily: "Roboto Mono" }}
            className="text-neutral-500 text-xs font-normal leading-5 tracking-[0.36px]"
          >
            {t('footer.contacts')}
          </Text>
          <div className="mt-2">
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px]">
              {t('footer.emailCompany')}
            </div>
            <div className="text-neutral-300 text-xs font-medium leading-5 tracking-[0.06px] mt-1">
              {t('footer.phoneCompany')}
            </div>
            <div className="pt-5">
              <Space>
                <Instagram style={styleIconFooter} />
                <Facebook style={styleIconFooter} />
                <Linkedin style={styleIconFooter} />
              </Space>
            </div>
          </div>
        </Col>

        {/*<div className={isMobile ? "mt-4 w-full flex" : ""} >*/}
        {/*</div>*/}
        {!currentUser && <Col span={isMobile ? 12 : 6}>
            <Text strong className="text-[16px] leading-6 tracking-[0.08px]">
              {t('footer.registerAndLogIn')}
            </Text>

            <div className="mt-2">
                <p className="text-neutral-300 text-xs leading-5 tracking-[0.06px] font-medium">
                  {t('footer.accessToDiscount')}
                </p>

              {!isMobile &&  <Input
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
              ></Input>}
                <Button
                    size="middle"
                    type="primary"
                    className="mt-2 bg-blue-400 text-xs"
                    onClick={() => {
                      if(isMobile) {
                        navigate('/register')
                      } else {
                        history("/login", { state: email });
                      }
                    }}
                >
                  {isMobile ? t("common.signup") : `${t("login.form.login")}/${t("common.signup")}`}
                </Button>
            </div>
        </Col>}

      </Row>
      <Divider orientation="center" className={isMobile ? 'pt-2': "pt-5"}></Divider>
      <Row>
        <Col span={isMobile ? 24 : 8} className={isMobile ? 'flex justify-center' : ''}>
          <Logo />
        </Col>
        <Col span={isMobile ? 12 : 8}>
          <p className="text-neutral-300 mt-2 text-[12px]">
          {t('footer.terms')}
          </p>
        </Col>
        <Col span={isMobile ? 12 : 8}>
          <p className="text-neutral-300 mt-2 text-[12px]">
          {t('footer.privacy')}
          </p>
        </Col>
      </Row>
    </Footer>
  );
};
