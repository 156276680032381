import { Background } from "../../../Components/Background";
import { ResetPasswordForm } from "./ResetPasswordForm";


export const ResetPassword = () => {
  return (
    <>
      <Background
        children={<ResetPasswordForm></ResetPasswordForm>}
      ></Background>
    </>
  );
};
