import { OrderBaseDto } from "../api";

export const tagColorOrderStatus = (text: string) => {
  switch (text) {
    case OrderBaseDto.status.DELIVERED:
      return {
        tag: "#FFF5C2",
        text: "#C09700",
      };
    case OrderBaseDto.status.CANCELLED:
      return {
        tag: "#FEEDEC",
        text: "#F04438",
      };
    case OrderBaseDto.status.REJECTED:
      return {
        tag: "#FDF1E8",
        text: "#E46A11",
      };
    case OrderBaseDto.status.PENDING:
      return {
        tag: "#FAFAFC",
        text: "#5D5D5D",
      };
    case OrderBaseDto.status.PROCESSING:
      return {
        tag: "#FAFAFC",
        text: "#5D5D5D",
      };
    case OrderBaseDto.status.SHIPPED:
      return {
        tag: "#FAFAFC",
        text: "#5D5D5D",
      };
    case OrderBaseDto.status.CONFIRMED:
      return {
        tag: "#E7F4EE",
        text: "#0D894F",
      };
    case OrderBaseDto.status.PAID:
      return {
        tag: "#E7F4EE",
        text: "#0D894F",
      };
    case OrderBaseDto.status.PAYMENT_AUTHORIZED:
      return {
        tag: "#E7F4EE",
        text: "#0D894F",
      };
  }
};
