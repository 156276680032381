import { Breadcrumb, Button, Col, Row, Tag } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GetOrderResponseDto, OrderService } from "../../../api";
import Breadcrumbs from "../../../Components/BreadCrumbs";
import { MyProductSummary } from "../../../Components/MyProductSummary";
import { OrderBox } from "../../../Components/OrderBox";
import { OrderStatus } from "../../../Components/OrderStatus";
import { PaymentSummary } from "../../../Components/PaymentSummary";
import { AuthContext } from "../../../contexts/authContext";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
import { toIncludes } from "../../../utils/request";
import { tagColorOrderStatus } from "../../../utils/tagColorOrderStatus";

export const OrderDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const { id } = useParams();
  const [order, setOrder] = useState<GetOrderResponseDto>();
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    OrderService.findOneOrderController(
      id,
      undefined,
      toIncludes<GetOrderResponseDto>({
        rows: {
          variant: {
            product: {
              brand: true,
            },
          },
        },
        paymentMethod: true
      })
    ).then((res) => setOrder(res.data));
  }, []);


  return (
    <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
      <Breadcrumbs
        breadcrumbs={[
          { title: t("menu.account"), href: "/profile" },
          { title: t("menu.orders"), href: "/profile/orders" },
          { title: id ?? "", href: `/profile/orders/${id}` }, //ID ordine
        ]}
      />

      <div className="mt-4">
        <div className={isMobile ? "" : "flex justify-between"}>
          <div
            className={isMobile ? "flex justify-between" : "flex justify-star"}
          >
            <div>
              <div className="font-semibold text-xs text-neutral-300 leading-4 tracking-[0.06px]">
                {t("order.orderNumber")}
              </div>
              <div className="text-blue-400 text-2xl font-bold leading-8 tracking-[0.12px]">
                {order?.id}
              </div>
            </div>
            <div className="ml-4">
              <Tag
                color={tagColorOrderStatus(order?.status ?? "")?.tag}
                style={{
                  borderRadius: 50,
                  borderWidth: 0,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontWeight: "bold",
                  color: tagColorOrderStatus(order?.status ?? "")?.text,
                }}
                key={order?.status}
              >
                <div className="capitalize flex">
                  {order?.status.toLocaleLowerCase()}
                </div>
              </Tag>
            </div>
          </div>

          <div className="flex justify-start">
            <div>

              <Button
                className="mt-2 text-sm text-blue-400 font-semibold border-blue-400 leading-4 tracking-[0.06px]"
                type="default"
                onClick={() => navigate('/profile/tickets')}
              >
                {t("order.contactSupport")}
              </Button>
            </div>
            {/*<div>*/}
            {/*  <Button*/}
            {/*    className="mt-2 bg-blue-400 text-xs font-semibold leading-4 tracking-[0.06px] ml-4"*/}
            {/*    type="primary"*/}
            {/*    onClick={() => navigate("return")}*/}
            {/*  >*/}
            {/*    {t("return.returnArticles")}*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <Row gutter={[16, 16]}>
          <Col span={isMobile ? 24 : 12}>
            <OrderStatus
              status={order?.status}
              date={dayjs(order?.createdAt)}
            ></OrderStatus>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <OrderBox showOrderDetails={false} order={order} />
          </Col>
        </Row>
      </div>
      <div className="mt-10">
        <Row gutter={[16, 16]}>
          <Col span={isMobile ? 24 : 12}>
            <MyProductSummary hoverflow={false} products={order} />
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <PaymentSummary
              deliveryDate={dayjs(order?.deliveredDate).format("DD-MM-YYYY")}
              title={t("common.summary")}
              totalProducts={order?.total}
              totalDiscount={order?.discount}
              vat={((order?.total || 0) + (order?.shippingCost || 0)) * 0.22}
              shippingCosts={order?.shippingCost!}
              credits={currentUser?.credit ?? 0}
              showPaymentButton={false}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
