/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SectionBaseDto } from './SectionBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type UpdateUserSectionPermissionResponseDto = {
    id?: number;
    uuid?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    permission: UpdateUserSectionPermissionResponseDto.permission;
    enable: boolean;
    section: SectionBaseDto;
    user: UserBaseDto;
};
export namespace UpdateUserSectionPermissionResponseDto {
    export enum permission {
        CREATE = 'CREATE',
        READ = 'READ',
        UPDATE = 'UPDATE',
        DELETE = 'DELETE',
        READ_OWN = 'READ_OWN',
        UPDATE_OWN = 'UPDATE_OWN',
        DELETE_OWN = 'DELETE_OWN',
    }
}

