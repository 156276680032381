/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginDto } from '../models/LoginDto';
import type { LoginResponseDto } from '../models/LoginResponseDto';
import type { MeResponse } from '../models/MeResponse';
import type { RequestRecoveryDto } from '../models/RequestRecoveryDto';
import type { RequestUpdatePassword } from '../models/RequestUpdatePassword';
import type { SignupDto } from '../models/SignupDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * @param requestBody
     * @returns LoginResponseDto
     * @throws ApiError
     */
    public static loginAuthController(
        requestBody: LoginDto,
    ): CancelablePromise<LoginResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param fields
     * @param includes
     * @param deleted
     * @returns MeResponse
     * @throws ApiError
     */
    public static meAuthController(
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<MeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/me',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static requestRecoveryAuthController(
        requestBody: RequestRecoveryDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/request-recovery',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updatePasswordAuthController(
        requestBody: RequestUpdatePassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/update-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static signUpAuthController(
        requestBody: SignupDto,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/sign-up',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static activateAuthController(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/activate/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
}
