/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TicketReplyBaseDto } from './TicketReplyBaseDto';
import type { TicketTypeBaseDto } from './TicketTypeBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type UpdateTicketRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    title: string;
    status: UpdateTicketRequestDto.status;
    lastReply?: (TicketReplyBaseDto | number) | null;
    replies: Array<(number | TicketReplyBaseDto)>;
    inCharge?: (UserBaseDto | number) | null;
    user: (UserBaseDto | number);
    type: (TicketTypeBaseDto | number);
};
export namespace UpdateTicketRequestDto {
    export enum status {
        OPEN = 'OPEN',
        IN_CHARGE = 'IN_CHARGE',
        SOLVED = 'SOLVED',
        CLOSED = 'CLOSED',
        ARCHIVED = 'ARCHIVED',
    }
}

