import {Button, Divider, Drawer, Dropdown, Layout as LayoutComponent, Space,} from "antd";
import IconButton from "../../IconButton";
import {Search as SearchImage} from "../../icons/Search";
import {Menu} from "../../icons/Menu";
import {SideBarAdminMenu} from "../../SideBarAdminMenu";
import {SideBarMenu} from "../../SideBarMenu";
import {ReactComponent as Logo} from "../../../assets/images/logo.svg";
import {ChevronDown} from "../../icons/ChevronDown";
import {isAdmin} from "../../../utils/isAdmin";
import ReactCountryFlag from "react-country-flag";
import {CartRowBaseDto, GetProductResponseDto, LanguageService, UserBaseDto} from "../../../api";
import {useTranslation} from "react-i18next";
import {User} from "../../icons/User";
import {Cart} from "../../../pages/user/Cart/Cart";
import {Cart as CartIcon} from "../../icons/Cart";
import {useBreakpoint} from "../../../utils/hooks/useBreakpoint";
import {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {AuthContext} from "../../../contexts/authContext";
import {adminMenu, items as adminItems} from "../../AdminLayout";
import {showSideBar, userMenuList} from "../../UserLayout";
import {SearchSelect} from "../../SearchSelect";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useUserPermissions} from "../../../utils/hooks/useUserPermissions";
import {CloseOutlined} from "@ant-design/icons"
import {CartData} from "../../../pages/user/Cart/components/CartFooter";

const {Header} = LayoutComponent;

interface Props {
  openTopDrawer: boolean;
  setOpenMenuDrawer: (value: boolean) => void;
  openMenuDrawer: boolean;
  selectedAdminItem: string;
  onOpenChange: (value: string[]) => void;
  openKeys: string[];
  selectedKeys: string[]
  setSelectedAdminItem: (value: string) => void
  setSelectedKeys: (value: string[]) => void
  selectedItem: string,
  setSelectedItem: (value: string) => void,
  openSearchInput: boolean
  handleSearch: (value: any) => void
  filteredOptions: GetProductResponseDto[],
  setOpenSearchInput: (value: boolean) => void
  handleCartOpen: (currentUser: UserBaseDto | undefined) => void
  openCartDrawer: boolean,
  handleCartClose: (value: any) => void,
  cartRows: CartRowBaseDto[],
  updateCartRows: () => void,
  languageItems: { key: string, label: ReactNode }[],
  logout: () => void,
  setOpenTopDrawer: (value: boolean) => void,
  cartData: CartData
}

export const HeaderComponent = ({
                                  openTopDrawer,
                                  setOpenMenuDrawer,
                                  openMenuDrawer,
                                  selectedAdminItem,
                                  onOpenChange,
                                  openKeys,
                                  selectedKeys,
                                  setSelectedAdminItem,
                                  setSelectedKeys,
                                  selectedItem,
                                  setSelectedItem,
                                  openSearchInput,
                                  handleSearch,
                                  filteredOptions,
                                  setOpenSearchInput,
                                  handleCartOpen,
                                  openCartDrawer,
                                  handleCartClose,
                                  cartRows,
                                  cartData,
                                  updateCartRows,
                                  languageItems,
                                  logout,
                                  setOpenTopDrawer
                                }: Props) => {
  const {t} = useTranslation();
  const {i18n} = useTranslation();
  const {isMobile} = useBreakpoint();
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const {permissions, setLanguage} = useContext(AuthContext);
  const {canUser} = useUserPermissions(permissions, currentUser);
  const location = useLocation();
  const [scrolledBottom, setScrolledBottom] = useState(false)
  const styleDropdownIcon = {
    fontSize: 10,
    marginLeft: isMobile ? 3 : 10,
    marginTop: 5,
  };

  const items = [
    {
      key: "1",
      label: <Link to={"/profile"}>{t("menu.account")}</Link>,
    },
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "2",
          label: <Link to={"/profile/addresses"}>{t("menu.addresses")}</Link>,
        },
      ]
      : []),
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "3",
          label: <Link to={"/profile/orders"}>{t("menu.orders")}</Link>,
        },
      ]
      : []),
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "4",
          label: <Link to={"/profile/favorites"}>{t("menu.favorites")}</Link>,
        },
      ]
      : []),
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "5",
          label: <Link to={"/profile/tickets"}>{t("menu.tickets")}</Link>,
        },
      ]
      : []),
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "6",
          label: <Link to={"/profile/transactions"}>{t("menu.transactions")}</Link>,
        },
      ]
      : []),
    // ...(!isAdmin(currentUser)
    //     ? [
    //         {
    //             key: "6",
    //             label: <Link to={"/profile/returns"}>{t("menu.returns")}</Link>,
    //         },
    //     ]
    //     : []),
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "7",
          label: <Link to={"/profile/privacy"}>{t("menu.disclosure")}</Link>,
        },
      ]
      : []),
    ...(!isAdmin(currentUser)
      ? [
        {
          key: "8",
          label: <Link to={"/profile/contacts"}>{t("menu.contacts")}</Link>,
        },
      ]
      : []),
    ...(isAdmin(currentUser) && !location.pathname.includes("admin")
      ? [
        {
          key: "9",
          label: <Link to={"/admin/products"}>{t("menu.backOffice")}</Link>,
        },
      ]
      : []),
    ...(isAdmin(currentUser) && location.pathname.includes("admin")
      ? [
        {
          key: "10",
          label: <Link to={"/"}>{t("menu.frontOffice")}</Link>,
        },
      ]
      : []),

    {
      key: "11",
      label: (
        <Link to={"/login"} onClick={() => logout()}>
          <span className="font-semibold text-blue-400">
            {t("menu.logout")}
          </span>
        </Link>
      ),
    },
  ];

  const cartItemsCount = useMemo(() => {
    if (!cartRows) return 0;
    return cartRows.length;
  }, [cartRows])

  const [lastScrollY, setLastScrollY] = useState(0); // Posizione di scroll precedente

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Se stiamo scrollando verso l'alto, mostra l'header
    if (currentScrollY < lastScrollY) {
      setScrolledBottom(true);
    }
    // Se stiamo scrollando verso il basso, nascondi l'header
    else if (currentScrollY > lastScrollY) {
      setScrolledBottom(false);
    }

    if(currentScrollY < 100)
      setScrolledBottom(false);

    // Aggiorna la posizione di scroll precedente
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    // Aggiungi il listener dell'evento scroll quando il componente viene montato
    window.addEventListener('scroll', handleScroll);

    // Rimuovi il listener quando il componente viene smontato
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]); // Dipende da lastScrollY

  return (
    <Header
      style={{
        position: openTopDrawer || scrolledBottom ? "fixed" : "relative",
        top: 0,
        transition: 'all 1s',
        transform: openTopDrawer ? 'translateY(-100%)' : 'translateY(0%)',
        zIndex: 10,
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "white",
        borderBottom: "1px solid var(--neutral-200, #DBDBDB)",
        boxShadow: "0px 4px 30px 0px rgba(131, 98, 234, 0.05)",
        paddingLeft: isMobile ? 10 : 50,
        paddingRight: isMobile ? 10 : 50,
      }}
    >
      {(location.pathname.includes("/profile") ||
        location.pathname.includes("/admin")) && (
        <IconButton
          className={!isMobile ? "-ml-7 mr-4 lg:hidden" : "ml-2 mr-4 pr-0 pl-0"}
          onClick={() => setOpenMenuDrawer(!openMenuDrawer)}
          icon={<Menu className="h-4 w-4"/>}
        ></IconButton>
      )}
      <Drawer placement={"left"} closable={false} onClose={() => setOpenMenuDrawer(!openMenuDrawer)}
              open={openMenuDrawer} height={"auto"} width={250}>
        <div className="ml-4">
          {location.pathname.includes("admin") ? (
            <SideBarAdminMenu
              openMenuDrawer={openMenuDrawer}
              adminMenu={adminMenu(selectedAdminItem, canUser)}
              items={adminItems(selectedAdminItem, canUser)}
              onOpenChange={(value) => onOpenChange(value)}
              openKeys={openKeys}
              selectedItem={selectedAdminItem}
              selectedKeys={selectedKeys}
              setSelectedItem={(value) => setSelectedAdminItem(value)}
              setSelectedKeys={(value) => setSelectedKeys(value)}
            ></SideBarAdminMenu>
          ) : (
            <SideBarMenu
              showSideBar={showSideBar(location)}
              userMenu={userMenuList(selectedItem, t)}
              selectedItem={selectedItem}
              setSelectedItem={(value: string) => setSelectedItem(value)}
              openMenuDrawer={openMenuDrawer}
            ></SideBarMenu>
          )}
        </div>
      </Drawer>
      <Logo className="cursor-pointer" onClick={() => navigate("/")}/>
      <div className={"flex flex-row items-center justify-end w-full"}>
        <div className={"flex space-x-1 items-center"}>
          <>
            {openSearchInput && !isMobile && (
              <SearchSelect currentUser={currentUser} handleSearch={handleSearch} filteredOptions={filteredOptions}/>)}

            {!openSearchInput && (<IconButton onClick={(e) => setOpenSearchInput(!openSearchInput)}
                                              icon={<SearchImage className="h-4 w-4"
                                                                 fill={isAdmin(currentUser) ? "#5D5D5D" : "#0950A8"}/>}/>)}

            {isMobile && openSearchInput && (
              <Drawer placement="bottom" title={t("common.search")} open={openSearchInput}
                      onClose={() => setOpenSearchInput(!openSearchInput)}>
                <SearchSelect currentUser={currentUser} handleSearch={handleSearch} filteredOptions={filteredOptions}/>
              </Drawer>
            )}
          </>

          {!isAdmin(currentUser) ? (
            <>
              <IconButton onClick={() => handleCartOpen(currentUser)}
                          icon={<CartIcon className="h-4 w-4 mt-[2px]" fill="#0950A8"/>}/>

              {openCartDrawer && (
                <Drawer
                  title={(
                    <div className={"w-full flex space-x-3 justify-between items-center px-5"}>
                      <div className={"flex flex-col"}>
                        <span className={"font-bold text-2xl"}>Carrello</span>
                        <span className={"text-sm font-normal"}>({cartItemsCount} articoli)</span>
                      </div>
                      <CloseOutlined onClick={handleCartClose}/>
                    </div>
                  )} width={450} closeIcon={null} open={openCartDrawer} onClose={handleCartClose}
                  styles={{header: {borderBottomWidth: 0}}}>
                  <Cart
                    cartRows={cartRows}
                    updateCartRows={updateCartRows}
                    cartData={cartData}
                  ></Cart>
                </Drawer>
              )}{" "}
            </>
          ) : (
            <>
              {/*<IconButton*/}
              {/*    className="mr-2"*/}
              {/*    onClick={(e) => console.log(e)}*/}
              {/*    icon={*/}
              {/*        <Badge size="small" count={5}>*/}
              {/*            <Notification*/}
              {/*                className={"h-5 w-5"}*/}
              {/*                fill={isAdmin(currentUser) ? "#5D5D5D" : "#0950A8"}*/}
              {/*            />*/}
              {/*        </Badge>*/}
              {/*    }*/}
              {/*></IconButton>*/}
            </>
          )}
          <div className={"flex space-x-1 items-center"}>
            <Dropdown
              menu={{
                items: languageItems,
                selectable: true,
                onClick: (e) => {
                  LanguageService.updateLanguageController(
                    Number(e.key.split("-")[0]),
                    {}
                  ).then((res) => {
                    if (res) {
                      i18n.changeLanguage(
                        e.key.split("-")[1].toLocaleLowerCase()
                      );
                      setLanguage(e.key.split("-")[1]);
                      //   setLanguageSelected({
                      //     id: Number(e.key.split("-")[0]),
                      //     countryCode: e.key.split("-")[1],
                      //   });
                      localStorage.setItem("languageId", e.key.split("-")[0]);
                    }
                  });
                },
              }}
              placement="bottom"
              arrow
            >
              <ReactCountryFlag countryCode={i18n.language.split("-")[0]} style={{
                fontSize: "1.5em",
                lineHeight: "1.5em"
              }} className={"cursor-pointer"}/>
            </Dropdown>
          </div>
        </div>
        <Divider type="vertical" className="h-10 bg-neutral-100 mx-4"/>
        <div onClick={() => navigate("/profile")}>
          <User className="h-4 w-4" fill="#0950A8"/>
        </div>
        <Space
          className={isMobile ? "ml-1" : "ml-4"}
          style={{
            marginRight: currentUser ? 0 : 10,
          }}
        >
          {!isMobile && (
            <Space.Compact direction="vertical" size="small" className="mb-6">
              {currentUser ? (
                <div className="w-auto">
                  <div className="text-sm font-medium text-neutral-500">
                    {currentUser?.name} {currentUser?.surname}
                  </div>
                  <div className="text-xs font-medium text-neutral-300">
                    {currentUser?.company?.name}
                  </div>
                </div>
              ) : (
                <span
                  className="text-sm font-medium text-blue-500 cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                    {t("login.form.login")}
                  </span>
              )}
            </Space.Compact>
          )}
          {currentUser && (
            <div className="mt-2">
              <Dropdown
                menu={{items, selectable: true}}
                placement="bottom"
                arrow
                className="ml-0 mr-0"
              >
                <ChevronDown
                  style={styleDropdownIcon}
                  className="cursor-pointer"
                  fill="#667085"
                />
              </Dropdown>
            </div>
          )}
        </Space>
        {!location.pathname.includes("admin") &&
          !location.pathname.includes("/profile") && (
            <>
              <Divider type="vertical" className="h-10 bg-neutral-100"/>
              <Button
                onClick={() => setOpenTopDrawer(!openTopDrawer)}
                type="text"
                icon={<Menu className="h-4 w-4"/>}
              ></Button>
            </>
          )}
      </div>
    </Header>
  );
};
