import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  Modal,
  notification,
  Row,
  Tabs,
  TabsProps,
  UploadFile,
} from "antd";
import {useContext, useEffect, useRef, useState} from "react";
import { CreateList } from "./components/CreateList";
import { SlideEvidence } from "./components/SlideEvidence";
import ReactCountryFlag from "react-country-flag";
import { LanguageService, SlideService } from "../../../api";
import { useTranslation } from "react-i18next";
import { Plus } from "../../../Components/icons/Plus";
import { Grid } from "./components/Grid";
import { SliderProducts } from "./SliderProducts";
import dayjs from "dayjs";
import {LoadingStateContext} from "../../../contexts/loadingStateContext";

interface EvidenceProduct {
  id: number | undefined;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  image: string;
  url: string;
}

interface GridProduct {
  title: string;
  startDate: string;
  endDate: string;
  media: {
    uid: string;
    path: string;
  };
}

interface SliderProduct {
  title: string;
  startDate: string;
  endDate: string;
}

export const Editor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [gridFileUpload, setGridFileUpload] = useState<UploadFile[]>([]);
  const [confirmOpenModal, setConfirmOpenModal] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [listyType, setListType] = useState<"grid" | "slider">();
  const [evidenceFileUpload, setEvidenceFileUpload] = useState<UploadFile[]>(
    []
  );
  const {toggleSpinner} = useContext(LoadingStateContext);
  const [formEvidenceProducts, setFormEvidenceProducts] = useState<EvidenceProduct[]>([
    {
      id: undefined,
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      url: "",
      image: "",
    },
  ]);

  const [productsGridForm, setProductsGridForm] = useState<GridProduct>({
    title: "",
    startDate: "",
    endDate: "",
    media: {
      uid: "",
      path: "",
    },
  });

  const [sliderProductsForm, setSliderProductsForm] = useState<SliderProduct>({
    title: "",
    startDate: "",
    endDate: "",
  });

  const [api, contextHolder] = notification.useNotification();

  const [selectedLanguage, setSelectedLanguage] = useState<number>(0);
  const [chosenLanguage, setChosenLanguage] = useState<number>(0);

  const [listProducts, setListProducts] = useState<number[]>([]);
  const [
    sliderProductEvidenceValues,
    setSliderProductEvidenceValues,
  ] = useState<any>();
  const [sliderGridProductValues, setSliderGridProductValues] = useState<any>();
  const [sliderProductValues, setSliderProductValues] = useState<any>();
  const productEvidenceFormRef: any = useRef();
  const productListFormRef: any = useRef();
  const productGridFormRef: any = useRef();
  const { t } = useTranslation();

  const addPanel = () => {
    const newPanelKey = `panel-new-${activePanels.length + 1}`;
    setActivePanels([...activePanels, newPanelKey]);
    setFormEvidenceProducts([
      ...formEvidenceProducts,
      {
        id: undefined,
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        url: "",
        image: "",
      },
    ]);
  };

  const removePanel = (panelKeyToRemove: string, index: number) => {
    const updatedPanels = activePanels.filter(
      (panelKey) => panelKey !== panelKeyToRemove
    );
    setActivePanels(updatedPanels);
    const newFormData = [...formEvidenceProducts];
    newFormData.splice(index, 1);
    setFormEvidenceProducts(newFormData);
  };

  useEffect(() => {
    updateHome();
    toggleSpinner(false);
  }, [selectedLanguage]);

  const updateHome = () => {
    SlideService.getHomeSlideController().then((res) => {
      if (res) {
        
        const eviProd: any[] = [];
        
        res?.evidenceSlider.map((res) => {
          eviProd.push({
            id: res.id,
            title:
              res.titles.filter(
                (item) => item.languageId === selectedLanguage
              )[0]?.value ?? "",
            description:
              res.titles.filter(
                (item) => item.languageId === selectedLanguage
              )[0]?.value ?? "",
            startDate: res.fromDate ?? "",
            endDate: res.fromDate ?? "",
            url: res.href,
            images: res.media,
          });
        });

        setFormEvidenceProducts(eviProd);
        setSliderProductEvidenceValues(res?.evidenceSlider);
        setSliderGridProductValues(res?.grid);
        setSliderProductValues(res?.products);
        setActivePanels(
          res?.evidenceSlider.map((item, index) => `panel-${item.id}`)
        );
        toggleSpinner(false);
      }
    });
  };

  useEffect(() => {
    const newArray: any[] = [];
    LanguageService.findAllLanguageController().then((res) => {
      res.data.map((item: any) => {
        newArray.push({
          id: item.id,
          name: item.name,
        });
      });
      setLanguages(newArray);
      setSelectedLanguage(newArray[0]?.id)
    });

    updateHome();
  }, []);

  const deleteSlide = (id: any) => {
    SlideService.deleteSlideController(id).then((res) => {
      api.success({
        message: t("dashboard.editor.slideDeleted"),
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #92D2B4)",
          background: "#F4FCF8",
          color: "#3DA172",
        },
      });
      SlideService.getHomeSlideController().then((res) => {
        if (res) {
          setSliderProductEvidenceValues(res?.evidenceSlider);
          setSliderGridProductValues(res?.grid);
          setSliderProductValues(res?.products);
          setActivePanels(
            res?.evidenceSlider.map((item, index) => `panel-${item.id}`)
          );
        }
        toggleSpinner(false);
      });
    });
  };

  const handleFormEvidenceProductsChange = (values: any, index: number) => {
    setFormChanged(true);
    const newFormData = [...formEvidenceProducts];
    newFormData[index] = {
      id: values.id > 0 ? values.id : undefined,
      title: values.title,
      description: values.description,
      startDate: values.startDate,
      endDate: values.endDate,
      url: values.url,
      image:
        evidenceFileUpload && evidenceFileUpload[0]?.preview
          ? evidenceFileUpload[0]?.preview
          : "",
    };
    setFormEvidenceProducts(newFormData);
  };

  const handleFormSliderProd = (values: any) => {
    setFormChanged(true);
    let newFormData = { ...sliderProductsForm };
    newFormData = {
      title: values.title,
      startDate: dayjs(values.startDate).toISOString() ?? "",
      endDate: dayjs(values.endDate).toISOString() ?? "",
    };
    setSliderProductsForm(newFormData);
  };

  const handleProductsGridFormChange = (values: any) => {
    setFormChanged(true);
    let newFormData = { ...productsGridForm };
    newFormData = {
      media: {
        uid: values?.media?.uid,
        path: values?.media?.path,
      },
      title: values?.title,
      startDate: dayjs(values?.startDate).toISOString() ?? "",
      endDate: dayjs(values?.endDate).toISOString() ?? "",
    };
    setProductsGridForm(newFormData);
  };

  const validateSlidesEvidenceForm = (formRef: any) => {
    if (formRef.current) {
      formRef.current
        .validateForm()
        .then((values: any) => {
          if (values) {
            createSlidesEvidence();
            setConfirmOpenModal(false)
            setSelectedLanguage(chosenLanguage)
          }
        })
        .catch((errorInfo: any) => {
          console.log("Errore di validazione:", errorInfo);
        });
    } else {
      createGridSlideProducts();
      createSliderProducts();
      setConfirmOpenModal(false)
      setSelectedLanguage(chosenLanguage)
    }
  };

  const createSlidesEvidence = () => {
    const valuesToAdd: {
      id: number | undefined;
      defaultTitle: string;
      defaultDescription: string;
      fromDate: any;
      toDate: any;
      image?: string;
      href: string;
      titles: { value: string; languageId: number }[];
      descriptions: { value: string; languageId: number }[];
    }[] = [];
    formEvidenceProducts.forEach((res, index) => {
      valuesToAdd.push({
        id: res.id,
        defaultTitle: "",
        defaultDescription: "",
        fromDate: res.startDate,
        toDate: res.endDate,
        ...(evidenceFileUpload[index]?.preview
          ? {
              image: evidenceFileUpload[index]?.preview
                ? evidenceFileUpload[index]?.preview
                : "",
            }
          : []),
        href: res.url,
        titles: [
          {
            value: res.title,
            languageId: selectedLanguage,
          },
        ],
        descriptions: [
          {
            value: res.description,
            languageId: selectedLanguage,
          },
        ],
      });
    });

    SlideService.setEvidenceSlideController(valuesToAdd).then(() => {
      setFormChanged(false);
      api.success({
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #92D2B4)",
          background: "#F4FCF8",
          color: "#3DA172",
        },
        message: t("dashboard.editor.slidesCreated"),
      });
    });
  };

  const createSliderProducts = () => {
    SlideService.setProductsSlideController({
      fromDate: sliderProductsForm.startDate || sliderProductValues.fromDate,
      toDate: sliderProductsForm.endDate || sliderProductValues.toDate,
      titles: [
        {
          value:
            sliderProductsForm.title ||
            sliderProductValues.titles.filter(
              (item: { languageId: number; value: string }) =>
                item?.languageId === selectedLanguage
            )[0]?.value,
          languageId: selectedLanguage,
        },
      ],
      products: listProducts,
    }).then(() => {
      setFormChanged(false);
      api.success({
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #92D2B4)",
          background: "#F4FCF8",
          color: "#3DA172",
        },
        message: t("dashboard.editor.slidesCreated"),
      });
    });
  };

  const createGridSlideProducts = async () => {

    const fileReader = new FileReader();

    const file = gridFileUpload[0]?.originFileObj;

    if(file)
      fileReader.readAsDataURL(file)

    const load: Promise<string | null> = new Promise((resolve) => {
      if(file)
        fileReader.onload = function() {
          resolve(fileReader.result as string);
        }
      else
        resolve(null)
    })


    const media = await load;

    SlideService.setGridSlideController({
      fromDate:
        productsGridForm?.startDate || sliderGridProductValues?.fromDate,
      titles: [
        {
          value:
            productsGridForm?.title ||
            sliderGridProductValues?.titles.filter(
              (item: { languageId: number; value: string }) =>
                item?.languageId === selectedLanguage
            )[0]?.value,
          languageId: selectedLanguage,
        },
      ],
      toDate: productsGridForm?.endDate || sliderGridProductValues?.toDate,
      products: listProducts,
      image: media
    }).then(() => {
      setFormChanged(false);
      api.success({
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #92D2B4)",
          background: "#F4FCF8",
          color: "#3DA172",
        },
        message: t("dashboard.editor.slidesGridCreated"),
      });
    });
  };

  const items: CollapseProps["items"] = activePanels.map((panelKey, index) => {
    return {
      key: panelKey,
      label: `${t("dashboard.editor.slide")} #${index + 1}`,
      children: (
        <SlideEvidence
          ref={productEvidenceFormRef}
          selectedLanguage={selectedLanguage}
          setEvidenceFileUpload={(values: UploadFile[]) => {
            const newArray = [...evidenceFileUpload];
            newArray[index] = values[0];
            setEvidenceFileUpload(newArray);
          }}
          handleFormChange={(values) =>
            handleFormEvidenceProductsChange(values, index)
          }
          titleForm={`${t("dashboard.editor.slide")} #${index + 1}`}
          sliderProductEvidenceValues={sliderProductEvidenceValues[index]}
        />
      ),
      extra: (
        <Button
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            removePanel(panelKey, index);
            if (sliderProductEvidenceValues[index]?.id) {
              deleteSlide(sliderProductEvidenceValues[index].id);
            }
          }}
          className="text-blue-400"
          type="text"
        >
          <span className="underline">{t("common.delete")}</span>
        </Button>
      ),
    };
  });

  const onChange = (key: string) => {
    if (
      sliderProductEvidenceValues.length !== formEvidenceProducts.length ||
      formChanged
    ) {
      setConfirmOpenModal(true);
      setChosenLanguage(Number(key))
    } else {
      setSelectedLanguage(Number(key));
    }
  };

  const itemsTab: TabsProps["items"] = languages?.map((res) => {
    return {
      key: res.id.toString(),
      label: (
        <ReactCountryFlag
          countryCode={res.name}
          style={{
            fontSize: "2em",
            lineHeight: "2em",
          }}
        ></ReactCountryFlag>
      ),
      children: (
        <>
          <div
            style={{
              borderRadius: "8px",
              border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
              background: "var(--neutral-white, #FFF)",
              boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
              padding: 24,
              height: "100%",
            }}
          >
            <div className="flex justify-between">
              <div className="text-blue-400 text-2xl font-bold leading-8 tracking-[0.12px]">
                {t("dashboard.editor.featuredProducts")}
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={addPanel}
                  style={{
                    height: 40,
                    border: "1px solid var(--Blue-400, #0950A8)",
                    color: "#0950A8",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                  className="bg-blue-400 text-white text-xs font-semibold leading-[18px]"
                >
                  <div className="flex justify-center align-middle  text-white">
                    <Plus fill="#FFFFFF"></Plus>{" "}
                    {t("dashboard.editor.addSlide")}
                  </div>
                </Button>
              </div>
            </div>
            <Collapse
              items={items}
              bordered={false}
              defaultActiveKey={[`panel-${1}`]}
              expandIconPosition="end"
              style={{
                backgroundColor: "white",
              }}
              className="admin-collapse"
            />
            <Button
              type="primary"
              onClick={() => validateSlidesEvidenceForm(productEvidenceFormRef)}
              style={{
                height: 40,
                border: "1px solid var(--Blue-400, #0950A8)",
                color: "#0950A8",
                fontSize: 12,
                fontWeight: 600,
                marginTop: 20,
              }}
              className="bg-blue-400 text-white text-xs font-semibold leading-[18px]"
            >
              <span className="text-white">{t("common.save")}</span>
            </Button>
          </div>
          <SliderProducts
            applyChanges={() => {
              createSliderProducts()
            }}
            selectedLanguage={selectedLanguage}
            createList={() => {
              setIsModalOpen(!isModalOpen);
              setListType("slider");
              setListProducts(sliderProductValues?.products);
            }}
            handleFormSliderProdChange={(values) =>
              handleFormSliderProd(values)
            }
            sliderProductValues={sliderProductValues}
          />
          <div
            style={{
              borderRadius: "8px",
              border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
              background: "var(--neutral-white, #FFF)",
              boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
              padding: 24,
              height: "100%",
              marginTop: 20,
            }}
          >
            <div className="text-blue-400 text-2xl font-bold leading-8 tracking-[0.12px] mb-4">
              {t("dashboard.editor.gridProductsSlider")}
            </div>

            <Grid
              titleForm={t("dashboard.editor.gridProductsSlider")}
              applyChanges={() => {
                createGridSlideProducts().then()
              }}
              createList={() => {
                setIsModalOpen(!isModalOpen);
                setListType("grid");
                setListProducts(sliderGridProductValues?.products);
              }}
              handleFormChange={(values) => {
                handleProductsGridFormChange(values);
              }}
              sliderProductGridValues={sliderGridProductValues}
              setGridFileUpload={(value: any) => setGridFileUpload(value)}
              gridFileUpload={gridFileUpload}
              selectedLanguage={selectedLanguage}
            />
          </div>
        </>
      ),
    };
  });
  
  return (
      <>
        <div>
          <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px] mb-4">
            {t("dashboard.editor.editorHomePage")}
          </div>
          <div className="p-1">
            <Tabs
                activeKey={selectedLanguage.toString()}
                items={itemsTab}
                onChange={onChange}
            ></Tabs>
          </div>

          <Modal
              open={isModalOpen}
              onOk={() => setIsModalOpen(false)}
              onCancel={() => setIsModalOpen(false)}
              width={1000}
              footer={() => {
                return (
                    <Row style={{marginTop: 20}} gutter={[16, 8]}>
                      <Col span={12}>
                        <Button
                            style={{
                              height: 48,
                              border: "1px solid var(--Blue-400, #0950A8)",
                              color: "#0950A8",
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                            block
                            onClick={() => setIsModalOpen(!isModalOpen)}
                        >
                          {t("common.cancel")}
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                            block
                            type="primary"
                            style={{
                              height: 48,
                              background: "#0950A8",
                              color: "white",
                              fontSize: 14,
                            }}
                            onClick={() => {
                              setIsModalOpen(!isModalOpen);

                              if (listyType === "grid") {
                                createGridSlideProducts();
                              } else {
                                createSliderProducts();
                              }
                            }}
                        >
                          <div className="font-semibold text-white">
                            {t("dashboard.editor.createList")}
                          </div>
                        </Button>
                      </Col>
                    </Row>
                );
              }}
          >
            <CreateList
                listProducts={listProducts}
                setListProducts={(value: number) =>
                    setListProducts([...listProducts, value])
                }
                removeItemFromList={(value: number) => {
                  const listWithoutItem = listProducts.filter(
                      (item) => item !== value
                  );
                  setListProducts(listWithoutItem);
                }}
            />
          </Modal>
        </div>
        <Modal
            className="modalEvidenceProd"
            open={confirmOpenModal}
            onOk={() => validateSlidesEvidenceForm(productEvidenceFormRef)}
            onCancel={() => {
              setConfirmOpenModal(false);
              setFormChanged(false);
              setSelectedLanguage(chosenLanguage)
            }}
            okText={t("common.save")}
            cancelText={t("common.cancel")}
        >
          <div> {t("dashboard.editor.wantSaveChanges")}</div>
        </Modal>
        {contextHolder}
      </>
  );
};
