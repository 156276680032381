import {
  Form,
  GetProp,
  Input,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { Image as ImageIcon } from "../../../../Components/icons/Image";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

type FieldType = {
  categoryName: string;
  image: [];
  id: number;
};

interface Props {
  category: {
    id: number;
    name: string;
    image: string;
    children: {
      id: number;
      name: string;
    }[];
  };
  handleFormChange: (values: any) => void;
  setFileUpload: (value: UploadFile[]) => void,
  fileUpload: UploadFile[]
}

export const CategoryForm = ({ category, handleFormChange, setFileUpload,fileUpload }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();


  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileUpload(newFileList);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          id: category?.id,
          categoryName: category?.name,
        }}
        onValuesChange={(changedField, allFields) =>{
          handleFormChange(allFields)}
        }
      >
        <Form.Item<FieldType>
          label=""
          name="id"
          className="hidden"
          // rules={[{ required: true, message: "Inserisci email!" }]}
        >
          <Input style={{ height: 40 }} />
        </Form.Item>

        <Form.Item<FieldType>
          label={t("dashboard.categories.categoryName")}
          name="categoryName"
          style={{
            fontWeight: 600,
          }}
          // rules={[{ required: true, message: "Inserisci email!" }]}
        >
          <Input style={{ height: 40 }} />
        </Form.Item>

        <Form.Item
          label="Images"
          name="image"
          valuePropName="filelist"
          getValueFromEvent={normFile}
          noStyle
        >
          <div className="mt-2">
            <div>
              <Upload.Dragger
                listType="picture"
                style={{
                  height: "100%",
                }}
                defaultFileList={category?.image ? [{
                  url:category?.image,
                  name: `${category?.name}.png`,
                  uid: ''
                }] : []}
                maxCount={1}
                onChange={onChange}
              >
                <p className="ant-upload-drag-icon">
                  <ImageIcon />
                </p>
                <p className="ant-upload-text">
                  {t("dashboard.editor.clickOrDrag")}
                </p>
              </Upload.Dragger>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
