import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { InfoLine } from "../../../Components/InfoLine";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

export const FAQ = () => {
  const { t } = useTranslation()
  const {isMobile} = useBreakpoint()
  return (
    <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
      <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
      {t('menu.FAQ')}
      </div>
      <div className="text-blue-400 text-sm font-bold leading-5 tracking-[0.07px] mt-10">
        Nome Area
      </div>
      <Row>
        <Col span={11}>
          <InfoLine item="test" edit={false} label="Nome ruolo"></InfoLine>

        </Col>
        <Col span={11} offset={1}>
          <InfoLine item="test" edit={false} label="Nome ruolo"></InfoLine>
        </Col>
      </Row>
      <div className="text-blue-400 text-sm font-bold leading-5 tracking-[0.07px] mt-10">
        Nome Area
      </div>
      <Row>
        <Col span={11}>
          <InfoLine item="test" edit={false} label="Nome ruolo"></InfoLine>
  
        </Col>
        <Col span={11} offset={1}>

          <InfoLine item="test" edit={false} label="Nome ruolo"></InfoLine>
        </Col>
      </Row>
    </div>
  );
};
