import { Link } from "react-router-dom";
import { BreadcrumbChevronRight } from "./icons/BreadcrumbChevronRight";

interface Props {
  breadcrumbs?: {
    title: string | number;
    href: string;
    onClick?: () => void;
  }[];
}
const Breadcrumbs = ({ breadcrumbs }: Props) => {
  return (
    <div className="breadcrumbs">
      <div>
        <ul style={{ listStyle: "none", padding: 0, margin: "10px 0" }}>
          {breadcrumbs &&
            breadcrumbs.map((item, index) => {
              return (
                <li key={item.title} style={{ display: "inline-block" }}>
                  <div className="flex justify-start">
                    <div>
                      <Link
                        onClick={item.onClick}
                        to={item.href}
                        className={`${
                          index === breadcrumbs.length - 1
                            ? "breadcrumb-select"
                            : "breadcrumb"
                        } capitalize`}
                      >
                        {typeof item.title === "string"
                          ? item.title?.toLocaleLowerCase()
                          : item.title}
                      </Link>
                    </div>

                    {index < breadcrumbs.length - 1 && (
                      <div className="pr-3 pl-3">
                        <BreadcrumbChevronRight />
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumbs;
