import { Button, Col, InputNumber, Pagination, Row, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DataType } from "./TableProduct";
import { numberWithCommasAndFixedDecimals } from "../../../../utils/numberWithCommasAndFixedDecimals";
import { isAdmin } from "../../../../utils/isAdmin";
import { UserBaseDto } from "../../../../api";
import { HeartButton } from "../../../../Components/HeartButton";
import { useEffect, useState } from "react";
import {CloseOutlined} from "@ant-design/icons";
import {DEFAULT_FALLBACK} from "../../../../App";

interface Props {
  tableData: DataType[] | undefined;
  showFavoriteButton: boolean;
  currentUser: UserBaseDto | undefined;
  handleInputChange: (value: any, key: any, dataIndex: string) => void;
  isFavoriteTable: boolean;
  isLoading?: boolean;
  setOpenLoginModal?: (value: boolean) => void;
  totalCount: number;
  pages: number[];
  setPages: (value: number[]) => void;
}
export const TableProductMobile = ({
  currentUser,
  tableData,
  showFavoriteButton,
  handleInputChange,
  isFavoriteTable,
  isLoading,
  setOpenLoginModal,
  totalCount,
  pages,
  setPages,
}: Props) => {
  const { t } = useTranslation();

  const labelStyle = {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "0.005em",
    color: "#02296C",
    marginBottom: 4,
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on component mount

  const truncateString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength - 3) + "...";
  };

  const maxChars = windowWidth < 380 ? 12 : 13;

  return (
    <div>
      {tableData?.map((item, index) => {
        const percentValue = (item.percentualeSconto * 100) + "%";
        const price = item.netto * Number(item.confezioni);
        const total =
          item.netto * (1 - item.percentualeSconto) * Number(item.confezioni);
        return (
          <Row
            key={index}
            className={`border-[1px] border-solid border-neutral-200 bg-[#F0F1F3] ${
              index < 1 ? " rounded-t-lg" : "mt-0"
            } ${index === tableData.length - 1 ? "rounded-b-lg" : ""}`}
          >
            <Col
              span={15}
              className="p-4 border-r-[1px] border-solid border-r-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.product")}</div>
              <div className="flex justify-start items-center">
                <div>
                  <div
                    className="w-10 h-10 rounded-lg"
                    style={{
                      background: `url(${item.media}), url(${DEFAULT_FALLBACK})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
                <div className="ml-2">
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 12,
                      fontWeight: 500,
                      letterSpacing: "0.005em",
                      opacity: item.stock === 0 ? 0.5 : 1,
                      color: "#2A2E34",
                    }}
                    className="font-medium capitalize text-xs"
                  >
                    {item?.prodotto.toLowerCase()}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={9} className="p-4">
              <div style={labelStyle}>{t("common.articleCode")}</div>
              <div className="flex justify-start items-center">
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 10,
                    fontWeight: 400,
                    letterSpacing: "0.005em",
                    opacity: item.stock === 0 ? 0.5 : 1,
                    marginTop: 4,
                  }}
                  className="text-blue-400 text-xs"
                >
                  {item?.articolo.toUpperCase()}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.color")}</div>
              <div
                className={
                  "rounded-full flex rotate-45 size-5 border border-solid border-neutral-200 overflow-hidden"
                }
              >
                {item.colore.map((color, index) => (
                  <div
                    key={index}
                    className={"h-full w-full"}
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            </Col>
            <Col
              span={6}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.size")}</div>
              <div className="flex justify-start items-center">
                <div
                  style={{
                    opacity: item.stock === 0 ? 0.5 : 1,
                  }}
                  className=" text-[#888888] font-medium"
                  //   className={
                  //     "rounded-full bg-neutral-100 text-[10px] m-0 flex justify-center uppercase cursor-pointer  h-5 w-5 text-center"
                  //   }
                >
                  {item.taglia.toLocaleLowerCase()}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.€net")}</div>
              <div className="flex justify-start items-center">
                <span
                  style={{
                    opacity: item.stock === 0 ? 0.5 : 1,
                  }}
                  className="text-xs text-[#888888]"
                >
                  {numberWithCommasAndFixedDecimals(item.netto)} €
                </span>
              </div>
            </Col>
            <Col
              span={6}
              className="p-4 border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.stock")}</div>
              <div className="flex justify-start items-center">
                <span
                  style={{
                    opacity: item.stock === 0 ? 0.5 : 1,
                  }}
                  className="text-xs text-[#888888]"
                >
                  {item.stock}
                </span>
              </div>
            </Col>
            <Col
              span={8}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.returnDate")}</div>
              <div className="flex justify-start items-center">
                <span
                  style={{
                    opacity: item.stock === 0 ? 0.5 : 1,
                  }}
                  className="text-xs text-[#888888]"
                >
                  {item.dataRientro}
                </span>
              </div>
            </Col>

            <Col
              span={8}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>
                {truncateString(t("common.qntPack"), maxChars)}
              </div>
              <div className="flex justify-start items-center">
                <span
                  style={{
                    opacity: item.stock === 0 ? 0.5 : 1,
                  }}
                  className="text-xs text-[#888888]"
                >
                  {item.quantita}
                </span>
              </div>
            </Col>
            <Col
              span={8}
              className="p-4  border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.totalPieces")}</div>
              <div className="flex justify-start items-center">
                <span
                  style={{
                    opacity: item.stock === 0 ? 0.5 : 1,
                  }}
                  className="text-xs text-[#888888]"
                >
                  {item.stock > 0 ? item.confezioni * item.quantita : 0}
                </span>
              </div>
            </Col>
            <Col
              span={8}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.packages")}</div>
              <div className="flex justify-start items-center">
                {!isAdmin(currentUser) || showFavoriteButton ? (
                  item.stock > 0 ? (
                    <div className="flex justify-between w-12">
                      <div>
                        <InputNumber
                          className=" w-12"
                          disabled={item.stock === 0 || (currentUser && !item.netto)}
                          min={0}
                          value={item.confezioni}
                          max={item.stock}
                          onChange={(e) => {
                            handleInputChange(e, item.key, "confezioni");
                          }}
                        />
                      </div>

                      <div>
                        <Button
                          style={{
                            opacity: item.stock === 0 || (currentUser && !item.netto) ? 0.5 : 1,
                          }}
                          onClick={() => {
                            handleInputChange(0, item.key, "confezioni");
                          }}
                          type="text"
                          className="w-[30%]"
                          icon={<Tooltip title={t("cart.removeItem")}><CloseOutlined /></Tooltip>}
                        ></Button>
                      </div>
                    </div>
                  ) : (
                    <Tag color="#FEEDEC" className="rounded-lg">
                      <span className="text-[#F04438]">
                        {t("product.outOfStock")}
                      </span>
                    </Tag>
                  )
                ) : (
                  <div>{item.confezioni}</div>
                )}
              </div>
            </Col>
            <Col
              span={8}
              className="p-4 border-r-[1px] border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.€tot")}</div>
              <div className=" items-center">
                <>
                  <div className="flex justify-between text-xs">
                    <div className="text-[10px] font-normal leading-[18px] tracking-[0.05px] line-through">
                      {numberWithCommasAndFixedDecimals(price)} €
                    </div>
                    <div className="text-[#AE0F0A] text-[10px] font-semibold leading-[18px] tracking-[0.05px]">
                      -{percentValue}
                    </div>
                  </div>
                  <div
                    style={{
                      opacity: item.stock === 0 ? 0.5 : 1,
                    }}
                    className="bg-blue-50 text-blue-400 rounded-xl text-center text-xs leading-4 tracking-[0.06px] font-semibold p-1"
                  >
                    {item.netto != null ? (
                      item.confezioni && item.stock > 0 ? (
                        numberWithCommasAndFixedDecimals(total)
                      ) : (
                        0
                      )
                    ) : (
                      <Tooltip title={t("common.loginToViewPrice")}>**</Tooltip>
                    )}
                    €
                  </div>
                </>
              </div>
            </Col>

            <Col
              span={showFavoriteButton ? 8 : 0}
              className="p-4 border-t-[1px] border-solid border-neutral-200 border-t-neutral-200 border-0"
            >
              <div style={labelStyle}>{t("common.preferite")}</div>
              <div className="flex justify-start items-center mt-4">
                <>
                  {!isLoading && (
                    <HeartButton
                      isFavoriteTable={isFavoriteTable}
                      data={item}
                      setOpenLoginModal={(value) =>
                        setOpenLoginModal && setOpenLoginModal(value)
                      }
                    />
                  )}
                </>
              </div>
            </Col>
          </Row>
        );
      })}
      <div className="flex justify-center p-4">
        <Pagination
          responsive={true}
          defaultCurrent={pages[0] + 1}
          pageSize={pages[1]}
          onChange={(page, pageSize) => {
            setPages([page, 12]);
            if (pageSize) {
              setPages([page, pageSize]);
            }
          }}
          total={totalCount}
        />
      </div>
    </div>
  );
};
