/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTranslationRequestDto } from '../models/CreateTranslationRequestDto';
import type { PatchTranslationRequestDto } from '../models/PatchTranslationRequestDto';
import type { UpdateTranslationRequestDto } from '../models/UpdateTranslationRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TranslationService {
    /**
     * @param lang
     * @returns any
     * @throws ApiError
     */
    public static getTranslationsTranslationController(
        lang: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/translations/language/{lang}',
            path: {
                'lang': lang,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static deleteTranslationController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/translations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneTranslationController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/translations/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static replaceTranslationController(
        id: number,
        requestBody: UpdateTranslationRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/translations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateTranslationController(
        id: number,
        requestBody: PatchTranslationRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/translations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param ids
     * @returns any
     * @throws ApiError
     */
    public static deleteManyTranslationController(
        ids: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/translations/delete/many',
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static restoreTranslationController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/translations/restore/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findAllTranslationController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/translations',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static createTranslationController(
        requestBody: CreateTranslationRequestDto,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/translations',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static countTranslationController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/translations/count',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
