import {
  Button,
  Collapse,
  CollapseProps,
  Modal,
  Tabs,
  TabsProps,
  UploadFile,
} from "antd";
import { SetStateAction, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import {
  CategoryBaseDto,
  CategoryService, LanguageBaseDto,
  LanguageService
} from "../../../../api";
import { toIncludes } from "../../../../utils/request";
import { CategoryForm } from "./CategoryForm";
import { Subcategory } from "./SubcategoryForm";

export const AdminCategories = () => {
  const { t } = useTranslation();
  const [languages, setLanguages] = useState<LanguageBaseDto[]>([]);
  const [formChanged, setFormChanged] = useState(false);
  const [confirmOpenModal, setConfirmOpenModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageBaseDto | undefined>(undefined);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [fileUpload, setFileUpload] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<
    {
      id: number;
      name: string;
      image: string;
      children: {
        id: number;
        name: string;
      }[];
    }[]
  >([]);

  const [itemsChanged, setItemsChanged] = useState<
    { id: number; name: string; media: string }[]
  >([]);

  useEffect(() => {
    LanguageService.findAllLanguageController().then((res) => {
      setLanguages(res.data);
      setSelectedLanguage(res.data[0]);
    });
  }, []);

  useEffect(() => {
    getCategoriesList();
  }, [selectedLanguage]);

  const getCategoriesList = () => {
    setCategories([]);
    CategoryService.findAllCategoryController(
      undefined,
      10000,
      undefined,
      undefined,
      undefined,
      toIncludes<CategoryBaseDto>({
        father: true,
        media: true,
        translations: {
          translation: {
            language: true,
          },
        },
      })
    ).then((res) => {
      let categoriesWithChild: {
        id: number;
        name: string;
        image: string;
        children: {
          id: number;
          name: string;
        }[];
      }[] = [];
      
      let fatherChildren = new Map();
      
      res.data.forEach((item: CategoryBaseDto) => {
        if (!item.father) {
          if (!fatherChildren.has(item.id)) fatherChildren.set(item.id, []);
        } else {
          if (!fatherChildren.has(item.father.id)) fatherChildren.set(item.father.id, []);
          
          const translation = item?.translations?.find((t: any) => t?.translation?.language.id === selectedLanguage?.id!)?.translation.translation;
          
          fatherChildren.get(item.father.id).push({
            id: item.id,
            name: translation ?? item.name,
          });
        }
      });

      res.data.forEach((item: CategoryBaseDto) => {
        if (!item.father) {
          const translation = item?.translations?.find((t: any) => t?.translation?.language.id === selectedLanguage?.id!)?.translation.translation!;
          
          categoriesWithChild.push({
            id: item.id!,
            name: translation ?? `${item.name}`,
            image: item.media?.path,
            children: fatherChildren.get(item.id),
          });
        }
      });
      
      const panels: SetStateAction<string[]> = [];
      categoriesWithChild.forEach((item) => {
        panels.push(item.name);
      });
      setActivePanels(panels);
      setCategories(categoriesWithChild);
    });
  };

  const onChangeTab = (key: string) => {
    if (formChanged) {
      setConfirmOpenModal(true);
    } else {
      setSelectedLanguage(languages.find((language) => language.id === Number(key)));
    }
  };

  const addOrUpdateItem = (newItem: any) => {
    const index = itemsChanged.findIndex((item) => item.id === newItem.id);
    if (index !== -1) {
      const newArray = [...itemsChanged];
      newArray[index] = newItem;
      setItemsChanged(newArray);
    } else {
      setItemsChanged((prevArray) => [...prevArray, newItem]);
    }
  };

  const save = () => {
    itemsChanged.forEach((item) => {
      CategoryService.updateCategoryController(item.id, {
        translations: [
          {
            value: item.name,
            languageId: selectedLanguage!.id!,
          },
        ],
        image: fileUpload[0]?.thumbUrl || "",
      }).then((res: any) => {
        if (res) {
          setFormChanged(false);
          setItemsChanged([]);
          getCategoriesList();
        }
      });
    });
  };

  const items: CollapseProps["items"] = activePanels.map((panelKey, index) => {
    return {
      key: panelKey,
      label: activePanels[index],
      children: (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: t("home.category"),
                children: (
                  <>
                    {categories && (
                      <CategoryForm
                        handleFormChange={(values) =>
                          //handleFormChange(values, index)
                          addOrUpdateItem({
                            id: values.id,
                            name: values.categoryName,
                          })
                        }
                        category={categories[index]}
                        fileUpload={fileUpload}
                        setFileUpload={(value) => {
                          setFileUpload(value);
                        }}
                      ></CategoryForm>
                    )}
                  </>
                ),
              },
              {
                key: "2",
                label: t("dashboard.categories.subcategory"),
                children: (
                  <Subcategory
                    handleSubcategoryChange={(value: string, id: number) => {
                      // handleFormChildren(value, id, index);
                      addOrUpdateItem({ id: id, name: value });
                    }}
                    subCategories={categories[index]?.children || []}
                  ></Subcategory>
                ),
              },
            ]}
          />
          {itemsChanged.length > 0 && (
            <Button
              type="primary"
              onClick={() => save()}
              style={{
                height: 40,
                border: "1px solid var(--Blue-400, #0950A8)",
                color: "#0950A8",
                fontSize: 12,
                fontWeight: 600,
                marginTop: 20,
              }}
              className="bg-blue-400 font-semibold text-xs h-[46px] whitespace-normal"
            >
              <span className="text-white">{t("common.save")}</span>
            </Button>
          )}
        </>
      ),
    };
  });

  const itemsTab: TabsProps["items"] = languages?.map((res) => {
    return {
      key: res.id!.toString(),
      label: (
        <ReactCountryFlag
          countryCode={res.name}
          style={{
            fontSize: "2em",
            lineHeight: "2em",
          }}
        ></ReactCountryFlag>
      ),
      children: (
        <>
          <div
            style={{
              borderRadius: "8px",
              border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
              background: "var(--neutral-white, #FFF)",
              boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
              padding: 24,
              height: "100%",
            }}
          >
            <Collapse
              items={items}
              bordered={false}
              expandIconPosition="end"
              style={{
                backgroundColor: "white",
              }}
              className="admin-collapse"
            />
          </div>
        </>
      ),
    };
  });
  return (
    <div>
      <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px] mb-4">
        {t("home.categories")}
      </div>
      <div className="p-1">
        <Tabs
          defaultActiveKey={languages[0]?.name}
          items={itemsTab}
          onChange={onChangeTab}
        ></Tabs>
      </div>
      <Modal
        className="modalEvidenceProd"
        open={confirmOpenModal}
        onOk={() => save}
        onCancel={() => setConfirmOpenModal(false)}
        okText={"common.save"}
        cancelText={"common.cancel"}
      >
        <div> {t("dashboard.editor.wantSaveChanges")}</div>
      </Modal>
    </div>
  );
};
