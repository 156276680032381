import {Avatar, Button, Col, Input, Pagination, Row} from "antd";

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ProductBaseDto, ProductService,} from "../../../../api";
import {ButtonFilter} from "../../../../Components/ButtonFilter";
import {Paged} from "../../../../utils/pagedResponse";
import {toIncludes} from "../../../../utils/request";

interface Props {
  setListProducts: (value: number) => void;
  listProducts: number[];
  removeItemFromList: (value: number) => void;
}

export const CreateList = ({
                             setListProducts,
                             listProducts,
                             removeItemFromList,
                           }: Props) => {
  const [products, setProducts] = useState<Paged<ProductBaseDto>>();
  const [prodName, setProdName] = useState<string>();
  const [filtersPassed, setFiltersPassed] = useState<any[]>([]);
  const {t} = useTranslation();
  const [pages, setPages] = useState([0, 12]);

  const findProductsWithFilters = (variantsFilters?: any) => {

    let filters;
    if (prodName) {
      if (!variantsFilters.variants) {
        filters = {
          variants: {
            name: {$containsIgnore: prodName},
            stock: {
              $gt: 0
            }
          },
        };
        variantsFilters = filters
      } else {
        variantsFilters.variants['name'] = {$containsIgnore: prodName}
        variantsFilters.variants.stock = {$gte: 0}
      }
    }

    if(!variantsFilters || variantsFilters?.length === 0) variantsFilters = {
      variants: {
        stock: {$gt: 0},
      }
    }

    ProductService.findAllProductController(
      pages[0],
      pages[1],
      JSON.stringify(variantsFilters),
      undefined,
      undefined,
      toIncludes<ProductBaseDto>({
        variants: {
          media: true
        },
        brand: true,
        media: true
      })
    ).then((res) => {
      setProducts(res);
    });
  };

  useEffect(() => {
    findProductsWithFilters(filtersPassed);
  }, [prodName, pages]);

  return (
    <div>
      <div className="text-blue-400 text-xl font-bold leading-[30px] tracking-[0.1px]">
        {t("common.products")}
      </div>
      <Input
        placeholder="Nome"
        style={{width: "100%", marginTop: 10}}
        onChange={(e) => {
          if (e.target.value.length >= 3) {
            setProdName(e.target.value);
          }
        }}
      ></Input>
      <div className="mt-4">
        <ButtonFilter
          showButton
          showSelectedFilters
          findProductsWithFilters={(v: any) => {
            setFiltersPassed(v);
            findProductsWithFilters(v);
          }}
        ></ButtonFilter>
      </div>
      <div style={{marginTop: 10, background: "#FAFAFC", padding: 10}}>
        {products?.data.map((res) => {
          const isAdded = listProducts?.filter((item) => item === res.id)[0];
          return (
            <Row className="mt-2">
              <Col span={1}>
                <Avatar
                  shape="square"
                  src={encodeURI(res.media?.path ?? res?.variants[0]?.media?.path)}
                ></Avatar>
              </Col>
              <Col span={19}>
                <div className="text-xs font-bold leading-[18px] tracking-[0.06px] ml-2">
                  {res?.name}
                </div>
                <div className="text-neutral-300 text-[10px] font-bold leading-4 tracking-[0.05px] ml-2">
                  {res?.brand?.name}
                </div>
              </Col>
              <Col span={3}>
                <Button
                  className="text-sm font-semibold"
                  type="text"
                  onClick={() => {
                    if (res.id) {
                      if (!isAdded) {
                        setListProducts(res?.id);
                      } else {
                        removeItemFromList(res?.id);
                      }
                    }
                  }}
                  style={{
                    color: isAdded ? "#F04438" : "#0950A8",
                    marginRight: 10,
                  }}
                >
                  {isAdded
                    ? t("dashboard.editor.remove")
                    : t("dashboard.editor.add")}
                </Button>
              </Col>
            </Row>
          );
        })}
        <div className="flex justify-center p-4">
          <Pagination
            responsive={true}
            defaultCurrent={pages[0]}
            onChange={(page, pageSize) => {
              setPages([page, 10]);
              if (pageSize) {
                setPages([page, pageSize]);
              }
            }}
            total={products?.totalCount}
          />
        </div>
      </div>
    </div>
  );
};
