/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCompanyRequestDto } from '../models/CreateCompanyRequestDto';
import type { PatchCompanyRequestDto } from '../models/PatchCompanyRequestDto';
import type { UpdateCompanyRequestDto } from '../models/UpdateCompanyRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompanyService {
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static deleteCompanyController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/companies/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneCompanyController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/companies/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static replaceCompanyController(
        id: number,
        requestBody: UpdateCompanyRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/companies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateCompanyController(
        id: number,
        requestBody: PatchCompanyRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/companies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param ids
     * @returns any
     * @throws ApiError
     */
    public static deleteManyCompanyController(
        ids: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/companies/delete/many',
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static restoreCompanyController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/companies/restore/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findAllCompanyController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/companies',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static createCompanyController(
        requestBody: CreateCompanyRequestDto,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/companies',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static countCompanyController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/companies/count',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
