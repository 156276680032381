import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export function Statistics() {
  const { t } = useTranslation()
  const data = () => {
    return {
      labels: [
        t('dashboard.salesProcess.Jan'),
        t('dashboard.salesProcess.Feb'),
        t('dashboard.salesProcess.Mar'),
        t('dashboard.salesProcess.Apr'),
        t('dashboard.salesProcess.May'),
        t('dashboard.salesProcess.Jun'),
        t('dashboard.salesProcess.Jul'),
        t('dashboard.salesProcess.Aug'),
        t('dashboard.salesProcess.Sep'),
        t('dashboard.salesProcess.Oct'),
        t('dashboard.salesProcess.Nov'),
        t('dashboard.salesProcess.Dec'),
      ],
      datasets: [
        {
          label: "Revenue",
          data: [33, 53, 85, 41, 44, 65, 100, 140, 50, 59, 84, 93],
          fill: "start",
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 250);
            gradient.addColorStop(0, "rgba(233, 136, 65, 0.50)");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0.00)");
            return gradient;
          },
          borderColor: "#E46A11",
        },
        {
          label: "Sales",
          data: [33, 25, 35, 51, 54, 76, 41, 44, 65, 100, 1, 35],
          fill: "start",
          borderColor: "#5C59E8",
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 250);
            gradient.addColorStop(0, "rgba(125, 122, 237, 0.50)");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0.00)");
            return gradient;
          },
        },
      ],
    };
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }); // Change locale according to your currency and country

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (label: any, index: any, labels: any) => {
            return formatter.format(label);
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.35,
      },
      point: {
        radius: 0,
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        labels: {
          render: "value",
          fontColor: "#fff",
          precision: 0,
          fontSize: 12,
          fontStyle: "bold",
          position: "outside",
          outsidePadding: 10,
          textMargin: 10,
          overlap: true,
          showZero: true,
          //   callbacks: function(value: string) {
          //     return '<span style="color: red">' + value + "</span>";
          //   },
          usePointStyle: true,
          font: {
            size: 12,
            color: "#667085",
          },
        },
      },
    },
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
  };

  return (
    <div
      style={{
        borderRadius: "8px",
        border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
        background: "var(--neutral-white, #FFF)",
        boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
        padding: 24,
        height: '100%'
      }}
    >
      <div className="text-[#333843] font-[Inter] text-lg font-medium leading-7 tracking-[0.09px]">
        Statictic
      </div>
      <div className="text-[#667085] font-[Inter] text-sm font-medium leading-5 tracking-[0.09px]">
        Revenue and Sales
      </div>
      <Line data={data()} options={options} />
    </div>
  );
}
