import React, { MouseEvent, ReactNode } from "react";
import { Button } from "antd";

interface Props {
  icon: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  className?: string;
}

const IconButton = ({ icon, onClick, className, ...rest }: Props) => {
  return (
    <Button type="text" onClick={onClick} {...rest} className={className}>
      {icon}
    </Button>
  );
};

export default IconButton;
