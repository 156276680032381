/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SectionBaseDto } from './SectionBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type UpdateUserSectionPermissionRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    permission: UpdateUserSectionPermissionRequestDto.permission;
    enable: boolean;
    section: (SectionBaseDto | number);
    user: (UserBaseDto | number);
};
export namespace UpdateUserSectionPermissionRequestDto {
    export enum permission {
        CREATE = 'CREATE',
        READ = 'READ',
        UPDATE = 'UPDATE',
        DELETE = 'DELETE',
        READ_OWN = 'READ_OWN',
        UPDATE_OWN = 'UPDATE_OWN',
        DELETE_OWN = 'DELETE_OWN',
    }
}

