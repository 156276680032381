import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useFormContext } from "../../../contexts/formContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
interface Props {
  errors: {
    email: {
      constraint: string;
      i18n: string;
    }[];
    password: {
      constraint: string;
      i18n: string;
    }[];
  };
  setRecaptcha: (value: string) => void;
}

export const Step3 = React.forwardRef((props: Props, ref) => {
  const { updateFormData } = useFormContext();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  type FieldType = {
    password?: string;
    confirmPassword?: string;
    reCaptcha?: string;
  };

  const handleFormChange = (allFields: any) => {
    updateFormData(allFields);
  };

  React.useImperativeHandle(ref, () => ({
    validateForm: () => form.validateFields(),
  }));

  const onChange = (value: any) => {
    props.setRecaptcha(value);
  };

  const showError = () => {
    let messages: string[] = [];
    if (props.errors.email) {
      messages.push(props.errors.email[0].i18n);
    }
    if (props.errors.password) {
      messages.push(props.errors.password[0].i18n);
    }
    return messages;
  };
  return (
    <div>
      <Form
        className="mt-4"
        name="registerFormStep3"
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
        form={form}
        wrapperCol={{ span: 22 }}
        onValuesChange={handleFormChange}
      >
        <Row>
          <Col span={isMobile ? 24 : 12} className='w-full'>
            <Form.Item<FieldType>
              label={t("common.password")}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterPassword"),
                },
              ]}
            >
              <Input.Password placeholder={t("common.password")} />
            </Form.Item>
            {!isMobile && (
              <div className="mt-12">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                  onChange={onChange}
                />
              </div>
            )}
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <Form.Item<FieldType>
              label={t("registration.confirmPassword")}
              name="confirmPassword"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterConfirmPassword"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("registrations.errors.passwordsNotMatch"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("registration.confirmPassword")} />
            </Form.Item>
           
          </Col>
        </Row>
      </Form>
      {isMobile && (
              <div className="mt-4">
                <ReCAPTCHA
                size="compact"
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                  onChange={onChange}
                />
              </div>
            )}
      <div className="text-[#d41313] text-center font-bold mt-8">
        {showError().map((res) => {
          return (
            <ul key={res}>
              <li className="text-left">{res}</li>
            </ul>
          );
        })}
      </div>
    </div>
  );
});
