
export const numberWithCommasAndFixedDecimals = (number: any) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return number; 
  }

  let formattedNumber = number.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const decimalPart = formattedNumber.split(',')[1];
  if (decimalPart.length === 1) {
    formattedNumber += '0';
  }

  return formattedNumber;
};
