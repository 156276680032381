import { Carousel } from "react-responsive-carousel";
import { VariantBaseDto } from "../../../../api";
import {useEffect, useState} from "react";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {DEFAULT_FALLBACK} from "../../../../App";
import {Image} from "antd";

interface Props {
    variants: VariantBaseDto[] | undefined;
    productImage?: string;
    productId: number;
}

export const ImageCarousel = ({ variants, productImage, productId }: Props) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageErrors, setImageErrors] = useState({} as Record<string, boolean>);
    const {t} = useTranslation()

    const images = Object.keys([productImage].concat(variants?.map((res) => {
        if(res.media?.path) return res.media?.path;
    }).filter((d) => !imageErrors[d!]).filter(x => x) || []).reduce((dict, v) => {
      if(v)
        dict[v] = true
      return dict
    }, {} as Record<string, boolean>)).map(encodeURI);

    if(images.length === 0) images?.push(DEFAULT_FALLBACK)

    useEffect(() => {
      setCurrentIndex(0)
    }, [productId])
    return (
        <div className={"relative"}>
            <div className={"absolute top-3 right-3 px-4 py-2 rounded-full bg-white/70 z-10 shadow-md"}>
                <p className={"m-0 text-xs font-bold"}>{t("common.productCarouselCount", {currentIndex: (currentIndex + 1), total: images?.length})}</p>
            </div>
            <Carousel
                infiniteLoop
                showStatus={false}
                showThumbs={true}
                onChange={(index) => setCurrentIndex(index)}
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div className={`${hasPrev ? "absolute" : "hidden"} top-0 bottom-0 left-4 flex justify-center items-center z-10`} onClick={clickHandler}>
                            <div className={"size-10 flex items-center justify-center bg-white/70 rounded-full hover:scale-110 shadow-md transition cursor-pointer"}>
                                <LeftOutlined/>
                            </div>
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div className={`${hasNext ? "absolute" : "hidden"} top-0 bottom-0 right-4 flex justify-center items-center z-10`} onClick={clickHandler}>
                            <div className={"size-10 flex items-center justify-center bg-white/70 rounded-full hover:scale-110 shadow-md transition cursor-pointer"}>
                                <RightOutlined />
                            </div>
                        </div>
                    );
                }}
            >
                {images?.map((thumbnail, index) => {
                    return (
                      <div key={thumbnail}
                           style={{
                             backgroundImage: `url(${thumbnail}), url(${DEFAULT_FALLBACK})`,
                             backgroundSize: "cover",
                             backgroundPosition: "center",
                           }} className="mb-2 rounded-lg aspect-square"/>
                )
                  ;
                })}
            </Carousel>
        </div>
    );
};
