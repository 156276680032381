import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

interface Props {
  // onLogin: (user: User) => void
  onLogin: (user: any) => void;
  onError: (error: AxiosError) => void;
  showErrorMessage: boolean;
  showNotAccount?: boolean
  email?: string
}

export const LoginForm = ({ onLogin, onError, showErrorMessage, showNotAccount = true, email }: Props) => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { t } = useTranslation();
  const {isMobile} = useBreakpoint()

  const onFinish = (values: any) => {
    onLogin(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    onError(errorInfo);
  };

  const recoveryPassword = () => {
    navigate("/recovery");
  };

  type FieldType = {
    email?: string;
    password?: string;
    remember?: string;
  };
  return (
    <div className={`bg-[white] rounded-lg p-10 ${isMobile ? 'ml-0' : 'ml-5'} h-full relative`}>
      <p className="text-center text-2xl font-bold">
        {t("login.header.welcome")}
      </p>
      <p className="text-center text-sm text-neutral-300 font-medium">
        {t("login.header.description")
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !==
                t("login.header.description").split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
      </p>
      <div className="flex justify-center">
        <Form
          className="mt-10 w-64"
          name="loginForm"
          form={form}
          initialValues={{ remember: true, email: email ?? '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label={t("common.email")}
            name="email"
            style={{
              fontWeight: 600,
            }}
            rules={[{ required: true, message: t('registration.errors.enterEmail') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label={t("common.password")}
            name="password"
            style={{
              fontWeight: 600,
              marginBottom: 0,
            }}
            rules={[{ required: true, message: t('registration.errors.enterPassword') }]}
          >
            <Input.Password />
          </Form.Item>

          <Row className="relative mt-0">
            <Col>
              <Form.Item<FieldType>
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 0, span: 16 }}
              >
                <Checkbox
                  className="w-4 h-4 absolute top-1"
                  style={{
                    fontSize: 10,
                  }}
                >
                  {t("login.form.rememberMe")}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col className="absolute right-0">
              <p
                className="text-neutral-300 mt-1 right-0 text-[10px] cursor-pointer"
                onClick={recoveryPassword}
              >
                {t("login.form.recoveryPassword")}
              </p>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="bg-blue-500 text-xs font-semibold mt-4"
            >
              {t("login.form.login")}
            </Button>
          </Form.Item>
          {showErrorMessage && (
            <div className="text-[#d41313] text-center font-bold -mt-5">
              {t("login.errors.emailOrPasswordNotValid")}
            </div>
          )}
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              block
              type="link"
              className="font-semibold text-blue-500 text-sm"
              onClick={() => {
                navigate("/register");
              }}
            >
              {t("common.signup")}
            </Button>
          </Form.Item>
        </Form>
      {showNotAccount &&  <div className="absolute bottom-10 font-normal font-xs">
          <p>
          {t("login.form.noAccount")}{" "}
            <span
              className="underline text-blue-500 cursor-pointer font-medium"
              onClick={() => {
                navigate("/register");
              }}
            >
              {t("common.signup")}
            </span>
          </p>
        </div>}
      </div>
    </div>
  );
};
