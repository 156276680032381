/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SetCartRowResponseMetaDto = {
    reason: SetCartRowResponseMetaDto.reason;
    action: SetCartRowResponseMetaDto.action;
    rowId: number;
    variantId: number;
    requestedQty: number;
    availableQty: number;
};
export namespace SetCartRowResponseMetaDto {
    export enum reason {
        ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
        ITEM_LIMIT_REACHED = 'ITEM_LIMIT_REACHED',
        WAREHOUSE_STOCKS_FINISHED = 'WAREHOUSE_STOCKS_FINISHED',
    }
    export enum action {
        CREATED = 'CREATED',
        UPDATED = 'UPDATED',
        DELETED = 'DELETED',
    }
}

