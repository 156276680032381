import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CategoryBaseDto, CategoryService } from "../../../api";
import { Layout } from "../../../Components/Layout/Layout";
import { toIncludes } from "../../../utils/request";
import { urlWithoutId } from "../../../utils/urlWithoutId";
import { ProductsList } from "./ProductsList";

export const Products = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [subCategoryId, setSubCategoryId] = useState<number | null>(null);

  useEffect(() => {
    const userLanguage = localStorage.getItem("languageId");
    if (location.search) {
      const parsedParam = queryString.parse(location.search);
      const categoryId = parsedParam?.category;
      const source = parsedParam?.source;
      if (categoryId) {
        CategoryService.findOneCategoryController(
          categoryId,
          undefined,
          toIncludes<CategoryBaseDto>({
            father: {
              translations: {
                translation: {
                  language: true,
                },
              },
            },
            translations: {
              translation: {
                language: true,
              },
            },
          })
        ).then((res) => {
          if (res.data.father) {
            res.data.father.translations.forEach((tr: any) => {
              const translation = tr.translation;
              if (
                translation?.language?.id === Number(userLanguage) &&
                translation.translation
              ) {
                setCategoryId(res.data.father.id);
                setCategoryName(translation.translation);
              }

              res.data.translations.forEach((tr: any) => {
                const translation = tr.translation;
                if (
                  translation?.language?.id === Number(userLanguage) &&
                  translation.translation
                ) {
                  setSubCategoryId(res.data.id);
                  setSubCategoryName(translation.translation);
                }
              });
            });
          } else {
            res.data.translations.forEach((tr: any) => {
              const translation = tr.translation;
              if (
                translation?.language?.id === Number(userLanguage) &&
                translation.translation
              ) {
                setCategoryId(res.data.id);
                setCategoryName(translation.translation);
              }
            });
          }
        });
      }

      if (source) {
        setSourceName(source.toString());
      }
    }
  }, [location, categoryId, subCategoryId]);

  return (
    <Layout

      breadcrumb={[
        { title: t("home.home"), href: "/" },
        { title: t("common.products"), href: "/products" },
        ...(location.search && categoryName && categoryId
          ? [
              {
                title: categoryName,
                href:
                  location.pathname +
                  urlWithoutId(location.search) +
                  `=${categoryId}`,
                onClick: () => {
                  setSubCategoryId(null);
                },
              },
            ]
          : []),

        ...(location.search && subCategoryName && subCategoryId
          ? [
              {
                title: subCategoryName,
                href:
                  location.pathname +
                  urlWithoutId(location.search) +
                  `=${subCategoryId}`,
              },
            ]
          : []),
        ...(location.search && sourceName
          ? [{ title: sourceName, href: location.pathname + location.search }]
          : []),
      ]}
      children={
        <ProductsList
          categoryName={categoryName}
          sourceName={sourceName}
          subCategoryName={subCategoryName}
          categoryId={categoryId}
          subCategoryId={subCategoryId}
        ></ProductsList>
      }
    ></Layout>
  );
};
