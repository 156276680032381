export const languageList = [
    { value: "EN-GB", label: "English" },
    { value: "IT-IT", label: "Italian" },
    { value: "ES-ES", label: "Spanish" },
    { value: "FR-FR", label: "French" },
    { value: "PT-PT", label: "Portuguese" },
    { value: "ZH-CN", label: "Chinese" },
    { value: "DE-DE", label: "German" },
    { value: "JA-JP", label: "Japanese" },
    { value: "RU-RU", label: "Russian" },
  ];
   