import React, { ReactNode, useContext, useState } from "react";

const FormContext = React.createContext<{
  updateFormData: (formData: any) => any;
  formData: any;
}>({
  updateFormData(formData: any) {
    return;
  },
  formData: {},
});

export const FormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [formData, setFormData] = useState({});
  let items = {};

  const updateFormData = (data: any) => {
    items = {
      ...items,
      data,
    };

    setFormData((prevData) => ({ ...prevData, ...data }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
