import { FC } from "react";
import { IconPropsType } from "./types";

export const Cube: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...fill}
    >
      <path
        d="M17.5747 6.26655C17.57 6.24458 17.57 6.22185 17.5747 6.19988C17.5706 6.18065 17.5706 6.16078 17.5747 6.14155V6.06655L17.5247 5.94155C17.5044 5.90744 17.4791 5.87655 17.4497 5.84988L17.3747 5.78322H17.333L14.0497 3.70822L10.9497 1.79155C10.878 1.73466 10.7959 1.69221 10.708 1.66655H10.6414C10.5669 1.65412 10.4908 1.65412 10.4164 1.66655H10.333C10.2362 1.68796 10.1434 1.72452 10.058 1.77488L3.83302 5.64988L3.75802 5.70822L3.68302 5.77488L3.59969 5.83322L3.55802 5.88322L3.50802 6.00822V6.08322V6.13322C3.49993 6.18848 3.49993 6.24462 3.50802 6.29988V13.5749C3.50774 13.7165 3.54355 13.8559 3.61208 13.9798C3.68061 14.1037 3.7796 14.2082 3.89969 14.2832L10.1497 18.1499L10.2747 18.1999H10.3414C10.4823 18.2446 10.6337 18.2446 10.7747 18.1999H10.8414L10.9664 18.1499L17.1664 14.3415C17.2865 14.2665 17.3854 14.1621 17.454 14.0381C17.5225 13.9142 17.5583 13.7748 17.558 13.6332V6.35822C17.558 6.35822 17.5747 6.29988 17.5747 6.26655ZM10.4997 3.47488L11.983 4.39155L7.32469 7.27488L5.83302 6.35822L10.4997 3.47488ZM9.66636 15.9749L5.08302 13.1749V7.84988L9.66636 10.6832V15.9749ZM10.4997 9.21655L8.90802 8.25822L13.5664 5.36655L15.1664 6.35822L10.4997 9.21655ZM15.9164 13.1499L11.333 15.9999V10.6832L15.9164 7.84988V13.1499Z"
        fill={fill}
      />
    </svg>
  );
};
