import { Button, Form, Modal, Select, Table } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  LanguageBaseDto,
  LanguageService,
  TranslationService,
} from "../../../api";
import { items } from "../../../Components/AdminLayout";
import { OriginalVariable } from "../../../Components/ButtonFilter";
import { languageList } from "../../../utils/languageList";
import { toIncludes } from "../../../utils/request";
import { TableFilters } from "../clients/components/TableFilters";

interface DataType {
  id: number;
  language: string;
  countryCode: string;
  translations: any;
}

export const Languages = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pages, setPages] = useState([0, 12]);
  const [totalValues, setTotalValues] = useState<number>(0);
  const [openModalAddLanguage, setOpenModalAddLanguage] = useState(false);
  const [allLanguages, setAllLanguages] = useState(languageList);
  const [countFilter, setCountFilter] = useState(0);
  const [filtersValue, setFiltersValue] = useState({});
  const [countryCodes, setCoutryCodes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [countryCodeChecked, setCountryCodeChecked] = useState<
    CheckboxValueType[]
  >([]);
  const [languagesChecked, setLanguagesChecked] = useState<CheckboxValueType[]>(
    []
  );

  const [selectedLanguage, setSelectedLanguage] = useState<{
    language: string;
    name: string;
  }>({
    language: "",
    name: "",
  });

  const fetchLanguages = (filters?: any) => {
    let data: any = [];
    LanguageService.findAllLanguageController(
      undefined,
      undefined,
      JSON.stringify(filters),
      undefined,
      undefined,
      toIncludes<LanguageBaseDto>({
        translations: true,
      })
    ).then((res) => {
      setTotalValues(res.totalCount);
      res.data.map((item: LanguageBaseDto) => {
        data.push({
          id: item.id,
          language: item.name,
          countryCode: item.metadata.languageCode,
          translations: item.translations,
        });
      });

      setCoutryCodes(
        res.data.map((el: any) => el?.metadata?.languageCode).flat()
      );
      setLanguages(res.data.map((el: any) => el.name).flat());
      setTableData(data);
      setIsLoading(false);
    });
  };

  // const filterCountry = () => {
  //   const languages: any = ISO6391.getLanguages(ISO6391.getAllCodes())
  //     .filter((l) => {
  //       return CountryLanguage.getCountries().filter((c) =>
  //         c.langCultureMs?.some((lc) => lc.langCultureName.includes(l.code))
  //       );
  //     })
  //     .map((res) => {
  //       return { value: res.code, label: res.name };
  //     });
  //   setAllLanguages(languages);
  // };

  useEffect(() => {
    setIsLoading(true);
    fetchLanguages();
    // filterCountry();
  }, []);

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setPages([pagination.current - 1, pagination.pageSize]);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      hidden: true,
    },
    {
      title: t("common.language"),
      dataIndex: "language",
      key: "language",
      render: (text) => {

        // const languageName = allLanguages.filter(
        //   (el) => el.value.split("-")[1] === text
        // );
        // return <span>{languageName[0].label}</span>;
        return <span>{text}</span>
      },
    },
    {
      title: t("common.code"),
      dataIndex: "countryCode",
      key: "countryCode",
      render: (text) => {
        return <span>{text.toUpperCase()}</span>;
      },
    },
    {
      title: t("common.translations"),
      dataIndex: "translations",
      key: "translations",
      render: (text, record) => {
        return (
          <Link to={`/admin/settings/languages/${record.id}/translations`}>
            <Button type="default">
              {t("dashboard.translations.goToAllTranslations")}
            </Button>
          </Link>
        );
      },
    },
  ];

  const onChangeInput = (column: string, value: string) => {
    let filters: OriginalVariable = filtersValue;
    setCountFilter(countFilter > 0 ? -1 : 0);
    if (column === "language") {
      if (value.length > 0) {
        setCountFilter(countFilter + 1);
        if (value.length > 1) {
          filters["name"] = { $containsIgnore: value };
        }
      } else {
        delete filters.name;
      }
      fetchLanguages(filters);
      setFiltersValue(filters);
    } else {
      if (value.length > 0) {
        setCountFilter(countFilter + 1);
        if (value.length > 1) {
          filters['metadata'] = {languageCode: { $containsIgnore: value }};
        }
      } else {
        delete filters.metadata.languageCode;
      }
      fetchLanguages(filters);
      setFiltersValue(filters);
    }
  };

  return (
    <div>
      <div className=" mb-4 flex justify-between">
        <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px]">
          {t("common.languages")}
        </div>
        <div>
          <Button
            size="middle"
            type="primary"
            className="mt-2 bg-blue-400 font-medium"
            onClick={() => setOpenModalAddLanguage(true)}
          >
            {t("dashboard.translations.addLanguage")}
          </Button>
        </div>
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
          background: "var(--neutral-white, #FFF)",
          boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
          height: "100%",
        }}
      >
        <div className="p-4">
          <TableFilters
            countFilter={countFilter}
            reset={() => setCountFilter(0)}
            filters={[
              {
                label: t('common.language'),
                type: "input",
                onChangeInput: (e) => onChangeInput("language", e.target.value),
              },
              {
                label: t('common.code'),
                type: "input",
                onChangeInput: (e) =>
                  onChangeInput("contryCode", e.target.value),
              },
            ]}
          ></TableFilters>
        </div>
        <Table
          columns={columns}
          onChange={handleChange}
          dataSource={tableData}
          loading={isLoading}
          pagination={{
            current: pages[0] + 1,
            pageSize: pages[1],
            total: totalValues,
          }}
        ></Table>
      </div>
      <Modal
        title={t("dashboard.translations.addLanguage")}
        open={openModalAddLanguage}
        onOk={() => {
          setOpenModalAddLanguage(false);
          LanguageService.createLanguageController({
            name: selectedLanguage?.language.split("-")[1],
            metadata: {
              languageCode: selectedLanguage?.language.split("-")[1],
            },
            translations: [],
          }).then((el) => {
            fetchLanguages();
          });
        }}
        onCancel={() => setOpenModalAddLanguage(false)}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value, option) => {
            const { label }: any = option;
            setSelectedLanguage({
              language: value,
              name: label,
            });
            // console.log(label);
            // const countries: any = CountryLanguage.getCountries().filter(
            //   (res) => {
            //     return res.langCultureMs?.some((el) =>
            //       el.displayName
            //         .toLocaleLowerCase()
            //         .includes(label.toLocaleLowerCase())
            //     );
            //   }
            // );

            // countries.map((res: { langCultureMs: any[] }) => {
            //   if (res.langCultureMs.length === 1) {
            //     setSelectedLanguage(
            //       res.langCultureMs[0]?.langCultureName.split("-")[0]
            //     );
            //   } else {
            //     if (res.langCultureMs.length > 1) {
            //       const test = res.langCultureMs.filter(
            //         (el: { langCultureName: string }) => {
            //           return (
            //             el.langCultureName.toLowerCase() ===
            //             `${value.toLowerCase()}-${value.toLowerCase()}`
            //           );
            //         }
            //       );
            //     }
            //   }
            // });
          }}
          className="w-full"
          options={allLanguages.filter((el) =>
            tableData.every(
              (item: any) => el.value.split("-")[1] !== item.countryCode
            )
          )}
        ></Select>
      </Modal>
    </div>
  );
};
