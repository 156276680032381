import { Avatar, Button, Col, Row, Table, Tag } from "antd";
import { useState } from "react";
import { ButtonFilter } from "../../../../Components/ButtonFilter";
import { Eye } from "../../../../Components/icons/Eye";
import { Filter } from "../../../../Components/icons/Filter";
import { Pencil } from "../../../../Components/icons/Pencil";
import { tagColorOrderStatus } from "../../../../utils/tagColorOrderStatus";

export const RecentOrders = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([0, 10]);

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;


  const dataSource = [
    {
      key: "1",
      orderId: "#302012",
      products: {
        name: "Handmade Pouch",
        sku: "302012",
        avatar: "",
      },
      date: "1 min ago",
      customer: {
        name: "John Bushmill",
        email: "JohnBushmill@gmail.com",
      },
      total: 120,
      amount: 320,
      payment: "Mastercard",
      status:'Processing',
    },
    {
      key: "2",
      orderId: "#302015",
      products: {
        name: "Smartwatch E2",
        sku: "302012",
        avatar: "",
      },
      total: 190,
      date: "5 hour ago",
      customer: {
        name: "John Bushmill",
        email: "JohnBushmill@gmail.com",
      },
      amount: 320,
      payment: "Mastercard",
      status: 'Delivered'
    },
  ];

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      sorter: (a: { orderId: string; }, b: { orderId: any; }) => a.orderId.localeCompare(b.orderId),
      render: (text: string) => {
        return (
          <div className="text-[#0950A8] font-[Inter] text-sm font-semibold leading-5 tracking-[0.07px]">
            {text}
          </div>
        );
      },
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      sorter: (a: { products: { name: string }; }, b: { products: { name: string} }) => a.products.name.localeCompare(b.products.name),
      render: (text: any) => {
        return (
          <Row>
            <Col span={5}>
              {" "}
              <Avatar shape="square" style={{ marginTop: 2 }}></Avatar>
            </Col>
            <Col span={18} offset={1}>
              <div className="text-[#333843] font-[Inter] text-sm font-medium leading-5 tracking-[0.07px]">
                {text.name}
              </div>
              <div className="text-[#667085] font-[Inter] text-sm font-normal leading-[18px] tracking-[0.07px]">
                SKU: {text.sku}
              </div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a: { date: string; }, b: { date: any; }) => a.date.localeCompare(b.date),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      // sorter: (a: { name: string; email: string }, b: { name: string; email: string }) => a.name.localeCompare(b.name),
      render: (text: { name: string; email: string }) => {
        return (
          <div>
            <div className="text-[#333843] font-[Inter] text-sm font-medium leading-5 tracking-[0.07px]">
              {text.name}
            </div>
            <div className="text-[#667085] font-[Inter] text-sm font-normal leading-[18px] tracking-[0.07px]">
              {text.email}
            </div>
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a: { total: number; }, b: { total: number; }) => a.total- b.total, 
      render: (text: number) => {
        return <span>$ {text}</span>;
      },
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      sorter: (a: { payment: string; }, b: { payment: any; }) => a.payment.localeCompare(b.payment),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: { status: string; }, b: { status: any; }) => a.status.localeCompare(b.status),
      render: (text: any) => {
        return (
          <Tag
            color={tagColorOrderStatus(text)?.tag}
            style={{
              borderRadius: 50,
              borderWidth: 0,
              fontWeight: "bold",
              color: tagColorOrderStatus(text)?.text,
            }}
            key={text}
          >
            <div className="capitalize">{text.toLocaleLowerCase()}</div>
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: () => {
        return (
          <div className="flex justify-start">
            <div>
              <Button type="text">
                <Eye></Eye>
              </Button>
            </div>
            <div>
              <Button type="text">
                <Pencil />
              </Button>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div
      style={{
        borderRadius: "8px",
        border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
        background: "var(--neutral-white, #FFF)",
        boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
        padding: 24,
        height: "100%",
      }}
    >
      <div className="flex justify-between">
        <div className="flex justify-start">
          <div className="text-[#333843] font-[Inter] text-lg leading-[18px] tracking-[0.09px]">
            Recent Orders
          </div>
          <div>
            <Tag
              style={{
                backgroundColor: "#E7F4EE",
                color: "#0D894F",
                borderWidth: 0,
              }}
              className="rounded-full ml-4"
            >
              + 2 Orders
            </Tag>
          </div>
        </div>
        <div className="flex">
          <div>
            {/* <Button className="bg-white  text-[#667085] text-sm leading-5 tracking-[0.07px] font-semibold border-[#667085]">
              <Row>
                <Filter fill="#667085" /> <span className="font-[Inter]">Filters</span> 
              </Row>
            </Button> */}
            <ButtonFilter
              showButton={true}
              showSelectedFilters={true}
            
              findProductsWithFilters={(v: any) => {
                //TODO: inserire la query quando avremo l'api
                console.log(v);
              }}
            ></ButtonFilter>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#0950A8",
                borderWidth: 0,
                marginLeft: 10,
                color: "white",
              }}
            >
              <span className="font-[Inter]">See more</span>
            </Button>
          </div>
        </div>
      </div>
      <Table
        // className="admin-table"
        style={{ marginTop: 20 }}
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 1 }}
        rowSelection={rowSelection}
      />
    </div>
  );
};
