import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import {
    AuthService,
    FavouriteService,
    GetFavouriteResponseDto,
    GetUserResponseDto,
    GetVariantResponseDto,
    OpenAPI,
    UserBaseDto,
    UserSectionPermissionBaseDto,
    VariantBaseDto,
    VariantService,
} from "../api";
import { SortOrder } from "antd/es/table/interface";

import { toFilter, toIncludes } from "../utils/request";

export const init: {
    isLogged: boolean;
} = {
    isLogged: false,
};

interface SortInfo {
    columnKey?: string;
    order?: SortOrder;
}

export const AuthContext = React.createContext<{
    login: (user: any) => any;
    getMe: () => void;
    // getCart: () => void;
    itemsWished: () => VariantBaseDto[] | undefined;
    retrieveWished: (
        userId?: number,
        sortedInfo?: SortInfo,
        variantId?: number
    ) => void;
    wished: GetVariantResponseDto[];
    token: string | undefined;
    currentUser: GetUserResponseDto | undefined;
    setCurrentUser: Dispatch<SetStateAction<GetUserResponseDto | undefined>>;
    logout: () => void;
    language: string;
    setLanguage: Dispatch<SetStateAction<string>>;
    permissions: Map<string, string[]>;
    totalWish: number;
    totalCartRows: number;
    setTotalCartRows: Dispatch<SetStateAction<number>>
}>({
    login(user: any) {
        return;
    },
    getMe() {
        return;
    },
    setCurrentUser() {
        return;
    },
    // getCart: () => undefined,
    token: "",
    language: "",
    itemsWished: () => undefined,
    retrieveWished: () => undefined,
    wished: [],
    permissions: new Map(),
    setLanguage() {
        return;
    },
    totalWish: 0,
    currentUser: undefined,
    logout() {
        return;
    },
    totalCartRows: 0,
    setTotalCartRows() {
        return;
    }
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
                                                                    children,
                                                                }) => {
    const [token, setToken] = useState<string | undefined>(undefined);
    const [currentUser, setCurrentUser] = useState<GetUserResponseDto | undefined>();
    const [language, setLanguage] = useState("");
    const [permissions, setPermissions] = useState(new Map());
    const [totalWish, setTotalWish] = useState(0);
    const [totalCartRows, setTotalCartRows] = useState(0);

    const [wished, setWished] = useState<GetVariantResponseDto[]>([]);
    
    const retrieveWished = (
        userId?: number,
        sortedInfo?: SortInfo,
        variantId?: number
    ) => {
        const field: any = sortedInfo?.columnKey;
        const order = sortedInfo?.order as SortOrder;
        const sortObject = {
            [field]: order === "ascend" ? "ASC" : "DESC",
        };

        const sortString = JSON.stringify({ variant: sortObject });

        if (userId || currentUser?.id) {
            FavouriteService.findAllFavouriteController(
                undefined,
                undefined,
                toFilter<GetFavouriteResponseDto>({
                    variant: {
                        id: variantId,
                    },
                    user: {
                        id: userId ? userId : currentUser?.id,
                    },
                }),
                sortedInfo?.columnKey
                    ? JSON.stringify({
                        variant: {
                            [sortedInfo.columnKey]:
                                sortedInfo.order === "ascend" ? "ASC" : "DESC",
                        },
                    })
                    : undefined,
                undefined,
                toIncludes<GetFavouriteResponseDto>({
                    variant: {
                        media: true,
                    },
                })
            ).then((f) => {
                setTotalWish(f.totalCount);
                const fetchData = async () => {
                    const variants: GetVariantResponseDto[] = [];
                    f.data.map((item: any) => {
                        variants.push({ ...item.variant, likeId: item.id });
                    });
                    setWished(variants);
                };

                fetchData();
            });
        }
    };

    const itemsWished = () => {
        return wished;
    };

    const getMe = () => {
        const userLanguage = localStorage.getItem("languageId");
        setLanguage(userLanguage ?? "");
        const localToken = localStorage.getItem("token");
        OpenAPI.HEADERS = {
            Authorization: `Bearer ${localToken}`,
        };
        AuthService.meAuthController(
            undefined,
            toIncludes<GetUserResponseDto>({
                company: {
                  gammaCustomer: true
                },
                contacts: true,
                addresses: true,
                defaultAddress: true,
                cart: {
                    rows: true,
                },
                roles: {
                    role: true,
                },
                permissions: {
                    section: true,
                },
            })
        )
            .then((f: any) => {
                let permissionsUser = new Map(permissions);
                setCurrentUser(f.data);
                retrieveWished(f.data.id);
                f.data.permissions.forEach((el: UserSectionPermissionBaseDto) => {
                    if (el.enable) {
                        if (!permissionsUser.get(el.permission)) {
                            permissionsUser.set(el.permission, []);
                        }
                        permissionsUser.get(el.permission).push(el.section.name);
                    }
                });
                setPermissions(permissionsUser);
            })
            .catch(() => {
                logout();
            });
    };

    useEffect(() => {
        getMe();
        retrieveWished();
    }, []);

    const login = async (user: any) => {
        return new Promise(async function(resolve) {
            let curUser;
            const token = user.data?.accessToken;
            localStorage.setItem("token", token);
            localStorage.setItem("languageId", "1");
            localStorage.setItem("i18nextLng", "it");
            OpenAPI.HEADERS = {
                Authorization: `Bearer ${token}`,
            };
            setToken(token);
            await AuthService.meAuthController(
                undefined,
                toIncludes<UserBaseDto>({
                    company: true,
                    contacts: true,
                    addresses: true,
                    cart: {
                        rows: true,
                    },
                    roles: {
                        role: true,
                    },
                })
            ).then((f: any) => {
                curUser = f.data;
                setCurrentUser(f.data);
                // setCartRows(f.data.cart.cartRows);
            });
            if (curUser) {
                resolve(curUser);
            }
        });
    };

    const logout = () => {
        setCurrentUser(undefined);
        setToken(undefined);
        localStorage.clear();
    };

    // render
    return (
        <AuthContext.Provider
            value={{
                login,
                token,
                currentUser,
                setCurrentUser,
                logout,
                language,
                setLanguage,
                getMe,
                itemsWished,
                retrieveWished,
                wished,
                permissions,
                totalWish,
                totalCartRows,
                setTotalCartRows
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
