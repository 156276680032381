import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

export const Privacy = () => {
  const {isMobile}= useBreakpoint()
  return (
    <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
      <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">Privacy & cookie policy</div>
      <div className="text-neutral-500 text-sm font-bold leading-4 tracking-[0.09px] mt-10">
        Titolo sezione
      </div>
      <div className="text-neutral-400 text-xs leading-4 tracking-[0.6px] mt-2">
        Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
        libero et velit interdum, ac aliquet odio mattis. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
        libero et velit interdum, ac aliquet odio mattis. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
      </div>
    </div>
  );
};
