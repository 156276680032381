export function toString(
  object: any,
  replacer?: (key: string, value: any) => any,
  space?: string,
) {
  return JSON.stringify(object, replacer, space);
}

/**
 * used to get a clone of an object and prevent access by ref,
 * if type is specified object will ereditate al function of the type
 *
 * class Person{
 *   public name:string;
 *   constructor(name){
 *      this.name = name;
 *   }
 *
 *   getName(){
 *       return this.name;
 *   }
 * }
 *
 * let jon = new Person('JON');
 * let anotherJon = clone<Person>(jon,Person);
 *
 * @param object source object to be cloned
 * @param constructor type if you want to cast the object and get all function of that, if is an Array just pass the constructor of the element in array es:
 *
 * let students:Array<Student>
 * to clone use the as clone<Array<Student>>(stdents,Student)
 */
export function clone<T>(object: any, constructor?: any): T {
  if (object) {
    const _type = (function () {
      if (Array.isArray(object)) {
        const first = object.find((f) => f != null) || {};
        constructor = constructor || first.constructor;
        return first.constructor;
      }
      return object.constructor;
    })();
    const tmp = JSON.parse(JSON.stringify(object));
    return castToObject(tmp, constructor || _type);
  }
  return object;
}

/**
 * set the value at path of a object, if is given constructor or a type, the setted value will be casted
 *
 * es
 *
 * class Car{
 *
 *     public owner:Person;
 * }
 *
 * class Person{
 *
 *   public name:string;
 *
 *   constructor(name){
 *      this.name = name;
 *   }
 *
 *   getName(){
 *       return this.name;
 *   }
 * }
 *
 * let obj = {};
 *
 * SetValueByPath(obj,'owner',new Person('JON'))
 * obj=> { owner : { name:'JON', getName:fn }}
 *
 * @param object object that will be manipulated
 * @param path path of property to assign  value
 * @param value
 * @param constructor constructor, class type, for cast object
 */
export function SetValueByPath<T, T2>(
  object: T,
  path: string,
  value: any,
  constructor?: (...args: any) => T2,
): T {
  if (path == null) return object;
  if (object == null) {
    object = {} as any;
  }
  const _path = path
    .replace(/\[(\w+)\]/g, '.[$1]')
    .split('.')
    .filter((f) => f != '');
  let tmp: any = object;

  for (let i = 0; i < _path.length; i++) {
    let key = _path[i];

    if (key.match(/\[(\w+)\]/g) != null) {
      key = key.replace(/\[(\w+)\]/g, '$1');
    }

    const isArray = (function () {
      if (i + 1 < _path.length) {
        return _path[i + 1].match(/\[(\w+)\]/g) != null;
      }
      return false;
    })();

    if (tmp[key] && i != _path.length - 1) tmp = tmp[key];
    else if (i != _path.length - 1) {
      tmp[key] = isArray ? tmp[key] || new Array<any>() : tmp[key] || {};
      tmp = tmp[key];
    } else {
      if (constructor && value) {
        value = castToObject(value, constructor);
      }
      tmp[key] = value;
    }
  }

  return object;
}

/**
 * By a given object, this function return the property specified by the path
 *
 * let obj = {
 *      a : 2,
 *      c : [{
 *              a:2
 *          },2,
 *          {c :
 *              {
 *                  c : 2
 *              }
 *           ]
 *          }
 *
 * let value = GetValueByPath(obj,'a');
 * value=>2
 * let value = GetValueByPath(obj,'c');
 * value => [...]
 * let value = GetValueByPath(obj,'c[0].a');
 * value => 2
 * @param object object that contain value
 * @param path full path of object
 */
export function GetValueByPath<T>(object: any, path: string): T {
  if (path == null) return object;
  const _path = path
    .replace(/\[(\w+)\]/g, '.$1')
    .split('.')
    .filter((f) => f != '');
  let res = object;
  for (const k of _path) {
    if (!res) return res;

    res = res[k];
  }
  return res;
}

/**
 * assign all property of element to a returned object, if assignTo is given
 * property would be setted to assignTo object
 *
 * es:
 *
 *
 * class Person{
 *   public name:string;
 *   getName(){
 *       return this.name;
 *   }
 * }
 *
 * let element = { name:'Jon'};
 *
 * element does not have property function getName(), element.getName() generate exception
 *
 * let person = assign<Person>(element,Person);
 *
 * person will be an object typed as Person, and getName will be setted correctly and return 'Jon'
 *
 * es.2
 *
 * let element = { name:'Jon'};
 *
 * let person:Person;
 *
 * assign<Person>(element,Person,person);
 *
 * person will be an object typed as Person, and getName will be setted correctly and return 'Jon'
 *
 * @param element source of data
 * @param type type of result, used to cast object returned
 * @param assignTo used to set property of element to another object
 */
export function assign<T>(element: any, type: any, assignTo?: T): T {
  let res: any = assignTo;
  res = castToObject(element, type, assignTo || res);
  return res;
}

/**
 * decorator to set a type of a object property
 * this would be useful to cast object element of an array cause Object.assign don't work
 *
 * @param constructor type definition of object
 * @param defaultValue set a default value to object
 */
export function setConstructor<T>(constructor: any, defaultValue?: T) {
  return function (target: any, propertyName: any) {
    let opt: PropertyDescriptor = {
      value: constructor,
      enumerable: false,
    };

    Object.defineProperty(target, 'constructor_' + propertyName, opt);

    opt = {
      value: target[propertyName] || defaultValue || new (<any>constructor)(),
      writable: true,
    };
    Object.defineProperty(target, propertyName, opt);

    opt = {
      get: function () {
        return constructor;
      },
      enumerable: false,
    };
    Object.defineProperty(target[propertyName], 'type', opt);
  };
}

/**
 * return  a T object casted from params type
 * @param data object to cast (source)
 * @param constructor
 * @param assignTo optional, if you would assign result to existing object
 */
export function castToObject<T>(
  data: any,
  constructor: any,
  assignTo?: any,
): T {
  const sType = function (target: any, type: any, propertyName?: string) {
    const opt: PropertyDescriptor = {
      get: function () {
        return type;
      },
      enumerable: false,
    };
    Object.defineProperty(
      propertyName ? target[propertyName] : target,
      'type',
      opt,
    );
  };
  if (Array.isArray(data)) {
    assignTo = new Array<any>();
    sType(assignTo, constructor);
  } else if (
    !(assignTo instanceof constructor) &&
    (typeof assignTo === 'object' || assignTo == null)
  ) {
    assignTo = typeof constructor == 'function' ? new constructor() : {};
  }

  for (const _prop in data) {
    const prop: any = _prop;
    if (Array.isArray(data)) {
      assignTo[prop] = castToObject(
        data[prop],
        (<any>assignTo).type || assignTo.constructor,
        null,
      );
    } else {
      const cl = (function () {
        if (assignTo[prop]) {
          return (
            assignTo['constructor_' + prop] ||
            assignTo[prop].type ||
            assignTo[prop].constructor
          );
        }
      })();
      const tmp = data[prop];

      if (!Array.isArray(tmp)) {
        if (typeof tmp == 'object' && cl && tmp != null) {
          assignTo[prop] = castToObject(data[prop], cl, assignTo[prop]);
        } else {
          assignTo[prop] = tmp;
        }
      } else {
        const arr = [];

        for (const el of tmp) {
          if (cl != null && !(new cl() instanceof Array)) {
            const _el = castToObject(el, cl, null);
            arr.push(_el);
          } else arr.push(el);
        }
        assignTo[prop] = arr;
      }
    }
  }
  if (typeof data !== 'object' && constructor != null) {
    return constructor(data);
  }
  return assignTo;
}
