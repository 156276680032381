/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PatchOrderRowRequestDto = {
    status: PatchOrderRowRequestDto.status;
};
export namespace PatchOrderRowRequestDto {
    export enum status {
        CONFIRMED = 'CONFIRMED',
        RETURNED = 'RETURNED',
        REFUND_FOR_NO_AVAILABILITY = 'REFUND_FOR_NO_AVAILABILITY',
        REFUND_FOR_RETURN_REQUEST = 'REFUND_FOR_RETURN_REQUEST',
    }
}

