import { FC } from "react";
import { IconPropsType } from "./types";

export const User: FC<IconPropsType> = (props, fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" {...props} {...fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 4.5C13.5 6.98528 11.4853 9 9 9C6.51472 9 4.5 6.98528 4.5 4.5C4.5 2.01472 6.51472 0 9 0C11.4853 0 13.5 2.01472 13.5 4.5ZM11.5 4.5C11.5 5.88071 10.3807 7 9 7C7.61929 7 6.5 5.88071 6.5 4.5C6.5 3.11929 7.61929 2 9 2C10.3807 2 11.5 3.11929 11.5 4.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16.9231C0 13.0996 3.09957 10 6.92308 10H11.0769C14.9004 10 18 13.0996 18 16.9231C18 18.0701 17.0701 19 15.9231 19H2.07692C0.929871 19 0 18.0701 0 16.9231ZM2 16.9231C2 14.2041 4.20414 12 6.92308 12H11.0769C13.7959 12 16 14.2041 16 16.9231C16 16.9656 15.9656 17 15.9231 17H2.07692C2.03444 17 2 16.9656 2 16.9231Z"
        fill={fill}
      />
    </svg>
  );
};
