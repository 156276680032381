import {useMemo, useRef, useState} from "react";
import {Select, Spin} from "antd";
import debounce from "lodash/debounce";

interface DebounceSelectProps<T> {
    fn: (value: string) => Promise<T>, //promise array T
    itemNameLabel: string[],
    itemValueLabel: string,
    debounceTimeout: number,
    onChange: (value: string) => void,
    defaultValue?: string
}

const DebounceSelect = ({
    fn,
    itemNameLabel,
    itemValueLabel,
    debounceTimeout,
    onChange,
    defaultValue
}: DebounceSelectProps<any>) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            
            setOptions([]);
            setFetching(true);
            
            fn(value).then((res: any) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                
                setOptions(res.map((item: any) => ({
                    label: itemNameLabel.map((label, index) => `${item[label]}${(itemNameLabel.length > 1 && index < itemNameLabel.length) ? " " : ""}`),
                    value: item[itemValueLabel]
                })));
                
                setFetching(false);
            });
        };
        
        return debounce(loadOptions, debounceTimeout);
    }, [fn, debounceTimeout]);
    
    return <Select defaultValue={defaultValue ?? undefined} onChange={onChange} allowClear={true} filterOption={false} showSearch={true} size={"middle"} onSearch={debounceFetcher} notFoundContent={fetching ? <Spin size="small" /> : null} options={options}/>
} 

export default DebounceSelect