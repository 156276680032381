/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateOrderRequestDto = {
    shippingAddress: number;
    credit?: number;
    /**
     * required for paypal
     */
    payload: Record<string, any> | null;
    paymentMethod: CreateOrderRequestDto.paymentMethod | null;
};
export namespace CreateOrderRequestDto {
    export enum paymentMethod {
        PAYPAL = 'PAYPAL',
        CREDIT = 'CREDIT',
    }
}

