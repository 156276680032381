import React, { useEffect, useState } from "react";
import { Button, notification, Select, TableProps } from "antd";
import { Form, Popconfirm, Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  GetOrderResponseDto,
  OrderRowService,
  PatchOrderRowRequestDto,
} from "../../../api";
import { numberWithCommasAndFixedDecimals } from "../../../utils/numberWithCommasAndFixedDecimals";

interface Item {
  key: string;
  productCode: string;
  variantCode: string;
  size: string;
  colors: string[];
  price: string;
  total: string;
  qty: number;
  status: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = (
    <Select
      options={[
        {
          value: PatchOrderRowRequestDto.status.CONFIRMED,
          label: PatchOrderRowRequestDto.status.CONFIRMED,
        },
      ]}
    ></Select>
  );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please select ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface Props {
  order: GetOrderResponseDto | undefined;
}

export const OrderRowsTable = ({ order }: Props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<Item[]>(() => {
    if (order && order.rows) {
      return order.rows.map((item) => ({
        key: item.id?.toString() ?? "",
        productCode: item.variant.product.code?.toString() ?? "",
        variantCode: item.variant.code ?? "",
        size: item.variant.size,
        colors: item.variant.colors,
        price: numberWithCommasAndFixedDecimals(item.price),
        qty: item.qty,
        total: numberWithCommasAndFixedDecimals(item.price * item.qty),
        status: order.status,
      }));
    } else {
      return [];
    }
  });

  const [notificationApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    let list: Item[] = [];
    if (order && order.rows) {
      order.rows.forEach((item) =>
        list.push({
          key: item.id?.toString() ?? "",
          productCode: item.variant.product.code?.toString() ?? "",
          variantCode: item.variant.code ?? "",
          size: item.variant.size,
          colors: item.variant.colors,
          price: numberWithCommasAndFixedDecimals(item.price),
          qty: item.qty,
          total: numberWithCommasAndFixedDecimals(item.price * item.qty),
          status: order.status,
        })
      );
    }
    setData(list);
  }, [order]);

  const [editingKey, setEditingKey] = useState("");
  const { t } = useTranslation();

  const isEditing = (record: Item) => record.key === editingKey;

  const editRow = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ status: "", ...record });
    setEditingKey(record.key);
  };

  const cancelEditing = () => {
    setEditingKey("");
  };

  const saveOrderRow = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    OrderRowService.updateOrderRowController(Number(key), {
      status: PatchOrderRowRequestDto.status.CONFIRMED,
    })
      .then((res) => {
        notificationApi.success({
          message: t("order.statusUpdated"),
          style: {
            borderRadius: "8px",
            border: "1px solid var(--200, #92D2B4)",
            background: "#F4FCF8",
            color: "#3DA172",
          },
        });
      })
      .catch((err) => {
        notificationApi.error({
          message:  t("order.statusNotUpdated"),
          style: {
            borderRadius: "8px",
            border: "1px solid var(--200, #bf3232)",
            background: "#fff0f0",
          },
        });
      });
  };

  const tableColumns = [
    {
      title: t("order.productCode"),
      dataIndex: "productCode",
      key: "productCode",
      editable: false,
    },
    {
      title: t("order.variantCode"),
      dataIndex: "variantCode",
      key: "variantCode",
      editable: false,
    },
    {
      title: t("common.size"),
      dataIndex: "size",
      key: "size",
      editable: false,
      render: (text: any, record: { stock: number }) => {
        return (
          <div
            style={{
              opacity: record.stock === 0 ? 0.5 : 1,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: t("common.color"),
      dataIndex: "color",
      key: "color",
      editable: false,
      render: (text: string, record: { stock: number }) => {
        return (
          <div
            style={{
              backgroundColor: text,
              border: text === "white" ? "0.5px solid grey" : "",
              opacity: record.stock === 0 ? 0.5 : 1,
            }}
            className="rounded-full bg-neutral-100 h-5 w-5 text-xs"
          ></div>
        );
      },
    },
    {
      title: t("common.price"),
      dataIndex: "price",
      editable: false,
      key: "price",
    },
    {
      title: t("common.quantity"),
      dataIndex: "qty",
      editable: false,
      key: "qty",
    },
    {
      title: t("common.total"),
      dataIndex: "total",
      editable: false,
      key: "total",
    },
    // {
    //   title: t("common.status"),
    //   dataIndex: "status",
    //   key: "status",
    //   editable: true,
    // },
    // {
    //   title: t("common.actions"),
    //   width: 400,
    //   render: (_: any, record: Item) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Button
    //           className="font-semibold"
    //           type="primary"
    //           onClick={() => saveOrderRow(record.key)}
    //         >
    //           {t("common.save")}
    //         </Button>
    //
    //         <Popconfirm
    //           title={t("clients.messages.sureToCancel")}
    //           onConfirm={cancelEditing}
    //           className="cursor-pointer"
    //         >
    //           <Button className="font-semibold text-blue-400" type="link">
    //             {t("common.cancel")}
    //           </Button>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <div className="flex justify-start">
    //         <Button
    //           className="p-0"
    //           type="text"
    //           disabled={editingKey !== ""}
    //           onClick={() => editRow(record)}
    //         >
    //           <div className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] text-center underline cursor-pointer">
    //             {t("common.edit")}
    //           </div>
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const mergedColumns: TableProps["columns"] = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
    <Form form={form} component={false}>
      <Table className="mt-6" components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered dataSource={data} columns={mergedColumns} rowClassName="editable-row" pagination={false}
      />
    </Form>
      {contextHolder}
     </>
  );
};
