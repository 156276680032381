import {GetUserResponseDto} from "../../api";
import {UserRole} from "../types";

export const useUserPermissions = (userPermissions: Map<string, string[]>, user: GetUserResponseDto | undefined) => {
  const canUser = (permission: string, section: string) => {
    if(user?.roles?.find(s => s.role.name === UserRole.ADMIN)) return true;
    if (userPermissions.has(permission)) {
      const sections = userPermissions?.get(permission);
      return sections?.includes(section);
    } else {
      return false;
    }
  };

  return { canUser };
};
