import { Button, Divider, Input, Modal } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthService } from "../api";
import { AuthContext } from "../contexts/authContext";

interface Props {
  openEditPasswordModal: boolean;
  setOpenEditPasswordModal: (openEditPasswordModal: boolean) => void;
  setShowMessage: (showMessage: boolean) => void;
}
export const EditPasswordModal = ({
  openEditPasswordModal,
  setOpenEditPasswordModal,
  setShowMessage,
}: Props) => {
  const { token } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { t } = useTranslation()
  return (
    <Modal
      cancelButtonProps={{
        hidden: true,
      }}
      onCancel={() => setOpenEditPasswordModal(!openEditPasswordModal)}
      okText={t("common.saveChanges")}
      onOk={() => {
        if (newPassword && confirmPassword) {
          AuthService.updatePasswordAuthController({
            confirmPassword: confirmPassword,
            password: newPassword,
            token: token ?? "",
          }).then((res: any) => {
            if (res) {
              setOpenEditPasswordModal(!openEditPasswordModal);
              setShowMessage(true);
            }
          });
        }
      }}
      okButtonProps={{
        block: true,
        size: "large",
        style: {
          backgroundColor: "#0950A8",
          fontSize: 12,
          fontWeight: 600,
          lineHeight: "18px",
          letterSpacing: "0.06px",
          padding: 10,
        },
      }}
      width={"400px"}
      title={
        <div>
          <div className="text-blue-400 text-[16px] font-semibold leading-6 tracking-[0.06px]">
            {t("profile.editPassword")}
          </div>
          <div className="mt-2 text-neutral-300 text-[10px] font-semibold leading-4 tracking-[0.05px]">
            {t("profile.changePassword")}
          </div>
        </div>
      }
      open={openEditPasswordModal}
    >
      <div className="mt-4">
        <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
          {t("profile.oldPassword")}
        </span>
        <Input.Password
          onChange={(e) => setOldPassword(e.target.value)}
          size="large"
          className="mt-1"
        />
        <Divider />
        <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
          {t("profile.newPassword")}
        </span>
        <Input.Password
          size="large"
          onChange={(e) => setNewPassword(e.target.value)}
          className="mt-1"
        />
        <div className="mt-3">
          <span className=" text-xs font-semibold leading-4 tracking-[0.06px]">
            {t('profile.confirmNewPassword')}
          </span>
          <Input.Password
            size="large"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="mt-1"
          />
        </div>
      </div>
    </Modal>
  );
};
