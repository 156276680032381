import { Col, Row } from "antd";

import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translationCategoriesValue } from "../../../../utils/translationValue";
import { useBreakpoint } from "../../../../utils/hooks/useBreakpoint";
import {DEFAULT_FALLBACK} from "../../../../App";

interface Props {
  categories: {
    id: number;
    name: string;
    image: string;
    translations: any[];
  }[];
}

export const Categories = ({ categories }: Props) => {
  const { t } = useTranslation();
  const history = useNavigate();

  const { isMobile } = useBreakpoint();

  const handleClick = (item: { id: number; name: string }) => {
    const queryParams = queryString.stringify({
      category: item.id,
    });
    history(`/products?${queryParams}`);
  };

  return (
    <div className="bg-neutral-100 w-full pt-4 pb-4 md:p-4 md:pt-8 md:pb-16">
      <div className="text-[#02296C] text-xl font-semibold leading-[30px] tracking-[0.1px] text-center mb-4">
        {t("home.categories")}
      </div>
      <Row
        gutter={isMobile ? 0 : 20}
        className={isMobile ? "" : "pl-8 pr-8"}
        justify="center"
        align="middle"
      >
        {categories.map((res) => {
          return (
            <Col
              key={res.name}
              onClick={() => handleClick(res)}
              className={`cursor-pointer mt-4`}
            >
              {/* <img
                className="rounded-lg w-60"
                alt={res.name}
                src={res.image ?? defaultImage}
                onError={(err) => console.log(err)}
              ></img> */}
              <div
                style={{
                  backgroundImage: `url(${res.image}), url(${DEFAULT_FALLBACK})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  aspectRatio: "1 / 1",
                  display: "block",
                  minHeight: "100%",
                }}
                className={`mb-2 rounded-lg ${isMobile ? 'w-[21rem]' : 'w-60'}`  }
              ></div>
              <div className="text-blue-500 text-center text-sm font-semibold leading-5 tracking-[0.07px] mt-2 cursor-pointer">
                {translationCategoriesValue(res.translations)
                  ? translationCategoriesValue(res.translations)
                  : res.name}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
