import { FC } from "react";
import { IconPropsType } from "./types";

export const Cart: FC<IconPropsType> = (props, fill) => {
  return (
    <svg height={20} width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props} {...fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.638 2.12231C4.45691 1.18063 3.63292 0.5 2.67398 0.5H1C0.447716 0.5 0 0.947715 0 1.5C0 2.05228 0.447716 2.5 1 2.5L2.67398 2.5L4.55002 12.2554C4.91221 14.1388 6.56018 15.5 8.47805 15.5H14.6873C16.5044 15.5 18.0932 14.2752 18.5556 12.518L19.8068 7.76348C20.3074 5.86122 18.8726 4 16.9056 4H4.99909L4.638 2.12231ZM5.38371 6L6.51403 11.8777C6.69513 12.8194 7.51911 13.5 8.47805 13.5H14.6873C15.5959 13.5 16.3903 12.8876 16.6215 12.009L17.8727 7.25449C18.0395 6.62041 17.5613 6 16.9056 6H5.38371Z"
        fill={fill ? fill : "#0950A8"}
      />
      <path
        d="M6.74997 19.5C5.92154 19.5 5.24997 18.8284 5.24997 18C5.24997 17.1716 5.92154 16.5 6.74997 16.5C7.5784 16.5 8.24997 17.1716 8.24997 18C8.24997 18.8284 7.5784 19.5 6.74997 19.5Z"
        fill={fill ? fill : "#0950A8"}
      />
      <path
        d="M15.5 19.5C14.6715 19.5 14 18.8284 14 18C14 17.1716 14.6715 16.5 15.5 16.5C16.3284 16.5 17 17.1716 17 18C17 18.8284 16.3284 19.5 15.5 19.5Z"
       fill={fill ? fill : "#0950A8"}
      />
    </svg>
  );
};
