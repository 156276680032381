import { Breadcrumb, Button, Checkbox, Col, Divider, Radio, Row, Steps } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartCard } from "../../../Components/CartCard";
import { Cube } from "../../../Components/icons/Cube";

export const ReturnSummary = () => {
    const navigate = useNavigate()
    const [delivery, setDelivery] = useState({
        pickUpPoint: false,
        company: false
    })
  const styleStepPassed =
    "w-[36px] h-[36px] bg-blue-100 rounded-full text-center";
  const stylestepNotPassed =
    "w-[36px] h-[36px] rounded-full text-center border-neutral-200 bg-white border-[1px] border-solid";

  const items = [
    {
      title: (
        <span className=" text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          {t('return.chooseArticles')}
        </span>
      ),
    },
    {
      title: (
        <span className="text-blue-400 text-xs font-medium leading-4 tracking-[0.06px]">
          {t('return.return')}
        </span>
      ),
      icon: (
        <div className={styleStepPassed}>
          <Cube fill={"#0950A8"} style={{ marginTop: "8px" }} />
        </div>
      ),
    },
    {
      title: (
        <span className=" text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px]">
          {t('return.confirm')}
        </span>
      ),
      icon: (
        <div className={stylestepNotPassed}>
          <Cube fill={"#DBDBDB"} style={{ marginTop: "8px" }} />
        </div>
      ),
    },
  ];
  return (
    <div className="p-7 ml-14 mr-14">
      <Breadcrumb
        separator=">"
        items={[
          {
            title: t('menu.account'),
          },
          {
            title:  t('menu.orders'),
          },
          {
            title: "numero ordine", //TODO: inserire id dell'ordine
          },
          {
            title: t('menu.returns'),
          },
        ]}
      />
      <div className="text-blue-400 text-lg font-bold leading-7 tracking-[0.09px] mt-4">
       {t('return.summaryReturn')}
      </div>
      <div className="mt-4 pl-44 pr-44">
        <Steps
          className="steps-summary-custom"
          current={2}
          labelPlacement="vertical"
          items={items}
        />
      </div>
      <Row>
        <Col span={11} className="mt-8">
          <div className="text-blue-400 text-sm font-semibold leading-5 tracking-[0.07px]">
            {t('return.selectedProduct')}
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
            <Row>
              <Col span={2}>
                <Checkbox className="mt-5" checked={true} />
              </Col>
               <Col span={22}>
                <CartCard
                id={1}
                  showColor={false}
                  showQuantity={false}
                  showSize={false}
                  showTrashButton={false}
                  name={"Copripiumino due piazze"}
                  brand="Iclub"
                  colors={[]}
                  size="XL"
                  price={230}
                  quantity={20}
                  changeImageSpan={4}
                productId={0}
                />
              </Col> 
            </Row>
            <Divider style={{ margin: "8px" }} />
          </div>
        </Col>
        <Col span={11} offset={2} className="mt-8">
          <div className="text-blue-400 text-sm font-semibold leading-5 tracking-[0.07px]">
           {t('return.packageDelivery')}
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
            <div className="mt-2">
              <Radio checked={delivery.company} onChange={() => {
                setDelivery({
                    company: true,
                    pickUpPoint: false
                })
              }}></Radio>
              <div className="mt-3">
                <div className="font-semibold text-sm text-neutral-500 leading-5 tracking-[0.07px]">
                 {t('return.farmDelivery')}
                </div>
                <div className="text-sm text-neutral-400 leading-5 tracking-[0.07px]">
                 {t('return.yourReturnWillBePickUp')}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
            <div className="mt-2">
              <Radio checked={delivery.pickUpPoint} onChange={() => {
                setDelivery({
                    company: false,
                    pickUpPoint: true
                })
              }}></Radio>
              <div className="mt-3">
                <div className="font-semibold text-sm text-neutral-500 leading-5 tracking-[0.07px]">
                 {t('return.deliveryPoint')}
                </div>
                <div className="text-sm text-neutral-400 leading-5 tracking-[0.07px]">
                {t('return.deliveryInAPoint')} '{"nome corriere"}'
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #DBDBDB)",
              padding: "16px",
            }}
            className="mt-4"
          >
            <Button onClick={() => navigate('/profile/orders/1/return-created')} block type="primary" className="bg-blue-400 text-xs font-semibold leading-5 tracking-[0.06px]">Crea reso</Button>
            <Button block type="text" className="mt-2 text-blue-400 font-semibold leading-4 tracking-[0.06px]">Annulla</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
