import {Alert, Button, Divider, Input, Modal, notification, Select} from "antd";
import {useContext, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {AuthService, TicketBaseDto, TicketReplyService, TicketService, TicketTypeBaseDto, UserBaseDto} from "../../api";
import { AuthContext } from "../../contexts/authContext";

interface Props {
  ticket: TicketBaseDto | null;
  isOpen: boolean;
  onClose: () => void,
  onSuccess: () => void,
  onFailure: (message: string) => void,
}

export const AssignTicketModal = ({
    ticket,
    isOpen,
    onSuccess,
    onFailure,
    onClose,
}: Props) => {

  const { t } = useTranslation()
    
  const confirmTicketAssignment = async () => {
    
    if(!ticket) return;
    
    await TicketService.takeTicketController(ticket.id!);

    onSuccess();
  }

  return (
      <Modal
          okText={t("common.confirm")}
          onOk={confirmTicketAssignment}
          okButtonProps={{
            block: false,
            size: "large",
            style: {
              backgroundColor: "#0950A8",
              fontSize: 12,
              fontWeight: 600,
              lineHeight: "18px",
              letterSpacing: "0.06px",
              padding: 10,
            },
          }}
          cancelText={t("common.cancel")}
          onCancel={onClose}
          cancelButtonProps={{
            block: false,
            size: "large",
            style: {
              backgroundColor: "#eeeeee",
              fontSize: 12,
              fontWeight: 600,
              lineHeight: "18px",
              letterSpacing: "0.06px",
              padding: 10,
            },
          }}
          width={"600px"}
          title={
            <div className="text-blue-400 text-[16px] font-semibold leading-6 tracking-[0.06px]">
              {t("dashboard.tickets.assignTicketModal.title")}
            </div>
          }
          open={isOpen}
      >
        <div className="mt-4">
          <p className={"text-neutral-300 text-sm leading-4 tracking-[0.05px]"}>{ticket?.inCharge ? t("dashboard.tickets.assignTicketModal.message", {inCharge: `${ticket.inCharge.name} ${ticket.inCharge.surname}`}) : t("dashboard.tickets.assignTicketModal.messageAlt")}</p>
        </div>
      </Modal>
  );
};
