import {Button, notification} from "antd";
import { useTranslation } from "react-i18next";
import { InfoLine } from "../../../Components/InfoLine";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
import {DeleteFilled, PlusOutlined, EditFilled, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import {ContactBaseDto, ContactService} from "../../../api";
import {AuthContext} from "../../../contexts/authContext";
import {toFilter} from "../../../utils/request";
import {DeleteContactModal} from "../../../Components/contacts/DeleteContactModal";

const validateEmail = (value: string) => {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
}

export const Contacts = () => {
    const { t } = useTranslation();
    const { isMobile } = useBreakpoint();

    const { currentUser } = useContext(AuthContext);
    const [api, contextHolder] = notification.useNotification();

    const [contacts, setContacts] = useState<ContactBaseDto[]>([]);
    const [selectedContact, setSelectedContact] = useState<ContactBaseDto | null>(null);
    const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);

    const getContactList = () => {
        ContactService.findAllContactController(
            undefined,
            undefined,
            toFilter<ContactBaseDto>({user: {id: currentUser?.id}}),
        ).then((res) => {
            if(!res.data) api.warning({message: "Si è verificato un errore durante il recupero dei contatti, riprova"});
            setContacts(res.data);
        })
    }

    useEffect(() => {
        getContactList();
    }, []);
    
    const addNewContact = () => {
        
        const newContact: ContactBaseDto = {
            name: "",
            email: "",
            phone: "",
            user: currentUser!
        }
        
        setContacts((contacts) => [
            ...contacts,
            newContact
        ]);
        
        setSelectedContact(newContact);
    }
    
    const cancelNewContactInsert = () => {
        setSelectedContact(null);
        setContacts((contacts) => contacts.filter((contact) => contact.id));
    }
    
    const updateSelectedContact = async () => {
        if(!selectedContact) return;
        
        if(!selectedContact.name) return api.warning({message: t("contacts.validationErrors.aliasRequired")});
        if(!selectedContact.phone && !selectedContact.email) return api.warning({message: t("contacts.validationErrors.emailOrPhoneRequired")});
        
        if (!validateEmail(selectedContact.email)) return api.warning({message: t("contacts.validationErrors.invalidEmail")})
        
        if(selectedContact.id){
            await ContactService.updateContactController(selectedContact.id!, selectedContact);
        }else{
            await ContactService.createContactController(selectedContact);
        }
        
        setSelectedContact(null);
        api.success({message: (selectedContact.id ? t("contacts.updateSuccess") : t("contacts.insertSuccess"))});
        getContactList();
    }
    
    const handleContactEditing = (name: string, value: string) => {
        if(!selectedContact) return;
        setSelectedContact((contact) => ({
            ...contact!,
            [name]: value,
        }));
    }
    
    const handleContactDelete = (selectedContact: ContactBaseDto) => {
        if(!selectedContact) return;
        setSelectedContact(selectedContact);
        setShowDeleteContactModal(true);
    }
    
    const deleteContact = async () => {
        if(!selectedContact) return;
        
        await ContactService.deleteContactController(selectedContact.id!);
        
        api.success({message: t("contacts.deleteSuccess")});
        
        setSelectedContact(null);
        setShowDeleteContactModal(false);
        
        getContactList();
    }

    return (
        <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
            <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
                <div className={"flex space-x-3 items-center justify-between"}>
                    {t("menu.contacts")}
                    <Button type={"primary"} icon={<PlusOutlined/>} onClick={addNewContact}>Aggiungi Contatto</Button>
                </div>
            </div>
            <div className={"mt-10"}>
                {contacts && (
                    <div className={"flex flex-col space-y-7"}>
                        {contacts.map((contact, index) => {
                            const isEditing = (selectedContact && selectedContact.id === contact.id) ?? false;
                            return (
                                <div key={`contact_${index}`} className={"flex flex-col space-y-5"}>
                                    <div className="text-blue-400 text-sm font-bold leading-5 tracking-[0.07px]">Contatto #{index +1}</div>
                                    <div className={"flex flex-col space-y-5 space-x-0 2xl:flex-row 2xl:space-x-10 2xl:space-y-0 items-center justify-evenly w-full"}>
                                        <div className={"flex flex-col space-y-5 space-x-0 2xl:flex-row 2xl:space-x-10 2xl:space-y-0 items-start 2xl:items-center justify-evenly w-full"}>
                                            <div className={"w-full 2xl:flex-1"}>
                                                <InfoLine item={contact.name} inputPlaceholder={"Alias contatto"} edit={isEditing} setEditItem={handleContactEditing.bind(this, "name")} label="Alias"></InfoLine>
                                            </div>
                                            <div className={"w-full 2xl:flex-1"}>
                                                <InfoLine inputType={"email"} inputPlaceholder={"mario.rossi@me.com"} item={contact.email} edit={isEditing} setEditItem={handleContactEditing.bind(this, "email")} label="Indirizzo E-Mail"></InfoLine>
                                            </div>
                                            <div className={"w-full 2xl:flex-1"}>
                                                <InfoLine inputType={"tel"} inputPlaceholder={"+39 111 222 3333"} item={contact.phone} edit={isEditing} setEditItem={handleContactEditing.bind(this, "phone")} label="Telefono"></InfoLine>
                                            </div>
                                        </div>
                                        <div className={"flex space-x-3 items-center"}>
                                            {isEditing ? <Button type={"primary"} color={"success"} icon={<CheckOutlined/>} onClick={updateSelectedContact} /> : <Button icon={<EditFilled/>} onClick={setSelectedContact.bind(this, contact)} />}
                                            {contact.id && <Button danger icon={<DeleteFilled/>} onClick={handleContactDelete.bind(this, contact)}/>}
                                            {!contact.id && <Button danger icon={<CloseOutlined/>} onClick={cancelNewContactInsert}/>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            
            <DeleteContactModal isOpen={showDeleteContactModal} onConfirm={deleteContact} onClose={setShowDeleteContactModal.bind(this, false)} />
            
            {contextHolder}
        </div>
    );
};