import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/authContext";
import { useWish } from "../utils/hooks/useWish";

interface Props {
  data: any;
  setOpenLoginModal: (value: boolean) => void;
  isFavoriteTable: boolean;
  onChange?: () => void
}

export const HeartButton = ({
  data,
  setOpenLoginModal,
  onChange
}: Props) => {
  const { currentUser, retrieveWished } = useContext(AuthContext);
  const { deleteFromWish, addToFavorite } = useWish();
  const [addedItemToFavorite, setAddedItemToFavorite] = useState(!!(data && data.likeId));
  const [loading, setLoading] = useState(false);

  const handleHeartButtonClick = async () => {
    if (!currentUser) {
      setOpenLoginModal(true);
      return;
    }

    setLoading(true);
    try {
      
      if (addedItemToFavorite) {
        if (data.likeId) {
          await deleteFromWish(data.key, data.likeId);
          setAddedItemToFavorite(false);
          retrieveWished()
        }
      } else {
        await addToFavorite(data.key, currentUser);
        setAddedItemToFavorite(true);
        retrieveWished()
      }
      
      onChange && onChange()
    } catch (error) {
      console.error(
        "Errore durante l'aggiunta/rimozione dai preferiti:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="w-full flex justify-center cursor-pointer"
      onClick={handleHeartButtonClick}
    >
      {loading ? (
        <Spin />
      ) : addedItemToFavorite ? (
        <HeartFilled style={{ color: "#002655", fontSize: 20 }} />
      ) : (
        <HeartOutlined style={{ color: "#1C4FA7", fontSize: 20 }} />
      )}
    </div>
  );
};
