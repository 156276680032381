import { Space } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VariantBaseDto, VariantService } from "../../../api";
import { CheckboxFilter } from "../../../Components/CheckboxFilter";
import { RangeInputFilter } from "../../../Components/RangeInputFilter";

export const labelStyle = "font-bold text-neutral-300 mb-0";

interface Props {
  selectedFilters?: any[];
  setSelectedFilters?: (f: any) => void;
  setPriceFilter?: Dispatch<
    SetStateAction<{ price: { $gte: number; $lte: number } }>
  >;
  findProductsWithFilters: Function;
  setFilters?: (f: any) => void;
  filters: { variants: any[] };
}

export const Filters = ({
  setSelectedFilters,
  setPriceFilter,
  findProductsWithFilters,
  selectedFilters,
  setFilters,
  filters,
}: Props) => {
  const { t } = useTranslation()
  const [variantValues, setVariantValues] = useState<VariantBaseDto[]>();
  const [priceState, setPrice] = useState({
    price: {
      $gte: 0,
      $lte: 0,
    },
  });
  const [sizeChecked, setSizeChecked] = useState<CheckboxValueType[]>([]);
  const [colorChecked, setColorChecked] = useState<CheckboxValueType[]>([]);
  const [countFilter, setCountFilter] = useState(0);
  const [sizeFilterCount, setSizeFilterCount] = useState(0);
  const [colorFilterCount, setColorFilterCount] = useState(0);
  const [priceFilterCount, setPriceFilterCount] = useState(0);

  useEffect(() => {
    VariantService.findAllVariantController().then((res) => {
      setVariantValues(res.data);
    });
  }, []);

  useEffect(() => {
    setCountFilter(sizeFilterCount + colorFilterCount + priceFilterCount);
  }, [sizeFilterCount, colorFilterCount, priceFilterCount]);

  const sizeValues = () => {
    let array: any = [];
    variantValues?.map((res) => {
      return array.push(res.size);
    });

    return array
      .filter((value: any, index: any) => array.indexOf(value) === index)
      .map((res: any) => {
        return { value: res, label: res };
      });
  };

  const colorValues = () => {
    let array: any = [];
    variantValues?.map((res) => {
      return array.push(...res.colors);
    });

    return array
      .filter((value: any, index: any) => array.indexOf(value) === index)
      .map((res: any) => {
        return { value: res, label: res };
      });
  };

  useEffect(() => {

    const sizes: SetStateAction<CheckboxValueType[]> = [];
    const colors: SetStateAction<CheckboxValueType[]> = [];
    sizeChecked.map((size) => {
      selectedFilters?.map((fil) => {
        if (size === fil) {
          sizes.push(size);
        }
      });
    });

    colorChecked.map((color) => {
      selectedFilters?.map((fil) => {
        if (color === fil) {
          colors.push(color);
        }
      });
    });

    setColorChecked(colors);
    setSizeChecked(sizes);

    const sizeFilter = sizes?.map((res) => {
      return {
        size: { $containsIgnore: res },
      };
    });

    const colorFilters = colors.map((res) => {
      return {
        color: { $containsIgnore: res },
      };
    });

    findProductsWithFilters({
      variants: [...sizeFilter, ...colorFilters ],
    });
  }, [ selectedFilters?.length]);

  return (
    <div className={`w-full`}>
      <Space direction="vertical" className={`w-full`}>
        <div className="flex justify-between">
          <div>
            <p className="font-xs font-medium">
              {t('filters.appliedFilters')}: {countFilter}
            </p>
          </div>
          <div>
            <p
              className="cursor-pointer underline text-neutral-300 font-xs"
              onClick={() => {
                setSizeChecked([]);
                setColorChecked([]);
                setCountFilter(0);
                setFilters &&
                  setFilters({
                    variants: [],
                  });
                setPrice({
                  price: {
                    $lte: 0,
                    $gte: 0,
                  },
                });
                setSelectedFilters && setSelectedFilters([]);
              }}
            >
              X {t('filters.reset')}
            </p>
          </div>
        </div>
        <CheckboxFilter
          itemChecked={sizeChecked}
          items={sizeValues()}
          onChange={(checkedValues: CheckboxValueType[]) => {
            setSizeFilterCount(checkedValues.length);
            setSizeChecked(checkedValues);
            setSelectedFilters && setSelectedFilters([]);
            const sizes = sizeValues().map((res: any) => {
              return res.value;
            });
            const notSizes = selectedFilters?.filter((el) => {
              return !sizes.includes(el);
            });

            const all = notSizes?.concat(checkedValues);

            setSelectedFilters && setSelectedFilters(all);

            const sizeFilter = checkedValues?.map((res) => {
              return {
                size: { $containsIgnore: res },
              };
            });
            setFilters &&
              setFilters({
                variants: [
                  ...filters.variants.filter((el: VariantBaseDto) => !el.size),
                  ...sizeFilter,
                ],
              });
            findProductsWithFilters({
              variants: [
                ...filters.variants.filter((el: VariantBaseDto) => !el.size),
                ...sizeFilter,
              ],
            });
          }}
          labelStyle={labelStyle}
          label={t('common.size')}
        />
        <RangeInputFilter
          label={t('common.price')}
          labelStyle={labelStyle}
          firstInput={{
            value: priceState.price.$gte,
            onChange: (value: any) => {
              setPriceFilter &&
                setPriceFilter({
                  price: {
                    $gte: Number(value),
                    $lte: priceState.price.$lte,
                  },
                });
              // setSelectedFilters && setSelectedFilters(Number(value));
              setPrice({
                price: {
                  $gte: Number(value),
                  $lte: priceState.price.$lte,
                },
              });
              setPriceFilterCount(1);
              setFilters &&
                setFilters({
                  variants: [
                    ...filters.variants.filter((el: any) => !el.price),
                    priceState,
                  ],
                });
              setTimeout(
                () =>
                  findProductsWithFilters({
                    variants: [
                      ...filters.variants.filter((el: any) => !el.price),
                      {
                        price: {
                          $gte: Number(value),
                          $lte: priceState.price.$lte,
                        },
                      },
                    ],
                  }),
                500
              );
            },
            min: 0,
            placeholder: t('common.min'),
            prefix: "€",
          }}
          secondInput={{
            value: priceState.price.$lte,
            onChange: (value: any) => {
              //   setSelectedFilters && setSelectedFilters(Number(value));
              setPriceFilter &&
                setPriceFilter({
                  price: {
                    $lte: Number(value),
                    $gte: priceState.price.$gte,
                  },
                });
              setPrice({
                price: {
                  $lte: Number(value),
                  $gte: priceState.price.$gte,
                },
              });
              setPriceFilterCount(1);
              setFilters &&
                setFilters({
                  variants: [
                    ...filters.variants.filter((el: any) => !el.price),
                    priceState,
                  ],
                });
              setTimeout(
                () =>
                  findProductsWithFilters({
                    variants: [
                      ...filters.variants.filter((el: any) => !el.price),
                      {
                        price: {
                          $lte: Number(value),
                          $gte: priceState.price.$gte,
                        },
                      },
                    ],
                  }),
                500
              );
            },
            min: 0,
            placeholder: t('common.max'),
            prefix: "€",
          }}
        />
        <CheckboxFilter
          itemChecked={colorChecked}
          items={colorValues()}
          onChange={(checkedValues: CheckboxValueType[]) => {
            setColorFilterCount(checkedValues.length);
            setColorChecked(checkedValues);
            setSelectedFilters && setSelectedFilters([]);
            const colors = colorValues().map((res: any) => {
              return res.value;
            });
            const notColors = selectedFilters?.filter((el) => {
              return !colors.includes(el);
            });

            const all = notColors?.concat(checkedValues);
            setSelectedFilters && setSelectedFilters(all);

            const colorFilters = checkedValues.map((res) => {
              return {
                color: { $containsIgnore: res },
              };
            });
            setFilters &&
              setFilters({
                variants: [
                  ...filters.variants.filter((el: VariantBaseDto) => !el.colors),
                  ...colorFilters,
                ],
              });
            findProductsWithFilters({
              variants: [
                ...filters.variants.filter((el: VariantBaseDto) => !el.colors),
                ...colorFilters,
              ],
            });
          }}
          labelStyle={labelStyle}
          label={t('common.color')}
        />
      </Space>
    </div>
  );
};
