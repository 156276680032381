import {Button, Divider, notification} from "antd";
import {useNavigate} from "react-router-dom";
import {PayPalScriptProvider, usePayPalScriptReducer,} from "@paypal/react-paypal-js";
import {CreateOrderResponseDto, OrderService,} from "../api";
import {useContext} from "react";
import {numberWithCommasAndFixedDecimals} from "../utils/numberWithCommasAndFixedDecimals";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {AuthContext} from "../contexts/authContext";
import {LoadingStateContext} from "../contexts/loadingStateContext";

interface Props {
  totalProducts: number | undefined;
  totalDiscount: number | undefined;
  shippingCosts: number | undefined;
  vat: number | undefined;
  credits: number;
  title: string;
  showPaymentButton?: boolean;
  paymentType?: string;
  deliveryDate?: string;
  addressId?: number;
}

let order: CreateOrderResponseDto | undefined = undefined;

export const PaymentSummary = ({
                                 totalProducts,
                                 totalDiscount,
                                 credits,
                                 title,
                                 showPaymentButton = true,
                                 paymentType,
                                 deliveryDate,
                                 addressId,
                                 vat,
                                 shippingCosts
                               }: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {currentUser} = useContext(AuthContext);
  const style = {layout: "vertical"};
  const [notificationApi, contextHolder] = notification.useNotification();

  const {toggleSpinner} = useContext(LoadingStateContext);

  const createOrder = async (data: any, actions: any) => {

    if (addressId || currentUser?.defaultAddress?.id) {

      toggleSpinner(true, t("cart.validatingOrder"));

      const orderApi: CreateOrderResponseDto = await OrderService.createOrderController(
        {
          payload: {},
          paymentMethod: "PAYPAL" as any,
          shippingAddress: addressId ?? currentUser?.defaultAddress?.id!,
        }
      ).then((res) => {
        order = res.data;
        return res.data;
      }).catch(x => {
        if (x.body.errors.message === 'ADDRESS_NOT_FOUND') {
          notificationApi.error({
            message: t("order.create.addressNotFound"),
            style: {
              borderRadius: "8px",
              border: "1px solid var(--200, #bf3232)",
              background: "#fff0f0",
            },
          });
        } else if (x.body.errors.message === 'ADDRESS_NOT_VALID') {
          notificationApi.error({
            message: t("order.create.addressNotValid"),
            style: {
              borderRadius: "8px",
              border: "1px solid var(--200, #bf3232)",
              background: "#fff0f0",
            },
          });
        } else
          notificationApi.error({
            message: t("order.create.error"),
            style: {
              borderRadius: "8px",
              border: "1px solid var(--200, #bf3232)",
              background: "#fff0f0",
            },
          });
        toggleSpinner(false)
        throw x;
      });
      if (paymentType === 'Paypal') {
        const link = orderApi.transaction.payload.links
          .find((x: { rel: string, href: string }) => x.rel === 'payer-action');

        window.location.href = link.href;
      }
      return Promise.resolve(null)
      // toggleSpinner(false);

      // return actions.order.create({
      //     purchase_units: [
      //         {
      //             reference_id: orderApi.id,
      //             amount: {
      //                 value: (totalProducts! - discountedPrice + totalShipping).toFixed(2)!,
      //             },
      //         },
      //     ],
      // });
    } else {
      showErrorAddress()
    }
  };

  const onApprove = async (data: any) => {
    await OrderService.checkoutOrderController(order?.uuid!).then(
      (res) => {
        navigate(`/profile/orders/${order?.id}`);
      }
    );
  };

  const onError = async () => {
    await OrderService.deleteOrderOrderController(order?.uuid!);
  };

  const ButtonWrapper = ({showSpinner}: { showSpinner: boolean }) => {
    const [{isPending}] = usePayPalScriptReducer();

    return (
      <>
        {showSpinner && isPending && <div className="spinner"/>}
        <Button
          className="mt-4 mb-4 bg-blue-400 text-white font-semibold text-xs"
          block onClick={() => createOrder(null, null)}>
          {t("order.payment.proceedToPayment")}
        </Button>
        {/*<PayPalButtons*/}
        {/*    style={{ layout: "vertical" }}*/}
        {/*    disabled={false}*/}
        {/*    forceReRender={[style]}*/}
        {/*    fundingSource={'paypal'}*/}
        {/*    createOrder={createOrder}*/}
        {/*    onApprove={onApprove}*/}
        {/*    onError={onError}*/}
        {/*    onCancel={onError}*/}
        {/*/>*/}
      </>
    );
  };

  // const totalShipping = useMemo(() => {
  //   if (Number(totalProducts) === 0) return 0;
  //   return (Math.round(Number(totalProducts) / 1000) + 1) * 33;
  // }, [totalProducts])
  //
  // const discountedPrice = useMemo(() => {
  //   if (!totalProducts || !totalDiscount) return 0;
  //   return (totalProducts - totalDiscount);
  // }, [totalProducts, totalDiscount])

  const showErrorAddress = () => {
    return notificationApi.error({
      message: t("order.payment.selectAddress"),
      style: {
        borderRadius: "8px",
        border: "1px solid var(--200, #bf3232)",
        background: "#fff0f0",
      },
    });
  }

  return (
    <>
        <span className="text-sm font-semibold leading-5 tracking-[0.07px]  text-blue-400">
          {title}
        </span>
      <div className="flex justify-between mt-2">
        <div className="text-xs leading-4 tracking-[0.06px]">
          {t("order.payment.totalProduct")}
        </div>
        <div className="text-xs font-semibold leading-4 tracking-[0.06px]">
          {numberWithCommasAndFixedDecimals(totalProducts! + totalDiscount!)} €
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="text-xs leading-4 tracking-[0.06px]">
          {t("order.payment.shipping")}
        </div>
        <div className="text-xs font-semibold leading-4 tracking-[0.06px]">
          {numberWithCommasAndFixedDecimals(shippingCosts)} €
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="text-xs leading-4 tracking-[0.06px]">
          {t("order.payment.discount")}
        </div>
        <div className="text-xs font-semibold leading-4 tracking-[0.06px] text-green-300">
          -{numberWithCommasAndFixedDecimals(totalDiscount)} €
        </div>
      </div>
      <Divider/>
      <div className="flex justify-between mt-2">
        <div className="text-xs leading-4 tracking-[0.06px]">
          {t("order.payment.vat")}
        </div>
        <div className="text-xs font-semibold leading-4 tracking-[0.06px]">
          {numberWithCommasAndFixedDecimals(vat)} €
        </div>
      </div>

      {paymentType === "Fido" && (
        <>
          <div className="flex justify-between mt-2">
            <div className="text-xs leading-4 tracking-[0.06px]">
              {t("order.payment.creditUsed")}
            </div>
            <div className="text-xs font-semibold leading-4 tracking-[0.06px]">
              {numberWithCommasAndFixedDecimals(((totalProducts || 0) + (vat || 0) + (shippingCosts || 0)))} €
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className="text-xs text-neutral-300 leading-4 tracking-[0.06px]">
              {t("order.payment.overdraft")}
            </div>
            <div className="text-xs text-neutral-300 leading-4 tracking-[0.06px]">
              {numberWithCommasAndFixedDecimals(credits - ((totalProducts || 0) + (vat || 0) + (shippingCosts || 0)))} €
            </div>
          </div>
          <Divider/>
        </>
      )}
      <div className="flex justify-between mt-2">
        <div className="text-sm font-bold leading-5 tracking-[0.07px]">
          {t("common.total")}
        </div>
        <div className="text-sm font-bold  leading-5 tracking-[0.07px]">
          {numberWithCommasAndFixedDecimals(((totalProducts || 0) + (vat || 0) + (shippingCosts || 0)))} €
        </div>
      </div>
      {showPaymentButton && paymentType && (
        <>
          {paymentType !== "Paypal" ? (
            <Button
              block
              onClick={async () => {
                if (addressId || currentUser?.defaultAddress?.id) {

                  toggleSpinner(true, t("cart.validatingOrder"));

                  await OrderService.createOrderController({
                    payload: {},
                    paymentMethod: "CREDIT" as any,
                    shippingAddress: addressId
                      ? addressId
                      : currentUser?.defaultAddress?.id ?? 0,
                  }).then(async (res) => {
                    navigate(`/profile/orders/${res?.data.id}`);
                  }).finally(() => {
                    toggleSpinner(false);
                  });
                } else {
                  showErrorAddress()
                }
              }}
              className="mt-4 mb-4 bg-blue-400 text-white font-semibold text-xs"
            >
              {t("order.payment.proceedToPayment")}
            </Button>
          ) : (
            <div className="mt-4">
              <PayPalScriptProvider
                options={{
                  clientId:
                    process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
                  currency: "EUR",
                }}
              >
                <ButtonWrapper showSpinner={false}/>
              </PayPalScriptProvider>
            </div>
          )}
          {contextHolder}
          {deliveryDate && (
            <div className="flex justify-center">
              <div className="text-xs leading-4 tracking-[0.06px]">
                {t("order.deliveryBy")}{" "}
                {dayjs(deliveryDate).format("DD-MM-YYYY")}.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
