import React, {Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import OverlayLoader from "../Components/OverlayLoader";
import {Spin} from "antd";

export const LoadingStateContext = React.createContext<{
  isLoading: boolean;
  toggleSpinner: (isLoading: boolean, message?: string) => void;
}>({
  isLoading: false,
  toggleSpinner: (isLoading: boolean, message?: string) => {}
});

export const LoadingStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  
  const toggleSpinner = (isLoading: boolean, message?: string) => {
    setIsLoading(isLoading);
    setMessage(message ?? "");
  }

  return (
    <LoadingStateContext.Provider value={{ isLoading, toggleSpinner }}>
      <>
        {children}
        {isLoading && (
            <div className={"fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center z-10"}>
              <div className={"flex flex-col"}>
                <Spin size={"large"}/>
                {message && <span className={"mt-4 text-base font-bold"}>{message}</span>}
              </div>
              <div className={"fixed top-0 left-0 bottom-0 right-0 bg-black/50 z-[5]"}/>
            </div>
        )}
      </>
    </LoadingStateContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(LoadingStateContext);
};
