import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {GetProductResponseDto, UserBaseDto} from "../api";
import {useBreakpoint} from "../utils/hooks/useBreakpoint";
import {isAdmin} from "../utils/isAdmin";
import {Search} from "./icons/Search";
import {useNavigate} from "react-router-dom";
import {DEFAULT_FALLBACK} from "../App";

interface Props {
  filteredOptions: GetProductResponseDto[];
  handleSearch: ((value: string) => void) | undefined;
  currentUser: UserBaseDto | undefined;
}

export const SearchSelect = ({
                               filteredOptions,
                               handleSearch,
                               currentUser,
                             }: Props) => {
  const {isMobile} = useBreakpoint();
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Select
      showSearch
      placeholder={t("common.search")}
      optionFilterProp="children"
      optionLabelProp="label"
      suffixIcon={
        <Search
          className="h-4 w-4"
          fill={isAdmin(currentUser) ? "#5D5D5D" : "#0950A8"}
        />
      }
      onSelect={(value, option) => {
        navigate(`/products/${option.value}`);
      }}
      onSearch={handleSearch}
      filterOption={false}
      style={{width: isMobile ? "100%" : 450}}
    >
      {filteredOptions.map((option) => (
        <Select.Option key={option.id} value={option.id} label={option.name}>
          <div className="flex justify-start">
            <div
              className="w-12 h-12 rounded-lg"
              style={{
                backgroundImage: `url(${ encodeURI(option.media?.path ?? option?.variants[0]?.media?.path)}), url(${DEFAULT_FALLBACK})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="ml-2">
              <div className="font-medium text-neutral-300 capitalize text-[10px]">
                {option?.code}
              </div>
              <div className="font-semibold text-blue-500 capitalize text-xs">
                {option?.name}
              </div>
              <div className="font-medium text-neutral-300 capitalize text-[10px]">
                {option?.brand?.name}
                {!option?.brand?.name && <span>&nbsp;</span>}

              </div>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
