import { Button, Col, DatePicker, DatePickerProps, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import {v4 as uuidv4} from "uuid";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";

type FieldType = {
  title: string;
  startDate: string;
  endDate: string;
};

export const SliderProducts = ({
  createList,
  applyChanges,
  handleFormSliderProdChange,
  sliderProductValues,
  selectedLanguage
}: {
  createList: () => void;
  applyChanges: () => void;
  handleFormSliderProdChange: (values: any) => void;
  sliderProductValues: any,
  selectedLanguage: number
}) => {
  const [form] = useForm();

  const { t } = useTranslation()

  useEffect(() => {
      form.setFieldValue('title', sliderProductValues?.titles.find((x: any) => x.languageId == selectedLanguage)?.value || '')
  }, [sliderProductValues]);
  return (
    <div
      style={{
        borderRadius: "8px",
        border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
        background: "var(--neutral-white, #FFF)",
        boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
        padding: 24,
        height: "100%",
        marginTop: 20,
      }}
    >
      <div className="text-blue-400 text-2xl font-bold leading-8 tracking-[0.12px]">
        {t("dashboard.editor.productsSlider")}
      </div>
    <Form
        name={`sliderProdottiForm_${selectedLanguage}_${uuidv4()}`}
        className="adminForm"
        form={form}
        initialValues={{
          title: sliderProductValues?.titles.find((x: any) => x.languageId == selectedLanguage)?.value,
          startDate: dayjs(sliderProductValues?.fromDate),
          endDate: dayjs(sliderProductValues?.toDate)
        }}
        onValuesChange={(changedField, allFields) =>{
          handleFormSliderProdChange(allFields)}
        }
        autoComplete="off"
        layout="vertical"
        style={{
          width: "100%",
          marginTop: 10,
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={7}>
            <Form.Item<FieldType>
              label={t("dashboard.editor.titleSlide")}
              name="title"
              style={{
                fontWeight: 600,
              }}
            >
              <Input style={{ height: 40 }} />
            </Form.Item>{" "}
          </Col>
          <Col span={6}>
            <Form.Item<FieldType>
              label={t("dashboard.editor.startDate")}
              name="startDate"
              style={{
                fontWeight: 600,
                width: "100%",
              }}
            >
              <DatePicker style={{ width: "100%", height: 40 }} />
            </Form.Item>{" "}
          </Col>
          <Col span={6}>
            <Form.Item<FieldType>
              label={t("dashboard.editor.endDate")}
              name="endDate"
              style={{
                fontWeight: 600,
                width: "100%",
                height: 40,
              }}
            >
              <DatePicker style={{ width: "100%", height: 40 }} format="DD-MM-YYYY" />
            </Form.Item>{" "}
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              style={{ marginTop: 25, background: "#0950A8", height: 40 }}
              onClick={createList}
            >
              <div className="font-semibold">
                {t("dashboard.editor.addProducts")}
              </div>
            </Button>
          </Col>
        </Row>
      <Row>
        <Button
          type="primary"
          onClick={() => {applyChanges()}}
          style={{
            height: 40,
            border: "1px solid var(--Blue-400, #0950A8)",
            color: "#0950A8",
            fontSize: 12,
            fontWeight: 600,
            marginTop: 20,
          }}
          className="bg-blue-400 text-white text-xs font-semibold leading-[18px]"
        >
          <span className="text-white">{t("common.save")}</span>
        </Button>
      </Row>
      </Form>
    </div>
  );
};
