import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import {
  GetProductResponseDto,
  ProductBaseDto,
  ProductService,
  SliderResponseGroupedResponseDto,
} from "../../../../api";
import { ProductCard } from "../../../../Components/ProductCard";
import { useTranslation } from "react-i18next";
import { translationValue } from "../../../../utils/translationValue";
import { toFilter, toIncludes } from "../../../../utils/request";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useBreakpoint } from "../../../../utils/hooks/useBreakpoint";
import {DEFAULT_FALLBACK} from "../../../../App";

interface Props {
  gridProducts: SliderResponseGroupedResponseDto | undefined;
  listProductsGrid: ProductBaseDto[];
}

export const GridSlider = ({ gridProducts, listProductsGrid }: Props) => {
  const { t } = useTranslation();
  const history = useNavigate();

  const { isMobile } = useBreakpoint();

  const handleClick = () => {
    const value = translationValue(gridProducts?.titles);
    if (value) {
      const queryParams = queryString.stringify({ source: value });
      history(`/products?${queryParams}`);
    }
  };

  return (
    <div className={isMobile ? "pl-0 pr-0" : "pl-16 pr-16"}>
      <div className="flex justify-between mb-4">
        <div className="text-blue-500 text-lg font-semibold leading-7 tracking-[0.09px]">
          {translationValue(gridProducts?.titles)}
        </div>
        <div
          className="text-neutral-300 text-xs font-semibold leading-[18px] tracking-[0.06px] underline cursor-pointer"
          onClick={handleClick}
        >
          {t("common.showAll")}
        </div>
      </div>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 12}>
          <Row gutter={[8, 8]}>
            {listProductsGrid?.map((res, index) => {
              if (index <= 3) {
                return (
                  <Col span={12} key={index}>
                    <ProductCard
                    subCategoryName=""
                      categoryName=""
                      sourceName={
                        translationValue(gridProducts?.titles)
                          ? translationValue(gridProducts?.titles)
                          : gridProducts?.title
                      }
                      data={res}
                    />{" "}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>
        {!isMobile && (
          <Col span={12}>
            <div
              className="h-full w-full rounded-lg"
              style={{
                backgroundImage: `url(${gridProducts?.media?.path}), url(${DEFAULT_FALLBACK})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Col>
        )}
      </Row>
    </div>
  );
};
