import { Badge, Col, Row } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: {
    label: string;
    icon: ReactNode;
    id: string;
    url: string;
    children?: any;
    notifications?: number;
  };
  selectedItem: string;
  setSelectedItem(value: string): void;
  setSelectedKeys?: (value: []) => void;
  openMenuDrawer?: boolean;
}
export const UserMenuItem = ({
  item,
  selectedItem,
  setSelectedItem,
  setSelectedKeys,
  openMenuDrawer,
}: Props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`mb-2`}>
      <div
        style={{
          backgroundColor: item.label === selectedItem ? "#0950A8" : "",
        }}
        className={`hover:bg-[#ebebed] cursor-pointer pl-3 pt-1 pb-1 rounded-lg ${
          openMenuDrawer ? "w-auto" : "w-12"
        } lg:w-auto`}
        onClick={() => {
          setSelectedItem(item.label);
          setSelectedKeys && setSelectedKeys([]);
          navigate(item.url);
        }}
      >
        <Row>
          <Col span={5}>
            {windowSize.width < 1024 &&
            !openMenuDrawer &&
            item.notifications &&
            item.notifications > 0 ? (
              <Badge count={item.notifications}>{item.icon}</Badge>
            ) : (
              <>{item.icon}</>
            )}
          </Col>
          <Col span={18} className={`mt-1 text-xs font-medium leading-4 tracking-[0.06px] ${openMenuDrawer ? "block" : "hidden"} ml-1 lg:block`} style={{color: item.label === selectedItem ? "white" : "#5D5D5D",}}>
            <div className="flex justify-between">
              <div> {item.label}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
