import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../../../contexts/formContext";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

export const Step2 = React.forwardRef((props, ref) => {
  const { updateFormData } = useFormContext();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  type FieldType = {
    company: string;
    vatNumber?: string;
    taxCode?: string;
    SDI?: string;
    registeredOffice?: string;
    reference?: string;
    contact?: string;
  };

  const handleFormChange = (allFields: any) => {
    updateFormData(allFields);
  };

  React.useImperativeHandle(ref, () => ({
    validateForm: () => form.validateFields(),
  }));

  return (
    <div>
      <Form
        wrapperCol={{ span: 22 }}
        className="mt-4"
        name="registerFormStep2"
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
        form={form}
        onValuesChange={handleFormChange}
      >
        <Row>
          <Col span={isMobile ? 24 : 12} className="w-full">
            <Form.Item<FieldType>
              label={t("common.company")}
              name="company"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterCompany"),
                },
              ]}
            >
              <Input placeholder={t("common.company")} />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("common.taxCode")}
              name="taxCode"
              className="mt-4"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterTaxCode"),
                },
              ]}
            >
              <Input placeholder={t("common.taxCode")} />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("common.registeredOffice")}
              name="registeredOffice"
              className="mt-4"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterRegisteredOffice"),
                },
              ]}
            >
              <Input placeholder={t("common.registeredOffice")} />
            </Form.Item>

            <Form.Item<FieldType>
              label={t("common.reference/administrator")}
              name="reference"
              className="mt-1"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterReference"),
                },
              ]}
            >
              <Input placeholder={t("common.reference/administrator")} />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12} className="w-full">
            <Form.Item<FieldType>
              label={t("common.vatNumber")}
              name="vatNumber"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterVatNumber"),
                },
              ]}
            >
              <Input placeholder={t("common.vatNumber")} />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("common.sdi")}
              name="SDI"
              className="mt-4"
              rules={[
                { required: true, message: t("registration.errors.enterSDI") },
              ]}
            >
              <Input placeholder={t("common.sdi")} />
            </Form.Item>
            {!isMobile && (
              <Form.Item label=" ">
                <Input className="invisible"></Input>
              </Form.Item>
            )}

            <Form.Item<FieldType>
              label={t("common.contact")}
              name="contact"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterContact"),
                },
              ]}
            >
              <Input placeholder={`${t("email")}/${t("phone")}`} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
