import {Flex, Radio, Tag} from "antd";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../contexts/authContext";
import { Payment } from "../../../../utils/types";

interface Props {
  payments: Payment[];
  onChange: (value: string) => void;
  selectedPaymentType: string;
}

export const PaymentComponent = ({
                                   payments,
                                   onChange,
                                   selectedPaymentType,
                                 }: Props) => {
  const {currentUser} = useContext(AuthContext)
  
  const {t} = useTranslation()
  
  return (
      <div className={"mt-4 flex flex-col space-y-4"}>
        {payments.map((payment) => {
          const isSelected = (payment.title.toUpperCase() === selectedPaymentType.toUpperCase());
          const isAvailable = (payment.title.toUpperCase() !== "FIDO" || (payment.title.toUpperCase() === "FIDO" && currentUser && currentUser.credit > 0));

          return (
              <div key={payment.title} onClick={(isAvailable) ? onChange.bind(this, payment.title) : undefined} className={`p-5 transition rounded-xl border border-solid ${!isAvailable ? "opacity-50 cursor-not-allowed" : "hover:shadow-md hover:border-blue-400 cursor-pointer"} ${isSelected ? "shadow-md border-blue-400 bg-[#ECFBFF]/70" : "bg-white border-neutral-200"}`}>
                <div className={"flex flex-col space-y-2"}>
                  <div className={"flex space-x-3 items-center justify-between w-full"}>
                    <div className={"flex space-x-2 items-center"}>
                      <span className={"text-lg font-bold"}>{payment.title}</span>
                      {payment.icons[0]}
                    </div>
                    {!isAvailable && <Tag>{t("common.notAvailable")}</Tag>}
                  </div>
                  <span className={"text-xs"}>{payment.description}</span>
                </div>
              </div>
          )
        })}
      </div>
  );
};
