import {useState} from "react";
import {Button, Input, Select} from "antd";
import {DeleteFilled} from "@ant-design/icons"
import {useTranslation} from "react-i18next";
import {UserStatus} from "../../utils/types";

interface ClientSearchFiltersProps {
    onSubmit: (filters?: Record<string, any>) => void
}

interface CustomerFilterProps {
    name: string,
    surname: string,
    email: string,
    status: string[],
    company: string;
    code?: number;
}

const ClientSearchFilters = ({
    onSubmit,
}: ClientSearchFiltersProps) => {
    
    const {t, i18n} = useTranslation();
    
    const [filters, setFilters] = useState<CustomerFilterProps>({
        company: "",
        name: "",
        surname: "",
        email: "",
        status: []
    })
    
    const handleReset = () => {
        setFilters({
            company: "",
            name: "",
            surname: "",
            email: "",
            status: [],
        })
        onSubmit(undefined);
    }
    
    const handleSubmit = () => {
        onSubmit(filters);
    }
    
    const formattedUserStatusList = Object.keys(UserStatus).map((value) => ({
        label: t(`userStatus.${value.toLowerCase()}`),
        value
    }))
    
    return (
        <div className={"flex flex-col min-w-[200px]"} onKeyUp={x => {
           if(x.key === "Enter") {
               handleSubmit()
           }
        }}>
            <hr className={"mb-4 border-neutral-100 w-full"}/>
            <div className={"flex flex-col space-y-2"}>
                <div className="flex flex-col space-y-1">
                    <span
                      className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("clients.searchFiltersPopover.denominationLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        company: e.target.value
                    }))} value={filters.company} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span
                      className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("clients.searchFiltersPopover.customerCodeLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        code: Number(e.target.value)
                    }))} value={filters.code} type={'number'} min={0} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span
                      className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("clients.searchFiltersPopover.nameLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        name: e.target.value
                    }))} value={filters.name} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span
                      className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("clients.searchFiltersPopover.surnameLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        surname: e.target.value
                    }))} value={filters.surname} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span
                      className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("clients.searchFiltersPopover.emailLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        email: e.target.value
                    }))} value={filters.email} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span
                      className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("clients.searchFiltersPopover.statusLabel")}</span>
                    <Select mode="tags" size={"middle"} allowClear
                            onChange={(value) => setFilters((filters) => ({...filters, status: value}))}
                            options={formattedUserStatusList}/>
                </div>
            </div>
            <hr className={"my-4 border-neutral-100 w-full"}/>
            <div className={"flex flex-row space-x-2 items-center w-full"}>
                <Button type={"primary"} className={"w-full"}
                        onClick={handleSubmit}>{t("clients.searchFiltersPopover.filterBtn")}</Button>
                <Button type={"default"} onClick={handleReset} icon={<DeleteFilled/>}/>
            </div>
        </div>
    )
}

export default ClientSearchFilters
