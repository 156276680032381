/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SectionBaseDto } from './SectionBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type PatchUserSectionPermissionRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    permission: PatchUserSectionPermissionRequestDto.permission;
    enable?: boolean | null;
    section?: (SectionBaseDto | number) | null;
    user?: (UserBaseDto | number) | null;
};
export namespace PatchUserSectionPermissionRequestDto {
    export enum permission {
        CREATE = 'CREATE',
        READ = 'READ',
        UPDATE = 'UPDATE',
        DELETE = 'DELETE',
        READ_OWN = 'READ_OWN',
        UPDATE_OWN = 'UPDATE_OWN',
        DELETE_OWN = 'DELETE_OWN',
    }
}

