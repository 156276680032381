import {Col, Collapse, notification, Pagination, Row,} from "antd";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ProductBaseDto, ProductService, VariantBaseDto, VariantService,} from "../../../api";
import {ButtonFilter} from "../../../Components/ButtonFilter";
import {Paged} from "../../../utils/pagedResponse";
import {toIncludes} from "../../../utils/request";
import {RowTable} from "./RowTable";
import ProductVariantModal, {VariantData} from "../../../Components/products/ProductVariantModal";
import {DEFAULT_FALLBACK} from "../../../App";

export const Products = () => {
  const [showEditVariantModal, setShowEditVariantModal] = useState(false);
  const [products, setProducts] = useState<Paged<ProductBaseDto>>();
  const [meta, setMeta] = useState<any>();
  const [selectedVariant, setSelectedVariant] = useState<VariantBaseDto>();
  const [selectedProduct, setselectedProduct] = useState<ProductBaseDto>();
  const [pagination, setPagination] = useState();
  const [pages, setPages] = useState([0, 10]);
  const {t} = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [price, setPrice] = useState({
    price: {
      $gte: 0,
      $lte: 0,
    },
  });

  useEffect(() => {
    // getAllProducts();
  }, [pages]);

  const columns = [
    t("common.article"),
    t("common.brand"),
    t("common.color"),
    t("common.size"),
    t("common.€net"),
    t("common.stock"),
    // t("common.packages"),
    t("common.returnDate"),
    t("common.edit"),
    // t('common.delete'),
  ];

  const findProductsWithFilters = (variantsFilters?: any) => {
    ProductService.findAllProductController(
      pages[0],
      pages[1],
      JSON.stringify(variantsFilters),
      undefined,
      undefined,
      toIncludes<ProductBaseDto>({
        variants: {
          media: true,
        },
        media: true,
        categories: {
          category: {
            father: true,
          },
        },
      })
    ).then((res) => {
      setMeta(res.meta)
      if (pages[0] >= 1 && products) {
        const el = products?.data.concat(res.data);
        setProducts({
          ...products,
          data: el,
          hasNext: res.hasNext,
          hasPrevious: res.hasPrevious,
        });
      } else {
        setProducts(res);
      }
    });
  };

  const updateVariant = async (updatedData: VariantData) => {
    if (!selectedVariant) return;

    try {
      await VariantService.updateVariantController(
        selectedVariant.id!,
        {
          size: updatedData.size,
          colors: updatedData.colors,
          isVisible: updatedData.isVisible
        }
      );

      if (updatedData.image && typeof updatedData.image === "object") await VariantService.setMediaVariantController(
        selectedVariant.id!,
        {
          file: updatedData.image
        }
      )

      api.success({message: t("dashboard.products.variantUpdateSuccess")});
    } catch (error) {
      if (process.env.NODE_ENV !== "production") console.log("updateVariant", error);
      api.error({message: t("dashboard.products.variantUpdateError")});
    } finally {
      setShowEditVariantModal(false);
    }
  }

  return (
    <div>
      <div className="text-[#333843] text-base font-semibold leading-6 mb-4">
        {t("common.products")}
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
          background: "var(--neutral-white, #FFF)",
          boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
          height: "100%",
        }}
      >
        <div className="p-4">
          <ButtonFilter
            showButton={true}
            show={
            {code: true, description: true, price: true, brand: true}
            }
            meta={meta}
            showSelectedFilters={true}
            findProducts={(v) => {
              findProductsWithFilters(v)
            }}
            findProductsWithFilters={(v: any) => {
              findProductsWithFilters(v);
            }}
          ></ButtonFilter>
        </div>
        <Row className="bg-neutral-100">
          {columns.map((res, index) => {
            return (
              <Col
                key={res}
                className="border-b border-b-[#F0F1F3] text-[#002655] text-xs font-semibold pb-[18px] pt-[18px] text-center"
                span={index === 4 ? 4 : 2}
              >
                {res}
              </Col>
            );
          })}
          <div className="w-full">
            {products?.data.map((product) => {
              return (
                <Collapse
                  key={product.id}
                  className="w-full"
                  expandIconPosition="end"
                  bordered={false}
                  onChange={() => console.log("prod", product)}
                  items={[
                    {
                      key: product.id,
                      label: (
                        <div>
                          <Row>
                            <Col xl={1} md={2}>
                              <div
                                className="w-8 h-8 rounded-lg"
                                style={{
                                  backgroundImage: `url(${encodeURI(product.media?.path ?? product?.variants[0]?.media?.path)}), url(${DEFAULT_FALLBACK})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  //backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                            </Col>
                            <Col xl={21} md={20}>
                              <div
                                className="text-xs text-[#2A2E34] font-bold leading-[18px] tracking-[0.06px] capitalize">
                                {product?.name.toLocaleLowerCase()}
                              </div>
                              <div className="text-neutral-300 text-[10px] font-bold leading-4 tracking-[0.05px]">
                                {/* TODO: cosa ci va? */}

                              </div>
                            </Col>

                            <Col xl={2} md={2} className="capitalize">
                              {product?.categories[0]?.category?.name?.toLocaleLowerCase()}
                            </Col>
                          </Row>{" "}
                        </div>
                      ),
                      children: (
                        <>
                          {" "}
                          {product?.variants?.map((variant, index) => {
                            return (
                              <div key={variant.id}>
                                <RowTable
                                  index={index}
                                  arrayLength={product?.variants.length}
                                  // deleteVariant={(variantValue) => {
                                  //   VariantService.deleteVariantController(
                                  //     variantValue?.id ? variantValue.id : 0
                                  //   ).then(() => {
                                  //     setIsModalOpen(false);
                                  //     notificationSuccess();
                                  //   });
                                  // }}
                                  variant={variant}
                                  brand={product?.brand?.name}
                                  edit={(variantValue) => {
                                    setSelectedVariant(variantValue);
                                    setselectedProduct(product);
                                    setShowEditVariantModal(true);
                                  }}
                                ></RowTable>
                              </div>
                            );
                          })}
                        </>
                      ),
                    },
                  ]}
                ></Collapse>
              );
            })}
          </div>
        </Row>
        <div className="flex justify-center p-4">
          <Pagination
            responsive={true}
            defaultCurrent={pages[0] + 1}
            pageSize={pages[1]}
            onChange={(page, pageSize) => {
              setPages([page - 1, pages[1]]);
              if (pageSize) {
                setPages([page - 1, pageSize]);
              }
            }}
            total={products?.totalCount}
          />
        </div>
      </div>

      <ProductVariantModal product={selectedProduct} variant={selectedVariant} isOpen={showEditVariantModal}
                           onConfirm={updateVariant} onCancel={() => setShowEditVariantModal(false)}/>
    </div>
  );
};
