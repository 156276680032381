import {Button, Col, InputNumber, notification, Row, Table} from "antd";

import {useContext, useMemo, useState,} from "react";
import {useTranslation} from "react-i18next";
import {VariantBaseDto} from "../../../../api";
import {Trash} from "../../../../Components/icons/Trash";
import {AuthContext} from "../../../../contexts/authContext";
import {useCart} from "../../../../utils/hooks/useCart";
import {ModalLogin} from "../../../../Components/ModalLogin";
import {COLORS} from "../../../../App";

interface RapidOrder {
  size: string;
  colors: string[];
  id: number;
  quantity: number;
  insert: boolean;
  error: boolean;
  stock: number;
}

interface Props {
  variants: VariantBaseDto[] | undefined;
  onRefreshVariant: () => void
}

export const RapidOrders = ({variants, onRefreshVariant}: Props) => {
  const {currentUser, setCurrentUser} = useContext(AuthContext);
  const {removeItemFromCart, setCart} = useCart();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [rapidOrders, setRapidOrders] = useState<RapidOrder[]>([]);
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const {t} = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const sizeList = useMemo(() => {
    if (!variants) return null;
    const list = variants.filter(x => x.stock > 0).sort((a, b) => a.sizeNumeric - b.sizeNumeric).map((variant) => variant.size);
    return new Set(list);
  }, [variants]);

  const colorList = useMemo(() => {
    if (!selectedSize || !variants) return null;

    const filteredVariants = variants.filter((variant) => variant.size === selectedSize);
    const colors = filteredVariants.map((variant) => variant.colors);
    const cleanedList: string[][] = [];

    colors.forEach((colors) => {
      if (!cleanedList.map((item) => item.join("").trim()).includes(colors.join("").trim())) cleanedList.push(colors);
    })

    return cleanedList;
  }, [variants, selectedSize])

  const selectedVariant = useMemo(() => {
    if (!variants || !selectedSize || (!selectedColors || selectedColors.length === 0)) return null;
    return variants.find((variant) => (variant.size === selectedSize && variant.colors === selectedColors));
  }, [selectedColors, selectedSize, variants]);

  const addItemsToCart = async () => {
    if (!rapidOrders) return;

    if (!currentUser) setOpenLoginModal(true);

    const reqData = rapidOrders.map((order) => ({
      qty: order.quantity,
      variant: order.id
    }))

    setIsLoading(true);

    try {
      const result = await setCart(reqData);

      const updatedRapidOrders = [...rapidOrders];

      result.forEach((row) => {
        if (row.meta && row.meta.reason) api.warning({message: t(`cart.meta.${row.meta.reason}`, {variantName: row.variant.name})});

        const localOrderIndex = rapidOrders.findIndex((order) => order.id === row.variant.id);

        if (localOrderIndex !== -1) {
          updatedRapidOrders[localOrderIndex] = {
            ...rapidOrders[localOrderIndex],
            quantity: row.qty,
            error: !!(row.meta && row.meta.reason),
          }
        }
      })

      setRapidOrders(updatedRapidOrders);

      api.success({
        message: t("common.prodAddedToCart"),
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #92D2B4)",
          background: "#F4FCF8",
          color: "#3DA172",
        },
      });

      onRefreshVariant();
    } catch (error) {
      api.error({message: t("common.messages.errorUpdatingCart")})
    } finally {
      setIsLoading(false);
    }
  }

  const deleteOrder = (order: RapidOrder) => {
    const newArray = rapidOrders.filter((item) => !(item.id === order.id));
    setRapidOrders(newArray);
    removeItemFromCart(order.id);
  }

  const addOrder = () => {
    if (!selectedSize || (!selectedColors || selectedColors.length === 0) || !selectedVariant) return null;

    let order: RapidOrder = {
      size: selectedSize,
      colors: selectedColors,
      id: selectedVariant.id!,
      quantity: 1,
      insert: true,
      stock: selectedVariant.stock,
      error: false
    };

    if (rapidOrders.filter((item) => item.id === selectedVariant.id).length > 0) {
      const newArray = rapidOrders.map((item, i) => {
        if (item.id === selectedVariant.id) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        } else {
          return item;
        }
      });
      setRapidOrders(newArray);
    } else {
      setRapidOrders([...rapidOrders, order]);
    }
  }

  const selectSize = (size: string) => {
    if (!size) return;
    setSelectedSize(size);
    setSelectedColors([]);
  }

  const rapidOrdersColumns = [
    {
      title: t("product.rapidOrder.sizeCol"),
      dataIndex: "size",
      key: "size",
    },
    {
      title: t("product.rapidOrder.colorsCol"),
      dataIndex: "colors",
      key: "colors",
    },
    {
      title: t("product.rapidOrder.quantityCol"),
      dataIndex: "quantity",
      key: "quantity",
      width: 100
    },
    {
      title: t("product.rapidOrder.stockCol"),
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: t("product.rapidOrder.actionsCol"),
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const rapidOrdersData = useMemo(() => {
    if (!rapidOrders) return [];
    return rapidOrders.map((order, index) => ({
      key: order.id,
      size: (
        <div
          className={"rounded-full h-7 flex items-center justify-center bg-neutral-100 mr-2 px-4 py-1 shadow-sm w-min"}>
          <span className={"text-xs uppercase"}>{order.size || 'NN'}</span>
        </div>
      ),
      colors: (
        <div
          className={"rounded-full flex flex-wrap rotate-45 size-7 border border-solid border-neutral-200 overflow-hidden"}>
          {order.colors.map((color, index) => (
            <div key={index} className={"h-full w-full"}
                 style={{backgroundColor: COLORS[color.toLowerCase()] ?? color.toLowerCase()}}/>
          ))}
        </div>
      ),
      quantity: (
        <div className={"flex flex-col space-y-1 min-w-[50]"}>
          <InputNumber
            size={"middle"}
            className={"w-full"}
            min={0}
            disabled={isLoading}
            value={order.quantity}
            style={{border: 1, borderColor: (order.error ? "red" : "lightgrey"), borderStyle: "solid"}}
            onChange={(value) => {
              const newArray = rapidOrders.map((item, i) => {
                if (index === i) {
                  return {
                    ...item,
                    quantity: Number(value),
                  };
                } else {
                  return item;
                }
              });
              setRapidOrders(newArray);
            }}
          />
          {!order.insert && (
            <span className="text-xs" style={{color: "red"}}>*{t("order.required")}</span>
          )}
        </div>
      ),
      stock: order.stock,
      actions: (
        <div className={"flex space-x-3 items-center"}>
          <Button disabled={isLoading} type={"text"} onClick={deleteOrder.bind(this, order)}
                  icon={<Trash style={{color: "lightgrey"}}/>}></Button>
        </div>
      )
    }));
  }, [rapidOrders])

  return (
    <>
      <Row gutter={24} className="mt-4 justify-around">
        <Col span={8}>
          <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
            {t("common.size")}
          </span>
          <div className="flex justify-normal flex-wrap mt-2">
            {sizeList && [...sizeList].filter(d => d).map((size, index) => {
              return (
                <div
                  onClick={selectSize.bind(this, size)}
                  key={`${size}_${index}`}
                  style={{
                    border: selectedSize === size ? "2px solid grey" : "",
                  }}
                  className={
                    "rounded-full h-7 flex items-center justify-center bg-neutral-100 mr-2 pl-2 pr-2 p-1 text-[10px] uppercase cursor-pointer"
                  }
                >
                  {size}
                </div>
              );
            })}
          </div>
        </Col>
        <Col span={8}>
          <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
            {t("common.colors")}
          </span>
          <div className="flex flex-wrap justify-start gap-0.5 items-center mt-2">
            {colorList && [...colorList].map((colors: string[], index) => {
              return (
                <div onClick={setSelectedColors.bind(this, colors)} key={"color_" + index}>
                  <div
                    className={`cursor-pointer rounded-full flex rotate-45 size-7 overflow-hidden ${(selectedColors && selectedColors === colors) ? "border-2 border-solid border-gray" : "border border-solid border-neutral-200"}`}>
                    {colors.map((color, index) => (
                      color === 'ASSORTITO' ? <div key={index} title={'Assortito'} className={"h-full w-full rainbow-circle"}></div> :
                        <div key={index} className={"h-full w-full"}
                             style={{backgroundColor: COLORS[color.toLowerCase()] ?? color.toLowerCase()}}/>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </Col>
        <Col span={6} className="flex justify-center items-center">
          <Button type={"primary"} disabled={!selectedSize || selectedColors.length === 0}
                  onClick={addOrder}>{t("order.createRapidOrder")}</Button>
        </Col>
      </Row>

      {rapidOrdersData.length > 0 && (
        <div className={"mt-6 flex flex-col space-y-5"}>
          <Table dataSource={rapidOrdersData} columns={rapidOrdersColumns} pagination={false}/>
          <div className={"self-end"}>
            <Button type={"primary"} disabled={isLoading} onClick={addItemsToCart}>Aggiungi al carrello</Button>
          </div>
        </div>
      )}

      <ModalLogin
        operationAfterLogin={(user: any) => {
          setCurrentUser(user);
          addItemsToCart();
        }}
        openLoginModal={openLoginModal}
        setOpenLoginModal={(value) => setOpenLoginModal(value)}
      ></ModalLogin>

      {contextHolder}
    </>
  );
};
