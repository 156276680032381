import {
  Button,
  Dropdown,
  notification,
  Pagination,
  Popover,
  Table,
  Tag,
} from "antd";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  TicketBaseDto,
  TicketService,
  TicketTypeBaseDto,
  TicketTypeService,
} from "../../../api";
import { toFilter, toIncludes } from "../../../utils/request";
import { format } from "date-fns";
import { AuthContext } from "../../../contexts/authContext";
import { Link } from "react-router-dom";
import { CloseTicketModal } from "../../../Components/tickets/CloseTicketModal";
import { AssignTicketModal } from "../../../Components/tickets/AssignTicketModal";
import TicketSearchFilters from "../../../Components/tickets/TicketSearchFilters";
import { SortOrder } from "antd/es/table/interface";
import { ItemType, MenuItemType } from "antd/lib/menu/hooks/useItems";

export const Tickets = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 5,
    totalCount: 0,
  });

  const [api, contextHolder] = notification.useNotification();
  const [ticketList, setTicketList] = useState<TicketBaseDto[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<TicketBaseDto | null>(
    null
  );
  const [searchFilters, setSearchFilters] = useState<
    Record<string, any> | undefined
  >(undefined);

  const [showCloseTicketModal, setShowCloseTicketModal] = useState(false);
  const [showTicketAssignModal, setShowTicketAssignModal] = useState(false);

  const [ticketTypes, setTicketTypes] = useState<TicketTypeBaseDto[]>([]);

  useEffect(() => {
    getTicketList();
    getTicketTypeList();
  }, []);

  const getTicketList = (sortedInfo?: any) => {
    let sortFilter = {};
    if (sortedInfo) {
      const sortField = sortedInfo.columnKey;
      const sortOrder: SortOrder = sortedInfo.order;
      sortFilter = {
        [sortField]: sortOrder === "ascend" ? "ASC" : "DESC",
      };
    }

    let filters = {};
    if (searchFilters) {
      if (searchFilters.title)
        filters = {
          ...filters,
          title: {
            $contains: searchFilters.title,
          },
        };
      if (searchFilters.number)
        filters = {
          ...filters,
          id: {
            $eq: searchFilters.number,
          },
        };
      if (searchFilters.createdBy)
        filters = {
          ...filters,
          user: {
            uuid: searchFilters.createdBy,
          },
        };
      if (searchFilters.inCharge)
        filters = {
          ...filters,
          inCharge: {
            uuid: searchFilters.inCharge,
          },
        };
      if (searchFilters.type)
        filters = {
          ...filters,
          type: {
            name: searchFilters.type.name,
          },
        };
    }

    TicketService.findAllTicketController(
      pagination.current ?? undefined,
      pagination.pageSize ?? undefined,
      searchFilters ? toFilter<TicketBaseDto>(filters) : undefined,
      sortedInfo ? JSON.stringify(sortFilter) : undefined,
      undefined,
      toIncludes<TicketBaseDto>({ lastReply: true, type: true, inCharge: true })
    ).then((res) => {
      if (!res.data) return;
      setTicketList(res.data);
      setPagination((value) => ({ ...value, totalCount: res.totalCount }));
    });
  };

  const getTicketTypeList = () => {
    TicketTypeService.findAllTicketTypeController().then((res) => {
      if (!res.data) return;
      setTicketTypes(res.data);
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: t("dashboard.tickets.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
    {
      title: t("dashboard.tickets.title"),
      dataIndex: "title",
      key: "title",
      sorter: true,
    },
    {
      title: t("dashboard.tickets.messageStatus"),
      dataIndex: "messageStatus",
      key: "messageStatus",
      sorter: true,
    },
    {
      title: t("dashboard.tickets.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("dashboard.tickets.createdBy"),
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: t("dashboard.tickets.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
    },
    {
      title: t("dashboard.tickets.lastUpdate"),
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      sorter: true,
    },
    {
      title: t("dashboard.tickets.assignee"),
      dataIndex: "assignee",
      key: "assignee",
    },
    {
      title: t("common.actions"),
      dataIndex: "actions",
      width: 200,
    },
  ];

  const ticketOptions = (ticket: TicketBaseDto) => {
    const options: ItemType<MenuItemType>[] = [];

    if (ticket.inCharge && ticket.inCharge.uuid === currentUser?.uuid) {
      options.splice(0, 0, {
        key: `#${ticket.id}_messages`,
        label: (
          <Link className={"text-xs"} to={`/admin/tickets/${ticket.id}`}>
            {t("dashboard.tickets.tableRowOptions.viewMessages")}
          </Link>
        ),
      });
    } else {
      options.push({
        key: `#${ticket.id}_assign`,
        label: (
          <span
            className={"text-xs"}
            onClick={() => handleTicketAssignment(ticket)}
          >
            {t("dashboard.tickets.tableRowOptions.assignToSelf")}
          </span>
        ),
      });
    }

    if (
      ticket.status !== TicketBaseDto.status.CLOSED &&
      ticket.status !== TicketBaseDto.status.SOLVED &&
      ticket.status !== TicketBaseDto.status.ARCHIVED
    ) {
      options.push({
        key: `#${ticket.id}_close`,
        label: (
          <span className={"text-xs"} onClick={() => handleTicketClose(ticket)}>
            {t("dashboard.tickets.tableRowOptions.closeTicket")}
          </span>
        ),
      });
    }

    return options;
  };

  const dataSource = useMemo(() => {
    if (!ticketList) return [];

    return ticketList.map((ticket) => {
      const hasReceivedResponse =
        ticket.lastReply?.createdBy !== currentUser?.uuid;

      const messageStatusLabel = hasReceivedResponse
        ? t("dashboard.tickets.statusType.received")
        : t("dashboard.tickets.statusType.waiting");
      const messageStatusColor = hasReceivedResponse ? "#3DA172" : "#0950A8";

      const statusLabel = t(`common.ticketStatus.${ticket.status}`);
      const statusColor = getTicketStatusColor(ticket.status);

      return {
        key: ticket.id,
        id: ticket.id,
        status: (
          <Tag className={"me-2 w-min text-xs"} color={statusColor}>
            {statusLabel}
          </Tag>
        ),
        title: <span className={"text-nowrap font-bold"}>{ticket.title}</span>,
        messageStatus: (
          <Tag color={messageStatusColor}>{messageStatusLabel}</Tag>
        ),
        type: (
          <Tag color={"blue"}>{t(`common.ticketType.${ticket.type.name}`)}</Tag>
        ),
        createdBy: (
          <span>
            {ticket.user.name} {ticket.user.surname}
          </span>
        ),
        createdAt: (
          <span className={"text-nowrap font-bold"}>
            {format(new Date(ticket.createdAt!), "dd/MM/yyyy hh:mm")}
          </span>
        ),
        lastUpdate: (
          <span className={"text-nowrap"}>
            {ticket.lastReply && ticket.lastReply.createdAt
              ? format(new Date(ticket.lastReply.createdAt), "dd/MM/yyyy hh:mm")
              : ""}
          </span>
        ),
        assignee: (
          <span className={"text-nowrap font-bold"}>
            {ticket.inCharge
              ? `${ticket.inCharge.name} ${ticket.inCharge.surname}`
              : t("dashboard.tickets.unassigned")}
          </span>
        ),
        actions: (
          <Dropdown
            menu={{ items: ticketOptions(ticket) }}
            placement="bottomRight"
            arrow={true}
          >
            <Button size={"small"} className={"text-xs px-3"} type={"primary"}>
              {t("common.actions")}
            </Button>
          </Dropdown>
        ),
      };
    });
  }, [ticketList]);

  const handleTicketClose = (ticket: TicketBaseDto) => {
    if (!ticket) return;
    setSelectedTicket(ticket);
    setShowCloseTicketModal(true);
  };

  const onTicketClosed = () => {
    api.success({ message: t("dashboard.tickets.closedMessage") });
    setShowCloseTicketModal(false);
    getTicketList();
  };

  const handleTicketAssignment = (ticket: TicketBaseDto) => {
    if (!ticket) return;
    setSelectedTicket(ticket);
    setShowTicketAssignModal(true);
  };

  const onTicketAssigned = () => {
    api.success({ message: t("dashboard.tickets.assignedMessage") });
    setShowTicketAssignModal(false);
    getTicketList();
  };

  useEffect(() => {
    getTicketList();
  }, [pagination.current, pagination.pageSize, searchFilters]);

  return (
    <div>
      <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px] mb-4">
        {t("menu.tickets")}
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
          background: "var(--neutral-white, #FFF)",
          boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
          height: "100%",
        }}
      >
        <div className={"p-5 md:p-7 md:pl-14 md:pr-14"}>
          <div className="flex justify-end items-center mb-5">
            <Popover
              trigger={"click"}
              placement="bottomRight"
              title={t('tickets.searchFilters')}
              content={
                <TicketSearchFilters
                  onSubmit={(filters) => setSearchFilters(filters)}
                  types={ticketTypes}
                />
              }
            >
              <Button>{t('tickets.searchFilters')}</Button>
            </Popover>
          </div>

          <div className={"flex flex-col space-y-4 overflow-auto"}>
            <Table
              onChange={(pagination, filters, sorter) => getTicketList(sorter)}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              showSorterTooltip={false}
            />
            <div className={"self-center"}>
              <Pagination
                responsive={true}
                defaultCurrent={pagination.current}
                total={pagination.totalCount}
                defaultPageSize={5}
                onChange={(page, pageSize) =>
                  setPagination((value) => ({
                    ...value,
                    current: page - 1,
                    pageSize: pageSize,
                  }))
                }
              />
            </div>
          </div>

          {contextHolder}

          <CloseTicketModal
            ticket={selectedTicket}
            isOpen={showCloseTicketModal}
            onClose={() => setShowCloseTicketModal(false)}
            onSuccess={onTicketClosed}
            onFailure={(message) => api.error({ message })}
          />
          <AssignTicketModal
            ticket={selectedTicket}
            isOpen={showTicketAssignModal}
            onClose={() => setShowTicketAssignModal(false)}
            onSuccess={onTicketAssigned}
            onFailure={(message) => api.error({ message })}
          />
        </div>
      </div>
    </div>
  );
};

export const getTicketStatusColor = (status: TicketBaseDto.status) => {
  let statusColor = "";
  switch (status) {
    case TicketBaseDto.status.ARCHIVED:
      statusColor = "default";
      break;
    case TicketBaseDto.status.CLOSED:
      statusColor = "default";
      break;
    case TicketBaseDto.status.IN_CHARGE:
      statusColor = "processing";
      break;
    case TicketBaseDto.status.OPEN:
      statusColor = "processing";
      break;
    case TicketBaseDto.status.SOLVED:
      statusColor = "success";
      break;
  }

  return statusColor;
};
