import { FavouriteService, UserBaseDto } from "../../api";

export const useWish = () => {
  const deleteFromWish = async (itemId: number, likeId: number | undefined) => {
    if (itemId && likeId) {
      await FavouriteService.deleteFavouriteController(likeId);
    }
  };

  const addToFavorite = async (
    itemId: number,
    user: UserBaseDto | undefined
  ) => {
    await FavouriteService.createFavouriteController({
      user: user?.id ?? 0,
      variant: itemId ?? 0,
    });
  };

  return { deleteFromWish, addToFavorite };
};
