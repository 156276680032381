import { format } from "date-fns";

type $in<T> = T[];
type $eq<T> = T | undefined | null;
type $lt<T> = T;
type $gt<T> = T;
type $gte<T> = T;
type $lte<T> = T;
type $not<T> = Where<T> | Operator<T>;
type $contains<T> = T;
type $between<T> = T[];
type ObjectID = string;
type Buffer = string;
type Operator<T> = {
  $in?: $in<T | undefined | null>;
  $eq?: $eq<T | undefined | null>;
  $lt?: $lt<T>;
  $gt?: $gt<T>;
  $gte?: $gte<T>;
  $lte?: $lte<T>;
  $not?: $not<T>;
  $contains?: $contains<T>;
  $containsIgnore?: $contains<T>;
  $between?: $between<T>;
};

export declare type BaseIncludeProperty<Property> = Property extends Array<
        infer I
    >
    ? BaseFieldProperty<NonNullable<I>>
    : Property extends object
        ? BaseIncludes<Property> | BaseIncludes<Property>[] | boolean
        : boolean;

export declare type BaseIncludes<Entity> = {
  [P in keyof Omit<Entity, "_type__name">]?: P extends "toString"
      ? unknown
      : BaseIncludeProperty<NonNullable<Entity[P]>>;
};

export declare type BaseFieldProperty<Property> = Property extends Array<
        infer I
    >
    ? BaseFieldProperty<NonNullable<I>>
    : Property extends object
        ? BaseFields<Property> | BaseFields<Property>[] | boolean
        : boolean;

export declare type BaseFields<Entity> = {
  [P in keyof Omit<Entity, "_type__name">]?: P extends "toString"
      ? unknown
      : BaseFieldProperty<NonNullable<Entity[P]>>;
};

export declare type FindInProperty<Property> = Property extends Array<infer I>
    ? FindInProperty<NonNullable<I>>
    : Property extends Buffer
        ? Property | Operator<Property>
        : Property extends Date
            ? Property | Operator<Property>
            : Property extends ObjectID
                ? Property | Operator<Property>
                : Property extends string
                    ? Property | Operator<Property>
                    : Property extends number
                        ? Property | Operator<Property>
                        : Property extends boolean
                            ? Property | Operator<Property>
                            : Property extends object
                                ? Where<Property> | Where<Property>[] | boolean
                                : Property | Operator<Property>;

export declare type Where<Entity> = {
  [P in keyof Omit<Entity, "_type__name">]?: P extends "toString"
      ? unknown
      : FindInProperty<NonNullable<Entity[P]>>;
};

export type Preset<T> = {
  includes?: BaseIncludes<T>;
  filters?: Where<T>;
  fields?: BaseFields<T>;
};

export const toFilter = <T>(filter: Where<T> | Where<T>[]) => {
  return JSON.stringify(filter);
};

export const toIncludes = <T>(includes: BaseIncludes<T>) => {
  function rKeys(o: any) {
    if (!o || typeof o !== "object") return [];

    const paths = [];
    const stack: any = [{ obj: o, path: [] }];

    while (stack.length > 0) {
      const { obj, path } = stack.pop()!;

      if (typeof obj === "object" && obj !== null) {
        for (const key in obj) {
          stack.push({ obj: obj[key], path: [...path, key] });
        }
      } else {
        paths.push(path);
      }
    }

    return paths;
  }
  const paths = rKeys(includes);
  return paths.map((f) => f.join(".")).join(",");
};

export const toSqlDate = <T>(date: Date) =>
    format(date, "yyyy-MM-dd HH:mm:ss.000000") as T;

export const Operators: (a: string, b: any) => boolean = (
    op: string,
    value: any
) => {
  const result = {
    $eq: true,
    $in: true,
    $lt: true,
    $gt: true,
    $gte: true,
    $lte: true,
    $not: true,
    $any: true,
    $between: true,
    $contains: true,
    $containsIgnore: true,
  }[op];
  return result || value;
};

export const mergeFilters = <T>(
    filters: Where<T> | Where<T>[],
    overrides: Where<T> | Where<T>[],
) => {
  // se override é un array tutto ció che si trova in override finsce in filters per n volte override
  // se override é un object e filters é un object, override riscrive le proprietá di filters
  const isObject = (d: unknown) => typeof d === 'object';
  const isOperator = (key: string) => Operators(key, undefined) !== undefined;

  if (Array.isArray(overrides)) {
    const _filters: (Where<any> | undefined)[] = [];
    overrides.forEach((value) => {
      if (Array.isArray(filters)) {
        filters.forEach((filter) => {
          _filters.push(mergeFilters(filter, value));
        });
      } else {
        _filters.push(mergeFilters(filters, value));
      }
    });
    return _filters;
  }
  if (Array.isArray(filters)) {
    const _filters: (Where<any> | undefined)[] = [];
    filters.forEach((value) => {
      if (Array.isArray(overrides)) {
        overrides.forEach((filter) => {
          _filters.push(mergeFilters(value, filter));
        });
      } else {
        _filters.push(mergeFilters(value, overrides));
      }
    });
    return _filters;
  }
  if (isObject(filters) && isObject(overrides)) {
    const _filters: Where<any> = {
      ...filters,
    };
    Object.entries(overrides).forEach((item) => {
      const [key, value] = item;
      if (!isObject(value) || !_filters[key] || isOperator(key)) {
        _filters[key] = value;
      } else if (isObject(value) && !Array.isArray(value)) {
        _filters[key] = mergeFilters(_filters[key], value as any);
      }

      if (Array.isArray(value)) {
        const _tmpFilters = value.map((f) => mergeFilters(_filters[key], f));
        _filters[key] = _tmpFilters;
      }
    });
    return _filters;
  }
};