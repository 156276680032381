import {Button, Input, notification, Tag} from "antd";
import { useTranslation } from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {
  TicketBaseDto, TicketReplyBaseDto, TicketReplyService,
  TicketService,
} from "../api";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";
import {toFilter, toIncludes} from "../utils/request";
import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../contexts/authContext";
import {UserOutlined} from "@ant-design/icons"
import {useFilePicker} from "use-file-picker";
import {FileAmountLimitValidator, FileSizeValidator} from "use-file-picker/validators";
import {format} from "date-fns";
import {SendOutlined} from "@ant-design/icons"
import {getTicketStatusColor} from "../pages/admin/tickets/Tickets";
import {Ticket} from "./icons/Ticket";

export const TicketDetails = () => {
  const { id: ticketId } = useParams();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const { isMobile } = useBreakpoint();
  const [ticket, setTicket] = useState<TicketBaseDto | null>(null);
  const [ticketReplies, setTicketReplies] = useState<TicketReplyBaseDto[]>([]);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate()
  
  const [message, setMessage] = useState("");

  const { openFilePicker: selectAttachment, plainFiles: attachment, loading, clear: clearAttachment } = useFilePicker({
    accept: [".pdf", ".jpg", ".jpeg", ".png"],
    multiple: false,
    validators: [
        new FileAmountLimitValidator({ min: 1, max: 1 }),
        new FileSizeValidator({maxFileSize: 4 * 1024 * 1024}), //4mb file size limit
    ],
  });
  
  useEffect(() => {
    TicketService.findOneTicketController(ticketId, undefined, toIncludes<TicketBaseDto>({lastReply: true, replies: { user: true }})).then((res) => {
      if(!res.data) return;
      setTicket(res.data);
      setTicketReplies(res.data.replies);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      TicketReplyService.findAllTicketReplyController( 
          undefined,
          undefined,
          (ticketId ? toFilter<TicketReplyBaseDto>({ticket: {id: +ticketId}}) : undefined),
          undefined,
          undefined,
          toIncludes<TicketReplyBaseDto>({user: true}),
      ).then((res) => {
        if(!res.data) return;
        setTicketReplies(res.data);
        console.log(res.data);
      });
    }, 40000); //Update every 40 seconds

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
  
  if(!ticket || !currentUser) return null;
  
  const hasReceivedResponse = (ticket.lastReply && ticket.lastReply.createdBy !== currentUser.uuid);
  
  const handleReplySubmit = async () => {
    if(!message) return;
    
    const createdReply = await TicketReplyService.createTicketReplyController({
      ticket,
      message
    }, undefined, toIncludes<TicketReplyBaseDto>({user: true}))
    
    if(!createdReply.data) return api.error({message: t("ticketDetails.errorMessage")});
    
    setTicketReplies((replies) => [...replies, createdReply.data]);
    setMessage("");
  }
  
  const now = new Date();

  const statusLabel = t(`common.ticketStatus.${ticket.status}`);
  const statusColor = getTicketStatusColor(ticket.status);
  
  return (
      <>
        <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
          {(currentUser && ticket) ? (
              <div className={"flex flex-col items-center justify-center"}>
                <div className={"w-full items-center flex"}>
                  <div className={"flex flex-col max-w-screen-lg w-full mx-auto"}>
                    <div className={"flex flex-row justify-between items-center mb-5"}>
                      <div className={"flex flex-col"}>
                        <div className={"flex space-x-3 items-center mb-1"}>
                          <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
                            {t("ticketDetails.title", {id: ticketId})}
                          </div>
                          <Tag color={statusColor}>{statusLabel}</Tag>
                        </div>
                        <div className={"flex md:space-x-2 items-center"}>
                          <div className={`size-2 rounded-full ${hasReceivedResponse ? "bg-green-400" : "bg-orange-300"}`}/>
                          <p className={`m-0 font-bold ${hasReceivedResponse ? "text-green-400" : "text-orange-300"}`}>{(hasReceivedResponse ? t("ticketDetails.statusType.received") : t("ticketDetails.statusType.waiting"))}</p>
                        </div>
                      </div>
                      <Button onClick={() => navigate(-1)}>{t("ticketDetails.returnToTicketList")}</Button>
                    </div>
                    <div>
                      <div className={"mt-5 rounded-lg w-full h-[700px] flex flex-col space-y-5 shadow-md border border-solid border-neutral-200 p-4 bg-white overflow-auto"}>
                        {ticketReplies.length > 0 ? (
                            <>
                              {ticketReplies.map((reply, index) => {
                                const sameDay = (format(now, "yyyy-MM-dd") === format(new Date(reply.createdAt!), "yyyy-MM-dd"));
                                const replyDate = (sameDay ? format(now, "hh:mm") : format(now, "dd/MM/yyyy hh:mm"));
                                const isMine = (reply.createdBy === currentUser.uuid);
                                return (
                                    <div key={index} className={`flex space-x-3 items-end ${isMine ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                      <div className={`rounded-full aspect-square p-2 shadow-md ${isMine ? "bg-blue-400" : "bg-gray-400"}`}>
                                        <UserOutlined className={"text-white text-2xl"}/>
                                      </div>
                                      <div className={`max-w-[60%] flex flex-col justify-center text-white p-3 ${isMine ? "bg-blue-400 rounded-l-2xl rounded-tr-2xl items-end text-end" : "bg-gray-400 rounded-r-2xl rounded-tl-2xl items-start text-start"}`}>
                                        <span className={"text-xs font-bold"}>{reply.user.name}</span>
                                        <p className={"text-xs my-1"}>{reply.message}</p>
                                        <span className={"text-[10px] font-bold"}>{replyDate}</span>
                                      </div>
                                    </div>
                                )
                              })}
                            </>
                        ) : (
                            <div className={"h-full flex items-center justify-center"}>
                              <p className={"text-lg font-bold text-neutral-300"}>{t("ticketDetails.noMessageFound")}</p>
                            </div>
                        )}
                      </div>
                      {(ticket.status === TicketBaseDto.status.OPEN || ticket.status === TicketBaseDto.status.IN_CHARGE) && (
                          <div className={"mt-5 rounded-lg shadow-md border border-solid border-neutral-200 p-4 bg-white"}>
                            <div className={"flex flex-col"}>
                              <div className={"flex space-x-5 items-start"}>
                                <Input.TextArea autoSize maxLength={500} onChange={(e) => setMessage(e.target.value)} value={message} size="large" className="flex-grow" placeholder={t("ticketDetails.messageInputPlaceholder")}/>
                                <Button type={"primary"} onClick={handleReplySubmit} size={"large"} shape={"circle"} icon={
                                  <SendOutlined/>}/>
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          ) : (
              <div className={"w-full flex items-center justify-center p-7 border border-blue-300 bg-blue-300/10 rounded-md"}>
                <p className={"text-lg text-neutral-black-700 font-bold mb-0"}>Recupero informazioni sul ticket...</p>
              </div>
          )}

          {contextHolder}
        </div>
      </>
  );
};
