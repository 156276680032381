import { Button, Divider, Form, Input, notification, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../../api";

export const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const onFinish = (values: FieldType) => {
    AuthService.updatePasswordAuthController({
      confirmPassword: values.confirmPassword,
      password: values.password,
      token: token ?? "",
    })
      .then((res) => {
        const btn = (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => navigate("/login")}
            >
              Ok
            </Button>
          </Space>
        );
        if (res) {
          api.info({
            message: t("recoveryPassword.backToLogin"),
            description: t("recoveryPassword.loginWithNewPassword"),
            btn,
          });
        }
      })
      .catch((err) => {
        if (err) {
          api.error({
            message: t("recoveryPassword.error"),
            description: t("recoveryPassword.noPasswordUpdated"),
          });
        }
      });
  };

  type FieldType = {
    password: string;
    confirmPassword: string;
  };

  return (
    <div className="bg-[white] rounded-lg p-10 ml-5 h-[100%] relative">
      <p className="text-center text-2xl font-bold">
        {t("recoveryPassword.insertNewPassword")}
      </p>
      {contextHolder}
      <div className="flex justify-center">
        <Form
          className="mt-10 w-64"
          name="resetPasswordForm"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label={t("common.password")}
            name="password"
            hasFeedback
            style={{
              fontWeight: 600,
            }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item<FieldType>
            label={t("registration.confirmPassword")}
            name="confirmPassword"
            hasFeedback
            style={{
              fontWeight: 600,
            }}
            rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('registration.errors.passwordsNotMatch')));
                  },
                }),
              ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="bg-blue-500 text-xs font-semibold mt-4"
            >
              {t("recoveryPassword.changePassword")}
            </Button>
          </Form.Item>

          <Button
            className="underline text-blue-500 cursor-pointer font-bold"
            block
            onClick={() => navigate("/login")}
            type="link"
          >
            Torna al login
          </Button>
        </Form>
      </div>
    </div>
  );
};
