import { Col, Row, Tag } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrderBaseDto } from "../api";
import { numberWithCommasAndFixedDecimals } from "../utils/numberWithCommasAndFixedDecimals";
import { tagColorOrderStatus } from "../utils/tagColorOrderStatus";
interface Props {
  order?: OrderBaseDto;
  showOrderDetails?: boolean;
  monthYear?: string;
}

export const OrderBox = ({
  order,
  showOrderDetails = true,
  monthYear,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const orderTotal = useMemo(() => {
    if (!order) return 0;
    return order.rows.reduce(
      (acc, item) => acc + (item.price * (1 - item.variant?.discountRate) * item.qty),
      0
    );
  }, [order]);

  const totalShipping = useMemo(() => {
    if (Number(orderTotal) === 0) return 0;
    return (Math.round(Number(orderTotal) / 1000) + 1) * 33;
}, [orderTotal])
  return (
    <div>
      <div
        style={{
          boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
          borderRadius: "4px",
          border: "1px solid var(--neutral-200, #DBDBDB)",
          padding: "16px",
          marginTop: 5,
        }}
      >
        {showOrderDetails && (
          <Row>
            <Col span={3}>
              <div className="font-semibold text-xs text-neutral-300 leading-4 tracking-[0.06px]">
                {t("order.orderNumber")}
              </div>
              <div className="text-neutral-500 text-lg font-bold leading-7 tracking-[0.09px]">
                {order?.id}
              </div>
            </Col>
            <Col span={21}>
              <div className="flex justify-between">
                <Tag
                  color={tagColorOrderStatus(order?.status ?? "")?.tag}
                  style={{
                    borderRadius: 50,
                    borderWidth: 0,
                    // height: 20,
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontWeight: "bold",
                    color: tagColorOrderStatus(order?.status ?? "")?.text,
                  }}
                  key={order?.status}
                >
                  <div className="capitalize flex">
                    {order?.status?.toLocaleLowerCase()}
                  </div>
                </Tag>
                <div
                  onClick={() => navigate(`/profile/orders/${order?.id}`)}
                  className="mt-4 text-neutral-300 text-xs font-semibold leading-4 tracking-[0.06px] underline cursor-pointer"
                >
                  {t("order.orderDetails")}
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row className={showOrderDetails ? "mt-5" : "mt-0"}>
          <Col span={showOrderDetails ? 6 : 12}>
            <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
              {t("order.orderDate")}
            </div>
            <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
              {dayjs(order?.createdAt).format("DD-MM-YYYY")}
            </div>
          </Col>
          <Col span={showOrderDetails ? 6 : 12}>
            <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
              {t("common.total")}
            </div>
            <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
              {numberWithCommasAndFixedDecimals(order?.total! + order?.vat! + order?.shippingVAT! + order?.shippingCost!)} €
            </div>
          </Col>
          <Col
            className={!showOrderDetails ? "mt-5" : "mt-0"}
            span={showOrderDetails ? 6 : 12}
          >
            <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
              {t("common.paymentMethod")}
            </div>
            <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
              {order?.paymentMethod?.name}
            </div>
          </Col>
          <Col
            className={!showOrderDetails ? "mt-5" : "mt-0"}
            span={showOrderDetails ? 6 : 12}
          >
            <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
              {t("order.orderStatus")}
            </div>
            <div className="text-neutral-500 text-xs leading-4 tracking-[0.06px]">
              <Tag
                color={tagColorOrderStatus(order?.status ?? "")?.tag}
                style={{
                  borderRadius: 50,
                  borderWidth: 0,
                  fontWeight: "bold",
                  color: tagColorOrderStatus(order?.status ?? "")?.text,
                }}
                key={order?.status}
              >
                <div className="capitalize">
                  {order?.status?.toLocaleLowerCase()}
                </div>
              </Tag>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
