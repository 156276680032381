/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductRequestDto } from '../models/CreateProductRequestDto';
import type { PatchProductRequestDto } from '../models/PatchProductRequestDto';
import type { UpdateProductRequestDto } from '../models/UpdateProductRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductService {
    /**
     * @param id
     * @param sizes
     * @param colors
     * @param brands
     * @param categories
     * @param fromPrice
     * @param toPrice
     * @param pageIndex
     * @param pageSize
     * @param withVariants
     * @param search
     * @returns any
     * @throws ApiError
     */
    public static searchProductController(
        id?: number,
        sizes?: Array<string>,
        colors?: Array<string>,
        brands?: Array<number>,
        categories?: Array<number>,
        fromPrice?: number,
        toPrice?: number,
        pageIndex?: number,
        pageSize?: number,
        withVariants?: boolean,
        search?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/search',
            query: {
                'id': id,
                'sizes': sizes,
                'colors': colors,
                'brands': brands,
                'categories': categories,
                'fromPrice': fromPrice,
                'toPrice': toPrice,
                'pageIndex': pageIndex,
                'pageSize': pageSize,
                'withVariants': withVariants,
                'search': search,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static checkExistsProductController(
        requestBody: {
            path?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/check-exists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static uploadProductController(
        formData: {
            file?: Blob;
            path?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/media',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static deleteProductController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneProductController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static replaceProductController(
        id: number,
        requestBody: UpdateProductRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateProductController(
        id: number,
        requestBody: PatchProductRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param ids
     * @returns any
     * @throws ApiError
     */
    public static deleteManyProductController(
        ids: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/delete/many',
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static restoreProductController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/restore/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findAllProductController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static createProductController(
        requestBody: CreateProductRequestDto,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static countProductController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/count',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
