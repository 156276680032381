import {Button, Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Check} from "../../../Components/icons/Check";
import {Layout} from "../../../Components/Layout/Layout";

export const Order = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  //TODO: inserire i dati una volta creato un ordine
  return (
    <Layout>
      <div className="p-6 xl:p-16">
        <Row>
          <Col span={15}>
            <div className="text-xs font-bold leading-5 tracking-[0.07px]">
              {t("order.summaryOrder")}
            </div>
            <Row gutter={[16, 16]}>
              <Col xs={8} md={4}>
                <Check/>
              </Col>
              <Col xs={16} md={20}>
                <div className="text-neutral-300 text-lg font-medium leading-7 tracking-[0.09px]">
                  {t("order.orderNumber")} #861402
                </div>
                <div className="text-neutral-500 text-2xl font-bold leading-8 tracking-[0.12px]">
                  {t("order.thanksForYourOrder")}
                </div>
              </Col>
            </Row>
            <div className="mt-4 border-solid border-[1px] border-neutral-200 rounded-[4px] p-4">
              <div className="text-neutral-500 text-xs font-semibold leading-4 tracking-[0.06px]">
                {t("order.orderUpdates")}
              </div>
              <div className="text-neutral-500 text-xs leading-[16px] tracking-[0.06px] mt-2">
                {t("order.emailWithUpdates")}
              </div>
            </div>
            <div className="mt-4 border-solid border-[1px] border-neutral-200 rounded-t-[4px] p-4">
              <Row>
                <Col xl={4} md={6} sm={24}>
                  <div className="text-neutral-300 text-xs font-semibold leading-4 tracking-[0.06px]">
                    {t("common.contact")}
                  </div>
                </Col>
                <Col xl={20} md={18} sm={24}>
                  <div className="text-neutral-500 text-xs font-semibold leading-[16px] tracking-[0.06px]">
                    mariorossi00@gmail.com
                  </div>
                </Col>
              </Row>
            </div>
            <div className="border-solid border-r-[1px] border-l-[1px] border-t-0 border-b-0 border-neutral-200 p-4">
              <Row>
                <Col md={6} sm={24} xl={4}>
                  <div className="text-neutral-300 text-xs font-semibold leading-4 tracking-[0.06px]">
                    {t("delivery.address")}
                  </div>
                </Col>
                <Col md={18} sm={24} xl={20}>
                  <div className="text-neutral-500 text-xs font-semibold leading-[16px] tracking-[0.06px]">
                    Via Molise 49/3, <br></br>61029 Urbino, <br></br>Italia
                  </div>
                </Col>
              </Row>
            </div>
            <div className="border-solid border-[1px] border-neutral-200 rounded-b-[4px] p-4">
              <Row>
                <Col xl={4} md={6} sm={24}>
                  <div className="text-neutral-300 text-xs font-semibold leading-4 tracking-[0.06px]">
                    {t("cart.payment")}
                  </div>
                </Col>
                <Col xl={20} md={18} sm={24}>
                  <div className="text-neutral-500 text-xs font-semibold leading-[16px] tracking-[0.06px]">
                    Mastercard
                  </div>
                </Col>
              </Row>
            </div>
            <div className="flex justify-between mt-4">
              <div className="text-sm leading-5 tracking-[0.07px] text-neutral-400">
                {t("order.needHelp")}{" "}
                <span className="text-blue-400 font-semibold text-xs leading-4 tracking-[0.06px] underline">
                  {t("order.contactUs")}
                </span>
              </div>
              <div>
                <Button
                  onClick={() => navigate("/products")}
                  type="primary"
                  className="bg-blue-400 text-xs font-semibold leading-4 tracking-[0.06px] text-white"
                >
                  {t("order.continueShopping")}
                </Button>
              </div>
            </div>
          </Col>
          <Col span={8} offset={1}>

            {/* <MyProductSummary
              total={"3459,76"}
              number={23}
              productName={"Copripiumino due piazze"}
              brand="Iclub"
              color="blue"
              size="XL"
              price={230}
              quantity={20}
            ></MyProductSummary> */}
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
