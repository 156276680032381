export type Payment = {
  title: string;
  icons: React.ReactNode[];
  description: string;
};

export enum UserRole {
  SALES = "SALES_MANGER",
  CLIENT = "B2B_CLIENT",
  ADMIN = "SUPER_ADMIN",
}

export enum FilterOperator {
  MINOR = "MINOR",
  EQUAL = "EQUAL",
  FROMTO = "FROMTO",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DISABLED = "DISABLED",
  SUSPENDED='SUSPENDED'
}

export enum Sections {
  users = "USERS",
  companies = "COMPANIES",
  products = "PRODUCTS",
  brands = "BRANDS",
  roles = "ROLES",
  orders = "ORDERS",
  variants = "VARIANTS",
  addresses = "ADDRESSES",
  categories = "CATEGORIES",
  paymentMethods = "PAYMENTMETHODS",
  translations = "TRANSLATIONS",
  languages = "LANGUAGES",
  sections = "SECTIONS",
  transactions = 'TRANSACTIONS',
  roleSectionPermissions = "ROLESECTIONSPERMISSIONS",
  userSectionPermissions = "USERSECTIONSPERMISSIONS",
}
