import { Col, Row } from "antd";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";
import { useNavigate } from "react-router-dom";

export const Background: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();


  return (
    <div className={`flex-auto ${isMobile ? "h-screen" : "h-screen"} bg-[#02296C]`}>
      <Row className={` ${isMobile ? "p-2" : "p-10 h-screen"} `}>
        <Col
          span={isMobile ? 24 : 12}
          className={`${isMobile ? "mt-2" : ""} relative`}
        >
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            <Logo></Logo>
          </div>
          {!isMobile && (
            <p className="text-[white] text-[32px] font-normal absolute bottom-0 w-[70%]">
              {t("login.introText")}
            </p>
          )}
        </Col>
        <Col span={isMobile ? 24 : 12} className={isMobile ? "mt-4" : ""}>
         {children}
        </Col>
      </Row>
    </div>
  );
};
