import React, {useContext, useEffect, useState} from "react";
import {Button, Form, message, Pagination, Popconfirm, Popover, Table, Tooltip,} from "antd";
import {Sections, UserRole, UserStatus} from "../../../utils/types";
import {UserBaseDto, UserSectionPermissionBaseDto, UserService,} from "../../../api";
import {Trash} from "../../../Components/icons/Trash";
import {SortOrder} from "antd/es/table/interface";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../contexts/authContext";
import {useUserPermissions} from "../../../utils/hooks/useUserPermissions";
import {EditableCell} from "./components/EditableCell";
import ClientSearchFilters from "../../../Components/clients/ClientSearchFilters";
import {toFilter, toIncludes} from "../../../utils/request";
import {SetValueByPath} from "../../../utils";
import {KeyOutlined} from "@ant-design/icons";

interface ClientTableRowProps {
  id: any;
  name: string;
  surname: string;
  email: string;
  status: UserStatus;
  adminReference: string;
  company: any;
  uuid: string;
}

export const Clients: React.FC = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<ClientTableRowProps[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const {t} = useTranslation();
  const {permissions, currentUser} = useContext(AuthContext);
  const {canUser} = useUserPermissions(permissions, currentUser);
  const [isLoading, setIsLoading] = useState(false);

  const [searchFilters, setSearchFilters] = useState<
    Record<string, any> | undefined
  >(undefined);

  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 5,
    totalCount: 0,
  });

  const getClientList = (sortedInfo?: any) => {
    let sortFilter = {};
    if (sortedInfo) {
      const sortField = sortedInfo.columnKey;
      const sortOrder: SortOrder = sortedInfo.order;
      SetValueByPath(
        sortFilter,
        sortField,
        sortOrder === "ascend" ? "ASC" : "DESC"
      );
    }

    type containsIgnore = {
      $containsIgnore?: string,
      $in?: Array<any>
    }
    let filters: {
      name?: containsIgnore,
      surname?: containsIgnore,
      email?: containsIgnore,
      status?: containsIgnore,
      company?: {
        customerCodeString?: containsIgnore,
        escapedName?: containsIgnore
      },
      roles?: {
        role: {
          name: string
        }
      }
    } = {};

    if (searchFilters) {

      if (searchFilters.name) filters = {
        ...filters,
        name: {
          $containsIgnore: searchFilters.name
        },
      }
      if (searchFilters.surname) filters = {
        ...filters,
        surname: {
          $containsIgnore: searchFilters.surname
        },
      }
      if (searchFilters.email) filters = {
        ...filters,
        email: {
          $containsIgnore: searchFilters.email
        },
      }
      if (searchFilters.status && searchFilters.status.length > 0) filters = {
        ...filters,
        status: {
          $in: searchFilters.status
        },
      }
      if (searchFilters.company) filters = {
        ...filters,
        company: {
          escapedName: {
            $containsIgnore: searchFilters.company.replace(/[^a-zA-Z1-9 ]/g, '')
          }
        },
      }
      if (searchFilters.code) filters = {
        ...filters,
        company: {
          ...(filters.company || {}),
          customerCodeString: searchFilters.code.toString()
        }
      }
      setIsLoading(true);
    }
    filters = {
      ...filters,
      roles: {
        role: {
          name: UserRole.CLIENT,
        },
      },
    };
    UserService.findAllUserController(
      pagination.current ?? undefined,
      pagination.pageSize ?? undefined,
      toFilter(filters),
      (sortedInfo) ? JSON.stringify(sortFilter) : undefined,
      undefined,
      toIncludes<UserBaseDto>({
        company: {
          gammaCustomer: true
        },
      })
    )
      .then((res) => {
        setData(res.data);
        setPagination((value) => ({
          ...value,
          totalCount: res.totalCount,
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const callMessage = (text: string, type: "success" | "error") => {
    if (type === "success") {
      messageApi.success({
        content: text,
        style: {
          marginTop: 50,
        },
      });
    } else {
      messageApi.error({
        content: text,
        style: {
          marginTop: 50,
        },
      });
    }
  };

  const isEditing = (record: ClientTableRowProps) => record.id === editingKey;

  const editClient = (
    record: Partial<ClientTableRowProps> & { id: React.Key }
  ) => {
    form.setFieldsValue({
      name: "",
      surname: "",
      email: "",
      status: "",
      code: record.company.code,
      companyName: record.company.name,
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancelEditing = () => {
    setEditingKey("");
  };

  const saveClient = async (key: React.Key | any) => {
    try {
      const row = (await form.validateFields()) as ClientTableRowProps;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setEditingKey("");
      } else {
        newData.push(row);
        setEditingKey("");
      }
      UserService.updateUserController(key, {
        surname: row.surname,
        name: row.name,
        email: row.email,
        status: row.status,
      })
        .then((res) => {
          callMessage(
            t("clients.messages.userSuccessfullyModified"),
            "success"
          );
          setData(newData);
        })
        .catch((err) => {
          callMessage(t("clients.messages.noPossibleChangeUser"), "error");
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const confirmDeleteUser = (userId: any) => {
    UserService.deleteUserController(userId).then((res) => {
      getClientList();
      callMessage(t("clients.messages.userRemoved"), "success");
    });
  };

  const statusSegment = (tooltip: string, color: string) => {
    return (
      <Tooltip title={<div className="capitalize">{tooltip}</div>}>
        <div
          className={`w-4 h-4 rounded-full ml-4 cursor-pointer`}
          style={{
            backgroundColor: color,
          }}
        ></div>
      </Tooltip>
    );
  };

  const showStatus = (text: string) => {
    switch (text) {
      case UserStatus.ACTIVE:
        return statusSegment(t("userStatus.active"), "#8aff8a");
      case UserStatus.SUSPENDED:
        return statusSegment(t("userStatus.inactive"), "#d6d2d2");
      case UserStatus.DISABLED:
        return statusSegment(t("userStatus.disabled"), "#ffeb3b");
    }
  };

  const columns = [
    {
      title: t("common.code"),
      dataIndex: "code",
      key: "company.code",
      editable: false,
      sorter: true,
      render: (text: string, o: any) => {
        return <div>{o.company?.code}</div>;
      },
    },
    {
      title: t("common.denomination"),
      dataIndex: "companyName",
      key: "companyName",
      editable: true,
      sorter: true,
      render: (text: string, item: any) => {
        return item.company?.name;
      }
    },
    {
      title: t("common.priceListNum"),
      dataIndex: "priceListNum",
      key: "priceListNum",
      editable: false,
      sorter: false,
      render: (text: string, item: any) => {
        return item.company.gammaCustomer.priceListNum;
      }
    },
    {
      title: t("common.name"),
      dataIndex: "name",
      key: "name",
      editable: true,
      sorter: true,
    },
    {
      title: t("common.surname"),
      dataIndex: "surname",
      key: "surname",
      editable: true,
      sorter: true,
    },
    {
      title: t("common.email"),
      dataIndex: "email",
      key: "email",
      editable: true,
      sorter: true,
    },
    {
      title: t("common.status"),
      dataIndex: "status",
      key: "status",
      editable: true,
      sorter: true,
      render: (text: string) =>
        // <div className="capitalize">{text.toLocaleLowerCase()}</div>
      {
        return <div>{showStatus(text)}</div>;
      },
    },
    {
      title: t("common.actions"),
      dataIndex: "actions",
      width: 200,
      render: (_: any, record: ClientTableRowProps) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            {canUser(
              UserSectionPermissionBaseDto.permission.UPDATE,
              Sections.users
            ) && (
              <Button
                className="font-semibold"
                type="primary"
                onClick={() => saveClient(record.id)}
              >
                {t("common.save")}
              </Button>
            )}
            {canUser(
              UserSectionPermissionBaseDto.permission.DELETE,
              Sections.users
            ) && (
              <Popconfirm
                title={t("clients.messages.sureToCancel")}
                onConfirm={cancelEditing}
                className="cursor-pointer"
              >
                <Button className="font-semibold text-blue-400" type="link">
                  {t("common.cancel")}
                </Button>
              </Popconfirm>
            )}
          </span>
        ) : (
          <div className="flex justify-start">
            {canUser(
              UserSectionPermissionBaseDto.permission.UPDATE,
              Sections.users
            ) && (
              <Button
                className="p-0"
                type="text"
                disabled={editingKey !== ""}
                onClick={() => editClient(record)}
              >
                <div
                  className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] text-center underline cursor-pointer">
                  {t("common.edit")}
                </div>
              </Button>
            )}
            <Popconfirm
              title={t("clients.messages.sureToDeleteUser")}
              onConfirm={() => confirmDeleteUser(record.id)}
              onCancel={cancelEditing}
              okText={t("clients.messages.yes")}
              cancelText={t("clients.messages.no")}
              className="cursor-pointer"
            >
              <Button
                disabled={editingKey !== ""}
                className="ml-2 mt-1"
                type="text"
              >
                <Trash/>
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: t("common.password"),
      dataIndex: "pass",
      render: (_: any, record: ClientTableRowProps) => {
        return (
          <div>
            <Popconfirm
              title={t("dashboard.clients.setRandomPassword")}
              className="cursor-pointer"
              onConfirm={() => {
                UserService.setCredentialsUserController(record.uuid).then();
              }}
            >
              <KeyOutlined/>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ClientTableRowProps) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    getClientList();
  }, [pagination.current, pagination.pageSize, searchFilters]);

  return (
    <div>
      <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px] mb-4">
        {t("clients.customers")}
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
          background: "var(--neutral-white, #FFF)",
          boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
          height: "100%",
        }}
      >
        <div className={"p-5 md:p-7 md:pl-14 md:pr-14"}>
          <div>
            <div className="flex justify-end items-center mb-5">
              <Popover
                trigger={"click"}
                placement="bottomRight"
                title={t('tickets.searchFilters')}
                content={<ClientSearchFilters onSubmit={setSearchFilters}/>}
              >
                <Button>{t('tickets.searchFilters')}</Button>
              </Popover>
            </div>
          </div>
          <div className={"flex flex-col space-y-4 overflow-auto"} id={"customer-table"}>
            <Form form={form} component={false}>
              <Table
                onChange={(pagination, filters, sorter) =>
                  getClientList(sorter)
                }
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                className="mt-4"
                loading={isLoading}
                dataSource={data.map((row) => ({
                  key: row.id,
                  ...row,
                }))}
                columns={mergedColumns}
                pagination={false}
                showSorterTooltip={false}
              />
            </Form>

            <div className={"self-center"}>
              <Pagination
                responsive={true}
                defaultCurrent={pagination.current}
                total={pagination.totalCount}
                defaultPageSize={5}
                showSizeChanger={true}
                pageSizeOptions={[5, 10, 20, 30, 50]}
                onShowSizeChange={(current, size) =>
                  setPagination((value) => ({
                    ...value,
                    current: current,
                    pageSize: size,
                  }))
                }
                onChange={(page, pageSize) =>
                  setPagination((value) => ({
                    ...value,
                    current: page - 1,
                    pageSize: pageSize,
                  }))
                }
              />
            </div>
          </div>
          {contextHolder}
        </div>
      </div>
    </div>
  );
}

