import {  Image } from "antd";
import { useCart } from "../utils/hooks/useCart";
import { numberWithCommasAndFixedDecimals } from "../utils/numberWithCommasAndFixedDecimals";
import { useTranslation } from "react-i18next";
import { DeleteFilled } from "@ant-design/icons";
import {DEFAULT_FALLBACK} from "../App";

interface Props {
  showTrashButton?: boolean;
  showQuantity?: boolean;
  showSize?: boolean;
  showColor?: boolean;
  name: string;
  brand: string;
  colors: string[];
  size: string;
  quantity: number;
  price: number;
  id: number;
  changeImageSpan?: number;
  updateCartRows?: Function;
  media?: string;
  productId: number
}

export const CartCard = ({
  showTrashButton = true,
  showQuantity = true,
  showSize = true,
  showColor = true,
  id,
  name,
  brand,
  colors,
  size,
  quantity,
  price,
  changeImageSpan = 7,
  updateCartRows,
  media,
                           productId
}: Props) => {
  const { removeItemFromCart } = useCart();
  const { t } = useTranslation();

  return (
    <div>
      <div className={"flex flex-row space-x-3 items-center"}>
        <Image
          preview={false}
          className="rounded-lg object-cover aspect-square flex-grow-0"
          width={120}
          src={media ? media : DEFAULT_FALLBACK}
        />
        <div className={"flex flex-col space-y-3 flex-grow"}>
          <div>
            <p className="text-blue-400 text-md font-semibold mb-0 leading-4 capitalize">
              <a href={'/products/' + productId}>
                {name?.toLowerCase()}
              </a>
            </p>
            {brand && (
              <span className="text-neutral-300 text-[10px] font-medium leading-[16px]">
                {brand}
              </span>
            )}
          </div>

          <div className="flex space-x-3 items-center w-full">
            {colors && (
              <div
                className={
                  "rounded-full flex rotate-45 size-5 border border-solid border-neutral-200 overflow-hidden"
                }
              >
                {colors.map((color, index) => (
                  <div
                    key={index}
                    className={"h-full w-full"}
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            )}
            {size && (
              <div className="uppercase bg-neutral-100 px-2 py-1 text-xs font-medium rounded-full flex items-center justify-center">
                {size}
              </div>
            )}
            {quantity && (
              <span className="text-xs font-medium text-nowrap">
                Qtà: {quantity} {t("common.packages")}
              </span>
            )}
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="mt-1 bg-blue-50 rounded-full text-center text-sm px-4 py-2 text-blue-400 font-semibold leading-4">
              {numberWithCommasAndFixedDecimals(price)} €
            </div>
            {showTrashButton && (
              <DeleteFilled
                className={
                  "text-neutral-200 hover:text-blue-400 text-lg cursor-pointer hover:scale-110 transition"
                }
                onClick={() =>
                  removeItemFromCart(id).then((res) => {
                    updateCartRows && updateCartRows();
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={"w-full border-solid border-[0.5px] border-neutral-200 my-7"}
      />
    </div>
  );
};
