import {Collapse, Pagination} from "antd";
import dayjs from "dayjs";
import {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {
    GetOrderResponseDto, OrderBaseDto,
    OrderService, TransactionBaseDto, TransactionService,
} from "../../../api";
import { OrderBox } from "../../../Components/OrderBox";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
import { toIncludes } from "../../../utils/request";
import "dayjs/locale/it";
import {format} from "date-fns";
import {it} from "date-fns/locale";
import {RightOutlined} from "@ant-design/icons";
import {Order} from "../Order/Order";

export const Transactions = () => {
    const { t } = useTranslation();
    const { isMobile } = useBreakpoint();
    const [transactions, setTransactions] = useState<TransactionBaseDto[]>([])
    const [pages, setPages] = useState([0, 10]);
    const [totalCount, setTotalCount] = useState(0);

    dayjs.locale("it");

    // useEffect(() => {
    //     getOrders();
    // }, [pages]);
    //
    // const groupContents = groupedOrders.map((group) => ({
    //     key: group.month,
    //     label: <span className={"text-md capitalize font-bold text-blue-400"}>{group.month}</span>,
    //     children: (
    //         <div className={"flex flex-col space-y-3"}>
    //             {group.orders.map((item: OrderBaseDto) => {
    //                 return <div key={item.id}><OrderBox order={item}/></div>;
    //             })}
    //         </div>
    //     )
    // }))

    return (
        <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
            <div className="text-sm text-blue-400 font-bold leading-5 tracking-[0.07px]">
                {t("order.orderHistory")}
            </div>
            {/*<div className="mt-6">*/}
            {/*    {groupContents.length > 0 ? (*/}
            {/*        <Collapse expandIcon={RightOutlined} accordion ghost items={groupContents} defaultActiveKey={[groupedOrders[0].month]} />*/}
            {/*    ) : (*/}
            {/*        <div></div>*/}
            {/*    )}*/}
            {/*</div>*/}
            {/*<div className="flex justify-center p-4">*/}
            {/*    <Pagination*/}
            {/*        responsive={true}*/}
            {/*        defaultCurrent={pages[0] + 1}*/}
            {/*        pageSize={pages[1]}*/}
            {/*        onChange={(page, pageSize) => {*/}
            {/*            setPages([page - 1, pages[1]]);*/}
            {/*            if (pageSize) {*/}
            {/*                setPages([page - 1, pageSize]);*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        total={totalCount}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};
