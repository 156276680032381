/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderRowBaseDto } from './OrderRowBaseDto';
import type { PaymentMethodBaseDto } from './PaymentMethodBaseDto';
import type { TransactionBaseDto } from './TransactionBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type CreateOrderResponseDto = {
    id?: number;
    number?: string;
    uuid?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    status: CreateOrderResponseDto.status;
    deliveredDate: string | null;
    shippingDate: string | null;
    shippingCost: number | null;
    shippingVAT: number | null;
    shippingAddress: Record<string, any>;
    total: number;
    vat: number;
    discount: number;
    credit: number;
    user: UserBaseDto;
    rows: Array<OrderRowBaseDto>;
    transaction: TransactionBaseDto;
    paymentMethod: PaymentMethodBaseDto;
    gammaRefId: number | null;
};
export namespace CreateOrderResponseDto {
    export enum status {
        PENDING = 'PENDING',
        CONFIRMED = 'CONFIRMED',
        PROCESSING = 'PROCESSING',
        SHIPPED = 'SHIPPED',
        DELIVERED = 'DELIVERED',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
        PAID = 'PAID',
        PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
    }
}

