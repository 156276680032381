import { FC } from "react";
import { IconPropsType } from "./types";

export const Plus: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...fill}
    >
      <path
        d="M9.66667 15.4167C9.66667 15.8769 10.0398 16.25 10.5 16.25C10.9602 16.25 11.3333 15.8769 11.3333 15.4167V10.8333H15.9167C16.3769 10.8333 16.75 10.4602 16.75 10C16.75 9.53976 16.3769 9.16667 15.9167 9.16667H11.3333V4.58333C11.3333 4.1231 10.9602 3.75 10.5 3.75C10.0398 3.75 9.66667 4.1231 9.66667 4.58333V9.16667H5.08333C4.6231 9.16667 4.25 9.53976 4.25 10C4.25 10.4602 4.6231 10.8333 5.08333 10.8333H9.66667V15.4167Z"
        fill={fill}
      />
    </svg>
  );
};
