import { FC } from "react";
import { IconPropsType } from "./types";

export const LeftIcon: FC<IconPropsType> = (props) => {
  return (
    <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75582 5.59068C10.0813 5.26524 10.0813 4.73761 9.75582 4.41217C9.43039 4.08673 8.90275 4.08673 8.57731 4.41217L3.58109 9.40839C3.57855 9.4109 3.57602 9.41343 3.57351 9.41597L3.28268 9.7068C3.11996 9.86951 3.11996 10.1333 3.28268 10.2961L3.57351 10.5869C3.57602 10.5894 3.57855 10.5919 3.58109 10.5945L8.57731 15.5907C8.90275 15.9161 9.43039 15.9161 9.75582 15.5907C10.0813 15.2652 10.0813 14.7376 9.75582 14.4122L6.17841 10.8348H15.8332C16.2934 10.8348 16.6665 10.4617 16.6665 10.0014C16.6665 9.54119 16.2934 9.16809 15.8332 9.16809H6.17841L9.75582 5.59068Z"
        fill="#0950A8"
      />
    </svg>
  );
};
