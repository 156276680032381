import {Button, Dropdown, notification, Pagination, Table, Tag} from "antd";
import { useTranslation } from "react-i18next";
import {useContext, useEffect, useMemo, useState} from "react";
import {
  TicketBaseDto,
  TicketService,
  TicketTypeBaseDto, TicketTypeService,
} from "../../../api";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
import { toIncludes } from "../../../utils/request";
import {NewTicketModal} from "../../../Components/tickets/NewTicketModal";
import {Link} from "react-router-dom";
import {CloseTicketModal} from "../../../Components/tickets/CloseTicketModal";
import {format} from "date-fns";
import {it} from "date-fns/locale";
import {AuthContext} from "../../../contexts/authContext";
import {getTicketStatusColor} from "../../admin/tickets/Tickets";

export const Tickets = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const { isMobile } = useBreakpoint();
  const [tickets, setTickets] = useState<TicketBaseDto[]>([]);
  const {currentUser} = useContext(AuthContext);

  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 5,
    totalCount: 0
  })
  
  const [showNewTicketModal, setShowNewTicketModal] = useState(false);
  const [showCloseTicketModal, setShowCloseTicketModal] = useState(false);
  const [ticketTypes, setTicketTypes] = useState<TicketTypeBaseDto[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<TicketBaseDto | null>(null);
  
  useEffect(() => {
    getTicketTypes();
    getTicketsList();
  }, []);

  useEffect(() => {
    getTicketsList();
  }, [pagination.current, pagination.pageSize]);
  
  const getTicketTypes = () => {
    TicketTypeService.findAllTicketTypeController().then((res) => {
      setTicketTypes(res.data);
    });
  }
  
  const getTicketsList = () => {
    TicketService.findAllTicketController(
        pagination.current,
        pagination.pageSize,
        undefined,
        undefined,
        undefined,
        toIncludes<TicketBaseDto>({lastReply: true, type: true}),
    ).then((res) => {
      if(!res.data) return;
      setTickets(res.data);
      setPagination((value) => ({...value, totalCount: res.totalCount}))
    })
  }
  
  const ticketOptions = (ticket: TicketBaseDto) => {
    const options = [
      {
        key: `#${ticket.id}_messages`,
        label: (
            <Link className={"text-xs"} to={`/profile/tickets/${ticket.id}`}>{t("tickets.tableRowOptions.viewMessages")}</Link>
        ),
      },
    ]
    
    if(ticket.status === TicketBaseDto.status.OPEN || ticket.status === TicketBaseDto.status.IN_CHARGE) {
      options.splice(1, 0, {
        key: `#${ticket.id}_close`,
        label: (
            <span className={"text-xs"} onClick={() => handleTicketClose(ticket)}>{t("tickets.tableRowOptions.closeTicket")}</span>
        ),
      })
    }
    
    return options;
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("tickets.status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("tickets.title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("tickets.messageStatus"),
      dataIndex: "messageStatus",
      key: "messageStatus",
    },
    {
      title: t("tickets.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tickets.lastMessage"),
      dataIndex: "lastMessage",
      key: "lastMessage",
    },
    {
      title: t("tickets.lastUpdate"),
      dataIndex: "dateLastMessage",
      key: "dateLastMessage",
    },
    {
      title: t("common.actions"),
      dataIndex: "actions",
      width: 200,
    },
  ];

  const dataSource = useMemo(() => {
    if(!tickets) return [];
    
    return tickets.map((ticket) => {

      const hasReceivedResponse = (ticket.lastReply?.createdBy !== currentUser?.uuid);

      const messageStatusLabel = (hasReceivedResponse ? t("dashboard.tickets.statusType.received") : t("dashboard.tickets.statusType.waiting"));
      const messageStatusColor = (hasReceivedResponse ? "#3DA172" : "#0950A8");

      const statusLabel = t(`common.ticketStatus.${ticket.status}`);
      const statusColor = getTicketStatusColor(ticket.status);
      
      return {
        key: ticket.id,
        id: ticket.id,
        status: <Tag className={"me-2 w-min text-xs"} color={statusColor}>{statusLabel}</Tag>,
        title: <span className={"text-nowrap font-bold"}>{ticket.title}</span>,
        messageStatus: <Tag color={messageStatusColor}>{messageStatusLabel}</Tag>,
        type: <Tag color={"blue"}>{t(`common.ticketType.${ticket.type.name}`)}</Tag>,
        lastMessage: <span className={"line-clamp-1"}>{ticket.lastReply?.message}</span>,
        dateLastMessage: <span className={"text-nowrap"}>{format(new Date(ticket.lastReply && ticket.lastReply.updatedAt ? ticket.lastReply.updatedAt : ticket.createdAt!), "dd/MM/yyyy hh:mm", {locale: it})}</span>,
        actions: (
            <Dropdown menu={{items: ticketOptions(ticket)}} placement="bottomRight" arrow={true}>
              <Button size={"small"} className={"text-xs px-3"} type={"primary"}>{t("common.actions")}</Button>
            </Dropdown>
        )
      }
    })
  }, [tickets])
  
  const handleTicketClose = (ticket: TicketBaseDto) => {
    if(!ticket) return;
    setSelectedTicket(ticket);
    setShowCloseTicketModal(true);
  }
  
  const confirmTicketCreation = () => {
    api.success({message: t("tickets.ticketCreated")});
    setShowNewTicketModal(false);
    getTicketsList();
  }

  return (
      <>
        <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
          <div className="flex justify-between items-center mb-5">
            <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
              {t("menu.tickets")}
            </div>
            <Button type={"primary"} onClick={() => setShowNewTicketModal(true)}>{t("tickets.createNew")}</Button>
          </div>

          <div className={"flex flex-col space-y-4"}>
            <div className={"overflow-auto"}>
              <Table columns={columns} dataSource={dataSource} pagination={false}/>
            </div>
            <div className={"self-center"}>
              <Pagination responsive={true} defaultCurrent={pagination.current} total={pagination.totalCount} defaultPageSize={5} onChange={(page, pageSize) => setPagination((value) => ({
                ...value,
                current: (page - 1),
                pageSize: pageSize
              }))}/>
            </div>
          </div>

          {contextHolder}

          <NewTicketModal types={ticketTypes} isOpen={showNewTicketModal} onSuccess={confirmTicketCreation} onClose={() => setShowNewTicketModal(false)}/>
          <CloseTicketModal ticket={selectedTicket} isOpen={showCloseTicketModal} onClose={() => setShowCloseTicketModal(false)} onSuccess={() => {
          }} onFailure={(message) => api.error({message})}/>
        </div>
      </>
  );
};
