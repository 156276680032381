import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../../../contexts/formContext";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

export const Step1 = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const { updateFormData } = useFormContext();
  const { t } = useTranslation()
  const {isMobile} = useBreakpoint()

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="39">+39</Select.Option>
      </Select>
    </Form.Item>
  );

  type FieldType = {
    name?: string;
    surname?: string;
    phone?: string;
    email?: string;
  };

  const handleFormChange = (allFields: any) => {
    updateFormData(allFields);
  };

  React.useImperativeHandle(ref, () => ({
    validateForm: () => form.validateFields(),
  }));

  return (
    <div className="flex-auto h-full">
      <Form
        className="mt-4"
        name="registerFormStep1"
        initialValues={{ remember: true, prefix: "39" }}
        autoComplete="off"
        layout="vertical"
        form={form}
        wrapperCol={{ span: 22 }}
        onValuesChange={handleFormChange}
      >
        <Row>
          <Col span={isMobile ? 24 : 12} className='w-full'>
            <Form.Item<FieldType>
              label={t("common.name")}
              name="name"
              rules={[{ required: true, message: t("registration.errors.enterName") }]}
            >
              <Input placeholder={t("common.name")} />
            </Form.Item>

            <Form.Item<FieldType>
              label={t("common.email")}
              name="email"
              className="mt-4"
              rules={[
                // { type: "email", message: "Inserisci una mail valida" },
                {
                  required: true,
                  message: t("registration.errors.enterEmail"),
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder={t("common.email")} />
            </Form.Item>
          </Col>

          <Col  span={isMobile ? 24 : 12} className='w-full'>
            <Form.Item<FieldType>
              label={t("common.surname")}
              name="surname"
              rules={[{ required: true, message: t("registration.errors.enterSurname") }]}
            >
              <Input placeholder={t("common.surname")} />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("common.phone")}
              name="phone"
              className="mt-4"
              rules={[
                {
                  required: true,
                  message: t("registration.errors.enterPhone") ,
                },
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                style={{ width: "100%" }}
                placeholder={t("common.phone")}
                inputMode="numeric"
                onKeyDown={(e) => {
                  // Ottiene il codice del tasto premuto
                  const keyCode = e.keyCode || e.which;
                  // Consente solo i tasti numerici e alcuni tasti speciali (es. freccia sinistra, freccia destra, backspace, ecc.)
                  if (
                    !(keyCode >= 48 && keyCode <= 57) && // Tasti numerici da 0 a 9
                    !(keyCode >= 96 && keyCode <= 105) && // Tasti numerici del tastierino numerico
                    ![8, 9, 13, 27, 37, 39].includes(keyCode)
                  ) {
                    // Altri tasti speciali
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
