import { useContext } from "react";
import { Background } from "../../../Components/Background";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { AuthContext } from "../../../contexts/authContext";
import { RecoveryPasswordForm } from "./RecoveryPasswordForm";
import { AuthService } from "../../../api";
import { useTranslation } from "react-i18next";


export const RecoveryPassword = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation()

  const onLogin = (user: any) => {
   
    const body = {
      username: user.email,
      password: user.password,
    };
    AuthService.loginAuthController(body as any)
      .then((res: any) => {
      auth.login(res)
        messageApi.success("Login success");
        navigate("/products");
      })
      .catch((err: AxiosError) => onError(err));
  };
  const onError = (err: AxiosError) => {
    if (Number(err.status) === 403) {
      messageApi.error(t('login.errors.emailOrPasswordNotValid'));
    }
  };
  return (
    <>
      {contextHolder}
      <Background
        children={<RecoveryPasswordForm onLogin={onLogin} onError={onError}></RecoveryPasswordForm>}
      ></Background>
    </>
  );
};
