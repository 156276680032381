import { Form, Input, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { UserStatus } from "../../../../utils/types";

interface Item {
    id: any;
    name: string;
    surname: string;
    email: string;
    code: string;
    status: UserStatus;
    adminReference: string;
  }

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text" | UserStatus;
    record: Item;
    index: number;
    children: React.ReactNode;
  }
  
export const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const { t } = useTranslation()
    const inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : dataIndex === "status" ? (
        <Select
          options={[
            {
              label: t("userStatus.active"),
              value: UserStatus.ACTIVE,
            },
            {
              label: t("userStatus.inactive"),
              value: UserStatus.SUSPENDED,
            }
          ]}
        ></Select>
      ) : (
        <Input />
      );
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
