import { Col, Row } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { VariantBaseDto } from "../../../api";
import { numberWithCommasAndFixedDecimals } from "../../../utils/numberWithCommasAndFixedDecimals";

interface Props {
  variant: VariantBaseDto;
  brand: string;
  edit: (variant: VariantBaseDto) => void;
  // deleteVariant: (variant: VariantBaseDto) => void;
  index: number;
  arrayLength: number;
}

export const RowTable = ({
  variant,
  brand,
  edit,
  // deleteVariant,
  index,
  arrayLength,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Row
      style={{
        borderBottom: "1px solid #F0F1F3",
        backgroundColor: "white",
        borderTopLeftRadius: index === 0 ? 8 : 0,
        borderTopRightRadius: index === 0 ? 8 : 0,
        borderBottomLeftRadius: index === arrayLength - 1 ? 8 : 0,
        borderBottomRightRadius: index === arrayLength - 1 ? 8 : 0,
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <Col
        span={2}
        className="text-[#0950A8] text-[12px] leading-[18px] tracking-[0.05px] pb-[18px] pt-[18px] text-center"
      >
        {variant.code}
      </Col>
      <Col
        span={2}
        className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] pb-[18px] pt-[18px] text-center capitalize"
      >
        {brand}
      </Col>
        <Col span={2} className="pb-[18px] pt-[18px] text-center">
            <div className={"rounded-full flex rotate-45 size-5 border border-solid border-neutral-200 overflow-hidden"}>
                {variant.colors.map((color, index) => (
                    <div key={index} className={"h-full w-full"} style={{backgroundColor: color}}/>
                ))}
            </div>
        </Col>
        <Col
            span={2}
            className="text-[#5D5D5D] text-[12px] font-semibold leading-[18px] tracking-[0.05px] pb-[18px] pt-[18px] text-center"
        >
            {variant.size}
        </Col>
        <Col
            span={4}
            className="text-[#2A2E34] text-[12px] font-medium leading-[18px] tracking-[0.06px] pb-[18px] pt-[18px] text-center"
      >
          {numberWithCommasAndFixedDecimals(variant.netPriceList1)} €{' '}-{' '}
          {numberWithCommasAndFixedDecimals(variant.netPriceList2)} €{' '}-{' '}
          {numberWithCommasAndFixedDecimals(variant.netPriceList3)} €{' '}
      </Col>
      <Col
        span={2}
        className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] pb-[18px] pt-[18px] text-center"
      >
        {variant.stock}
      </Col>
      {/* TODO:  confezioni lo trovo solo in cart */}
      {/* <Col
        span={2}
        className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] pb-[18px] pt-[18px] text-center"
      >
        {0}
      </Col> */}
      <Col
        span={2}
        className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] pb-[18px] pt-[18px] text-center ml-1"
      >
        {variant.refuelingDate ? dayjs(variant.refuelingDate).format('DD-MM-YYYY') : 'no date'}
      </Col>
      <Col
        span={2}
        className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] pb-[18px] pt-[18px] text-center underline cursor-pointer ml-1"
        onClick={() => edit(variant)}
      >
        {t("common.edit")}
      </Col>
      {/* <Col
        span={2}
        className="pb-[18px] pt-[18px] text-center cursor-pointer"
        onClick={() => deleteVariant(variant)}
      >
        <Trash />
      </Col> */}
    </Row>
  );
};
