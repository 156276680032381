import ImgCrop from "antd-img-crop";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Upload,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import type { UploadFile, UploadProps, GetProp } from 'antd';

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Image as ImgUpload} from "../../../../Components/icons/Image";
import {v4 as uuidv4} from "uuid";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


type FieldType = {
  title: string;
  startDate: string;
  endDate: string;
  id: number
};

interface Props {
  titleForm: string;
  createList?: () => void;
  setGridFileUpload: (value: any ) => void
  handleFormChange: (values: any, fileUpload: UploadFile[]) => void
  gridFileUpload: any,
  selectedLanguage: number,
  sliderProductGridValues: {
    media: {
      uid: string,
      path: string
    }
    titles: {
      languageId: number, 
      value: string
    }[];
    fromDate: string;
    toDate: string;
  },
  applyChanges: () => void
}

export const Grid = ({ titleForm, createList, handleFormChange, sliderProductGridValues, setGridFileUpload, selectedLanguage, applyChanges}: Props) => {
  const [fileUpload, setFileUpload] = useState<UploadFile[]>(sliderProductGridValues?.media?.path ? [
    {
      uid: sliderProductGridValues?.media?.uid,
      name: 'image.png',
      status: 'done',
      url: sliderProductGridValues?.media?.path,
      thumbUrl:  sliderProductGridValues?.media?.path,
    },
  ] : []);
  const [form] = useForm();
  const { t } = useTranslation()

  const onChange: UploadProps["onChange"] = async ({ fileList: newFileList }) => {
    if(newFileList.length > 0){
      newFileList[0].preview =  await getBase64(newFileList[0].originFileObj) as string
    }
    setFileUpload(newFileList);
    if(newFileList){
    setGridFileUpload(newFileList)
  }
  };

  const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
    form.setFieldValue('title', sliderProductGridValues?.titles.find((x: any) => x.languageId == selectedLanguage)?.value || '')
  }, [sliderProductGridValues]);

 
  return (
    <div>
      <Form
        name={`${titleForm}_${uuidv4()}`}
        form={form}
        className="adminForm"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          title: sliderProductGridValues?.titles.filter((item: {
            languageId: number, value: string
          }) => item?.languageId === selectedLanguage)[0]?.value,
          startDate: dayjs(sliderProductGridValues?.fromDate),
          endDate:dayjs(sliderProductGridValues?.toDate),
        }}
        onValuesChange={(changedField, allFields) => { handleFormChange(allFields, fileUpload)}}
        style={{
          width: "100%",
        }}
      >
        <Row>
          <Col span={10}>

            <Form.Item<FieldType>
              label={t('dashboard.editor.titleSlide')}
              name='title'
              style={{
                fontWeight: 600,
              }}
            >
              <Input style={{  height: 40}}/>
            </Form.Item>

            <Row
              style={{
                marginTop: -10,
              }}
            >
              <Col span={11}>
                <Form.Item<FieldType>
                  label={t('dashboard.editor.startDate')}
                  name="startDate"
                  style={{
                    fontWeight: 600,
                    width: "100%",
                  }}
  
                >
                  <DatePicker style={{ width: "100%", height: 40 }}  format='DD-MM-YYYY'/>
                </Form.Item>
               
                   <Button
                   type="primary"
                   style={{ marginTop: 25, background: "#0950A8", height: 40 }}
                   onClick={createList}
                 >
                  <div className="font-semibold">{t("dashboard.editor.createList")}</div> 
                 </Button>
                  
                
              </Col>
              <Col span={11} offset={2}>
                <Form.Item<FieldType>
                   label={t('dashboard.editor.endDate')}
                  name="endDate"
                  style={{
                    fontWeight: 600,
                    width: "100%",
                  }}
  
                >
                  <DatePicker style={{ width: "100%", height: 40 }} format='DD-MM-YYYY'/>
                </Form.Item>
              </Col>
            </Row>
          
          </Col>
     
          <Col span={13} offset={1}>
              <div className="mt-6">
             
             <div className="border-dashed border bg-[#F9F9FC] w-1/2 h-96 border-[#E0E2E7] cursor-pointer p-8 flex justify-center">
           
    
       <ImgCrop rotationSlider showGrid aspectSlider showReset> 
                    <Upload
                      listType="picture"
                      fileList={fileUpload}
                      onChange={onChange}
                      onPreview={onPreview}
                      maxCount={1}
                      style={{ width: '50%' , textAlign: 'center'}}
                      className='custom-crop'>
                      <div className="text-center mt-28">
                          <p className="ant-upload-drag-icon">
                            <ImgUpload />
                          </p>
                          <div className="text-[#858D9D] text-xs font-medium leading-[18px] tracking-[0.06px]">
                          {t('dashboard.editor.clickOrDrag')}
                          </div> 
                      </div>
                  </Upload>
                </ImgCrop>
               </div>
              </div>
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={() => {applyChanges()}}
            style={{
              height: 40,
              border: "1px solid var(--Blue-400, #0950A8)",
              color: "#0950A8",
              fontSize: 12,
              fontWeight: 600,
              marginTop: 20,
            }}
            className="bg-blue-400 text-white text-xs font-semibold leading-[18px]"
          >
            <span className="text-white">{t("common.save")}</span>
          </Button>
        </Row>
      </Form>
    </div>
  );
};
