/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleBaseDto } from './RoleBaseDto';
import type { SectionBaseDto } from './SectionBaseDto';
export type UpdateRoleSectionPermissionResponseDto = {
    id?: number;
    uuid?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    permission: UpdateRoleSectionPermissionResponseDto.permission;
    enable: boolean;
    section: SectionBaseDto;
    role: RoleBaseDto;
};
export namespace UpdateRoleSectionPermissionResponseDto {
    export enum permission {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
    }
}

