import {Empty, Pagination, Select} from "antd";
import dayjs from "dayjs";
import {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {
    GetOrderResponseDto, OrderBaseDto,
    OrderService,
} from "../../../api";
import { OrderBox } from "../../../Components/OrderBox";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
import {toFilter, toIncludes} from "../../../utils/request";
import "dayjs/locale/it";
import {format, subMonths} from "date-fns";
import {it} from "date-fns/locale";

interface OrderGroup {
    month: string,
    orders: OrderBaseDto[]
}

export const Orders = () => {
    const { t } = useTranslation();
    const { isMobile } = useBreakpoint();
    const [orders, setOrders] = useState<GetOrderResponseDto[]>([]);
    const [pages, setPages] = useState([0, 1000]);
    const [totalCount, setTotalCount] = useState(0);
    const [yearFilter, setYearFilter] = useState(0);

    dayjs.locale("it");

    const getOrders = () => {

        let startDate = subMonths(new Date(), 3);
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1)
        if(yearFilter && yearFilter > 0){
            startDate = new Date(yearFilter, 0, 1);
            endDate = new Date(yearFilter, 11, 31);
        }

        const searchPeriod = [format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd")];
        
        OrderService.findAllOrderController(
            pages[0],
            pages[1],
            toFilter<OrderBaseDto>({createdAt: {$between: searchPeriod}}),
            JSON.stringify({createdAt: "DESC"}),
            undefined,
            toIncludes<GetOrderResponseDto>({
                rows: true,
                user: true,
                paymentMethod: true,
            })
        ).then((res) => {
            setOrders(res.data);
            setTotalCount(res.totalCount);
        });
    };

    const groupedOrders: OrderGroup[] = useMemo(() => {
        if(!orders) return [];
        const list: OrderGroup[] = [];
        orders.forEach((order) => {
            const createdAt = format(new Date(order.createdAt!), "MMMM yyyy", {locale: it});
            const groupIndex = list.findIndex((item) => item.month === createdAt);
            if(groupIndex !== -1){
                list[groupIndex].orders = [
                    ...list[groupIndex].orders,
                    order
                ]
            }else{
                list.push({
                    month: createdAt,
                    orders: [order]
                })
            }
        })

        return list;
    }, [orders]);

    useEffect(() => {
        getOrders();
    }, [pages, yearFilter]);

    const filterOptionsList = useMemo(() => {
        let list = [];
        const currentDate = new Date();
        const startDate = currentDate.getFullYear() - 10;
        const endDate = currentDate.getFullYear();
        for(let i = startDate; i < endDate; i++) list.push({label: i.toString(), value: i});
        list.push({label: "Ultimi 3 mesi", value: 0});
        
        return list.reverse();
    }, [])

    return (
        <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
            <div className={"w-full flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3 items-start md:justify-between"}>
                <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
                    {t("menu.orders")}
                </div>
                <div className={"flex space-x-3 items-center w-full md:justify-end"}>
                    <span className={"font-bold text-sm whitespace-nowrap"}>Periodo</span>
                    <Select defaultValue={0} className={"w-full md:w-[150px]"} options={filterOptionsList} onChange={setYearFilter}/>
                </div>
            </div>
            {groupedOrders && groupedOrders.length > 0 ? (
                <>
                    <div className="mt-6 flex flex-col space-y-3">
                        <div className={"flex flex-col space-y-3"}>
                            {groupedOrders.map((group, index) => {
                                return (
                                    <div key={index} className={"flex flex-col space-y-3"}>
                                        <span className={"text-blue-400 font-bold text-lg capitalize"}>{group.month}</span>
                                        <div className={"flex flex-col space-y-3"}>
                                            {group.orders.map((item: OrderBaseDto) => {
                                                return <div key={item.id}><OrderBox order={item}/></div>;
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="flex justify-center p-4">
                        <Pagination
                            responsive={true}
                            defaultCurrent={pages[0] + 1}
                            pageSize={pages[1]}
                            onChange={(page, pageSize) => {
                                setPages([page - 1, pages[1]]);
                                if (pageSize) setPages([page - 1, pageSize]);
                            }}
                            total={totalCount}
                        />
                    </div>
                </>
            ) : (
                <div className={"w-full h-[300px] flex items-center justify-center"}>
                    <Empty description={t("orders.notFoundMessage")} />
                </div>
            )}
        </div>
    );
};
