import { Button, Form, notification, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  TranslationBaseDto,
  TranslationService,
  UserSectionPermissionBaseDto,
} from "../../../../api";
import { AuthContext } from "../../../../contexts/authContext";
import { useUserPermissions } from "../../../../utils/hooks/useUserPermissions";
import { toFilter } from "../../../../utils/request";
import { Sections } from "../../../../utils/types";
import { EditableCell } from "./components/EditableCell";

interface Item {
  id: any;
  key: string;
  translation: string;
}


export const Translations = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<Item[]>([]);
  const [pages, setPages] = useState([0, 10]);
  const [editingKey, setEditingKey] = useState("");
  const { permissions, currentUser } = useContext(AuthContext);
  const { canUser } = useUserPermissions(permissions, currentUser);
  const { languageId } = useParams();
  const [api, contextHolder] = notification.useNotification()
  const [totalValues, setTotalValues] = useState<number>(0)

  useEffect(() => {
    setIsLoading(true);
    TranslationService.findAllTranslationController(
      pages[0],
      pages[1],
      toFilter<TranslationBaseDto>({
        language: {
          id: Number(languageId),
        },
      })
    )
      .then((res) => {
        setTotalValues(res?.totalCount)
        const el: Item[] = res.data.map(
          (res: TranslationBaseDto) => ({
            id: res.id,
            key: res.label,
            translation: res.translation,
          })
        );
        setTableData(el);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero dei dati:", error);
        setIsLoading(false);
      });
  }, [languageId, pages]);

  const isEditing = (record: Item) => record.id === editingKey;

  const cancel = () => {
    setEditingKey("");
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setPages([pagination.current - 1, pagination.pageSize]);
  };


  const save = async (key: React.Key | any) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setTableData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setTableData(newData);
        setEditingKey("");
      }
      TranslationService.updateTranslationController(key, {
        label: row.key,
        translation: row.translation
      })
        .then((res) => {
          api.success({
            message: t("dashboard.translations.translationEdited"),
            style: {
              borderRadius: "8px",
              border: "1px solid var(--200, #92D2B4)",
              background: "#F4FCF8",
              color: "#3DA172",
            },
          });

        })
        .catch((err) => {
          api.error({
            message: t("dashboard.translations.translationCouldNotBeChanged"),
            style: {
              borderRadius: "8px",
              border: "1px solid var(--200, #bf3232)",
              background: "#fff0f0",
            },
          });
       
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const edit = (record: Partial<Item> & { id: React.Key }) => {
    form.setFieldsValue({
      key: "",
      translation: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const columns = [
    {
      title: t("dashboard.translations.key"),
      dataIndex: "key",
      key: "key",
      editable: true,
    },
    {
      title: t("dashboard.translations.translation"),
      dataIndex: "translation",
      key: "translation",
      editable: true,
    },
    {
      title: t("common.actions"),
      dataIndex: "actions",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            {canUser(
              UserSectionPermissionBaseDto.permission.UPDATE,
              Sections.users
            ) && (
              <Button
                className="font-semibold"
                type="primary"
                onClick={() => save(record.id)}
              >
                {t("common.save")}
              </Button>
            )}
          </span>
        ) : (
          <div className="flex justify-start">
            {canUser(
              UserSectionPermissionBaseDto.permission.UPDATE,
              Sections.users
            ) && (
              <Button
                className="p-0"
                type="text"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                <div className="text-[#888] text-[12px] font-medium leading-[18px] tracking-[0.06px] text-center underline cursor-pointer">
                  {t("common.edit")}
                </div>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px] mb-4">
        {t("common.translations")}
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
          background: "var(--neutral-white, #FFF)",
          boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
          height: "100%",
        }}
      >
        <Form form={form} component={false}>
          <Table
            onChange={handleChange}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={isLoading}
            dataSource={tableData}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              current: pages[0] + 1,
              pageSize: pages[1],
              onChange: cancel,
              total: totalValues
            }}
          />
        </Form>
       {contextHolder}
      </div>
    </div>
  );
};
