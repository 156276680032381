import { FC, ReactNode, useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";

export const ProtectedRoute: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser, login } = useContext(AuthContext);

  // const retrieveCurrentUser = useCallback(() => {
  //   AuthService.meAuthController(
  //     undefined,
  //     toIncludes<UserBaseDto>({
  //       company: true,
  //       contacts: true,
  //       addresses: true,
  //       cart: {
  //         rows: true
  //       },
  //     })
  //   )
  //     .then((f: any) => login(f))
  // }, [login])


  const outcome = useMemo((): boolean => {
    const token = localStorage.getItem('token')
    // if (!currentUser) retrieveCurrentUser()
    return token || currentUser ? true : false;
  }, [currentUser]);


  return (
    <>
      {outcome ? (
        children
      ) : (
        <Navigate to={"/login"} />
      )}
    </>
  );
};
