import { FC } from "react";
import { IconPropsType } from "./types";

export const Eye: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 4.16675C15.1083 4.16675 17.5256 7.59172 18.3765 9.1929C18.6475 9.70275 18.6475 10.2974 18.3765 10.8073C17.5256 12.4084 15.1083 15.8334 9.99996 15.8334C4.89164 15.8334 2.47436 12.4084 1.62339 10.8073C1.35242 10.2974 1.35242 9.70274 1.62339 9.1929C2.47436 7.59172 4.89164 4.16675 9.99996 4.16675ZM5.69692 7.06483C4.31336 7.98153 3.50548 9.20294 3.09512 9.97507C3.09054 9.98369 3.08865 9.98967 3.08783 9.99295C3.08699 9.99629 3.08683 10.0001 3.08683 10.0001C3.08683 10.0001 3.08699 10.0039 3.08783 10.0072C3.08865 10.0105 3.09054 10.0165 3.09512 10.0251C3.50548 10.7972 4.31336 12.0186 5.69692 12.9353C5.1257 12.0995 4.79163 11.0888 4.79163 10.0001C4.79163 8.91133 5.1257 7.90061 5.69692 7.06483ZM14.303 12.9353C15.6866 12.0186 16.4944 10.7972 16.9048 10.0251C16.9094 10.0165 16.9113 10.0105 16.9121 10.0072C16.9126 10.005 16.913 10.002 16.913 10.002L16.9131 10.0001L16.9128 9.99641L16.9121 9.99295C16.9113 9.98967 16.9094 9.98369 16.9048 9.97507C16.4944 9.20294 15.6866 7.98154 14.303 7.06484C14.8742 7.90062 15.2083 8.91134 15.2083 10.0001C15.2083 11.0888 14.8742 12.0995 14.303 12.9353ZM6.4583 10.0001C6.4583 8.04407 8.04396 6.45841 9.99997 6.45841C11.956 6.45841 13.5416 8.04407 13.5416 10.0001C13.5416 11.9561 11.956 13.5417 9.99997 13.5417C8.04396 13.5417 6.4583 11.9561 6.4583 10.0001Z"
        fill="#667085"
      />
    </svg>
  );
};
