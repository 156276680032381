import { Divider, Menu, MenuProps, Row } from "antd";
import { random } from "lodash";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryBaseDto } from "../../api";
import { useBreakpoint } from "../../utils/hooks/useBreakpoint";
import { translationCategoriesValue } from "../../utils/translationValue";
import { ChevronDown } from "../icons/ChevronDown";

interface Props {
  categories: CategoryBaseDto[];
}

interface GroupedData {
  [key: string]: {
    father: any;
    children: any[];
  };
}

export const TopMenu = ({ categories }: Props) => {
  const [menuSelected, setMenuSelected] = useState("");
  const [columns, setColumns] = useState<number>(0);
  const history = useNavigate();
  const languageId = localStorage.getItem("languageId");
  const [list, setList] = useState<any[]>();
  const menu = (label: string) => {
    const children = list?.find(x => x.father.name === label)?.children || [];
    const cols = Math.ceil(children.length / 4);
    const chunkSize = Math.ceil(children.length / cols);
    setColumns(cols);
    setMenuSelected(label);
  };


  const { isMobile } = useBreakpoint();

  useEffect(() => {
    const groupedData: GroupedData = {};

    categories.forEach((item) => {
      if (item.father && item.father.id) {
        const fatherId = String(item.father.id);

        if (groupedData[fatherId]) {
          groupedData[fatherId].children.push(item);
        } else {
          groupedData[fatherId] = { father: item.father, children: [] };
        }
      } else {
        const itemId = String(item.id);
        if (!groupedData[itemId]) {
          groupedData[itemId] = { father: item, children: [] };
        }
      }
    });

    const groupedArray = Object.values(groupedData);

    setList(groupedArray);
  }, []);

  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const items: any[] | undefined = list?.map((res) => {
    return {
      label:
        translationCategoriesValue(res?.father.translations) ?? res.father.name,
      key: res.father.id,
      children: res.children.map((item: any) => {
        return {
          label: translationCategoriesValue(item.translations) ?? item.name,
          key: item.id,
        };
      }),
    };
  });

  return (
    <div>
      {!isMobile ? (
        <div onMouseLeave={() => {}} key={random()}>
          <div className="flex justify-center h-[63px]">
            {categories.map((item) => {
              return (
                <>
                  {!item.father && (
                    <div
                      key={item.id}
                      className="m-4 flex justify-center cursor-pointer mt-5"
                      onMouseOver={() => {
                        menu(
                          translationCategoriesValue(item?.translations) ??
                            item.name
                        );
                      }}
                    >
                      <div className="mt-[3px] mr-2 capitalize">
                        {translationCategoriesValue(item?.translations) ??
                          item.name}
                      </div>
                      <div className="flex">
                        <ChevronDown
                          fill="#888888"
                          className="w-4"
                        ></ChevronDown>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
          {menuSelected && (
            <div>
              <div className="text-sm font-semibold leading-5 tracking-[0.07px] flex justify-center">
                {menuSelected}
              </div>
              <div className="flex justify-center">
                <div className="m-8 mt-2">
                  <div className={"grid"} style={
                    {gridTemplateColumns:`repeat(${columns}, 1fr)`}
                  }>
                    {list?.map((item) => {
                      const fatherName =
                        item?.father?.translations?.find(
                          (t: any) =>
                            t?.translation?.language.id === Number(languageId)
                        )?.translation.translation ?? item.father.name;
                      if (fatherName === menuSelected) {
                        const children = item.children.map((res: any) => (
                          <div
                            key={res.name}
                            onClick={() => {
                              const queryParams = queryString.stringify({
                                category: res.id,
                              });
                              history(`/products?${queryParams}`);
                            }}
                            className="text-sm font-normal leading-5 tracking-[0.07px] mt-1 cursor-pointer capitalize"
                          >
                            {res.translations
                              ?.find(
                                (t: any) =>
                                  t?.translation?.language.id ===
                                  Number(languageId)
                              )
                              ?.translation.translation?.toLocaleLowerCase() ??
                              res.name}
                          </div>
                        ));
                        const cols = Math.ceil(children.length / 4);
                        const chunkSize = Math.ceil(children.length / cols);
                        const columns = Array.from({ length: cols }, (_, i) => (
                          <div
                            key={i}
                            className="flex flex-col mx-4 text-center"
                          >
                            {children.slice(i * chunkSize, (i + 1) * chunkSize)}
                          </div>
                        ));
                        return columns;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: openKeys.length > 0 ? "500px" : "100%",
            overflow: "auto",
          }}
        >
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{ width: "100%", overflow: "auto" }}
            items={items}
          />
        </div>
      )}
    </div>
  );
};
