import { Modal } from "antd";
import { AxiosError } from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, UserBaseDto } from "../api";
import { AuthContext } from "../contexts/authContext";
import { LoginForm } from "../pages/guest/Login/LoginForm";
import { isAdmin } from "../utils/isAdmin";

interface Props {
  setOpenLoginModal: (value: boolean) => void;
  openLoginModal: boolean;
  operationAfterLogin?: (user: UserBaseDto) => void;
}

export const ModalLogin = ({
  setOpenLoginModal,
  openLoginModal,
  operationAfterLogin,
}: Props) => {
  const { login } = useContext(AuthContext);
  const [showErrorMessage, setErrorMessage] = useState(false);

  const onLogin = (user: { email: string; password: string }) => {
    const body = {
      username: user.email,
      password: user.password,
    };
    AuthService.loginAuthController(body as any)
      .then((res: any) => {
        if (res) {
          login(res).then((user: UserBaseDto) => {
            if (user && operationAfterLogin) operationAfterLogin(user);
          });
        }
      })
      .catch((err: AxiosError) => onError(err));
  };
  const onError = (err: AxiosError) => {
    console.log(err);
    if (err) {
      setErrorMessage(true);
    }
  };

  return (
    <Modal
      footer={null}
      onCancel={() => setOpenLoginModal(false)}
      style={{ top: 20 }}
      open={openLoginModal}
    >
      <LoginForm
        showNotAccount={false}
        onLogin={onLogin}
        onError={onError}
        showErrorMessage={showErrorMessage}
      ></LoginForm>
    </Modal>
  );
};
