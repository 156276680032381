export const translationValue = (array: any) => {
  const userLanguage = localStorage.getItem("languageId");
  let value;
  if (array) {
    array.map((res: { languageId: number; value: string }) => {
      if (res.languageId === Number(userLanguage)) {
        value = res.value;
      }
    });
  }
  return value;
};

export const translationCategoriesValue = (array: any) => {
  const userLanguage = localStorage.getItem("languageId");
  let value;
  if (array) {
    array.map((res: {translation: { language: {id: number}; translation: string}} ) => {
      if (res.translation.language.id === Number(userLanguage)) {
        value = res.translation.translation;
      }
    });
  }
  return value;
};
