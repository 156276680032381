import { Collapse, InputNumber } from "antd";

interface Props {
  labelStyle: string;
  label: string;
  firstInput: {
    value: number;
    onChange: Function;
    min: number;
    placeholder: string;
    prefix?: string;
  };
  secondInput: {
    value: number;
    onChange: Function;
    min: number;
    placeholder: string;
    prefix?: string;
  };
}

export const RangeInputFilter = ({
  labelStyle,
  label,
  firstInput,
  secondInput,
}: Props) => {
  return (
    <Collapse
      size="small"
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <p className={labelStyle}>{label}</p>,
          children: (
            <div className="flex justify-between">
              <InputNumber
                className="w-1/2"
                value={firstInput.value}
                onChange={(value) => firstInput.onChange(value)}
                min={firstInput.min}
                placeholder={firstInput.placeholder}
                prefix={firstInput.prefix}
                keyboard={true}
              />
              <InputNumber
                className="w-1/2 ml-2"
                value={secondInput.value}
                onChange={(value) => secondInput.onChange(value)}
                min={secondInput.min}
                placeholder={secondInput.placeholder}
                prefix={secondInput.prefix}
                keyboard={true}
              />
            </div>
          ),
        },
      ]}
    />
  );
};
