import {
    CreateUserResponseDto,
    RoleBaseDto, RoleService,
    UpdateUserResponseDto,
    UserBaseDto,
    UserService
} from "../../../../api";
import {useEffect, useMemo, useState} from "react";
import {Button, Input, message, Modal, notification, Select} from "antd";
import {useTranslation} from "react-i18next";
import {UserRole} from "../../../../utils/types";
import {format} from "date-fns";

interface UserData {
    name: string,
    surname: string,
    password: string,
    email: string,
    roles: number[]
}

interface UserModalProps {
    user?: UserBaseDto,
    roles: RoleBaseDto[]
    isOpen: boolean,
    onSuccess: () => void,
    closeModal: () => void
}

const UserModal = ({user, roles, isOpen, onSuccess, closeModal}: UserModalProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation();
    
    const [userData, setUserData] = useState<UserData>({
        name: "",
        surname: "",
        password: "",
        email: "",
        roles: []
    })

    useEffect(() => {
        setUserData({
            name: user?.name ?? "",
            surname: user?.surname ?? "",
            password: user?.password ?? "",
            email: user?.email ?? "",
            roles: user?.roles.map((role) => role.role.id!) ?? []
        })
    }, [user]);

    const setValue = (key: string, value: string | number[]) => {
        setUserData((data) => ({
            ...data,
            [key]: value
        }))
    }
    
    const formattedRoleList = useMemo(() => {
        if(!roles) return [];
        return roles.filter((role) => role.name !== UserRole.CLIENT).map((role) => ({
            label: role.name,
            value: role.id
        }))
    }, [roles])
    
    const saveData = async () => {
        if(!userData.name || !userData.surname || !userData.email) return api.warning({message: t("dashboard.users.editModal.genericValidationError")});
        
        if(!user && !userData.password) return api.warning({message: t("dashboard.users.editModal.passwordValidationError")});
        
        if(!userData.roles || userData.roles.length === 0) return api.warning({message: t("dashboard.users.editModal.rolesValidationError")});
        
        setIsLoading(true);

        try {
            if(user){
                await UserService.updateUserController(
                    user.id!,
                    {
                        name: userData.name,
                        surname: userData.surname,
                        password: userData.password,
                        email: userData.email,
                    }
                ) as UpdateUserResponseDto;

                if(userData.roles) {
                    await UserService.setRolesUserController(
                        user.id!,
                        {
                            roles: userData.roles
                        }
                    )
                }

                api.success({message: t("dashboard.users.editModal.successUpdate")});
            } else{
                await UserService.createUserController({
                    name: userData.name,
                    surname: userData.surname,
                    password: userData.password,
                    email: userData.email,
                    rolesToAssign: userData.roles
                }) as CreateUserResponseDto

                api.success({message: t("dashboard.users.editModal.successInsert")});
            }

            onSuccess();
        } catch (error) {
            api.error({message: t("dashboard.users.editModal.error")});
        } finally {
            setIsLoading(false);
        }
    }
    
    const modalContent = (
        <div className={"grid grid-cols-2 gap-y-5 gap-x-3"}>
            <div className="flex flex-col space-y-1">
                <span className="text-xs font-semibold leading-4">{t("dashboard.users.editModal.nameLabel")}</span>
                <Input disabled={isLoading} onChange={(e) => setValue("name", e.target.value)} value={userData.name} size="large" className="mt-1"/>
            </div>

            <div className="flex flex-col space-y-1">
                <span className="text-xs font-semibold leading-4">{t("dashboard.users.editModal.surnameLabel")}</span>
                <Input disabled={isLoading} onChange={(e) => setValue("surname", e.target.value)} value={userData.surname} size="large" className="mt-1"/>
            </div>

            <div className="flex flex-col space-y-1">
                <span className="text-xs font-semibold leading-4">{t("dashboard.users.editModal.emailLabel")}</span>
                <Input type={"email"} disabled={isLoading} onChange={(e) => setValue("email", e.target.value)} value={userData.email} size="large" className="mt-1"/>
            </div>

            <div className="flex flex-col space-y-1">
                <span className="text-xs font-semibold leading-4">{t("dashboard.users.editModal.passwordLabel")}</span>
                <Input.Password disabled={isLoading} onChange={(e) => setValue("password", e.target.value)} value={userData.password} size="large" className="mt-1"/>
            </div>

            <div className="flex flex-col space-y-1 col-span-2">
                <span className="text-xs font-semibold leading-4">{t("dashboard.users.editModal.roleLabel")}</span>
                <Select mode={"multiple"} disabled={isLoading} options={formattedRoleList} value={userData.roles} onChange={(roles) => setValue("roles", roles)}/>
            </div>
        </div>
    )

    return (
        <Modal
            title={
                <p className="text-[#0950A8] text-base font-semibold leading-6">{user ? `${user.name} ${user.surname}` : t("dashboard.users.editModal.newTitle")}</p>}
            open={isOpen} onOk={saveData} onCancel={closeModal} footer={null}
        >
            {contextHolder}
            <hr className={"my-4 border-solid border-gray-100"}/>
            {modalContent}
            <hr className={"my-4 border-solid border-gray-100"}/>
            <div className={"flex space-x-3 w-full justify-between items-center"}>
                <Button disabled={isLoading} block type={"default"} className={"m-0 h-[40px] border border-solid border-[var(--Blue-400, #0950A8)] text-[#0950A8] text-sm font-bold"} onClick={closeModal}>
                    {t("common.cancel")}
                </Button>
                <Button disabled={isLoading} block type={"primary"} className={"m-0 h-[40px] bg-[#0950A8] text-sm font-bold"} onClick={saveData}>
                    {t("common.saveChanges")}
                </Button>
            </div>
        </Modal>
    )
}

export default UserModal

