import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { UserMenuItem } from "./UserMenuItem";

interface Props {
  showSideBar: boolean;
  userMenu: {
    label: string;
    icon: ReactNode;
    id: string;
    url: string;
  }[];
  selectedItem: string;
  setSelectedItem: (value: string) => void;
  openMenuDrawer?: boolean;
}
export const SideBarMenu = ({
  showSideBar,
  userMenu,
  selectedItem,
  setSelectedItem,
  openMenuDrawer,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div>
      {(showSideBar || openMenuDrawer) && (
        <div className={`flex ${openMenuDrawer ? 'justify-start' : 'justify-center'}`}>
          <div className={`${openMenuDrawer ? "block" : "hidden"} lg:block`}>
            <div className="mt-4 p-2">
              <div className="text-blue-400 text-lg font-bold leading-7 tracking-[0.09px]">
                {t('menu.yourAccount')}
              </div>
              <div className="mt-2">
                {userMenu
                  .filter((item) => item.id === "account")
                  .map((res) => {
                    return (
                      <div key={res.label}>
                        <UserMenuItem
                          item={res}
                          selectedItem={selectedItem}
                          setSelectedItem={(value) => setSelectedItem(value)}
                          openMenuDrawer={openMenuDrawer}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="p-2">
              <div className="text-blue-400 text-lg font-bold leading-7 tracking-[0.09px] ">
              {t('menu.privacy')}
              </div>
              <div className="mt-2">
                {userMenu
                  .filter((item) => item.id === "privacy")
                  .map((res) => {
                    return (
                      <div key={res.label}>
                        <UserMenuItem
                          item={res}
                          selectedItem={selectedItem}
                          setSelectedItem={(value) => setSelectedItem(value)}
                          openMenuDrawer={openMenuDrawer}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* <div className="p-2">
              <div className="text-blue-400 text-lg font-bold leading-7 tracking-[0.09px]">
              {t('menu.info')}
              </div>
              <div className="mt-2">
                {userMenu
                  .filter((item) => item.id === "info")
                  .map((res) => {
                    return (
                      <div key={res.label}>
                        <UserMenuItem
                          item={res}
                          selectedItem={selectedItem}
                          setSelectedItem={(value) => setSelectedItem(value)}
                          openMenuDrawer={openMenuDrawer}
                        />
                      </div>
                    );
                  })}
              </div>
            </div> */}
          </div>
          <div className={`${openMenuDrawer ? 'hidden' : 'block'} lg:hidden`}>
            {!openMenuDrawer && (
              <div className="mt-2">
                {userMenu.map((res) => {
                  return (
                    <div key={res.label}>
                      <UserMenuItem
                        item={res}
                        selectedItem={selectedItem}
                        setSelectedItem={(value) => setSelectedItem(value)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
