import { FC } from "react";
import { IconPropsType } from "./types";

export const HeartMenu: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...fill}
      {...props}
    >
      <path
        d="M20.1603 4.61006C19.0986 3.54806 17.691 2.90238 16.1935 2.79044C14.696 2.67849 13.2081 3.10772 12.0003 4.00006C10.7279 3.0537 9.14427 2.62457 7.5682 2.7991C5.99212 2.97362 4.54072 3.73884 3.50625 4.94064C2.47178 6.14245 1.9311 7.69158 1.99308 9.27607C2.05506 10.8606 2.71509 12.3627 3.84028 13.4801L11.2903 20.9301C11.3832 21.0238 11.4938 21.0982 11.6157 21.1489C11.7376 21.1997 11.8683 21.2259 12.0003 21.2259C12.1323 21.2259 12.263 21.1997 12.3849 21.1489C12.5067 21.0982 12.6173 21.0238 12.7103 20.9301L20.1603 13.4801C20.7429 12.8978 21.2051 12.2064 21.5205 11.4454C21.8358 10.6844 21.9982 9.86879 21.9982 9.04506C21.9982 8.22133 21.8358 7.40567 21.5205 6.64469C21.2051 5.88371 20.7429 5.19233 20.1603 4.61006ZM18.7503 12.0701L12.0003 18.8101L5.25028 12.0701C4.65545 11.4728 4.25025 10.7131 4.0855 9.8864C3.92076 9.05969 4.00381 8.20274 4.32423 7.42305C4.64465 6.64336 5.18817 5.97565 5.88662 5.50368C6.58507 5.03171 7.40734 4.7765 8.25028 4.77006C9.37639 4.77282 10.4554 5.22239 11.2503 6.02006C11.3432 6.11379 11.4538 6.18818 11.5757 6.23895C11.6976 6.28972 11.8283 6.31586 11.9603 6.31586C12.0923 6.31586 12.223 6.28972 12.3449 6.23895C12.4667 6.18818 12.5773 6.11379 12.6703 6.02006C13.4886 5.31094 14.5458 4.9392 15.6278 4.98008C16.7099 5.02096 17.736 5.47141 18.4985 6.24025C19.2609 7.00909 19.7029 8.0389 19.7348 9.12125C19.7667 10.2036 19.3862 11.2576 18.6703 12.0701H18.7503Z"
        fill={fill}
      />
    </svg>
  );
};
