import { Col, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { CategoryBaseDto } from "../../../../api";

interface Props {
    subCategories: {
        id: number;
        name: string;
    }[]
  handleSubcategoryChange: (value: string, index: number) => void;
}

export const Subcategory = ({ subCategories, handleSubcategoryChange }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={[18, 18]}>
          {subCategories.map((item, index) => {
            if (index + (1 % 2) !== 0) {
              return (
                <Col span={12} key={index}>
                  <Form.Item
                    label={`${t("dashboard.categories.subcategory")} #${index +
                      1}`}
                    name={`subcategory${index + 1}`}
                    style={{
                      fontWeight: 600,
                      marginTop: -15,
                    }}
                  >
                    <Input style={{ height: 40 }} defaultValue={item.name} onChange={(e) => handleSubcategoryChange(e.target.value, item.id)}/>
                  </Form.Item>
                </Col>
              );
            } else {
              return (
                <Col span={12}>
                  <Form.Item
                    label={`${t("dashboard.categories.subcategory")} #${index +
                      1}`}
                    name={`subcategory${index + 1}`}
                    style={{
                      fontWeight: 600,
                      marginTop: -15,
                    }}
                  >
                    <Input style={{ height: 40 }} defaultValue={item.name} onChange={(e) => handleSubcategoryChange(e.target.value, index)}/>
                  </Form.Item>
                </Col>
              );
            }
          })}
        </Row>
      </Form>
    </div>
  );
};
