import {
  Button,
  Col,
  DatePicker,
  Form,
  GetProp,
  Input,
  Row,
  Upload,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { Image as ImageIcon } from "../../../../Components/icons/Image";
import React, { useState } from "react";
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {v4 as uuidv4} from "uuid";

const { TextArea } = Input;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type FieldType = {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  images: [];
  url: string
  id:  number
};

interface Props {
  titleForm: string;
  handleFormChange: (values: any) => void
  sliderProductEvidenceValues?: any,
  setEvidenceFileUpload: (values: UploadFile[]) => void
  selectedLanguage: number,
}

export const SlideEvidence = React.forwardRef(({ handleFormChange, sliderProductEvidenceValues, setEvidenceFileUpload, selectedLanguage}: Props, ref) => {
  const [fileUpload, setFileUpload] = useState<UploadFile[]>(sliderProductEvidenceValues?.media?.path ?[
    {
      uid: sliderProductEvidenceValues?.media?.uuid,
      name: 'image.png',
      url: sliderProductEvidenceValues?.media?.path,
    },
  ] : []);

  const [form] = useForm();
  const { t } = useTranslation()
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
   
    return e?.fileList;
  };

  React.useImperativeHandle(ref, () => ({
    validateForm: () => form.validateFields(),
  }));

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange: UploadProps["onChange"] = async({ fileList: newFileList }) => {

    if(newFileList.length > 0){
    newFileList[0].preview =  await getBase64(newFileList[0].originFileObj) as string
  }
    setFileUpload(newFileList);
    setEvidenceFileUpload(newFileList)

     
  };

  const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  return (
    <div>
      <Form
        name={uuidv4()}
        form={form}
        className="adminForm"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          id: sliderProductEvidenceValues?.id ,
          title: sliderProductEvidenceValues?.titles.filter((item: {
            languageId: number, value: string
          }) => item?.languageId === selectedLanguage)[0]?.value,
          description: sliderProductEvidenceValues?.descriptions.filter((item: {
            languageId: number, value: string
          }) => item?.languageId === selectedLanguage)[0]?.value,
          url: sliderProductEvidenceValues?.href,
          startDate: dayjs(sliderProductEvidenceValues?.fromDate),
          endDate: dayjs(sliderProductEvidenceValues?.toDate)
        }}
        onValuesChange={(changedField, allFields) =>  {handleFormChange(allFields)}}
        style={{
          width: "100%",
        }}
      >
        <Row>
          <Col span={10}>
          <Form.Item<FieldType>
              label=''
              name="id"
              className="hidden"
             // rules={[{ required: true, message: "Inserisci email!" }]}
            >
              <Input style={{  height: 40}}/>
            </Form.Item>

            <Form.Item<FieldType>
              label={t('dashboard.editor.titleSlide')}
              name="title"
              required
              style={{
                fontWeight: 600,
              }}
              rules={[{ required: true, message: t('dashboard.editor.titleSlide') }]}
              
            >
              <Input style={{  height: 40}}/>
            </Form.Item>

              <Form.Item<FieldType>
              label={t('dashboard.editor.descriptionSlide')}
                name="description"
                required
                style={{
                  fontWeight: 600,
                  marginTop: -10,
                }}
                rules={[{ required: true, message: t('dashboard.editor.descriptionSlide') }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            
                 <Form.Item<FieldType>
                label={t('dashboard.editor.url')}
              name="url"
              required
              style={{
                fontWeight: 600,
              }}
              rules={[{ required: true, message: t('dashboard.editor.url') }]}
            >
              <Input style={{  height: 40}} />
            </Form.Item>
            <Row
              style={{
                marginTop: -10,
              }}
            >
              <Col span={11}>
                <Form.Item<FieldType>
                  label={t('dashboard.editor.startDate')}
                  name="startDate"
                  required
                  style={{
                    fontWeight: 600,
                    width: "100%",
                  }}
                  rules={[{ required: true, message: t('dashboard.editor.startDate') }]}
                >
                  <DatePicker style={{ width: "100%", height: 40 }} format="DD-MM-YYYY" />
                  </Form.Item>
                
              </Col>
              <Col span={11} offset={2}>
                <Form.Item<FieldType>
                   label={t('dashboard.editor.endDate')}
                  name="endDate"
                  required
                  style={{
                    fontWeight: 600,
                    width: "100%",
                  }}
                  rules={[{ required: true, message: t('dashboard.editor.endDate') }]}
                >
                  <DatePicker style={{ width: "100%", height: 40 }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
            </Row>
          
          </Col>
     
          <Col span={13} offset={1}>
            <Form.Item
              label={t('dashboard.editor.image') }
              name='images'
              required
              valuePropName="filelist"
              getValueFromEvent={normFile}
            //  rules={[{ required: true, message: t('dashboard.editor.image') }]}
            >
              <div className="">
                <div className={fileUpload.length > 0 ? 'md:h-[260px]' :"md:h-[350px]"}>
                <Upload.Dragger listType="picture" style={{
                  height: '100%'
                }}
                fileList={fileUpload}
                maxCount={1}
                onChange={onChange}
                onPreview={onPreview}>
                  <p className="ant-upload-drag-icon">
                    <ImageIcon />
                  </p>
                  <p className="ant-upload-text">
                   {t('dashboard.editor.clickOrDrag')}
                  </p>
                </Upload.Dragger>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
