import { FC } from "react";
import { IconPropsType } from "./types";

export const CashChecked: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9666 10.4475C16.3519 10.0517 16.3433 9.41859 15.9475 9.03339C15.5517 8.64819 14.9186 8.65675 14.5334 9.05253L11.2737 12.4017L9.8755 11.2809C9.44459 10.9354 8.81522 11.0047 8.46979 11.4356C8.12435 11.8666 8.19365 12.4959 8.62457 12.8414L11.0857 14.8143C11.2867 14.9754 11.5771 14.9575 11.7567 14.7729L15.9666 10.4475Z"
        fill="#5C59E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7ZM18.1707 6H19C19.5523 6 20 6.44771 20 7V7.82929C19.1476 7.52801 18.472 6.85241 18.1707 6ZM16.1 6H7.89998C7.5023 7.95913 5.95913 9.5023 4 9.89998V14.1C5.95913 14.4977 7.5023 16.0409 7.89998 18H16.1C16.4977 16.0409 18.0409 14.4977 20 14.1V9.89998C18.0409 9.5023 16.4977 7.95913 16.1 6ZM20 16.1707C19.1476 16.472 18.472 17.1476 18.1707 18H19C19.5523 18 20 17.5523 20 17V16.1707ZM5.82929 18C5.52801 17.1476 4.85241 16.472 4 16.1707V17C4 17.5523 4.44772 18 5 18H5.82929ZM4 7.82929C4.85241 7.52801 5.52801 6.85241 5.82929 6H5C4.44772 6 4 6.44772 4 7V7.82929Z"
        fill="#5C59E8"
      />
    </svg>
  );
};
