import { FC } from "react";
import { IconPropsType } from "./types";

export const ChevronRight: FC<IconPropsType> = (props, fill) => {
  return (
    // <svg
    //   width="19"
    //   height="18"
    //   viewBox="0 0 19 18"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"

    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M7.09467 3.96967C6.80178 4.26256 6.80178 4.73744 7.09467 5.03033L11.0643 9L7.09467 12.9697C6.80178 13.2626 6.80178 13.7374 7.09467 14.0303C7.38756 14.3232 7.86244 14.3232 8.15533 14.0303L12.9205 9.26516C13.0669 9.11872 13.0669 8.88128 12.9205 8.73484L8.15533 3.96967C7.86244 3.67678 7.38756 3.67678 7.09467 3.96967Z"

    //   />
    // </svg>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...fill}
    >
      <path
        d="M10.5917 9.99996L7.64169 12.95C7.48648 13.1061 7.39937 13.3173 7.39937 13.5375C7.39937 13.7576 7.48648 13.9688 7.64169 14.125C7.71916 14.2031 7.81133 14.2651 7.91288 14.3074C8.01443 14.3497 8.12335 14.3715 8.23336 14.3715C8.34337 14.3715 8.45229 14.3497 8.55384 14.3074C8.65539 14.2651 8.74756 14.2031 8.82503 14.125L12.3584 10.5916C12.4365 10.5142 12.4985 10.422 12.5408 10.3204C12.5831 10.2189 12.6049 10.11 12.6049 9.99996C12.6049 9.88995 12.5831 9.78103 12.5408 9.67948C12.4985 9.57793 12.4365 9.48576 12.3584 9.4083L8.82503 5.8333C8.74716 5.75606 8.65481 5.69496 8.55328 5.65349C8.45175 5.61202 8.34303 5.59099 8.23336 5.59163C8.12369 5.59099 8.01497 5.61202 7.91344 5.65349C7.81191 5.69496 7.71956 5.75606 7.64169 5.8333C7.48648 5.98943 7.39937 6.20064 7.39937 6.42079C7.39937 6.64095 7.48648 6.85216 7.64169 7.0083L10.5917 9.99996Z"
        // fill="white"
        fill={fill}
      />
    </svg>
  );
};
