/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEvidenceResponseDto } from '../models/CreateEvidenceResponseDto';
import type { CreateGridResponseDto } from '../models/CreateGridResponseDto';
import type { CreateProductResponseDto } from '../models/CreateProductResponseDto';
import type { CreateSlideRequestDto } from '../models/CreateSlideRequestDto';
import type { HomeResponseDto } from '../models/HomeResponseDto';
import type { PatchSlideRequestDto } from '../models/PatchSlideRequestDto';
import type { UpdateSlideRequestDto } from '../models/UpdateSlideRequestDto';
import type { UpsertEvidenceSliderDto } from '../models/UpsertEvidenceSliderDto';
import type { UpsertGridDto } from '../models/UpsertGridDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SlideService {
    /**
     * @param requestBody
     * @returns CreateEvidenceResponseDto
     * @throws ApiError
     */
    public static setEvidenceSlideController(
        requestBody: Array<UpsertEvidenceSliderDto>,
    ): CancelablePromise<Array<CreateEvidenceResponseDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/slides/evidence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CreateProductResponseDto
     * @throws ApiError
     */
    public static setProductsSlideController(
        requestBody: UpsertGridDto,
    ): CancelablePromise<CreateProductResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/slides/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CreateGridResponseDto
     * @throws ApiError
     */
    public static setGridSlideController(
        requestBody: UpsertGridDto,
    ): CancelablePromise<CreateGridResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/slides/grid',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns HomeResponseDto
     * @throws ApiError
     */
    public static getHomeSlideController(): CancelablePromise<HomeResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/slides/home',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static deleteSlideController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/slides/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneSlideController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/slides/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static replaceSlideController(
        id: number,
        requestBody: UpdateSlideRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/slides/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateSlideController(
        id: number,
        requestBody: PatchSlideRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/slides/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param ids
     * @returns any
     * @throws ApiError
     */
    public static deleteManySlideController(
        ids: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/slides/delete/many',
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static restoreSlideController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/slides/restore/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findAllSlideController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/slides',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static createSlideController(
        requestBody: CreateSlideRequestDto,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/slides',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static countSlideController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/slides/count',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
