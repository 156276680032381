import { Button } from "antd";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";
import { Filter } from "./icons/Filter";

interface Props {
    filtersRender: () => ReactNode
    selectedFilters: []
}

export const TableButtonFilters = ({filtersRender, selectedFilters}: Props) => {
    const [showFilters, setShowFilters] = useState(false)
    const {isMobile} = useBreakpoint()
    const {t} = useTranslation()
  return (
    <div className="flex justify-end">
      <div className="relative z-50">
        <Button
          className={`g-white text-blue-400 text-sm leading-5 tracking-[0.07px] font-semibold border-blue-400 ${
            isMobile ? "h-[40px]" : ""
          }`}
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        >
          <div className="flex justify-stretch">
            <div className="w-4 h-4">
              <Filter fill="#0950A8"></Filter>
            </div>
            <div className="ml-2 font-semibold leading-5 text-xs">
              {t("common.filters")}
            </div>
            {selectedFilters.length > 0 && (
              <div className="text-center ml-2">
                <div className="h-5 w-5 bg-blue-400 text-white rounded-full text-center font-normal">
                  {selectedFilters.length}
                </div>
              </div>
            )}
          </div>
        </Button>
        {showFilters && (
          <div className="absolute top-10 z-50 right-0 w-80">
            <div
              className="w-80"
              style={{
                borderRadius: "8px",
                border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
                background: "var(--neutral-white, #FFF)",
                boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
                padding: 24,
                height: "100%",
              }}
            >
              {filtersRender()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
