import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const SalesProcess = () => {
  const {t} = useTranslation()
  const options = {
    responsive: true,
    plugins: {
        legend: {
            labels: {
              render: "value",
              fontColor: "#fff",
              precision: 0,
              fontSize: 12,
              fontStyle: "bold",
              position: "outside",
              outsidePadding: 10,
              textMargin: 10,
              overlap: true,
              showZero: true,
              //   callbacks: function(value: string) {
              //     return '<span style="color: red">' + value + "</span>";
              //   },
              usePointStyle: true,
              font: {
                size: 12,
                color: "#667085",
              },
            },
          },
    },
  };

  const labels = [
    t('dashboard.salesProcess.Jan'),
    t('dashboard.salesProcess.Feb'),
    t('dashboard.salesProcess.Mar'),
    t('dashboard.salesProcess.Apr'),
    t('dashboard.salesProcess.May'),
    t('dashboard.salesProcess.Jun'),
    t('dashboard.salesProcess.Jul'),
    t('dashboard.salesProcess.Aug'),
    t('dashboard.salesProcess.Sep'),
    t('dashboard.salesProcess.Oct'),
    t('dashboard.salesProcess.Nov'),
    t('dashboard.salesProcess.Dec'),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [33, 53, 85, 41, 44, 65, 100, 140, 50, 59, 84, 93],
        backgroundColor: ['rgba(220,0,10,0.5)'],
        borderColor: 'rgba(0,0,0,1)',
      },
      {
        label: "Dataset 2",
        data: [33, 25, 35, 51, 54, 76, 41, 44, 65, 100, 1, 35],
        backgroundColor: ['rgba(0,10,220,0.5)', ],
        borderColor: 'rgba(0,0,0,1)',
      },
    ],
  };
  return (
    <div
      style={{
        borderRadius: "8px",
        border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
        background: "var(--neutral-white, #FFF)",
        boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
        padding: 24,
        height: '100%'
      }}
    >
      <div className="text-[#333843] font-[Inter] text-lg font-medium leading-7 tracking-[0.09px]">
        {t('dashboard.salesProcess.salesProcess')}
      </div>
      <div className="text-[#667085] font-[Inter] text-sm font-medium leading-5 tracking-[0.07px]">
       {t('dashboard.salesProcess.thisQuarter')}
      </div>
      <Bar options={options} data={data} />
    </div>
  );
};
