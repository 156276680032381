import { useContext, useState } from "react";
import { Background } from "../../../Components/Background";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { AuthContext } from "../../../contexts/authContext";
import { LoginForm } from "./LoginForm";
import { isAdmin } from "../../../utils/isAdmin";
import { AuthService, UserBaseDto } from "../../../api";

export const Login = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation();

  const email = location?.state;

  const [messageApi, contextHolder] = message.useMessage();
  const [showErrorMessage, setErrorMessage] = useState(false);
  const onLogin = (user: { email: string; password: string }) => {
    const body = {
      username: user.email,
      password: user.password,
    };
    AuthService.loginAuthController(body as any)
      .then((res: any) => {
        if (res) {
          auth.login(res).then((user: UserBaseDto) => {
            if (isAdmin(user)) {
              navigate("/admin/products");
            } else {
              navigate("/");
            }
          });
        }
      })
      .catch((err: AxiosError) => onError(err));
  };
  const onError = (err: AxiosError) => {
    console.log(err);
    if (err) {
      setErrorMessage(true);
    }
    // if (Number(err.status) === 403) {
    //   messageApi.error("Error wrong email or password");
    // }
  };
  return (
    <>
      {contextHolder}
      <Background
        children={
          <LoginForm
            email={email}
            onLogin={onLogin}
            onError={onError}
            showErrorMessage={showErrorMessage}
          ></LoginForm>
        }
      ></Background>
    </>
  );
};
