import {Button, Empty, Row} from "antd";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RoleBaseDto,
  RoleService,
  SectionBaseDto,
  SectionService,
  UserBaseDto,
  UserService,
} from "../../../../api";
import { toFilter, toIncludes } from "../../../../utils/request";
import UserModal from "./UserModal";
import UserPermissionsModal from "./UserPermissionsModal";
import {UserOutlined} from "@ant-design/icons"
import {isAdmin} from "../../../../utils/isAdmin";

export const Users = () => {
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [roles, setRoles] = useState<RoleBaseDto[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserBaseDto>();
  const [users, setUsers] = useState<UserBaseDto[]>();
  const [sections, setSections] = useState<SectionBaseDto[]>([]);
  const { t } = useTranslation();

  const getUsersList = () => {
    UserService.findAllUserController(
      undefined,
      undefined,
      toFilter<UserBaseDto>({
        roles: {
          role: {
            name: { $not: "B2B_CLIENT" }
          }
        },
      }),
      undefined,
      undefined,
      toIncludes<UserBaseDto>({
        roles: {
          role: true,
        },
      })
    ).then((res) => {
      setUsers(res.data);
    });
  };
  
  const getRolesList = () => {
    RoleService.findAllRoleController().then((res) => {
      setRoles(res.data);
    });
  }
  
  const getSections = () => {
    SectionService.findAllSectionController().then((res) => {
      setSections(res.data);
    });
  };

  useEffect(() => {
    getRolesList();
    getUsersList();
    getSections();
  }, []);

  // const savePermissionsToApi = async () => {
  //   try {
  //     for (const sectionId in permissions) {
  //       if (Object.prototype.hasOwnProperty.call(permissions, sectionId)) {
  //         const sectionPermissions = permissions[sectionId];
  //         for (const permissionType in sectionPermissions) {
  //           if (
  //             Object.prototype.hasOwnProperty.call(
  //               sectionPermissions,
  //               permissionType
  //             )
  //           ) {
  //             let permission: any;
  //
  //             switch (permissionType) {
  //               case CreateUserSectionPermissionRequestDto.permission.CREATE:
  //                 permission =
  //                   CreateUserSectionPermissionRequestDto.permission.CREATE;
  //                 break;
  //               case CreateUserSectionPermissionRequestDto.permission.READ:
  //                 permission =
  //                   CreateUserSectionPermissionRequestDto.permission.READ;
  //                 break;
  //               case CreateUserSectionPermissionRequestDto.permission.UPDATE:
  //                 permission =
  //                   CreateUserSectionPermissionRequestDto.permission.UPDATE;
  //                 break;
  //               case CreateUserSectionPermissionRequestDto.permission.DELETE:
  //                 permission =
  //                   CreateUserSectionPermissionRequestDto.permission.DELETE;
  //                 break;
  //             }
  //             if (permission) {
  //               const permissionValue = sectionPermissions[permissionType];
  //               UserSectionPermissionService.createUserSectionPermissionController(
  //                 {
  //                   user: selectedUser!.id!,
  //                   section: Number(sectionId),
  //                   enable: permissionValue,
  //                   permission: permission,
  //                 }
  //               )
  //                 .then((res) => console.log(res))
  //                 .catch((err) => console.log(err));
  //             }
  //           }
  //         }
  //       }
  //     }
  //     console.log("Users saved successfully");
  //   } catch (error) {
  //     console.error("Error saving permissions:", error);
  //   }
  // };

  // const save = () => {
  //   if (selectedUser?.id) {
  //     if (rolesSelected.length > 0) {
  //       let obj: { id: number; name: string }[] = [];
  //       RoleService.findAllRoleController().then((res) => {
  //         res.data.forEach((itemRole: any) => {
  //           rolesSelected.forEach((role) => {
  //             if (itemRole.name === role) {
  //               obj.push({
  //                 id: itemRole.id,
  //                 name: itemRole.name,
  //               });
  //             }
  //           });
  //         });
  //
  //         UserService.setRolesUserController(selectedUser?.id ?? 0, {
  //           roles: obj.map((res) => res.id),
  //         }).then((res) => {
  //           if (res) {
  //             updateUsers();
  //           }
  //         });
  //       });
  //     }
  //   }
  //  
  //   savePermissionsToApi();
  // };
  
  return (
      <>
        <div className={"p-5 md:p-7 md:pl-14 md:pr-14"}>
          <div className="flex justify-between items-center mb-5">
            <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
              {t("dashboard.users.title")}
            </div>
            <Button type={"primary"} onClick={() => setShowUserModal(true)}>{t("dashboard.users.add")}</Button>
          </div>
          <Row gutter={[16, 8]}>
            {(users && users.length > 0) ? (
                <div className={"grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-5 gap-5"}>
                  {users.map((user, index) => {
                    return (
                        <div key={index} className={"flex flex-col items-center justify-center p-7 bg-white rounded-md border border-solid border-gray-200 hover:shadow-md transition"}>
                          <UserOutlined className={"text-[50px] text-blue-400"}/>
                          <div className={"flex flex-col space-y-2 text-center mt-4"}>
                            <span className={"font-bold text-md"}>{user.name} {user.surname}</span>
                            <span className={"text-xs"}>{user.email}</span>
                          </div>
                          <hr className={"w-full border-solid border-gray-100 my-4"}/>
                          <div className={"flex space-x-3 items-center"}>
                            <Button disabled={isAdmin(user)} type={"primary"} onClick={() => {
                              setShowUserModal(true);
                              setSelectedUser(user);
                            }}>{t("dashboard.users.edit")}</Button>
                            <Button disabled={isAdmin(user)} onClick={() => {
                              setShowPermissionsModal(true);
                              setSelectedUser(user);
                            }}>{t("dashboard.users.permissions")}</Button>
                          </div>
                        </div>
                    )
                  })}
                </div>
            ) : (
                <div className={"w-full py-10"}>
                  <Empty description={t("dashboard.users.notFound")}/>
                </div>
            )}
          </Row>
          {/*<Modal*/}
          {/*    title={*/}
          {/*      <div>*/}
          {/*        <div className="text-[#0950A8] text-base font-semibold leading-6 tracking-[0.08px]">*/}
          {/*          {selectedUser?.name} {selectedUser?.surname}*/}
          {/*        </div>*/}
          {/*        <div className="text-[#888] text-sm font-semibold leading-6 tracking-[0.08px] capitalize">*/}
          {/*          <Select*/}
          {/*              mode="multiple"*/}
          {/*              allowClear*/}
          {/*              style={{width: "100%"}}*/}
          {/*              onChange={handleChange}*/}
          {/*              defaultValue={selectedUser?.roles.map((res) => res.role.name)}*/}
          {/*              options={roles.map((res) => {*/}
          {/*                return {*/}
          {/*                  value: res.name,*/}
          {/*                  label: res.name,*/}
          {/*                };*/}
          {/*              })}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    }*/}
          {/*    open={showPermissionsModal}*/}
          {/*    onOk={handleOk}*/}
          {/*    onCancel={handleCancel}*/}
          {/*    footer={() => {*/}
          {/*      return (*/}
          {/*          <Row style={{marginTop: 20}} gutter={[16, 8]}>*/}
          {/*            <Col span={12}>*/}
          {/*              <Button*/}
          {/*                  style={{*/}
          {/*                    height: 40,*/}
          {/*                    border: "1px solid var(--Blue-400, #0950A8)",*/}
          {/*                    color: "#0950A8",*/}
          {/*                    fontSize: 14,*/}
          {/*                    fontWeight: 600,*/}
          {/*                  }}*/}
          {/*                  block*/}
          {/*                  type="default"*/}
          {/*                  onClick={() => setShowPermissionsModal(!showPermissionsModal)}*/}
          {/*              >*/}
          {/*                {t("common.cancel")}*/}
          {/*              </Button>*/}
          {/*            </Col>*/}
          {/*            <Col span={12}>*/}
          {/*              <Button*/}
          {/*                  onClick={() => {*/}
          {/*                    save();*/}
          {/*                    setShowPermissionsModal(!showPermissionsModal);*/}
          {/*                  }}*/}
          {/*                  block*/}
          {/*                  type="primary"*/}
          {/*                  style={{*/}
          {/*                    height: 40,*/}
          {/*                    background: "#0950A8",*/}
          {/*                    color: "white",*/}
          {/*                    fontSize: 14,*/}
          {/*                    fontWeight: 600,*/}
          {/*                  }}*/}
          {/*              >*/}
          {/*                {t("common.saveChanges")}*/}
          {/*              </Button>*/}
          {/*            </Col>*/}
          {/*          </Row>*/}
          {/*      );*/}
          {/*    }}*/}
          {/*>*/}
          {/*  <Row className="bg-[#F0F1F3] pt-[18px] pb-[18px] text-center">*/}
          {/*    <Col span={8}></Col>*/}
          {/*    <Col*/}
          {/*        span={4}*/}
          {/*        className="text-[#002655] text-xs font-semibold leading-[18px] tracking-[0.06px]"*/}
          {/*    >*/}
          {/*      {t("dashboard.permissions.read")}*/}
          {/*    </Col>*/}
          {/*    <Col*/}
          {/*        span={4}*/}
          {/*        className="text-[#002655] text-xs font-semibold leading-[18px] tracking-[0.06px]"*/}
          {/*    >*/}
          {/*      {t("dashboard.permissions.create")}*/}
          {/*    </Col>*/}
          {/*    <Col*/}
          {/*        span={4}*/}
          {/*        className="text-[#002655] text-xs font-semibold leading-[18px] tracking-[0.06px]"*/}
          {/*    >*/}
          {/*      {t("common.edit")}*/}
          {/*    </Col>*/}
          {/*    <Col*/}
          {/*        span={4}*/}
          {/*        className="text-[#002655] text-xs font-semibold leading-[18px] tracking-[0.06px]"*/}
          {/*    >*/}
          {/*      {t("common.delete")}*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*  {sections.map((item) => {*/}
          {/*    return (*/}
          {/*        <Row*/}
          {/*            key={item.name}*/}
          {/*            style={{borderBottom: "1px solid #F0F1F3"}}*/}
          {/*            className="pt-[18px] pb-[18px]"*/}
          {/*        >*/}
          {/*          <Col*/}
          {/*              span={8}*/}
          {/*              className="text-[#888] text-xs font-medium leading-[18px] tracking-[0.06px]"*/}
          {/*          >*/}
          {/*            <div className="ml-2">{item.name}</div>*/}
          {/*          </Col>*/}
          {/*          <Col span={4} className="text-center">*/}
          {/*            <Checkbox*/}
          {/*                checked={(item?.id && permissions[item?.id]?.READ) || false}*/}
          {/*                onChange={(e) =>*/}
          {/*                    item.id &&*/}
          {/*                    handlePermissionChange(*/}
          {/*                        item.id,*/}
          {/*                        CreateUserSectionPermissionRequestDto.permission.READ,*/}
          {/*                        e.target.checked*/}
          {/*                    )*/}
          {/*                }*/}
          {/*            />*/}
          {/*          </Col>*/}
          {/*          <Col span={4} className="text-center">*/}
          {/*            <Checkbox*/}
          {/*                checked={(item?.id && permissions[item?.id]?.CREATE) || false}*/}
          {/*                onChange={(e) =>*/}
          {/*                    item.id &&*/}
          {/*                    handlePermissionChange(*/}
          {/*                        item.id,*/}
          {/*                        CreateUserSectionPermissionRequestDto.permission.CREATE,*/}
          {/*                        e.target.checked*/}
          {/*                    )*/}
          {/*                }*/}
          {/*            />*/}
          {/*          </Col>*/}
          {/*          <Col span={4} className="text-center">*/}
          {/*            <Checkbox*/}
          {/*                checked={(item?.id && permissions[item?.id]?.UPDATE) || false}*/}
          {/*                onChange={(e) =>*/}
          {/*                    item.id &&*/}
          {/*                    handlePermissionChange(*/}
          {/*                        item.id,*/}
          {/*                        CreateUserSectionPermissionRequestDto.permission.UPDATE,*/}
          {/*                        e.target.checked*/}
          {/*                    )*/}
          {/*                }*/}
          {/*            />*/}
          {/*          </Col>*/}
          {/*          <Col span={4} className="text-center">*/}
          {/*            <Checkbox*/}
          {/*                checked={(item?.id && permissions[item?.id]?.DELETE) || false}*/}
          {/*                onChange={(e) =>*/}
          {/*                    item.id &&*/}
          {/*                    handlePermissionChange(*/}
          {/*                        item.id,*/}
          {/*                        CreateUserSectionPermissionRequestDto.permission.DELETE,*/}
          {/*                        e.target.checked*/}
          {/*                    )*/}
          {/*                }*/}
          {/*            />*/}
          {/*          </Col>*/}
          {/*        </Row>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Modal>*/}
        </div>
        <UserModal roles={roles} isOpen={showUserModal} closeModal={() => {
          setShowUserModal(false);
          setSelectedUser(undefined);
        }} onSuccess={() => {
          setShowUserModal(false);
          setSelectedUser(undefined);
          getUsersList();
        }} user={selectedUser} />
        <UserPermissionsModal sections={sections} roles={roles} isOpen={showPermissionsModal} user={selectedUser} closeModal={() => {
          setShowPermissionsModal(false);
          setSelectedUser(undefined);
        }} />
      </>
  );
};
