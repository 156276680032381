import {ReactNode, useCallback, useContext, useEffect, useState,} from "react";
import {Drawer, Layout as LayoutComponent, MenuProps} from "antd";

import {FooterComponent} from "../Footer";
import {useLocation} from "react-router-dom";
import {
  CartRowBaseDto,
  CartService,
  CategoryBaseDto,
  CategoryService,
  GetProductResponseDto,
  LanguageBaseDto,
  LanguageService,
  ProductService,
  UserBaseDto,
} from "../../api";
import {AuthContext} from "../../contexts/authContext";
import {TopMenu} from "./TopMenu";
import {toIncludes} from "../../utils/request";
import Breadcrumbs from "../BreadCrumbs";
import {useTranslation} from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import {ModalLogin} from "../ModalLogin";
import {useBreakpoint} from "../../utils/hooks/useBreakpoint";
import {HeaderComponent} from "./components/Header";
import {debounce} from "lodash";
import {CartData} from "../../pages/user/Cart/components/CartFooter";

const {Header, Content} = LayoutComponent;

interface Props {
  children: ReactNode;
  breadcrumb?: { title: string; href: string; onClick?: () => void }[];
  userMenu?: boolean;
  noPaddingPage?: boolean;
}

export const Layout = ({
                         children,
                         breadcrumb,
                         userMenu = false,
                         noPaddingPage = false,
                       }: Props) => {
  const {logout, setTotalCartRows} = useContext(AuthContext);
  const [openCartDrawer, setOpenCartDrawer] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [languages, setLanguages] = useState<LanguageBaseDto[]>([]);
  const [languageSelected, setLanguageSelected] = useState({
    id: 1,
    countryCode: "IT",
  });
  const [openSearchInput, setOpenSearchInput] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openTopDrawer, setOpenTopDrawer] = useState(false);
  const [cartRows, setCartRows] = useState<CartRowBaseDto[]>([]);
  const [cartData, setCartData] = useState<CartData>({
    shippingCosts: 0,
    gross: 0,
    taxable: 0,
    discount: 0
  });
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>("Account");
  const [selectedAdminItem, setSelectedAdminItem] = useState<string>(
    "Dashboard"
  );

  const rootSubmenuKeys = ["setting"];
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [categories, setCategories] = useState<CategoryBaseDto[]>([]);

  const {isMobile} = useBreakpoint();

  const {setLanguage} = useContext(AuthContext);

  const location = useLocation();

  const {t} = useTranslation();

  const handleCartClose = useCallback(() => setOpenCartDrawer(false), []);

  const updateCartRows = () => {
    CartService.findOneCartController(0, undefined, toIncludes({
      rows: {
        variant: {product: {brand: true, media: true}, media: true},
      }
    })).then(d => {
      debugger;
      setTotalCartRows(d.data.rows.length);
      setCartRows(d.data.rows);
      setCartData({
        discount: d.data.discount,
        gross: d.data.gross,
        taxable: d.data.taxable,
        shippingCosts: d.data.shippingCosts,
      })
    })
  }

  const handleCartOpen = useCallback((currentUser: UserBaseDto | undefined) => {
    if (currentUser) {
      setOpenCartDrawer(true);
      updateCartRows();
    } else {
      setOpenLoginModal(true);
    }
  }, []);

  const languageItems = languages.map((item) => {
    return {
      key: `${item.id}-${item.name}`,
      label: (
        <ReactCountryFlag
          countryCode={item.name}
          style={{
            fontSize: "1.5em",
            lineHeight: "1.5em",
          }}
        ></ReactCountryFlag>
      ),
    };
  });

  useEffect(() => {
    const userLanguage = localStorage.getItem("languageId");
    if (userLanguage) {
      setLanguageSelected({id: Number(userLanguage), countryCode: "IT"});
    }
    const lan = localStorage.getItem("i18nextLng");
    if (lan) setLanguage(lan);
    CategoryService.findAllCategoryController(
      undefined,
      1000,
      undefined,
      undefined,
      undefined,
      toIncludes<CategoryBaseDto>({
        father: {
          translations: {
            translation: {
              language: true,
            },
          },
        },
        translations: {
          translation: {
            language: true,
          },
        },
      })
    ).then((res) => {
      if (res) {
        setCategories(res.data);
      }
    });

    LanguageService.findAllLanguageController().then((res) => {
      setLanguages(res.data);
    });
  }, []);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<GetProductResponseDto[]>([]);

  const findProducts = (filter?: any) => {
    ProductService.findAllProductController(
      undefined,
      undefined,
      JSON.stringify([
        {name: {$containsIgnore: filter}, variants: {stock: {$gt: 0}}},
        {variants: {size: {$containsIgnore: filter}, stock: {$gt: 0}}},
        // { variants: { color: { $containsIgnore: filter } } },
        {variants: {name: {$containsIgnore: filter}, stock: {$gt: 0}}},
        {brand: {name: {$containsIgnore: filter}}, variants: {stock: {$gt: 0}}},
        {variants: {code: {$containsIgnore: filter}, stock: {$gt: 0}}},
        {code: {$containsIgnore: filter}, variants: {stock: {$gt: 0}}}
      ]),
      undefined,
      undefined,
      toIncludes<GetProductResponseDto>({
        variants: {
          media: true
        },
        media: true,
        brand: true
      })
    ).then((res) => {

      setFilteredOptions(res.data);
    });
  };

  const findProductsDebounce = debounce(findProducts, 500)


  const handleSearch = (value: any) => {
    setSearchText(value);
    if (value.length >= 3) {
      findProductsDebounce(value);
    }
    setTimeout(() => {
      debounce(findProducts, 500);
    }, 500);


  };

  return (
    <LayoutComponent>
      <HeaderComponent
        cartRows={cartRows}
        cartData={cartData}
        filteredOptions={filteredOptions}
        handleCartClose={handleCartClose}
        handleCartOpen={(currentUser) => handleCartOpen(currentUser)}
        handleSearch={handleSearch}
        logout={logout}
        languageItems={languageItems}
        onOpenChange={onOpenChange}
        openCartDrawer={openCartDrawer}
        openKeys={openKeys}
        openMenuDrawer={openMenuDrawer}
        openSearchInput={openSearchInput}
        openTopDrawer={openTopDrawer}
        selectedAdminItem={selectedAdminItem}
        selectedItem={selectedItem}
        selectedKeys={selectedKeys}
        setOpenMenuDrawer={(value) => setOpenMenuDrawer(value)}
        setOpenSearchInput={(value) => setOpenSearchInput(value)}
        setOpenTopDrawer={(value) => setOpenTopDrawer(value)}
        setSelectedAdminItem={(value) => setSelectedAdminItem(value)}
        setSelectedItem={(value) => setSelectedItem(value)}
        setSelectedKeys={(value) => setSelectedKeys(value)}
        updateCartRows={updateCartRows}
      ></HeaderComponent>

      {!location.pathname.includes("admin") && (
        <Drawer
          placement={"top"}
          className={`custom-drawer ${
            openTopDrawer ? "custom-drawer-open" : "custom-drawer-closed"
          } border-b-[1px] border-t-0 border-l-0 border-r-0 border-neutral-200 border-solid mt-16`}
          closable={false}
          onClose={() => setOpenTopDrawer(!openTopDrawer)}
          open={openTopDrawer}
          height={"auto"}
          style={{position: "absolute", top: 0, height: "auto"}}
        >
          <TopMenu categories={categories}></TopMenu>
        </Drawer>
      )}

      <Content
        className="site-layout bg-white h-[100%]"
        style={{
          padding:
            userMenu && !noPaddingPage
              ? "0px 0 0 50px"
              : noPaddingPage
                ? "0px"
                : isMobile
                  ? "0px 20px"
                  : "0px 50px",
          marginTop: openTopDrawer ? 64 : 0,
        }}
      >
        {breadcrumb && breadcrumb.length > 0 && (
          <div className={isMobile ? "mb-2 mt-2" : "ml-24 mb-10 mt-10"}>
            <Breadcrumbs breadcrumbs={breadcrumb}/>
          </div>
        )}

        <div className="bg-white min-h-screen">{children}</div>
        <ModalLogin
          operationAfterLogin={(user) => {
            handleCartOpen(user);
          }}
          openLoginModal={openLoginModal}
          setOpenLoginModal={(value) => setOpenLoginModal(value)}
        ></ModalLogin>
      </Content>
      <FooterComponent></FooterComponent>
    </LayoutComponent>
  );
};
