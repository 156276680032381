import { FC } from "react";
import { IconPropsType } from "./types";

export const RightIcon: FC<IconPropsType> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.2442 24.4122C19.9187 24.7377 19.9187 25.2653 20.2442 25.5908C20.5696 25.9162 21.0973 25.9162 21.4227 25.5908L26.4189 20.5945C26.4215 20.592 26.424 20.5895 26.4265 20.587L26.7173 20.2961C26.88 20.1334 26.88 19.8696 26.7173 19.7069L26.4265 19.416C26.424 19.4135 26.4215 19.411 26.4189 19.4085L21.4227 14.4123C21.0973 14.0868 20.5696 14.0868 20.2442 14.4122C19.9187 14.7377 19.9187 15.2653 20.2442 15.5908L23.8216 19.1682L14.1668 19.1682C13.7066 19.1682 13.3335 19.5413 13.3335 20.0015C13.3335 20.4617 13.7066 20.8348 14.1668 20.8348L23.8216 20.8348L20.2442 24.4122Z"
        fill="#0950A8"
      />
    </svg>
  );
};
