import { FC } from "react";
import { IconPropsType } from "./types";

export const Paypal: FC<IconPropsType> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.10676 23.1865L8.52271 20.5445L7.59617 20.523H3.17188L6.24654 1.0276C6.25608 0.968743 6.2871 0.913867 6.33243 0.874897C6.37776 0.835926 6.43582 0.814453 6.49626 0.814453H13.9562C16.4328 0.814453 18.142 1.32981 19.0343 2.34701C19.4526 2.8242 19.719 3.32285 19.8479 3.87162C19.9831 4.44742 19.9855 5.13536 19.8535 5.97441L19.8439 6.03565V6.57328L20.2622 6.81028C20.6146 6.99718 20.8945 7.21111 21.1092 7.45607C21.4671 7.86406 21.6986 8.3826 21.7964 8.99738C21.8974 9.62965 21.864 10.382 21.6986 11.2338C21.5077 12.2136 21.1991 13.067 20.7824 13.7652C20.399 14.4087 19.9107 14.9423 19.3309 15.3559C18.7774 15.7487 18.1197 16.047 17.3761 16.2379C16.6555 16.4256 15.834 16.5202 14.9329 16.5202H14.3523C13.9372 16.5202 13.5339 16.6697 13.2174 16.9377C12.9001 17.2113 12.6901 17.5851 12.6257 17.9939L12.582 18.2317L11.8471 22.8882L11.8137 23.0592C11.8049 23.1133 11.7898 23.1403 11.7676 23.1586C11.7477 23.1753 11.719 23.1865 11.6912 23.1865H8.10676Z"
        fill="#253B80"
      />
      <path
        d="M20.6586 6.09766C20.6364 6.24002 20.6109 6.38556 20.5823 6.53508C19.5985 11.5861 16.2327 13.331 11.9341 13.331H9.74541C9.21971 13.331 8.77673 13.7127 8.69481 14.2313L7.57422 21.3381L7.25689 23.3527C7.20361 23.693 7.46606 24 7.80963 24H11.6915C12.1512 24 12.5417 23.666 12.6141 23.2127L12.6523 23.0154L13.3831 18.3772L13.4301 18.1227C13.5016 17.6678 13.8929 17.3338 14.3526 17.3338H14.9332C18.6942 17.3338 21.6384 15.8068 22.499 11.388C22.8584 9.54214 22.6723 8.00083 21.7212 6.91682C21.4333 6.58995 21.0762 6.31875 20.6586 6.09766Z"
        fill="#179BD7"
      />
      <path
        d="M19.629 5.68735C19.4787 5.6436 19.3236 5.60384 19.1646 5.56805C19.0047 5.53306 18.8409 5.50204 18.6723 5.475C18.0822 5.37956 17.4356 5.33423 16.7429 5.33423H10.8957C10.7518 5.33423 10.615 5.36684 10.4925 5.42569C10.2229 5.55532 10.0225 5.81062 9.97399 6.12317L8.73012 14.0015L8.69434 14.2313C8.77625 13.7128 9.21924 13.331 9.74494 13.331H11.9336C16.2323 13.331 19.598 11.5853 20.5818 6.53514C20.6112 6.38563 20.6359 6.24008 20.6582 6.09772C20.4092 5.9657 20.1396 5.85277 19.8493 5.75654C19.7777 5.73268 19.7038 5.70961 19.629 5.68735Z"
        fill="#222D65"
      />
      <path
        d="M9.97421 6.12308C10.0227 5.81052 10.2231 5.55523 10.4927 5.42639C10.616 5.36753 10.752 5.33493 10.896 5.33493H16.7431C17.4358 5.33493 18.0824 5.38026 18.6725 5.4757C18.8411 5.50274 19.0049 5.53375 19.1648 5.56875C19.3238 5.60453 19.4789 5.6443 19.6292 5.68804C19.704 5.71031 19.778 5.73337 19.8503 5.75644C20.1406 5.85267 20.4102 5.9664 20.6592 6.09763C20.9518 4.23104 20.6568 2.96014 19.6475 1.80933C18.5349 0.5424 16.5267 0 13.9571 0H6.49712C5.97222 0 5.52446 0.381748 5.44334 0.901084L2.33607 20.5969C2.27483 20.9866 2.57546 21.3381 2.96834 21.3381H7.57397L8.73034 14.0014L9.97421 6.12308Z"
        fill="#253B80"
      />
    </svg>
  );
};
