/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderRowBaseDto } from './OrderRowBaseDto';
import type { PaymentMethodBaseDto } from './PaymentMethodBaseDto';
import type { TransactionBaseDto } from './TransactionBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type PatchOrderRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    status?: PatchOrderRequestDto.status | null;
    deliveredDate?: string | null;
    shippingDate?: string | null;
    shippingCost?: number | null;
    shippingAddress?: Record<string, any> | null;
    total?: number | null;
    discount?: number | null;
    credit?: number | null;
    user?: (UserBaseDto | number) | null;
    rows?: Array<(number | OrderRowBaseDto)> | null;
    transaction?: (TransactionBaseDto | number) | null;
    paymentMethod?: (PaymentMethodBaseDto | number) | null;
};
export namespace PatchOrderRequestDto {
    export enum status {
        PENDING = 'PENDING',
        CONFIRMED = 'CONFIRMED',
        PROCESSING = 'PROCESSING',
        SHIPPED = 'SHIPPED',
        DELIVERED = 'DELIVERED',
        CANCELLED = 'CANCELLED',
        REJECTED = 'REJECTED',
    }
}

