import {Checkbox, Collapse} from "antd";
import {CheckboxValueType} from "antd/es/checkbox/Group";

interface Props {
  labelStyle: string;
  label: string;
  items: any;
  onChange: (e: CheckboxValueType[] | any) => void;
  itemChecked: CheckboxValueType[];
}

export const CheckboxFilter = ({
                                 labelStyle,
                                 label,
                                 items,
                                 onChange,
                                 itemChecked,
                               }: Props) => {
  return (
    <Collapse
      size="small"
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <p className={labelStyle}>{label}</p>,
          children: (
            <Checkbox.Group
              className="flex flex-col"
              value={itemChecked}
              onChange={(value: CheckboxValueType[]) => onChange(value)}
              options={items}
            ></Checkbox.Group>
          ),
        },
      ]}
    />
  );
};
