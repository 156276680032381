import { FC } from "react";
import { IconPropsType } from "./types";

export const ChevronLeft: FC<IconPropsType> = (props, fill) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40843 10L12.3584 7.05004C12.5136 6.8939 12.6008 6.68269 12.6008 6.46254C12.6008 6.24238 12.5136 6.03117 12.3584 5.87504C12.281 5.79693 12.1888 5.73494 12.0872 5.69263C11.9857 5.65032 11.8768 5.62854 11.7668 5.62854C11.6568 5.62854 11.5478 5.65032 11.4463 5.69263C11.3447 5.73494 11.2526 5.79693 11.1751 5.87504L7.64176 9.40837C7.56366 9.48584 7.50166 9.57801 7.45935 9.67956C7.41705 9.78111 7.39526 9.89003 7.39526 10C7.39526 10.11 7.41705 10.219 7.45935 10.3205C7.50166 10.4221 7.56366 10.5142 7.64176 10.5917L11.1751 14.1667C11.253 14.2439 11.3453 14.305 11.4468 14.3465C11.5484 14.388 11.6571 14.409 11.7668 14.4084C11.8764 14.409 11.9852 14.388 12.0867 14.3465C12.1882 14.305 12.2806 14.2439 12.3584 14.1667C12.5136 14.0106 12.6008 13.7994 12.6008 13.5792C12.6008 13.359 12.5136 13.1478 12.3584 12.9917L9.40843 10Z"
        fill="white"
      />
    </svg>
  );
};
