/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TicketTypeBaseDto } from './TicketTypeBaseDto';
export type PatchTicketRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    title?: string | null;
    status: PatchTicketRequestDto.status;
    type?: (TicketTypeBaseDto | number) | null;
};
export namespace PatchTicketRequestDto {
    export enum status {
        OPEN = 'OPEN',
        IN_CHARGE = 'IN_CHARGE',
        SOLVED = 'SOLVED',
        CLOSED = 'CLOSED',
        ARCHIVED = 'ARCHIVED',
    }
}

