import { Input } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  item: any | undefined;
  showEditPassword?: boolean;
  editPassword?: () => void;
  edit: boolean;
  setEditItem?: (value: string) => void,
  inputType?: "text" | "password" | "tel" | "email",
  inputPlaceholder?: string
}
export const InfoLine = ({ label, item, showEditPassword = false , editPassword, edit, setEditItem, inputType = "text", inputPlaceholder}: Props) => {
  const { t } = useTranslation()
  return (
    <div style={{ borderBottom: "1px solid #DBDBDB" }} className="p-4 w-full">
      <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 justify-start items-center w-full">
        <div className="w-full md:w-52 text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px] ">
          {label}
        </div>

        <div className="flex justify-between w-full">
          <div className="text-neutral-500 text-xs font-medium leading-4 tracking-[0.06px] w-full">
            {!edit ? item : label === 'Password' ? item : <Input placeholder={inputPlaceholder} type={inputType} onChange={(e) => setEditItem && setEditItem(e.target.value)} defaultValue={item}></Input>}
          </div>
          {showEditPassword && (
            <div className="underline text-neutral-300 text-xs font-medium leading-4 tracking-[0.06px] cursor-pointer w-full flex justify-end"
            onClick={editPassword}>
              {t('profile.editPassword')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
