import { Avatar, Col, Row, Tag } from "antd";

export const SalesByLocation = () => {
  const data = [
    {
      avatar: "",
      location: "United Kingdom",
      total: "340",
      amount: "$7829",
      percentage: 12,
    },
    {
      avatar: "",
      location: "Spain",
      total: "340",
      amount: "$7829",
      percentage: -12,
    },
    {
      avatar: "",
      location: "Italy",
      total: "340",
      amount: "$7829",
      percentage: 0,
    },
  ];
  return (
    <div
      style={{
        borderRadius: "8px",
        border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
        background: "var(--neutral-white, #FFF)",
        boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
        padding: 24,
        height: "100%",
      }}
    >
      <div className="text-[#333843] font-[Inter] text-lg font-medium leading-7 tracking-[0.09px]">
        Sales by Location
      </div>
      <div className="text-[#667085] font-[Inter] text-sm font-medium leading-5 tracking-[0.09px]">
        Sales performance by location
      </div>
      <div className="mt-4">
        {data.map((item) => {
          return (
            <Row
            key={item.location}
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "lightgray",
                marginBottom: 5,
                marginTop: 15
              }}
            >
              <Col span={15}>
                <Row>
                  <Col span={4}>
                    
                    <Avatar shape="square" style={{ marginTop: 2 }}></Avatar>
                  </Col>
                  <Col span={18} offset={1}>
                    <div className="text-[#333843] font-[Inter] text-sm font-medium leading-5 tracking-[0.07px]">
                      {item.location}
                    </div>
                    <div className="text-[#667085] font-[Inter] text-sm font-normal leading-[18px] tracking-[0.07px]">
                      {item.total}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <div className="flex justify-between mt-2">
                  <div className="text-[#1A1C21] font-[Inter] text-sm font-medium leading-5 tracking-[0.07px]">
                    {item.amount}
                  </div>
                  <div className="ml-2">
                    <Tag
                      color={
                        item.percentage > 0
                          ? "#E7F4EE"
                          : item.percentage === 0
                          ? "#F0F1F3"
                          : "#FDF1E8"
                      }
                      style={{
                        borderRadius: 50,
                        borderWidth: 0,
                        fontWeight: "bold",
                        color:
                          item.percentage > 0
                            ? "#0D894F"
                            : item.percentage === 0
                            ? "#667085"
                            : "#F04438",
                      }}
                      key={item.percentage}
                    >
                      {item.percentage > 0
                        ? "+" + item.percentage
                        : item.percentage}
                      %
                    </Tag>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};
