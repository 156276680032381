import { Input, Modal, Select } from "antd";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TicketReplyService, TicketService, TicketTypeBaseDto } from "../../api";
import { AuthContext } from "../../contexts/authContext";

interface Props {
  isOpen: boolean;
  onClose: () => void,
  onSuccess: () => void,
  types: TicketTypeBaseDto[]
}

export const NewTicketModal = ({
   isOpen,
   onSuccess,
   onClose,
   types
}: Props) => {

  const [ticketData, setTicketData] = useState({
    type: types[0] ?? "",
    title: "",
    message: ""
  })

  const { token } = useContext(AuthContext);
  const { t } = useTranslation()

  const ticketTypeOptions = useMemo(() => {
    return types.map((type) => ({
      label: t(`tickets.types.${type.name}`),
      value: type.name
    }))
  }, [types])

  const handleSubmit = async () => {

    if(!ticketData || !ticketData.title || !ticketData.message) return;
    
    const createdTicket = await TicketService.createTicketController({title: ticketData.title, user: null, type: 1});
    if(!createdTicket.data) console.log("Ticket creation error");
    
    const createdMessage = await TicketReplyService.createTicketReplyController({message: ticketData.message, ticket: createdTicket.data});
    if(!createdMessage.data) console.log("Ticket reply creation error");

    onSuccess();
  }

  const selectType = (selectedValue: string) => {
    if(!selectedValue) return;
    const selectedType = types.find((type) => type.name === selectedValue);
    if(selectedType) setTicketData((data) => ({...data, type: selectedType}))
  }
  
  const closeModal = () => {
    setTicketData({
      type: types[0] ?? "",
      title: "",
      message: ""
    });
    onClose();
  }

  return (
      <Modal
          cancelButtonProps={{
            hidden: true,
          }}
          onCancel={closeModal}
          okText={t("common.confirm")}
          onOk={handleSubmit}
          okButtonProps={{
            block: false,
            size: "large",
            style: {
              backgroundColor: "#0950A8",
              fontSize: 12,
              fontWeight: 600,
              lineHeight: "18px",
              letterSpacing: "0.06px",
              padding: 10,
            },
          }}
          width={"600px"}
          title={
            <div>
              <div className="text-blue-400 text-[16px] font-semibold leading-6 tracking-[0.06px]">
                {t("tickets.newTicketModal.title")}
              </div>
              <div className="mt-2 text-neutral-300 text-[10px] font-semibold leading-4 tracking-[0.05px]">
                {t("tickets.newTicketModal.message")}
              </div>
            </div>
          }
          open={isOpen}
      >
        <div className="mt-4 flex flex-col space-y-3">
          <div className="flex flex-col space-y-1">
            <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
              {t("tickets.newTicketModal.typeSelectLabel")}
            </span>
            <Select options={ticketTypeOptions} value={ticketData.type.name} onChange={selectType} />
          </div>

          <div className="flex flex-col space-y-1">
            <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
              {t("tickets.newTicketModal.titleFieldLabel")}
            </span>
            <Input onChange={(e) => setTicketData((data) => ({...data, title: e.target.value}))} value={ticketData.title} size="large" className="mt-1"/>
          </div>

          <div className="flex flex-col space-y-1">
          <span className="text-xs font-semibold leading-4 tracking-[0.06px]">
            {t("tickets.newTicketModal.messageFieldLabel")}
          </span>
            <Input.TextArea onChange={(e) => setTicketData((data) => ({
              ...data,
              message: e.target.value
            }))} size="large" value={ticketData.message} className="mt-1" rows={3}/>
          </div>
        </div>
      </Modal>
  );
};
