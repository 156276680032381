import { CartCard } from "../../../Components/CartCard";
import { CartRowBaseDto } from "../../../api";
import {CartData, CartFooter} from "./components/CartFooter";
import { ShoppingCartOutlined } from "@ant-design/icons";

interface Props {
  cartRows: CartRowBaseDto[];
  updateCartRows: Function;
  cartData: CartData,
}

export const Cart = ({ cartRows, updateCartRows, cartData }: Props) => {
  return (
    <div className="overflow-hidden relative h-full flex flex-col">
      <div className="overflow-auto py-5 px-10 flex-grow">
        {cartRows && cartRows.length > 0 ? (
          cartRows?.map((res) => {
            return (
              <div key={res.id}>
                <CartCard
                  id={res?.id ?? 0}
                  name={res?.variant?.name}
                  brand={res?.variant?.product?.brand?.name}
                  colors={res?.variant?.colors}
                  size={res?.variant?.size}
                  price={
                    (res.price - res.variant.discount) * Number(res.qty)
                  }
                  quantity={res?.qty}
                  updateCartRows={updateCartRows}
                  media={res?.variant.media?.path ?? res.variant?.product?.media?.path}
                  productId={res.variant!.product!.id!}
                />
              </div>
            );
          })
        ) : (
          <div
            className={
              "flex flex-col space-y-3 items-center justify-center h-full"
            }
          >
            <ShoppingCartOutlined className={"text-6xl text-blue-300"} />
            <p className={"font-bold text-lg text-neutral-400"}>
              Il tuo carrello è vuoto
            </p>
          </div>
        )}
      </div>
      <CartFooter cartRows={cartRows} cartData={cartData} />
    </div>
  );
};
