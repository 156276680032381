import React, { useRef, useState } from "react";
import { Button, Col, Row, Tabs } from "antd";
import { Background } from "../../../Components/Background";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useFormContext } from "../../../contexts/formContext";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthService } from "../../../api";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";

export const Register = () => {
  const { formData } = useFormContext();

  return (
    <>
      <Background
        children={<RegisterComponent formData={formData}></RegisterComponent>}
      ></Background>
    </>
  );
};

const RegisterComponent = ({ formData }: any) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = React.useState("1");
  const [recaptcha, setRecaptcha] = useState("");
  const [error, setError] = useState<any>([]);
  const navigate = useNavigate();
  const formRefStep1: any = useRef();
  const formRefStep2: any = useRef();
  const formRefStep3: any = useRef();
  const { isMobile } = useBreakpoint();

  const validateForm = (formRef: any, key: number) => {
    if (formRef.current) {
      formRef.current
        .validateForm()
        .then((values: any) => {
          setActiveKey(key.toString());
        })
        .catch((errorInfo: any) => {
          console.log("Errore di validazione:", errorInfo);
        });
    }
  };

  const next = () => {
    let key = Number(activeKey);
    key += 1;
    if (activeKey === "1") {
      validateForm(formRefStep1, key);
    } else if (activeKey === "2") {
      validateForm(formRefStep2, key);
    } else if (activeKey === "3") {
      validateForm(formRefStep3, key);
    }
  };

  const previous = () => {
    let key = Number(activeKey);
    key -= 1;
    setActiveKey(key.toString());
  };

  const showPreviousButton = () => {
    return Number(activeKey) > 1;
  };

  const showNextButton = () => {
    return Number(activeKey) < 3;
  };

  const save = () => {
    AuthService.signUpAuthController({
      confirmPassword: formData.confirmPassword,
      email: formData.email,
      name: formData.name,
      password: formData.password,
      surname: formData.surname,
      phoneNumber: formData.phone,
      companyDenomination: formData.company,
      vatNumber: formData.vatNumber,
      taxCode: formData.taxCode,
      sdi: formData.SDI,
      registeredOffice: formData.registeredOffice,
      adminReference: formData.reference,
      contact: formData.contact,
    })
      .then((res: any) => {
        navigate("/login");
      })
      .catch((err: any) => {
        let errorsList: any[] = [];
        errorsList = err.body.errors;
        setError(errorsList);
      });
  };

  const items = [
    {
      key: "1",
      label: "1",
      children: <Step1 ref={formRefStep1} />,
    },
    {
      key: "2",
      label: "2",
      children: <Step2 ref={formRefStep2} />,
    },
    {
      key: "3",
      label: "3",
      children: (
        <Step3
          ref={formRefStep3}
          errors={error}
          setRecaptcha={(value) => setRecaptcha(value)}
        />
      ),
    },
  ];

  const Buttons = () => {
    return (
      <div className="w-full h-[5%]">
        <Row>
          <Col span={16}>
            {showPreviousButton() && (
              <Button
                className="text-blue-400 font-semibold text-sm leading-5 tracking-[0.07px]"
                type="link"
                onClick={() => {
                  previous();
                }}
                icon={<LeftOutlined />}
              >
                {t("registration.back")}
              </Button>
            )}
          </Col>
          <Col span={7}>
            {showNextButton() ? (
              <Button
                type="primary"
                onClick={() => next()}
                className="float-right"
                htmlType="submit"
              >
                {t("registration.forward")}
                <RightOutlined />
              </Button>
            ) : (
              <Button
                disabled={!recaptcha}
                type="primary"
                onClick={() => save()}
                className="float-right"
                htmlType="submit"
              >
                {t("common.save")}
                <RightOutlined />
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div
      className={`bg-[white] rounded-lg pl-12 pr-12 h-full ${
        isMobile ? "p-2" : "p-10"
      }`}
    >
      <div className={isMobile ? "h-full" : "h-[95%]"}>
        <p className="text-2xl font-bold mb-0">
          {" "}
          {t("registration.registration")}
        </p>
        <p className="text-sm text-neutral-300 font-medium mt-2">
          {t("login.header.description")}
        </p>
        <Tabs
          className="signup-tabs"
          defaultActiveKey="1"
          activeKey={activeKey}
          items={items}
        />
        {isMobile && <Buttons />}
      </div>

      <div> {!isMobile && <Buttons />}</div>
    </div>
  );
};
