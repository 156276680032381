import { Checkbox, Divider, Form, notification, Popconfirm, Radio } from "antd";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AddressService, UserBaseDto } from "../../../../api";
import { PencilAddresses } from "../../../../Components/icons/PencilAddresses";
import { Plus } from "../../../../Components/icons/Plus";
import { Trash } from "../../../../Components/icons/Trash";
import { AuthContext } from "../../../../contexts/authContext";
import { AddAddress } from "./AddAddress";

export type FieldType = {
  citta: string;
  civico: string;
  codicePostale: string;
  cognome: string;
  email: string;
  indirizzo: string;
  nazione: string;
  nome: string;
  numero: string;
  nuovoIndirizzo: string;
  predefinito: boolean;
  prefix: string;
  provincia: string;
};

export const DeliveryAddress = ({
  currentUser,
  showTitle = true,
  showDeleteEditButton = false,
  showBox = false,
  setAddressId,
}: {
  currentUser: UserBaseDto | any;
  showTitle?: boolean;
  showDeleteEditButton?: boolean;
  showBox: boolean;
  setAddressId?: (value: number) => void;
}) => {
  const { getMe } = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [openNewAddress, setOpenNewAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<{
    alias: string;
    name: string;
    surname: string;
    street: string;
    streetNumber: string;
    zipCode: string;
    province: string;
    city: string;
    country: string;
    email: string;
    phone: string;
    id: number;
    setAsDefault: boolean;
  }>();
  const [newAddress, setNewAddress] = useState<
    {
      citta: string;
      civico: string;
      codicePostale: string;
      cognome: string;
      email: string;
      indirizzo: string;
      nazione: string;
      nome: string;
      numero: string;
      nuovoIndirizzo: string;
      predefinito: boolean;
      prefix: string;
      provincia: string;
    }[]
  >([]);
  const [form] = Form.useForm();
  const [editAddress, setEditAddress] = useState(0);
  const location = useLocation()

  const onFinish = (values: FieldType) => {
    setNewAddress([...newAddress, values]);
    setOpenNewAddress(!openNewAddress);
    AddressService.createAddressController({
      alias: values.nuovoIndirizzo,
      city: values.citta,
      country: values.nazione,
      email: values.email,
      name: values.nome,
      phone: values.numero,
      province: values.provincia,
      street: values.indirizzo,
      surname: values.cognome,
      user: currentUser ?? 0,
      zipCode: values.codicePostale,
      streetNumber: values.civico,
      setAsDefault: values.predefinito,
    }).then((res: any) => {
      if (res) {
        getMe();
        api.success({
          style: {
            borderRadius: "8px",
            border: "1px solid var(--200, #92D2B4)",
            background: "#F4FCF8",
            color: "#3DA172",
          },
          message: t("delivery.messages.addressAdded"),
        });
      }
    });
  };

  const onEditAddress = async (values: any, addressId: number) => {
    setEditAddress(0);
    await AddressService.updateAddressController(addressId, {
      alias: values.nuovoIndirizzo,
      city: values.citta,
      country: values.nazione,
      email: values.email,
      name: values.nome,
      phone: values.numero,
      province: values.provincia,
      street: values.indirizzo,
      surname: values.cognome,
      user: currentUser ?? 0,
      zipCode: values.codicePostale,
      streetNumber: values.civico,
      setAsDefault: values.predefinito,
    }).then((res) => {
      if (res) {
        getMe();
        api.success({
          style: {
            borderRadius: "8px",
            border: "1px solid var(--200, #92D2B4)",
            background: "#F4FCF8",
            color: "#3DA172",
          },
          message: t("delivery.messages.addressEdited"),
        });
      }
    });
  };

  const deleteAddress = (addressId: number) => {
    AddressService.deleteAddressController(addressId).then((res) => {
      getMe();
      if (res) {
        api.success({
          style: {
            borderRadius: "8px",
            border: "1px solid var(--200, #92D2B4)",
            background: "#F4FCF8",
            color: "#3DA172",
          },
          message: t("delivery.messages.addressesDeleted"),
        });
      }
    });
  };

  return (
    <>
      {showTitle && (
        <span className="text-sm font-semibold leading-5 tracking-[0.07px] text-blue-400">
          {t("delivery.deliveryAddress")}
        </span>
      )}
      {currentUser?.addresses.map(
        (item: {
          alias: string;
          name: string;
          surname: string;
          street: string;
          streetNumber: string;
          zipCode: string;
          city: string;
          country: string;
          email: string;
          phone: string;
          id: number;
          province: string;
          setAsDefault: boolean;
        }) => {
          return (
            <div className="mt-2" key={item.id}>
              <div
                className={
                  showBox
                    ? "border-[1px] border-[#DBDBDB] border-solid rounded-lg p-4"
                    : ""
                }
              >
                <div className="flex justify-between">
                  {!showDeleteEditButton ? (
                    <Radio
                      checked={selectedAddress ? selectedAddress.id === item.id : (currentUser as UserBaseDto)?.defaultAddress?.id === item.id}
                      onChange={() => {
                        setSelectedAddress(item);
                        setAddressId && setAddressId(item.id!);
                      }}
                    >
                      {item.alias.toLocaleUpperCase()}
                    </Radio>
                  ) : (
                    <div className="font-bold">
                      {item.alias.toLocaleUpperCase()}
                    </div>
                  )}
                  {showDeleteEditButton && (
                    <div className="flex">
                      <Popconfirm
                        placement="topLeft"
                        title={t("profile.AreYouSureToDeleteAddress")}
                        okText={t("clients.messages.yes")}
                        cancelText={t("clients.messages.no")}
                        onConfirm={() => deleteAddress(item.id)}
                      >
                        <div className="cursor-pointer mr-4">
                          <Trash></Trash>
                        </div>
                      </Popconfirm>

                      <div
                        className="cursor-pointer"
                        onClick={() => setEditAddress(item.id)}
                      >
                        <PencilAddresses></PencilAddresses>
                      </div>
                    </div>
                  )}
                </div>
                {!(editAddress === item.id) ? (
                  <div className={location.pathname.includes('cart') ? "ml-0" : "ml-6"}>
                    <div className="flex justify-between mt-2">
                      <div className="text-xs leading-4 tracking-[0.06px]">
                        {item.name} {item.surname}
                      </div>
                      <div className="text-xs leading-4 tracking-[0.06px]">
                        {item.street} {item.streetNumber}, {item.zipCode}{" "}
                        {item.city}, {item.country}
                      </div>
                    </div>
                    <div className="flex justify-between mt-2">
                      <div className="text-xs leading-4 tracking-[0.06px]">
                        {item.email}
                      </div>
                    </div>
                    <div className="flex justify-between mt-2">
                      <div className="text-xs leading-4 tracking-[0.06px]">
                        {item.phone}
                      </div>
                    </div>
                    <Checkbox
                      checked={
                        item.id === currentUser?.defaultAddress?.id
                      }
                      onChange={(e) => {
                        onEditAddress(
                          {
                            nuovoIndirizzo: item.alias,
                            citta: item.city,
                            nazione: item.country,
                            email: item.email,
                            nome: item.name,
                            telefono: item.phone,
                            provincia: item.province,
                            indirizo: item.street,
                            cognome: item.surname,
                            user: currentUser ?? 0,
                            codicePostale: item.zipCode,
                            civico: item.street,
                            predefinito: e.target.checked,
                          },
                          item.id
                        );
                      }}
                    >
                      {t("delivery.savedAsDefaultAddress")}
                    </Checkbox>

                    {!showBox && <Divider></Divider>}
                  </div>
                ) : (
                  <AddAddress
                    onFinish={(values) => onEditAddress(values, item.id)}
                    form={form}
                    valuesToEdit={
                      currentUser.addresses.filter(
                        (el: any) => el.id === editAddress
                      )[0]
                    }
                    userDefaultAddressId={currentUser?.defaultAddress?.id}
                  ></AddAddress>
                )}
              </div>
            </div>
          );
        }
      )}

      {showBox && <Divider></Divider>}
      <div
        className={`flex justify-start cursor-pointer ${location.pathname.includes('cart') ? "ml-0" : "ml-6"}ml-6`}
        onClick={() => setOpenNewAddress(!openNewAddress)}
      >
        <Plus fill="#858D9D" />{" "}
        <span className="ml-2 mt-[2px] text-neutral-300 text-xs font-semibold leading-4 tracking-[0.06px]">
          {t("delivery.addAddress")}
        </span>
      </div>

      <Divider className={showBox ? "" : "ml-6"}></Divider>
      {openNewAddress && (
        <AddAddress
          onFinish={(values) => onFinish(values)}
          form={form}
        ></AddAddress>
      )}
      {contextHolder}
    </>
  );
};
