import { Avatar, Button, Col, Row, Table, Tag } from "antd";

import { Key, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonFilter } from "../../../../Components/ButtonFilter";
import { Filter } from "../../../../Components/icons/Filter";

export const TopSelling = () => {
  const [price, setPrice] = useState({
    price: {
      $gte: 0,
      $lte: 0,
    },
  });
  const dataSource = [
    {
      key: "1",
      products: {
        name: "Handmade Pouch",
        sku: "302012",
        avatar: "",
      },
      sales: "401",
      amount: 320,
      price: "$183",
      status: "Published",
    },
    {
      key: "2",
      products: {
        name: "Smartwatch E2",
        sku: "302012",
        avatar: "",
      },
      sales: "401",
      amount: 320,
      price: "$183",
      status: "Low stock",
    },
  ];
  const { t } = useTranslation()

  const columns = [
    {
      title: t('common.products'),
      dataIndex: "products",
      key: "products",
      sorter: (
        a: { products: { name: string } },
        b: { products: { name: string } }
      ) => a.products.name.localeCompare(b.products.name),
      render: (text: any) => {
        return (
          <Row>
            <Col span={4}>
              {" "}
              <Avatar shape="square" style={{ marginTop: 2 }}></Avatar>
            </Col>
            <Col span={19} offset={1}>
              <div className="text-[#333843] font-[Inter] text-sm font-medium leading-5 tracking-[0.07px]">
                {text.name}
              </div>
              <div className="text-[#667085] font-[Inter] text-sm font-normal leading-[18px] tracking-[0.07px]">
                SKU: {text.sku}
              </div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t('common.sales'),
      dataIndex: "sales",
      key: "sales",
      sorter: (a: { sales: string }, b: { sales: string }) =>
        a.sales.localeCompare(b.sales),
    },
    {
      title: t('common.amount'),
      dataIndex: "amount",
      key: "amount",
      sorter: (a: { amount: number }, b: { amount: number }) =>
        a.amount - b.amount,
      render: (text: number) => {
        return <span>$ {text}</span>;
      },
    },
    {
      title: t('common.price'),
      dataIndex: "price",
      key: "price",
      sorter: (a: { price: string }, b: { price: string }) =>
        a.price.localeCompare(b.price),
    },
    {
      title: t('common.status'),
      dataIndex: "status",
      key: "status",
      sorter: (a: { status: string }, b: { status: string }) =>
      a.status.localeCompare(b.status),
      render: (text: any) => {
        return (
          <Tag
            color={text === "Published" ? "#E7F4EE" : "#FDF1E8"}
            style={{
              borderRadius: 50,
              borderWidth: 0,
              fontWeight: "bold",
              color: text === "Published" ? "#0D894F" : "#E46A11",
            }}
            key={text}
          >
            {text}
          </Tag>
        );
      },
    },
  ];

  return (
    <div
      style={{
        borderRadius: "8px",
        border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
        background: "var(--neutral-white, #FFF)",
        boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
        padding: 24,
        height: "100%",
      }}
    >
      <div className="flex justify-between">
        <div className="text-[#333843] font-[Inter] text-lg font-medium leading-7 tracking-[0.09px]">
          {t('dashboard.salesProcess.topSellingProduct')}
        </div>
        <div>
          {/* <Button className="bg-white text-[#667085] text-sm leading-5 tracking-[0.07px] font-semibold border-[#667085]">
            <Row>
              <Filter fill="#667085" /> Filters
            </Row>
          </Button> */}
          <ButtonFilter
            showButton={true}
            showSelectedFilters={true}
           
            findProductsWithFilters={(v: any) => {
              //TODO: inserire la query quando avremo l'api
              console.log(v);
            }}
            // findProductsWithFilters={(v: any) => {
            //   findProductsWithFilters(v);
            // }}
          ></ButtonFilter>{" "}
        </div>
      </div>
      <Table
        // className="admin-table"
        style={{ marginTop: 20 }}
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 1 }}
      />
    </div>
  );
};
