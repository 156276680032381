import {
    CreateUserSectionPermissionRequestDto, GetUserSectionPermissionResponseDto,
    RoleBaseDto, SectionBaseDto,
    UserBaseDto, UserSectionPermissionService, UserService,
} from "../../../../api";
import {useEffect, useMemo, useState} from "react";
import {Button, Checkbox, Col, Input, Modal, notification, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {UserRole} from "../../../../utils/types";
import {toFilter, toIncludes} from "../../../../utils/request";
import {da} from "date-fns/locale";

interface UserPermissions {
    roles: number[],
    permissions: Record<number, Record<CreateUserSectionPermissionRequestDto.permission, boolean>>
}

interface UserPermissionsModalProps {
    user?: UserBaseDto,
    sections: SectionBaseDto[]
    roles: RoleBaseDto[]
    isOpen: boolean,
    closeModal: () => void
}

const UserPermissionsModal = ({user, sections, roles, isOpen, closeModal}: UserPermissionsModalProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [userPermissions, setUserPermissions] = useState<UserPermissions>({
        roles: (user?.roles ? user.roles.map((role) => role.role.id!) : []),
        permissions: {}
    });
    const { t } = useTranslation();
    
    useEffect(() => {
        
        const permissionObj: Record<number, Record<string, boolean>> = {};
        sections.forEach((section) => {
            permissionObj[section.id!] = {
                [CreateUserSectionPermissionRequestDto.permission.READ]: false,
                [CreateUserSectionPermissionRequestDto.permission.CREATE]: false,
                [CreateUserSectionPermissionRequestDto.permission.UPDATE]: false,
                [CreateUserSectionPermissionRequestDto.permission.DELETE]: false,
            }
        })
        
        setUserPermissions((data) => ({
            ...data,
            permissions: permissionObj
        }))
        
        getUserPermissions();
    }, [sections, user]);
    
    const formattedRoleList = useMemo(() => {
        if(!roles || !user) return [];
        return roles.filter((role) => role.name !== UserRole.CLIENT).map((role) => ({
            label: role.name,
            value: role.id
        }))
    }, [user, roles]);

    const getUserPermissions = () => {
        UserSectionPermissionService.findAllUserSectionPermissionController(
            undefined,
            1000,
            toFilter({
                user: {
                    id: user?.id,
                },
            }),
            undefined,
            undefined,
            toIncludes<GetUserSectionPermissionResponseDto>({
                section: true,
            })
        ).then((res) => {
            const permissionsObject = res.data.reduce(
                (acc: { [x: string]: { [x: string]: any } }, curr: { section: any; permission: any; enable: boolean }) => {
                    const { section, permission, enable } = curr;
                    if (!acc[section.id]) acc[section.id] = {};
                    acc[section.id][permission] = enable;

                    return acc;
                }, {}
            );
            setUserPermissions((data) => ({...data, permissions: permissionsObject}));
        });
    };
    
    const saveUserPermissions = async () => {
        
        if(!user) return;
        
        if(!userPermissions.permissions) return api.warning({message: t("dashboard.users.permissionsModal.rolesValidationError")});
        
        setIsLoading(true);
        
        const promises: Promise<string | number>[] = [];
        
        if(userPermissions.roles) await UserService.setRolesUserController(
            user.id!,
            {
                roles: userPermissions.roles
            }
        )
        
        Object.keys(userPermissions.permissions).forEach((sectionId) => {
            const permissions = userPermissions.permissions[Number(sectionId)];
            Object.keys(permissions).forEach((permission) => {
                const enabled = permissions[permission as CreateUserSectionPermissionRequestDto.permission];
                
                promises.push(new Promise(() => {
                    UserSectionPermissionService.createUserSectionPermissionController(
                        {
                            user: user!.id!,
                            section: Number(sectionId),
                            enable: enabled,
                            permission: permission as CreateUserSectionPermissionRequestDto.permission,
                        }
                    )
                }))
            })
        })

        Promise.all(promises).then(function(results) {
            api.success({message: t("dashboard.users.permissionsModal.success")});
        }).catch(function(error) {
            api.success({message: t("dashboard.users.permissionsModal.error")});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const setValue = (key: string, value: string | number[]) => {
        setUserPermissions((data) => ({
            ...data,
            [key]: value
        }))
    }

    const handlePermissionChange = (
        sectionId: string | number,
        permissionType: any,
        value: any
    ) => {
        setUserPermissions((userPermissions) => ({
            ...userPermissions,
            permissions: {
                ...userPermissions.permissions,
                [sectionId]: {
                    ...userPermissions.permissions[Number(sectionId)],
                    [permissionType]: value,
                },   
            }
        }));
    };
    
    const sectionLabels = [
        t("dashboard.permissions.read"),
        t("dashboard.permissions.create"),
        t("dashboard.permissions.edit"),
        t("dashboard.permissions.delete"),
    ]
    
    const permissionTypes = [
        CreateUserSectionPermissionRequestDto.permission.READ,
        CreateUserSectionPermissionRequestDto.permission.CREATE,
        CreateUserSectionPermissionRequestDto.permission.UPDATE,
        CreateUserSectionPermissionRequestDto.permission.DELETE,
    ]
    
    const modalContent = (
        <div className={"flex flex-col space-y-3"}>
            <div className={"flex flex-col space-y-3"}>
                <div className="flex flex-col space-y-1 col-span-2">
                    <span className="text-xs font-semibold leading-4">{t("dashboard.users.permissionsModal.rolesLabel")}</span>
                    <Select mode={"multiple"} disabled={isLoading} options={formattedRoleList} value={userPermissions.roles} onChange={(roles) => setValue("roles", roles)}/>
                </div>
            </div>

            <div className={"grid grid-cols-8 gap-3"}>
                <div className={"col-span-4"}/>
                {sectionLabels.map((label, index) => {
                    return (
                        <span key={`label_${index}`} className={"text-[#002655] font-bold text-xs text-center whitespace-nowrap"}>{label}</span>
                    )
                })}

                {sections.map((section, sectionIndex) => {
                    return (
                        <>
                            <span key={`section_${sectionIndex}`} className={"col-span-4 text-start capitalize font-bold"}>{section.name.toLowerCase()}</span>
                            {permissionTypes.map((type, permissionIndex) => {
                                return (
                                    <div key={`section_${sectionIndex}_permission_${permissionIndex}`} className={"flex items-center justify-center"}>
                                        <Checkbox checked={(section?.id && userPermissions.permissions[section?.id] && userPermissions.permissions[section?.id][type]) || false} onChange={(e) => section.id && handlePermissionChange(section.id, type, e.target.checked)}/>
                                    </div>
                                )
                            })}
                        </>
                    )
                })}
            </div>
        </div>
    );
    
    return (
        <Modal
            title={
                <p className="text-[#0950A8] text-base font-semibold leading-6">{t("dashboard.users.permissionsModal.title", {user: `${user?.name} ${user?.surname}`})}</p>}
            open={isOpen}
            onOk={saveUserPermissions}
            onCancel={closeModal}
            footer={null}
        >
            {contextHolder}
            <hr className={"my-4 border-solid border-gray-100"}/>
            {modalContent}
            <hr className={"my-4 border-solid border-gray-100"}/>
            <div className={"flex space-x-3 w-full justify-between items-center"}>
                <Button disabled={isLoading} block type={"default"} className={"m-0 h-[40px] border border-solid border-[var(--Blue-400, #0950A8)] text-[#0950A8] text-sm font-bold"} onClick={closeModal}>
                    {t("common.cancel")}
                </Button>
                <Button disabled={isLoading} block type={"primary"} className={"m-0 h-[40px] bg-[#0950A8] text-sm font-bold"} onClick={saveUserPermissions}>
                    {t("common.saveChanges")}
                </Button>
            </div>
        </Modal>
    )
}

export default UserPermissionsModal

