import {Col, notification, Row} from "antd";
import {useContext, useEffect, useMemo, useState} from "react";

import { Fido } from "../../../Components/icons/Fido";
import { Paypal } from "../../../Components/icons/Paypal";
import { Layout } from "../../../Components/Layout/Layout";
import { Payment } from "../../../utils/types";
import { DeliveryAddress } from "./components/DeliveryAddress";
import { PaymentComponent } from "./components/Payment";
import { PaymentSummary } from "../../../Components/PaymentSummary";
import { AuthContext } from "../../../contexts/authContext";
import { toIncludes } from "../../../utils/request";
import {
  CartRowBaseDto,
  CartRowService, CartService,
} from "../../../api";
import { useTranslation } from "react-i18next";
import { ProductVariantsTable, ProductVariantsTableRow } from "../../../Components/ProductVariantsTable";
import {useCart} from "../../../utils/hooks/useCart";
import {CartData, CartFooter} from "./components/CartFooter";

export const CartSummary = () => {
  const { setCart} = useCart();
  const { wished, currentUser, totalCartRows } = useContext(AuthContext);
  const [cartRows, setCartRows] = useState<ProductVariantsTableRow[]>([]);
  const [pages, setPages] = useState([0, 10]);
  const [paymentType, setPaymentType] = useState("");
  const { t } = useTranslation();
  const [totalValues, setTotalValues] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [addressId, setAddressId] = useState(0);
  const [api, contextHolder] = notification.useNotification();
  const [cartData, setCartData] = useState<CartData>({
    gross: 0,
    taxable: 0,
    shippingCosts: 0,
    discount: 0
  });
  if(currentUser && !addressId && currentUser.defaultAddress?.id) setAddressId(currentUser.defaultAddress.id)
  useEffect(() => {
    fetchCartRow();
  }, [pages, totalCartRows]);

  const payments: Payment[] = [
    {
      title: "Paypal",
      icons: [<Paypal />],
      description: t("cart.payPalDescription"),
    },
    {
      title: "Fido",
      icons: [<Fido />],
      description: t("cart.fidoDescription"),
    },
  ];

  const handleInputChange = async (value: any, key: any, dataIndex: string) => {

    const updatedRows = [...cartRows];
    const index = updatedRows.findIndex((item) => item.key === key);
    if (index !== -1) {
      updatedRows[index] = {
        ...updatedRows[index],
        [dataIndex]: value,
        update: (value !== updatedRows[index].qtyInputValue),
      };
    }

    setCartRows(updatedRows);
  };
  
  const updateCartRows = async () => {
    const reqData = cartRows.map((row) => ({
      qty: row.confezioni,
      variant: row.key
    }))
    
    const result = await setCart(reqData);
    
    const updatedRows = [...cartRows];

    result.forEach((row) => {
      if(row.meta && row.meta.reason) api.warning({message: t(`cart.meta.${row.meta.reason}`, {variantName: row.variant.name})});

      const localCartRowIndex = cartRows.findIndex((cartRow) => cartRow.key === row.variant.id);

      if(localCartRowIndex !== -1){
        updatedRows[localCartRowIndex] = {
          ...cartRows[localCartRowIndex],
          confezioni: row.qty,
          qtyInputValue: row.qty,
          error: !!(row.meta && row.meta.reason),
        }
      }
    })
    
    setCartRows(updatedRows);
    CartService.findOneCartController(0).then(d => {
      setCartData({
        discount: d.data.discount,
        gross: d.data.gross,
        taxable: d.data.taxable,
        shippingCosts: d.data.shippingCosts,
      })
    })
    api.success({message: t("common.messages.cartUpdated")});
  }

  const handleTableChange = (pagination: any, filter: any, sorter: any) => {
    if (sorter.order) {
      fetchCartRow({ variants: {} }, sorter);
    }
    if (pagination.current - 1 !== pages[0]) {
      setPages([pagination.current - 1, pagination.pageSize]);
    }
  };

  const fetchCartRow = (filters?: any, sorter?: any) => {
    let variantsFilters = filters ? filters.variants : undefined;
    setIsLoading(true);
    CartService.findOneCartController(0).then(d => {
      setCartData({
        discount: d.data.discount,
        gross: d.data.gross,
        taxable: d.data.taxable,
        shippingCosts: d.data.shippingCosts,
      })
    })
    CartRowService.findAllCartRowController(
        pages[0],
        pages[1],
        JSON.stringify(variantsFilters),
        undefined,
        undefined,
        toIncludes<CartRowBaseDto>({
          variant: { product: { brand: true }, media: true },
          cart: true,
        })
    ).then((res) => {
      const initialValues = res.data.map((item: any, index: number) => ({
        key: item?.variant?.id,
        prodotto: item?.variant?.name,
        articolo: item?.variant?.code,
        colore: item?.variant?.colors,
        taglia: item?.variant?.size,
        netto: item?.variant?.price,
        stock: item?.variant?.stock,
        confezioni: item?.qty,
        quantita: item?.variant?.qtyForPackage || 1,
        totalePezzi: item?.variant?.default,
        dataRientro: item?.variant?.refuelingDate,
        sconto: item?.variant?.discount,
        percentualeSconto: item?.variant?.discountRate,
        totale: "",
        media: item?.variant.media ? item.variant.media?.path : undefined,
        productId: item.variant.product.id,
        product: item.variant.product,
        likeId: item.likeId
      }));
      setCartRows(initialValues);
      setTotalValues(res.totalCount);
      setIsLoading(false);
    });
  };
  //
  // const totalProducts = useMemo(() => {
  //   if(!cartRows) return 0;
  //   return cartRows.reduce((acc, item) => acc + item.netto * (1 - item.percentualeSconto) * item.confezioni, 0);
  // }, [cartRows])
  //
  // const totalDiscount = useMemo(() => {
  //   if(!cartRows) return 0;
  //   return cartRows.reduce((acc, item) => acc + (item.sconto * item.confezioni), 0);
  // }, [cartRows])

  return (
      <div>
        <Layout>
          <div className="mt-4">
            {cartRows && (
                <ProductVariantsTable
                    handleTableRefresh={() => fetchCartRow()}
                    pages={pages}
                    setPages={(value) => setPages(value)}
                    isLoading={isLoading}
                    totalValues={totalValues}
                    showFido={false}
                    showDeleteButton={false}
                    showEditButton={false}
                    showFavoriteButton
                    title={t("cart.cartSummary")}
                    showFilter={false}
                    showTag={false}
                    manageFilters={(value) => fetchCartRow(value)}
                    handleInputChange={handleInputChange}
                    handleTableChange={(pagination: any, filter: any, sorter: any) =>
                        handleTableChange(pagination, filter, sorter)
                    }
                    actionButtonLabel={(cartRows.length > 0 ? "Aggiorna Carrello" : undefined)}
                    onActionButtonClick={() => updateCartRows()}
                    variants={cartRows}
                    cart={true}
                ></ProductVariantsTable>
            )}
          </div>
          <Row>
            <Col span={8} className="p-4">
              <DeliveryAddress
                  setAddressId={(value) => setAddressId(value)}
                  showBox={false}
                  currentUser={currentUser}
              />
            </Col>
            <Col span={8} className="p-4">
            <span className="text-sm font-semibold leading-5 tracking-[0.07px]  text-blue-400">
              {t("cart.payment")}
            </span>
              <PaymentComponent
                  selectedPaymentType={paymentType}
                  onChange={(value) => setPaymentType(value)}
                  payments={payments}
              />
            </Col>
            <Col span={8} className="p-4">
              {/*<CartFooter cartRows={[]} cartData={{*/}
              {/*  gross: 0,*/}
              {/*  net: 0,*/}
              {/*  shippingCosts: 0,*/}
              {/*  discount: 0*/}
              {/*}}></CartFooter>*/}
              <PaymentSummary
                  addressId={addressId}
                  paymentType={paymentType}
                  title={t("cart.cart")}
                  totalProducts={cartData.taxable}
                  totalDiscount={cartData.discount}
                  shippingCosts={cartData.shippingCosts}
                  vat={cartData.gross - cartData.taxable + (cartData.shippingCosts * 0.22)}
                  credits={currentUser?.credit ?? 0}
              />
            </Col>
          </Row>
        </Layout>
        {contextHolder}
      </div>
  );
};
