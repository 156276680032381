/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleBaseDto } from './RoleBaseDto';
import type { SectionBaseDto } from './SectionBaseDto';
export type CreateRoleSectionPermissionRequestDto = {
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    permission: CreateRoleSectionPermissionRequestDto.permission;
    enable: boolean;
    section: (SectionBaseDto | number);
    role: (RoleBaseDto | number);
};
export namespace CreateRoleSectionPermissionRequestDto {
    export enum permission {
        CREATE = 'CREATE',
        READ = 'READ',
        UPDATE = 'UPDATE',
        DELETE = 'DELETE',
        READ_OWN = 'READ_OWN',
        UPDATE_OWN = 'UPDATE_OWN',
        DELETE_OWN = 'DELETE_OWN',
    }
}

