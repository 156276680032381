/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderRequestDto } from '../models/CreateOrderRequestDto';
import type { OrderBaseDto } from '../models/OrderBaseDto';
import type { ProcessOrderRequestDto } from '../models/ProcessOrderRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrderService {
    /**
     * @param authorization
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static confirmOrderOrderController(
        authorization: string,
        requestBody: Array<ProcessOrderRequestDto>,
    ): CancelablePromise<{
        result?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/external/webhook/confirms',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param authorization
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static rejectOrderOrderController(
        authorization: string,
        requestBody: Array<ProcessOrderRequestDto>,
    ): CancelablePromise<{
        result?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/external/webhook/reject',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uuid
     * @returns OrderBaseDto
     * @throws ApiError
     */
    public static checkoutOrderController(
        uuid: string,
    ): CancelablePromise<OrderBaseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/{uuid}/checkout',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns void
     * @throws ApiError
     */
    public static deleteOrderOrderController(
        uuid: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/{uuid}/delete',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static shippedOrderController(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/shipped/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param reason
     * @returns any
     * @throws ApiError
     */
    public static rejectOrderController(
        uuid: string,
        reason: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/reject/{uuid}',
            path: {
                'uuid': uuid,
            },
            query: {
                'reason': reason,
            },
        });
    }
    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static confirmOrderController(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/confirm/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findAllOrderController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static createOrderController(
        requestBody: CreateOrderRequestDto,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneOrderController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static countOrderController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/count',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
