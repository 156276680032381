import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DeliveryAddress } from "../Cart/components/DeliveryAddress";
import { AuthContext } from "../../../contexts/authContext";

export const Addresses = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="p-7 md:pl-14 md:pr-14 sm:pl-4 sm:pr-4">
      <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px] mb-4">
        {t("menu.addresses")}
      </div>
      
      <DeliveryAddress showBox={true} showDeleteEditButton={true} showTitle={false} currentUser={currentUser}></DeliveryAddress>
    </div>
  );
};
