import {CartRowService, CartService, SetCartRowDto, SetCartRowResponseDto, SetCartRowResponseMetaDto} from "../../api";

export const useCart = () => {

    const removeItemFromCart = async (id: number) => {
        return await CartRowService.deleteCartRowController(id).then((res: any) => {
            if (res) {
                return res
            }
        });
    };

    const addItemToCart = async (
        qty: number,
        variantId: number
    ) => {
        try {
            const response = await CartRowService.createCartRowController({
                qty: qty,
                variant: variantId,
            });
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const setCart = async (rows: SetCartRowDto[]): Promise<SetCartRowResponseDto[]> => {
        const response = await CartService.setCartController({
            rows
        });

        return response.rows;
    }

    return {
        removeItemFromCart,
        addItemToCart,
        setCart
    };
};
