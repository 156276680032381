// import { useEffect, useMemo, useState } from 'react'

// export type Breakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
// export const toMBreakPoints: Breakpoint[] = ['xxs', 'xs', 'sm']

// function getBreakPoint(windowWidth: number): Breakpoint {
//   if (windowWidth > 1536) {
//     return '2xl'
//   } else if (windowWidth > 1280) {
//     return 'xl'
//   } else if (windowWidth > 1024) {
//     return 'lg'
//   } else if (windowWidth > 768) {
//     return 'md'
//   } else if (windowWidth > 640) {
//     return 'sm'
//   } else if (windowWidth > 375) {
//     return 'xs'
//   } else {
//     return 'xxs'
//   }
// }

// export function useBreakpoint() {
//   const isWindowClient = typeof window === 'object'

//   // state
//   const [windowSize, setWindowSize] = useState<Breakpoint>(
//     getBreakPoint(window.innerWidth)
//   )
//   const [isMobile, setIsMobile] = useState<boolean>(false)

//   const setSize = () => {
//     const breakpoint = getBreakPoint(window.innerWidth)
//     setWindowSize(breakpoint)
//     setIsMobile(toMBreakPoints.includes(breakpoint))
//   }

//   // call 'setSize' on mount
//   useMemo(() => setSize(), [])

//   // register hooks to call 'setSize'
//   useEffect(() => {
//     if (isWindowClient) {
//       window.addEventListener('resize', setSize)
//       window.addEventListener('load', setSize)
//       return () => window.removeEventListener('resize', setSize)
//     }
//   }, [isWindowClient, setWindowSize])

//   return { size: windowSize, isMobile }
// }

import { useState, useLayoutEffect } from "react";

export type Breakpoint = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
export const toMBreakPoints: Breakpoint[] = ["xxs", "xs", "sm"];

function getBreakPoint(windowWidth: number): Breakpoint {
  if (windowWidth > 1536) {
    return "2xl";
  } else if (windowWidth > 1280) {
    return "xl";
  } else if (windowWidth > 1024) {
    return "lg";
  } else if (windowWidth > 768) {
    return "md";
  } else if (windowWidth > 640) {
    return "sm";
  } else if (windowWidth > 375) {
    return "xs";
  } else {
    return "xxs";
  }
}

export function useBreakpoint() {
  const isWindowClient = typeof window === "object";

  // state
  const [windowSize, setWindowSize] = useState<Breakpoint>(
    getBreakPoint(isWindowClient ? window.innerWidth : 0)
  );
  const [isMobile, setIsMobile] = useState<boolean>(
    toMBreakPoints.includes(getBreakPoint(isWindowClient ? window.innerWidth : 0))
  );

  useLayoutEffect(() => {
    const updateSize = () => {
      const newSize = getBreakPoint(window.innerWidth);
      setWindowSize(newSize);
      setIsMobile(toMBreakPoints.includes(newSize));
    };

    if (isWindowClient) {
      updateSize();
      window.addEventListener("resize", updateSize);
      window.addEventListener("popstate", updateSize);
      return () => {
        window.removeEventListener("resize", updateSize);
        window.removeEventListener("popstate", updateSize);
      };
    }
  }, [isWindowClient]);

  return { size: windowSize, isMobile };
}
