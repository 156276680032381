import { Collapse, Input } from "antd";

interface Props {
    labelStyle: string;
    label: string;
    onChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const InputFilter = ({
    labelStyle,
    label,
    onChange,
  }: Props) => {
  return (
    <Collapse
      size="small"
      expandIconPosition="end"
      items={[
        {
          key: "1",
          label: <p className={labelStyle}>{label}</p>,
          children: (
            <Input
              className="w-full"
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e)}
            ></Input>
          ),
        },
      ]}
    />
  );
};
