import {Modal} from "antd";
import { useTranslation } from "react-i18next";
import {TicketBaseDto, TicketService} from "../../api";
import {Ticket} from "../icons/Ticket";

interface Props {
  ticket: TicketBaseDto | null,
  isOpen: boolean,
  onClose: () => void,
  onSuccess: () => void,
  onFailure: (message: string) => void,
}

export const CloseTicketModal = ({
 ticket,
 isOpen,
 onClose,
 onSuccess,
 onFailure
}: Props) => {

  const { t } = useTranslation();

  const closeTicket = async () => {

    if(!ticket || !ticket.id) return;

    const res = await TicketService.updateTicketController(ticket.id, {
        status: TicketBaseDto.status.SOLVED
    });
    
    if(!res.data) return onFailure(t("closeTicketModal.error"));

    onSuccess();
  }

  if(!ticket) return null;

  return (
      <Modal
          okText={t("common.confirm")}
          onOk={closeTicket}
          okButtonProps={{
            block: false,
            size: "large",
            style: {
              backgroundColor: "#0950A8",
              fontSize: 12,
              fontWeight: 600,
              lineHeight: "18px",
              letterSpacing: "0.06px",
              padding: 10,
            },
          }}
          cancelText={t("common.cancel")}
          onCancel={onClose}
          cancelButtonProps={{
              block: false,
              size: "large",
              style: {
                  backgroundColor: "#eeeeee",
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "18px",
                  letterSpacing: "0.06px",
                  padding: 10,
              },
          }}
          width={"600px"}
          title={
            <div className="text-blue-400 text-[16px] font-semibold leading-6 tracking-[0.06px]">
              {t("closeTicketModal.title")}
            </div>
          }
          open={isOpen}
      >
        <div className="mt-4">
          <p className={"text-neutral-300 text-sm leading-4 tracking-[0.05px]"}>{t("closeTicketModal.message")}</p>
        </div>
      </Modal>
  );
};
