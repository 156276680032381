import {notification} from "antd";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../contexts/authContext";
import {CartRowBaseDto, CartRowService, UserBaseDto} from "../../../api";
import {useCart} from "../../../utils/hooks/useCart";
import {useBreakpoint} from "../../../utils/hooks/useBreakpoint";
import {ProductVariantsTable, ProductVariantsTableRow} from "../../../Components/ProductVariantsTable";
import {toIncludes} from "../../../utils/request";

export const Favorites = () => {
  const {t} = useTranslation();
  const {retrieveWished, wished, currentUser, totalWish} = useContext(
    AuthContext
  );
  const {addItemToCart} = useCart();
  const [api, contextHolder] = notification.useNotification();
  const {isMobile} = useBreakpoint();
  const [dataTable, setDataTable] = useState<ProductVariantsTableRow[]>([]);

  const [pages, setPages] = useState([0, 10]);
  const [isLoading, setIsLoading] = useState(false);

  const calValue = (data: any[], variantId: number) =>
    data?.filter((el: any) => el?.variant?.id === variantId)[0]?.qty ?? 0;

  const findCartRow = () => {
    CartRowService.findAllCartRowController(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      toIncludes<CartRowBaseDto>({variant: true})
    ).then((cart) => {
      const values = wished.map((res: any) => calValue(cart.data, res.id));
      const initialValues = wished.map((res: any, index: number) => ({
        key: res?.id,
        prodotto: res?.name,
        articolo: res?.code,
        colore: res?.colors,
        taglia: res?.size,
        netto: res?.price,
        stock: res?.stock,
        confezioni: values[index],
        quantita: res?.qtyForPackage,
        totalePezzi: res?.default,
        dataRientro: res?.refuelingDate,
        totale: "",
        sconto: res.discount,
        percentualeSconto: res.discountRate,
        likeId: res.likeId ? res.likeId : undefined,
        media: res.variant?.media ? res.variant.media.path : undefined,
        update: false,
        qtyInputValue: values[index],
        disabled: false,
        productId: 0,
        variant: res.variant,
        inCart: false,
        product: res
      }));
      setDataTable(initialValues);
    });
  }

  useEffect(() => {
    if (currentUser?.id) retrieveWished(currentUser.id);
    findCartRow()
  }, []);

  useEffect(() => {
    findCartRow()
  }, [wished])

  const handleAddItemsToCart = (
    data: any[] | undefined,
    user: UserBaseDto | undefined
  ) => {
    if (user) {
      data?.map(async (res: any) => {
        if (res.confezioni > 0 && res.update) {
          const response = await addItemToCart(res.quantita, res.key);
          if (response?.message?.includes("Internal Server Error")) {
            api.error({
              message: t("common.notAddedToCart"),
              style: {
                borderRadius: "8px",
                border: "1px solid var(--200, #bf3232)",
                background: "#fff0f0",
              },
            });
          } else {
            api.success({
              message: t("common.prodAddedToCart"),
              style: {
                borderRadius: "8px",
                border: "1px solid var(--200, #92D2B4)",
                background: "#F4FCF8",
                color: "#3DA172",
              },
            });
          }
        }
      });
    }
  };

  const handleTableChange = (pagination: any, filter: any, sorter: any) => {
    if (sorter.order) {
      retrieveWished(undefined, sorter);
    }
    if (pagination.current - 1 !== pages[0]) {
      setPages([pagination.current - 1, pagination.pageSize]);
      retrieveWished(undefined, sorter);
    }
  };

  const handleInputChange = (value: any, key: any, dataIndex: string) => {
    setDataTable((prevData: ProductVariantsTableRow[]) => {
      const newData = [...prevData];
      const index = newData.findIndex((item) => item.key === key);
      if (index !== -1) {
        newData[index] = {
          ...newData[index],
          [dataIndex]: value,
          update: value != newData[index].qtyInputValue,
        };
      }

      return newData;
    });
  };

  return (
    <>
      {wished && (
        <div className={`${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
          <div className="flex justify-between">
            <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
              {t("menu.favorites")}
            </div>
            {/* <div>
              <Input
                placeholder={t("common.search")}
                prefix={<SearchOutlined />}
              ></Input>
            </div> */}
          </div>

          <ProductVariantsTable
            handleTableRefresh={() => retrieveWished(currentUser?.id)}
            isLoading={isLoading}
            isFavoriteTable
            totalValues={totalWish}
            showFido={false}
            showDeleteButton={false}
            showEditButton={false}
            showFavoriteButton={true}
            title={""}
            showFilter={false}
            showTag={false}
            variants={dataTable}
            pages={pages}
            setPages={(value) => setPages(value)}
            manageFilters={(value) => retrieveWished(value)}
            handleInputChange={handleInputChange}
            handleTableChange={(pagination: any, filter: any, sorter: any) =>
              handleTableChange(pagination, filter, sorter)
            }
            actionButtonLabel={t("common.addToCart")}
            onActionButtonClick={(data: any) => {
              handleAddItemsToCart(data, currentUser);
            }}
          ></ProductVariantsTable>

          {contextHolder}
        </div>
      )}
    </>
  );
};
