import { Input, Space } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxFilter } from "../../../../Components/CheckboxFilter";
import { InputFilter } from "../../../../Components/InputFilter";
import { TableButtonFilters } from "../../../../Components/TableButtonFilters";
import { labelStyle } from "../../../guest/Products/Filters";
import {RangePickerProps} from "antd/es/date-picker";

interface Props {
  reset: () => void;
  countFilter: number;
  filters: {
    label: string;
    type: string;
    onChangeCheckbox?: (value: CheckboxValueType[]) => void;
    onChangeRangeDate?: (value: RangePickerProps['value']) => void
    onChangeRangeNumber?: (type: 'min' | 'max', value: any) => void
    onChangeInput?: (
      value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    items?: any;
    itemChecked?: CheckboxValueType[];
  }[];
}

export const TableFilters = ({ reset, countFilter, filters }: Props) => {
  const { t } = useTranslation();

  const filtersRender = () => {
    return (
      <div className={`w-full`}>
        <Space direction="vertical" className={`w-full`}>
          <div className="flex justify-between">
            <div>
              <p className="font-xs font-medium">
                {t("filters.appliedFilters")}: {countFilter}
              </p>
            </div>
            <div>
              <p
                className="cursor-pointer underline text-neutral-300 font-xs"
                onClick={() => {
                  reset();
                  //   setSizeChecked([]);
                  //   setColorChecked([]);
                  //   setBrandChecked([]);
                  //   setCountFilter(0);
                  //   setPrice([0, 0]);
                  //   setSelectedFilters([]);
                }}
              >
                X {t("filters.reset")}
              </p>
            </div>
          </div>
          {filters.map((item) => {
            if (item.type === "input") {
              return (
                <InputFilter
                  label={item.label}
                  onChange={(value) => {
                    item.onChangeInput && item.onChangeInput(value)
                  }}
                  labelStyle={labelStyle}
                ></InputFilter>
              );
            } else if (item.type === "checkbox") {
              // console.log('---',item.items)
              return (
                <CheckboxFilter
                  label={item.label}
                  itemChecked={item.itemChecked ?? []}
                  items={item.items}
                  labelStyle={labelStyle}
                  onChange={(value) =>
                    item.onChangeCheckbox && item.onChangeCheckbox(value)
                  }
                ></CheckboxFilter>
              );
            }
          })}
          {/* <CheckboxFilter
                itemChecked={sizeChecked}
                items={getValues("size")}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setSizeFilterCount(checkedValues.length);
                  setSizeChecked(checkedValues);
                  setSelectedFilters([]);
                  checkboxOnChange(checkedValues, "size");
                }}
                labelStyle={labelStyle}
                label={t("common.size")}
              /> */}
          {/* <RangeInputFilter
                label={t("common.price")}
                labelStyle={labelStyle}
                firstInput={{
                  value: priceState[0],
                  onChange: (value: number) => {
                    if (value === null) return;
    
                    const all: any = selectedFilters?.filter((el) => {
                      return !el.includes("min");
                    });
    
                    all?.push(`min:${value}`);
                    setSelectedFilters && setSelectedFilters(all);
    
                    setPrice([Number(value), priceState[1]]);
                    setPriceFilterCount(1);
    
                    findProductsDebounce(
                      "price",
                      priceState[1],
                      Number(value),
                      filtersValue
                    );
                  },
                  min: 0,
                  placeholder: t("filters.min"),
                  prefix: "€",
                }}
                secondInput={{
                  value: priceState[1],
                  onChange: (value: number) => {
                    if (value === null) return;
    
                    const all: any = selectedFilters?.filter((el) => {
                      return !el.includes("max");
                    });
    
                    all?.push(`max:${value}`);
                    setSelectedFilters && setSelectedFilters(all);
    
                    setPrice([priceState[0], Number(value)]);
                    setPriceFilterCount(1);
    
                    setTimeout(() => {
                      findProductsDebounce(
                        "price",
                        Number(value),
                        priceState[0],
                        filtersValue
                      );
                    }, 500);
                  },
                  min: 0,
                  placeholder: t("filters.max"),
                  prefix: "€",
                }}
              /> */}
          {/* <CheckboxFilter
                itemChecked={colorChecked}
                items={getValues("color")}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setColorFilterCount(checkedValues.length);
                  setColorChecked(checkedValues);
                  setSelectedFilters([]);
                  checkboxOnChange(checkedValues, "color");
                }}
                labelStyle={labelStyle}
                label={t("common.color")}
              /> */}
          {/* <CheckboxFilter
                itemChecked={brandChecked}
                items={getValues("brand")}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setBrandFilterCount(checkedValues.length);
                  setBrandChecked(checkedValues);
                  setSelectedFilters([]);
                  checkboxOnChange(checkedValues, "brand");
                }}
                labelStyle={labelStyle}
                label={t("common.brand")}
              /> */}
        </Space>
      </div>
    );
  };

  return (
    <div>
      <TableButtonFilters
        filtersRender={filtersRender}
        selectedFilters={[]}
      ></TableButtonFilters>
    </div>
  );
};
