import { FC } from "react";
import { IconPropsType } from "./types";

export const Linkedin: FC<IconPropsType> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.0033 6.99992H4.97514C4.66852 7.01841 4.36137 6.97349 4.07289 6.86796C3.78441 6.76242 3.52079 6.59855 3.29848 6.38657C3.07617 6.17458 2.89995 5.91904 2.78083 5.6359C2.66171 5.35276 2.60224 5.04811 2.60614 4.74095C2.61004 4.4338 2.67722 4.13075 2.80349 3.85072C2.92976 3.5707 3.11241 3.31972 3.34003 3.11344C3.56765 2.90717 3.83535 2.75004 4.12641 2.65187C4.41748 2.5537 4.72566 2.51658 5.03172 2.54286C5.33928 2.52058 5.64814 2.56218 5.93885 2.66504C6.22956 2.7679 6.49585 2.92979 6.72097 3.14054C6.9461 3.35129 7.12518 3.60634 7.24696 3.88964C7.36875 4.17295 7.43061 4.47839 7.42864 4.78676C7.42667 5.09513 7.36093 5.39975 7.23554 5.68148C7.11015 5.96322 6.92783 6.21596 6.70004 6.42382C6.47225 6.63168 6.20392 6.79017 5.91191 6.88931C5.61991 6.98845 5.31056 7.02611 5.0033 6.99992Z"
        fill="#02296C"
      />
      <path d="M7.01758 10H3.01758V22H7.01758V10Z" fill="#02296C" />
      <path
        d="M17.5171 10C16.843 10.0018 16.1781 10.156 15.5721 10.451C14.966 10.746 14.4344 11.1741 14.0171 11.7034V10H10.0171V22H14.0171V16.5C14.0171 15.9696 14.2278 15.4609 14.6029 15.0858C14.9779 14.7107 15.4867 14.5 16.0171 14.5C16.5475 14.5 17.0562 14.7107 17.4313 15.0858C17.8064 15.4609 18.0171 15.9696 18.0171 16.5V22H22.0171V14.5C22.0171 13.9091 21.9007 13.3239 21.6745 12.7779C21.4484 12.232 21.1169 11.7359 20.6991 11.318C20.2812 10.9002 19.7851 10.5687 19.2392 10.3425C18.6932 10.1164 18.108 10 17.5171 10Z"
        fill="#02296C"
      />
    </svg>
  );
};
