/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TicketReplyBaseDto } from './TicketReplyBaseDto';
import type { TicketTypeBaseDto } from './TicketTypeBaseDto';
import type { UserBaseDto } from './UserBaseDto';
export type TicketBaseDto = {
    id?: number;
    uuid?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
    deletedBy?: string | null;
    title: string;
    status: TicketBaseDto.status;
    lastReply?: TicketReplyBaseDto | null;
    replies: Array<TicketReplyBaseDto>;
    inCharge?: UserBaseDto | null;
    user: UserBaseDto;
    type: TicketTypeBaseDto;
};
export namespace TicketBaseDto {
    export enum status {
        OPEN = 'OPEN',
        IN_CHARGE = 'IN_CHARGE',
        SOLVED = 'SOLVED',
        CLOSED = 'CLOSED',
        ARCHIVED = 'ARCHIVED',
    }
}

