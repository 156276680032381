import { Col, Row } from "antd";
import { t } from "i18next";
import { MenuProps } from "rc-menu";
import { ReactNode, useContext, useEffect, useState } from "react";
import { UserSectionPermissionBaseDto } from "../api";
import { AuthContext } from "../contexts/authContext";
import { fillIcon } from "../utils/fillIcon";
import { useUserPermissions } from "../utils/hooks/useUserPermissions";
import { Sections } from "../utils/types";
import { Call } from "./icons/Call";
import { Cart } from "./icons/Cart";
import { Graph } from "./icons/Graph";
import { Grid } from "./icons/Grid";
import { Help } from "./icons/Help";
import { PencilEditor } from "./icons/PencilEditor";
import { Setting } from "./icons/Setting";
import { Ticket } from "./icons/Ticket";
import { UserApproved } from "./icons/UserApproved";
import { Layout } from "./Layout/Layout";
import { SideBarAdminMenu } from "./SideBarAdminMenu";

interface Props {
  children: ReactNode;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  visible?: boolean,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    visible,
  } as MenuItem;
}

export const adminMenu = (selectedItem: string, canUser: Function) => [
  {
    label: t("menu.dashboard"),
    icon: (
      <Graph
        fill={fillIcon("Dashboard", selectedItem)}
        style={{ width: 24, height: 24 }}
      />
    ),
    id: "dashboard",
    url: "/admin/dashboard",
    children: [],
    notifications: 0,
    visible: false,
  },
  {
    label: t("common.products"),
    icon: <Grid fill={fillIcon("Prodotti", selectedItem)} />,
    id: "prodotti",
    url: "/admin/products",
    children: [],
    notifications: 0,
    visible: canUser(
      UserSectionPermissionBaseDto.permission.READ,
      Sections.products
    ),
  },

  {
    label: t("clients.customers"),
    icon: <UserApproved fill={fillIcon("Clienti", selectedItem)} />,
    id: "clienti",
    url: "/admin/clients",
    children: [],
    notifications: 0,
    visible: canUser(
      UserSectionPermissionBaseDto.permission.READ,
      Sections.users
    ),
  },
  {
    label: t("menu.orders"),
    icon: <Cart fill={fillIcon("Ordini", selectedItem)} />,
    id: "ordini",
    url: "/admin/orders",
    children: [],
    notifications: 5,
    visible: canUser(
      UserSectionPermissionBaseDto.permission.READ,
      Sections.orders
    ),
  },
  // {
  //   label: t("menu.transactions"),
  //   icon: <Call fill={fillIcon("Transazioni", selectedItem)} />,
  //   id: "transazioni",
  //   url: "/admin/transitions",
  //   children: [],
  //   notifications: 21,
  //   visible: canUser(
  //     UserSectionPermissionBaseDto.permission.READ,
  //     Sections.transactions
  //   ),
  // },
  {
    label: t("menu.settings"),
    icon: <Setting fill="#5D5D5D" />,
    id: "impostazioni",
    url: "/admin/settings",
    notifications: 0,
    children: [
      {
        label: t("menu.users"),
        url: "/admin/settings/users",
        visible: canUser(
          UserSectionPermissionBaseDto.permission.UPDATE,
          Sections.userSectionPermissions
        ),
      },
      {
        label: t("common.languages"),
        url: "/admin/settings/languages",
        visible: canUser(
          UserSectionPermissionBaseDto.permission.UPDATE,
          Sections.translations
        ),
      },
      {
        label: t("home.categories"),
        url: "/admin/settings/categories",
        visible: canUser(
          UserSectionPermissionBaseDto.permission.UPDATE,
          Sections.categories
        ),
      },
      {
        label: t("dashboard.editor.editorHomePage"),
        icon: (
          <PencilEditor fill={fillIcon("Editor Home Page", selectedItem)} />
        ),
        id: "editor",
        url: "/admin/editor",
        children: [],
        notifications: 0,
        visible: canUser(
          UserSectionPermissionBaseDto.permission.UPDATE,
          Sections.products
        ),
      },
    ],
  },
  {
    label: t("menu.tickets"),
    icon: <Ticket fill={fillIcon("Tickets", selectedItem)} />,
    id: "tickets",
    url: "/admin/tickets",
    children: [],
    notifications: 0,
    visible: true,
    //TODO: aggiungere permesso
    // visible: canUser(
    //   UserSectionPermissionBaseDto.permission.READ,
    //   Sections.orders
    // ),
  },
  // {
  //   label: t("menu.support"),
  //   icon: <Help fill={fillIcon("Supporto", selectedItem)} />,
  //   id: "supporto",
  //   url: "/admin/support",
  //   children: [],
  //   notifications: 0,
  // },
];


export const items = (selectedItem: string, canUser: Function) => {
  const setting = adminMenu(selectedItem, canUser).find(d => d.id === 'impostazioni')!;
  return [
    getItem(
      setting.label,
      "setting",
      setting.visible,
      setting.icon,
      setting?.children.map((res) => {
        return getItem(res.label, res.label, res.visible);
      })
    ),
  ];
}

export const AdminLayout = ({ children }: Props) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout
      noPaddingPage={windowSize.width < 1024}
      userMenu={true}
      children={
        <AdminLayoutComponent windowSize={windowSize} children={children} />
      }
    ></Layout>
  );
};

interface PropsAdminLayoutComponent {
  children: ReactNode;
  windowSize: {
    width: number;
    height: number;
  };
}

export const AdminLayoutComponent = ({
  windowSize,
  children,
}: PropsAdminLayoutComponent) => {
  const { permissions, currentUser } = useContext(AuthContext);
  const { canUser } = useUserPermissions(permissions, currentUser);


  const [selectedItem, setSelectedItem] = useState<string>("Prodotti");

  const rootSubmenuKeys = ["setting"];
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const colSpan = () => {
    if (windowSize.width > 1024) {
      return 4;
    } else if (windowSize.width < 1024) {
      return 0;
    } else {
      return 3;
    }
  };


  return (
    <Row>
      <Col
        span={colSpan()}
        className="min-h-screen max-h-max relative"
        style={{
          borderRight: "1px solid var(--neutral-200, #DBDBDB)",
        }}
      >
        <SideBarAdminMenu
          adminMenu={adminMenu(selectedItem, canUser)}
          items={items(selectedItem, canUser)}
          onOpenChange={(value) => onOpenChange(value)}
          openKeys={openKeys}
          selectedItem={selectedItem}
          selectedKeys={selectedKeys}
          setSelectedItem={(value) => setSelectedItem(value)}
          setSelectedKeys={(value) => setSelectedKeys(value)}
        ></SideBarAdminMenu>
      </Col>
      <Col
        span={windowSize.width < 1024 ? 24 : 20}
        className={"p-5  bg-neutral-100"}
      >
        {children}
      </Col>
    </Row>
  );
};
