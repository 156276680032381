import {Button, ColorPicker, Image, Input, Modal, notification, Switch} from "antd";
import {ProductBaseDto, VariantBaseDto} from "../../api";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {CameraFilled, DeleteFilled, CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {numberWithCommasAndFixedDecimals} from "../../utils/numberWithCommasAndFixedDecimals";
import {useFilePicker} from "use-file-picker";
import {FileAmountLimitValidator, FileSizeValidator} from "use-file-picker/validators";

export interface VariantData {
    size: string;     
    colors: string[];
    image: File | string | undefined;
    isVisible: boolean;
}

interface ProductVariantModalProps {
    product?: ProductBaseDto;
    variant?: VariantBaseDto;
    isOpen: boolean,
    onConfirm: (data: VariantData) => void,
    onCancel: () => void,
}

const ProductVariantModal = ({product, variant, isOpen, onConfirm, onCancel}: ProductVariantModalProps) => {
    
    const [variantData, setVariantData] = useState<VariantData>({
        size: "",
        colors: [],
        image: undefined,
        isVisible: false,
    })

    const { openFilePicker: selectImage, plainFiles: imageFile, filesContent: imageContent, clear: clearImage } = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false,
        validators: [
            new FileAmountLimitValidator({ min: 1, max: 1 }),
            new FileSizeValidator({maxFileSize: 5 * 1024 * 1024}), //5MB
        ],
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            if(errors) return api.warning({message: t(`common.imagePickerError.${errors[0].reason}`)});
            setVariantData((data) => ({
                ...data,
                image: plainFiles[0]
            }))
        },
    });
    
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation();

    useEffect(() => {
        clearImage();
        if(variant){
            setVariantData({
                colors: variant.colors,
                image: variant.media?.path,
                size: variant.size,
                isVisible: variant.isVisible
            })
        }
    }, [variant]);
    
    const image = useMemo(() => {
        console.log(variantData.image);
        if(imageContent && imageContent.length > 0) return imageContent[0].content;
        if(typeof variantData.image === "string") return variantData.image;
        return null;
    }, [imageContent, variantData])
    
    const setValue = (key: string, value: string | string[] | boolean) => {
        setVariantData((data) => ({
            ...data,
            [key]: value
        }))
    }
    
    const removeColor = (value: string) => {
        setVariantData((data) => ({
            ...data,
            colors: data.colors.filter((color) => color !== value)
        }))
    }
    
    const addColor = () => {
        setVariantData((data) => ({
            ...data,
            colors: [
                ...data.colors,
                "#FFFFFF"
            ]
        }))
    }
    
    const editColor = (index: number, value: string) => {
        if(variantData.colors && variantData.colors[index]){
            const editedColors = [...variantData.colors];
            editedColors[index] = value;
            
            setVariantData((data) => ({
                ...data,
                colors: editedColors
            }))
        }
    }
    
    const modalContent = (
        <div className={"grid grid-cols-2 gap-y-5 gap-x-3"}>
            <div className={"flex flex-col space-y-2 col-span-2"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.articleImage")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {image ? (
                        <div className={"relative w-full h-full"}>
                            <Image width={"100%"} height={300} src={image} className={"border rounded-md"} preview={false}/>
                            {imageFile && imageFile.length > 0 && (
                                <div className={"absolute top-0 right-0 p-3 bg-white/90 rounded-tr-md rounded-bl-md group cursor-pointer shadow-md"} onClick={clearImage}>
                                    <DeleteFilled className={"text-lg group-hover:scale-110 transition"}/>
                                </div>
                            )}
                            <div className={"absolute top-0 left-0 p-3 bg-white/90 rounded-tl-md rounded-br-md group cursor-pointer shadow-md"} onClick={selectImage}>
                                <CameraFilled className={"text-lg group-hover:scale-110 transition"}/>
                            </div>
                        </div>
                    ) : (
                        <div className={"flex items-center justify-center w-full h-[300px] border border-solid border-neutral-200 rounded-md"}>
                            <CameraFilled onClick={selectImage} className={"text-4xl hover:text-blue-400 hover:scale-110 transition cursor-pointer"}/>
                        </div>
                    )}
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.articleCode")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {variant?.code.toUpperCase()}
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.brand")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {product?.brand?.name}
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px] flex w-full justify-between items-center">
                    {t("common.colors")}
                    {variantData.colors.length < 2 && <Button type={"primary"} size={"small"} icon={<PlusCircleOutlined/>} onClick={addColor}>{t("common.add")}</Button>}
                </div>
                <div>
                    {variantData.colors.length > 0 ? (
                        <div className={"grid grid-cols-2 md:grid-cols-3 gap-3"}>
                            {variantData.colors.map((color, index) => {
                                return (
                                    <div key={index} className={"group space-x-2 flex items-center rounded-md p-1 border border-solid border-gray-300"}>
                                        <ColorPicker style={{border: 0}} value={color} size="middle" showText onChange={(hex) => editColor(index, hex.toHexString())}/>
                                        <div className={"opacity-100 md:opacity-0 md:group-hover:opacity-100 transition"}>
                                            <CloseCircleOutlined className={"text-lg text-red-400"} onClick={removeColor.bind(this, color)}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <p>Inserisci uno o più colori</p>
                    )}
                </div>
            </div>

            <div className={"flex flex-col space-y-2"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.size")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    <Input onChange={(e) => setValue("size", e.target.value)} value={variantData.size} size="middle"/>
                </div>
            </div>

            <div className={"flex flex-col space-y-1"}>
                <div className="text-[#0950A8] text-xs font-semibold leading-6">
                    {t("product.isVariantVisible")}
                </div>
                <div className="text-[#888] text-xs font-semibold leading-6 capitalize">
                    <Switch checked={variantData.isVisible} onChange={(value) => setValue("isVisible", value)}/>
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2 md:col-span-1"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.€net")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {numberWithCommasAndFixedDecimals(variant?.price) >= 0 ? numberWithCommasAndFixedDecimals(variant?.price) : "**"}
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2 md:col-span-1"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.stock")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {variant?.stock}
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2 md:col-span-1"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.qntPack")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {variant?.qtyForPackage}
                </div>
            </div>

            <div className={"flex flex-col space-y-2 col-span-2 md:col-span-1"}>
                <div className="text-blue-400 text-md font-semibold leading-[18px]">
                    {t("common.returnDate")}
                </div>
                <div className="text-[#2A2E34] text-sm font-normal leading-5 mt-1">
                    {variant?.refuelingDate ?? "N/A"}
                </div>
            </div>
        </div>
    );

    return (
        <Modal
            title={<p className="text-[#0950A8] text-base font-semibold leading-6">{variant?.name}</p>}
            open={isOpen}
            onOk={onConfirm.bind(this, variantData)}
            onCancel={onCancel}
            footer={() => {
                return (
                    <div className={"flex space-x-3 w-full justify-between items-center"}>
                        <Button block type={"default"} className={"m-0 h-[40px] border border-solid border-[var(--Blue-400, #0950A8)] text-[#0950A8] text-sm font-bold"} onClick={onCancel}>
                            {t("common.cancel")}
                        </Button>
                        <Button block type={"primary"} className={"m-0 h-[40px] bg-[#0950A8] text-sm font-bold"} onClick={onConfirm.bind(this, variantData)}>
                            {t("common.saveChanges")}
                        </Button>
                    </div>
                )
            }}
        >
            {contextHolder}
            <hr className={"my-4 border-solid border-gray-100"}/>
            {modalContent}
            <hr className={"my-4 border-solid border-gray-100"}/>
        </Modal>
    )
}

export default ProductVariantModal