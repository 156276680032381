import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import {
  GetProductResponseDto,
  ProductBaseDto,
  ProductService,
  SliderResponseGroupedResponseDto,
} from "../api";
import { LeftIcon } from "./icons/LeftArrow";
import { RightIcon } from "./icons/RightArrow";
import MultiCarousel from "react-multi-carousel";
import { ProductCard } from "./ProductCard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translationValue } from "../utils/translationValue";
import queryString from "query-string";
import { useBreakpoint } from "../utils/hooks/useBreakpoint";

interface Props {
  sliderProducts?: SliderResponseGroupedResponseDto | undefined;
  listProductsSlider?: ProductBaseDto[];
  relatedProducts?: GetProductResponseDto[]
  categoryId?: number
}

export const RelatedProducts = ({
  sliderProducts,
  listProductsSlider,
  relatedProducts,
  categoryId
}: Props) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const {isMobile} = useBreakpoint()

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group absolute top-[40%] -left-[5%] w-full">
        {currentSlide !== 0 && (
          <Button
            type="link"
            className={currentSlide === 0 ? "disable" : "mt-[10px]"}
            onClick={() => previous()}
            icon={<LeftIcon />}
          />
        )}
        <Button
          type="link"
          className="absolute -right-[10%] -top-[0%]"
          onClick={() => next()}
          icon={<RightIcon />}
        ></Button>
      </div>
    );
  };

  const handleClick = () => {
    const value = translationValue(sliderProducts?.titles);
    if (value) {
      const queryParams = queryString.stringify({ source: value });
      history(`/products?${queryParams}`);
    }
    if(relatedProducts && relatedProducts?.length > 0) {
      const categoryParam = queryString.stringify({
        category: categoryId
      });
      history(`/products?${categoryParam}`);
    }
  };

  return (
    <div>
      <Row justify="center" align="top">
        <Col span={21}>
          <div className="flex justify-between">
            <div className="text-lg font-semibold leading-7 pb-2 text-blue-500">
              {translationValue(sliderProducts?.titles)
                ? translationValue(sliderProducts?.titles)
                : sliderProducts?.title}
            </div>
            <div
              className="text-xs font-semibold leading-5 pb-2 text-neutral-200 underline cursor-pointer"
              onClick={handleClick}
            >
              {t("common.showAll")}
            </div>
          </div>
          {/* {sliderProducts && ( */}
            <MultiCarousel
              partialVisible={true}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={!isMobile ? <ButtonGroup /> : undefined}
              responsive={responsive}
              swipeable={true}
              keyBoardControl
            >
              {listProductsSlider ?listProductsSlider?.map((res) => {
                return (
                  <div className="mr-4" key={res.id}>
                    <ProductCard
                      subCategoryName=""
                      categoryName=""
                      sourceName={
                        translationValue(sliderProducts?.titles)
                          ? translationValue(sliderProducts?.titles)
                          : ""
                      }
                      data={res}
                    />
                  </div>
                );
              }) : relatedProducts?.map((item) => {
                return <div className="mr-4" key={item.id}>
                <ProductCard
                  subCategoryName=""
                  categoryName=""
                  sourceName={""}
                  data={item}
                />
              </div>
              })}
            </MultiCarousel>
          {/* )} */}
        </Col>
      </Row>
    </div>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
};
