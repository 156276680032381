import {useMemo, useState} from "react";
import {Button, Input, Select} from "antd";
import {TicketTypeBaseDto} from "../../api/models/TicketTypeBaseDto";
import {UserBaseDto, UserService} from "../../api";
import {DeleteFilled} from "@ant-design/icons"
import {useTranslation} from "react-i18next";
import DebounceSelect from "../DebounceSelect";
import {mergeFilters, toFilter, toIncludes} from "../../utils/request";
import {UserRole} from "../../utils/types";

interface TicketSearchFiltersProps {
    onSubmit: (filters?: Record<string, any>) => void
    types: TicketTypeBaseDto[],
}

interface TicketFilterProps {
    number: string,
    title: string,
    type: TicketTypeBaseDto | undefined,
    createdBy: string,
    inCharge: string,
}

const TicketSearchFilters = ({
    onSubmit,
    types,
}: TicketSearchFiltersProps) => {
    
    const {t, i18n} = useTranslation();
    
    const [users, setUsers] = useState<UserBaseDto[]>([]);
    
    const [filters, setFilters] = useState<TicketFilterProps>({
        number: "",
        title: "",
        type: undefined,
        createdBy: "",
        inCharge: ""
    })
    
    const handleReset = () => {
        setFilters({
            number: "",
            title: "",
            type: undefined,
            createdBy: "",
            inCharge: "",
        })
        onSubmit(undefined);
    }
    
    const getUserList = async (filter?: string) => {
        
        const filters = mergeFilters<UserBaseDto>([{name: {$contains: filter}}, {surname: {$contains: filter}}], {
            roles: {
                role: {
                    name: UserRole.ADMIN
                }
            }
        })
        
        return UserService.findAllUserController(
            undefined,
            undefined,
            (filter) ? toFilter<UserBaseDto>(filters!) : undefined,
            undefined,
            undefined,
            toIncludes<UserBaseDto>({
                roles: {
                    role: true,
                },
            })
        ).then((res) => {
            setUsers(res.data);
            return res.data;
        });
    }
    
    const getClientList = (filter?: string) => {
        const filters = mergeFilters<UserBaseDto>([{name: {$contains: filter}}, {surname: {$contains: filter}}], {
            roles: {
                role: {
                    name: UserRole.CLIENT
                }
            }
        })

        return UserService.findAllUserController(
            undefined,
            undefined,
            (filter) ? toFilter<UserBaseDto>(filters!) : undefined,
            undefined,
            undefined,
            toIncludes<UserBaseDto>({
                roles: {
                    role: true,
                },
            })
        ).then((res) => {
            return res.data;
        });
    }
    
    const handleSubmit = () => {
        onSubmit(filters);
    }
    
    const formattedTypeList = useMemo(() => {
        return [
            {label: t("dashboard.tickets.searchFiltersPopover.any"), value: ""},
            ...types.map((type) => ({
                label: t(`common.ticketType.${type.name}`),
                value: type.name
            }))
        ]
    }, [types])

    const selectType = (selectedValue: string) => {
        if(!selectedValue) setFilters((filters) => ({...filters, type: undefined}))
        const selectedType = types.find((type) => type.name === selectedValue);
        if(selectedType) setFilters((filters) => ({...filters, type: selectedType}))
    }

    const selectUser = (selectedValue: string) => {
        setFilters((filters) => ({...filters, inCharge: selectedValue ?? ""}))
    }

    const selectClient = (selectedValue: string) => {
        setFilters((filters) => ({...filters, createdBy: selectedValue ?? ""}))
    }
    
    return (
        <div className={"flex flex-col min-w-[200px]"}>
            <hr className={"mb-4 border-neutral-100 w-full"}/>
            <div className={"flex flex-col space-y-2"}>
                <div className="flex flex-col space-y-1">
                    <span className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("dashboard.tickets.searchFiltersPopover.numberLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        number: e.target.value
                    }))} type={"number"} value={filters.number} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("dashboard.tickets.searchFiltersPopover.titleLabel")}</span>
                    <Input onChange={(e) => setFilters((filters) => ({
                        ...filters,
                        title: e.target.value
                    }))} value={filters.title} size="middle" className="mt-1"/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("dashboard.tickets.searchFiltersPopover.typeLabel")}</span>
                    <Select options={formattedTypeList} size={"middle"} defaultValue={filters.type ? filters.type.name : ""} onChange={selectType}/>
                </div>
                <div className="flex flex-col space-y-1">
                    <span className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("dashboard.tickets.searchFiltersPopover.createdByLabel")}</span>
                    <DebounceSelect fn={getClientList} itemNameLabel={["name", "surname"]} defaultValue={filters.createdBy ? filters.createdBy : ""} itemValueLabel={"uuid"} debounceTimeout={500} onChange={selectClient} />
                </div>
                <div className="flex flex-col space-y-1">
                    <span className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("dashboard.tickets.searchFiltersPopover.inChargeLabel")}</span>
                    <DebounceSelect fn={getUserList} itemNameLabel={["name", "surname"]} defaultValue={filters.inCharge ? filters.inCharge : ""} itemValueLabel={"uuid"} debounceTimeout={500} onChange={selectUser} />
                </div>
            </div>
            <hr className={"my-4 border-neutral-100 w-full"}/>
            <div className={"flex flex-row space-x-2 items-center w-full"}>
                <Button type={"primary"} className={"w-full"} onClick={handleSubmit}>{t("dashboard.tickets.searchFiltersPopover.filterBtn")}</Button>
                <Button type={"default"} onClick={handleReset} icon={<DeleteFilled/>}/>
            </div>
        </div>
    )
}

export default TicketSearchFilters