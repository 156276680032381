import { Button, Form, Input, notification } from "antd";
import { AxiosError } from "axios";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../api";


interface Props {
  onLogin: (user: any) => void;
  onError: (error: AxiosError) => void;
}

export const RecoveryPasswordForm = ({ onLogin, onError }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [emailNotValid, setEmailNotValid] = useState(false);
  const [api, contextHolder] = notification.useNotification();


  const onFinish = (values: FieldType) => {
    AuthService.requestRecoveryAuthController({
      user: values.email || "",
    })
      .then((res: any) => {
        api.info({
          message: t('recoveryPassword.checkEmailTitle'),
          description: t('recoveryPassword.checkEmailDescription')
        });
      })
      .catch(() => {
        setEmailNotValid(true);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    onError(errorInfo);
  };

  type FieldType = {
    email?: string;
  };

  return (
    <div className="bg-[white] rounded-lg p-10 ml-5 h-[100%] relative">
      <p className="text-center text-2xl font-bold">{t('recoveryPassword.recoveryYourPassword')}</p>
      <p className="text-center text-sm text-neutral-300 font-medium">
      {t('recoveryPassword.enterYourEmail')}
      </p>
      {emailNotValid && (
        <div className="text-[#d41313] text-center font-bold">
          {t('recoveryPassword.emailNotExist')}
        </div>
      )}
      <div className="flex justify-center">
        <Form
          className="mt-10 w-64"
          name="recoveryPasswordForm"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label={t('common.email')}
            name="email"
            style={{
              fontWeight: 600,
            }}
            rules={[
              { type: "email", message:    t('recoveryPassword.enterValidEmail') },
              { required: true, message: t('registration.errors.enterEmail') },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="bg-blue-500 text-xs font-semibold mt-4"
            >
              {t('recoveryPassword.sendEmail')}
            </Button>
          </Form.Item>
          
            <Button className="underline text-blue-500 cursor-pointer font-bold" block onClick={() => navigate('/login')} type="link">Torna al login</Button>
          
        </Form>

        <div className="absolute bottom-10 font-normal font-xs">
          <p>
            {t('login.form.noAccount')}{" "}
            <span
              className="underline text-blue-500 cursor-pointer font-medium"
              onClick={() => {
                navigate("/register");
              }}
            >
              {t('common.signup')}
            </span>
          </p>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};
