
import { UserBaseDto } from "../api";
import { UserRole } from "./types";

export const isAdmin = (currentUser: UserBaseDto | undefined) => {
  if (
    currentUser &&
    currentUser?.roles?.filter(
      (item: { role: { name: any } }) => item.role.name === UserRole.ADMIN
    )?.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};
