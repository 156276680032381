import { Col, Row } from "antd";
import { TFunction } from "i18next";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Location, useLocation } from "react-router-dom";
import { fillIcon } from "../utils/fillIcon";
import { Account } from "./icons/Account";
import { Addresses } from "./icons/Addresses";
import { Call } from "./icons/Call";
import { File } from "./icons/File";
import { Heart } from "./icons/Heart";
import { HeartMenu } from "./icons/HeartMenu";
import { Help } from "./icons/Help";
import { Orders } from "./icons/Orders";
import { Returns } from "./icons/Returns";
import { Layout } from "./Layout/Layout";
import { SideBarMenu } from "./SideBarMenu";
import {Ticket} from "./icons/Ticket";

export const userMenuList = (
  selectedItem: string,
  t: TFunction<"translation", undefined>
) => [
  {
    label: t("menu.account"),
    icon: (
      <Account
        fill={fillIcon(t("menu.account"), selectedItem)}
        style={{ width: 24, height: 24 }}
      />
    ),
    id: "account",
    url: "/profile",
  },
  {
    label: t("menu.addresses"),
    icon: <Addresses fill={fillIcon(t("menu.addresses"), selectedItem)} />,
    id: "account",
    url: "/profile/addresses",
  },
  {
    label: t("menu.orders"),
    icon: <Orders fill={fillIcon(t("menu.orders"), selectedItem)} />,
    id: "account",
    url: "/profile/orders",
  },
  // {
  //   label: t("menu.returns"),
  //   icon: <Returns fill={fillIcon(t("menu.returns"), selectedItem)} />,
  //   id: "account",
  //   url: "/profile/returns",
  // },
  {
    label: t("menu.favorites"),
    icon: <HeartMenu fill={fillIcon(t("menu.favorites"), selectedItem)} />,
    id: "account",
    url: "/profile/favorites",
  },
  {
    label: t("menu.tickets"),
    icon: <Ticket fill={fillIcon(t("menu.tickets"), selectedItem)} />,
    id: "account",
    url: "/profile/tickets",
  },
  {
    label: t("menu.disclosure"),
    icon: <File fill={fillIcon(t("menu.disclosure"), selectedItem)} />,
    id: "privacy",
    url: "/profile/privacy",
  },
  {
    label: t("menu.contacts"),
    icon: <Call fill={fillIcon(t("menu.contacts"), selectedItem)} />,
    id: "privacy",
    url: "/profile/contacts",
  },
  {
    label: t("menu.faq"),
    icon: <Help fill={fillIcon(t("menu.faq"), selectedItem)} />,
    id: "info",
    url: "/profile/faq",
  },
];

export const showSideBar = (location: Location<any>) => {
  if (
    location.pathname.endsWith("/return") ||
    location.pathname.endsWith("/return-summary") ||
    location.pathname.endsWith("/return-created")
  ) {
    return false;
  } else {
    return true;
  }
};

interface Props {
  children: ReactNode;
  showMenu?: boolean;
}

export const UserLayout = ({ children }: Props) => {
  const [selectedItem, setSelectedItem] = useState<string>("Account");
  const location = useLocation();
  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    // Aggiungi un listener per l'evento di ridimensionamento della finestra
    window.addEventListener("resize", handleResize);

    // Pulisci il listener quando il componente viene smontato
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    userMenuList(selectedItem, t).map((res) => {
      if (res.url === location.pathname) {
        setSelectedItem(res.label);
      }
    });
  }, [location.pathname, selectedItem, userMenuList]);

  const UserLayoutComponent = () => {
    return (
      <Row>
        <Col
          span={
            showSideBar(location) && windowSize.width > 1024
              ? 4
              : windowSize.width < 1024
              ? 0
              : 3
          }
          className="min-h-screen max-h-max"
          style={{
            borderRight: showSideBar(location)
              ? "1px solid var(--neutral-200, #DBDBDB)"
              : " ",
          }}
        >
          <SideBarMenu
            showSideBar={showSideBar(location)}
            selectedItem={selectedItem}
            setSelectedItem={(value) => setSelectedItem(value)}
            userMenu={userMenuList(selectedItem, t)}
          ></SideBarMenu>
        </Col>
        <Col
          span={windowSize.width < 1024 ? 24 : 20}
          className={
            showSideBar(location) === false
              ? "pl-0 pr-0"
              : "max-w-screen-2xl mx-auto"
          }
        >
          {children}
        </Col>
      </Row>
    );
  };
  return (
    <Layout
      noPaddingPage={true}
      userMenu={true}
      children={<UserLayoutComponent />}
    ></Layout>
  );
};
