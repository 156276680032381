import {Button, Input, Space, Tag} from "antd";
import {useEffect, useState,} from "react";
import {Filter} from "./icons/Filter";
import {useTranslation} from "react-i18next";
import {useBreakpoint} from "../utils/hooks/useBreakpoint";
import {useDebouncedValue} from "../utils/hooks/useDebouncedValues";
import {CheckboxFilter} from "./CheckboxFilter";
import {CheckboxOptionType} from "antd/es/checkbox/Group";
import {RangeInputFilter} from "./RangeInputFilter";

const labelStyle = "font-bold text-neutral-300 mb-0";

export type OriginalVariable = {
  [key: string]: any;
};

export type AvailableFilters = {
  name?: { $containsIgnore: string };
  code?: string;
  brand?: { $in: number[] };
  categories?: { $in: number[] };
  variants?: {
    size?: { $in: number[] },
    color?: { $in: number[] },
    netPriceList1?: { $between: number[] };
  }
}

interface Props {
  findProductsWithFilters: (e: any) => void;
  findProducts?: (e: AvailableFilters) => void;
  showSelectedFilters?: boolean;
  showButton: boolean;
  productId?: string;
  meta?: {
    sizes: Record<string, string>,
    colors: Record<string, string>,
    brands: Record<string, string>,
    categories: Record<string, string>,
  },
  show?: {
    code: boolean,
    description: boolean,
    brand: boolean,
    price: boolean
  }
}

type k = keyof AvailableFilters;

export const ButtonFilter = ({
                               findProductsWithFilters,
                               findProducts,
                               showSelectedFilters = false,
                               showButton,
                               show,
                               meta,
                               productId
                             }: Props) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [filters, setFilters] = useState<AvailableFilters>({});
  const debouncedSearchTerm = useDebouncedValue(filters, 500);

  const {isMobile} = useBreakpoint();

  const {t} = useTranslation();

  useEffect(() => {
    findProducts && findProducts(filters);
  }, [debouncedSearchTerm]);

  const updateFilters = (filters: AvailableFilters) => {
    setFilters(
      Object.keys(filters).reduce((
        value, key
      ) => {
        const typedKey = key as keyof AvailableFilters; // Cast di key a una chiave di AvailableFilters
        if (filters[typedKey]) value[typedKey] = filters[typedKey] as any
        return value;
      }, {} as AvailableFilters)
    )
  }


  const filtersRender = () => {
    return (
      <div className={`w-full`}>
        <Space direction="vertical" className={`w-full`}>
          <div className="flex justify-between">
            <div>
              <p className="font-xs font-medium">
                {t("filters.appliedFilters")}: {0}
              </p>
            </div>
            <div>
              <p
                className="cursor-pointer underline text-neutral-300 font-xs"
                onClick={() => {
                  updateFilters({})
                }}
              >
                X {t("filters.reset")}
              </p>
            </div>
          </div>
          {show?.code && <div className="flex flex-col space-y-1">
            <span
                className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("Item code")}</span>
              <Input onChange={(e) => {
                updateFilters({
                  ...filters,
                  code: e.target.value
                })
              }} type={"text"} value={filters.code} size="middle" className="mt-1"/>
          </div>}
          {show?.description && <div className="flex flex-col space-y-1">
            <span
                className="text-xs font-semibold leading-4 tracking-[0.06px]">{t("Nome")}</span>
              <Input
                  value={filters.name?.$containsIgnore}
                  onChange={(e) => {
                    if (e.target.value)
                      updateFilters({
                        ...filters,
                        name: {
                          $containsIgnore: e.target.value
                        }
                      })
                    else
                      updateFilters({
                        ...filters,
                        name: undefined
                      })
                  }} type={"text"} size="middle" className="mt-1"/>
          </div>}
          {show?.price !== false && <RangeInputFilter labelStyle={labelStyle} label={t('common.price')} firstInput={
            {
              min: 0,
              onChange: (value: number) => {
                let max = filters.variants?.netPriceList1?.$between[1] || value + 1;
                if(max < value) {
                  max = value + 1
                }
                updateFilters({
                  ...filters,
                  variants: {
                    netPriceList1: {
                      $between: [
                        value,
                        max
                      ].filter(f => f > 0)
                    }
                  }
                })
              },
              value: filters.variants?.netPriceList1?.$between[0] || 0,
              placeholder: t("filters.min"),
              prefix: "€",
            }}
                                            secondInput={
                                              {
                                                min: 0,
                                                onChange: (value: number) => {
                                                  let min = filters.variants?.netPriceList1?.$between[0] || 0;
                                                  if(min > value) {
                                                    min = value - 1
                                                  }
                                                  updateFilters({
                                                    ...filters,
                                                    variants: {
                                                      netPriceList1: {
                                                        $between: [
                                                          min,
                                                          value
                                                        ]
                                                      }
                                                    }
                                                  })
                                                },
                                                value: filters.variants?.netPriceList1?.$between[1] || 0,
                                                placeholder: t("filters.max"),
                                                prefix: "€",
                                              }
                                            }/>}

          <CheckboxFilter
            labelStyle={labelStyle}
            label={t("common.size")}
            items={Object.values(meta?.sizes || {})}
            onChange={(d) => {
              if (d.length > 0)
                updateFilters({
                  ...filters,
                  variants: {
                    size: {
                      $in: d
                    }
                  }
                })
              else
                updateFilters({
                  ...filters,
                  variants: {
                    ...(filters.variants || {}),
                    size: undefined
                  }
                })
            }}
            itemChecked={filters?.variants?.size?.$in || []}/>
          {show?.brand !== false && <CheckboxFilter
              labelStyle={labelStyle}
              label={t("common.brand")}
              items={
                Object.keys(meta?.brands || {})
                  .filter(d => d)
                  .map(d => {
                    return {
                      label: meta?.brands[d],
                      value: d
                    } as CheckboxOptionType
                  }).filter(d => d.value && d.label)
              }
              onChange={(d) => {
                if (d.length === 0)
                  updateFilters({
                    ...filters,
                    brand: undefined
                  })
                else
                  updateFilters({
                    ...filters,
                    brand: {
                      $in: d
                    }
                  })
              }}
              itemChecked={filters?.brand?.$in || []}/>}

          <CheckboxFilter
            labelStyle={labelStyle}
            label={t("common.color")}
            items={
              Object.keys(meta?.colors || {})
                .filter(d => d)
                .map(d => {
                  return {
                    label: meta?.colors[d],
                    value: d
                  } as CheckboxOptionType
                }).filter(d => d.value && d.label)
            }
            onChange={(d) => {
              if (d.length === 0)
                updateFilters({
                  ...filters,
                  variants: {
                    ...(filters.variants || {}),
                    color: undefined
                  }
                })
              else
                updateFilters({
                  ...filters,
                  variants: {
                    ...(filters.variants || {}),
                    color: {
                      $in: d
                    }
                  }
                })
            }}
            itemChecked={filters?.variants?.color?.$in || []}/>
        </Space>
      </div>
    );
  };

  return (
    <div>
      {showButton ? (
        <>
          <div className="flex justify-between">
            <div className="text-base leading-6 tracking-[0.08px] font-semibold text-neutral-black-700 flex ">
              <div className="flex ml-4 justify-between flex-wrap">
                {showSelectedFilters && selectedFilters.length > 0 && (
                  <div className="text-[#888] text-[10px] font-semibold leading-[18px] tracking-[0.05px] mt-2">
                    {t("filters.selectedFilters")}:
                  </div>
                )}
                {showSelectedFilters &&
                  selectedFilters.map((res) => {
                    return (
                      <Tag
                        key={res}
                        className="custom-tag ml-4"
                        closable
                        onClose={(e) => {
                          e.preventDefault();
                          const updatedFilters = selectedFilters.filter(
                            (filter) => filter !== res
                          );
                          setSelectedFilters(updatedFilters);
                        }}
                      >
                        {res}
                      </Tag>
                    );
                  })}
              </div>
            </div>
            <div className="relative z-50">
              <Button
                className={`g-white text-blue-400 text-sm leading-5 tracking-[0.07px] font-semibold border-blue-400 ${
                  isMobile ? "h-[40px]" : ""
                }`}
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                <div className="flex justify-stretch">
                  <div className="w-4 h-4">
                    <Filter fill="#0950A8"></Filter>
                  </div>
                  <div className="ml-2 font-semibold leading-5 text-xs">
                    {t("common.filters")}
                  </div>
                  {selectedFilters.length > 0 && (
                    <div className="text-center ml-2">
                      <div className="h-5 w-5 bg-blue-400 text-white rounded-full text-center font-normal">
                        {selectedFilters.length}
                      </div>
                    </div>
                  )}
                </div>
              </Button>
              {showFilters && (
                <div className="absolute top-10 z-50 right-0 w-80">
                  <div
                    className="w-80"
                    style={{
                      borderRadius: "8px",
                      border:
                        " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
                      background: "var(--neutral-white, #FFF)",
                      boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
                      padding: 24,
                      height: "100%",
                    }}
                  >
                    {filtersRender()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div>{filtersRender()}</div>
      )}
    </div>
  );
};
