/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SetCartDto } from '../models/SetCartDto';
import type { SetCartResponseDto } from '../models/SetCartResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CartService {
    /**
     * @param requestBody
     * @returns SetCartResponseDto
     * @throws ApiError
     */
    public static setCartController(
        requestBody: SetCartDto,
    ): CancelablePromise<SetCartResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/carts/set',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneCartController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/carts/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
