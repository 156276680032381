/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCategoryRequestDto } from '../models/CreateCategoryRequestDto';
import type { PatchCategoryRequestDto } from '../models/PatchCategoryRequestDto';
import type { UpdateCategoryRequestDto } from '../models/UpdateCategoryRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CategoryService {
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static deleteCategoryController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findOneCategoryController(
        id: any,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static replaceCategoryController(
        id: number,
        requestBody: UpdateCategoryRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateCategoryController(
        id: number,
        requestBody: PatchCategoryRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param ids
     * @returns any
     * @throws ApiError
     */
    public static deleteManyCategoryController(
        ids: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/categories/delete/many',
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static restoreCategoryController(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/categories/restore/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static findAllCategoryController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/categories',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
    /**
     * @param requestBody
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static createCategoryController(
        requestBody: CreateCategoryRequestDto,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/categories',
            query: {
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param page
     * @param pageSize
     * @param filters
     * @param sorting
     * @param fields
     * @param includes
     * @param deleted
     * @returns any
     * @throws ApiError
     */
    public static countCategoryController(
        page?: number,
        pageSize?: number,
        filters?: string,
        sorting?: string,
        fields?: string,
        includes?: string,
        deleted?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/categories/count',
            query: {
                'page': page,
                'pageSize': pageSize,
                'filters': filters,
                'sorting': sorting,
                'fields': fields,
                'includes': includes,
                'deleted': deleted,
            },
        });
    }
}
