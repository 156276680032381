import {Button, Modal, Popconfirm, Spin, Table, Tabs, Tag} from "antd";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";

import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {GetOrderResponseDto, OrderBaseDto, OrderService, UserSectionPermissionBaseDto} from "../../../api";
import {OriginalVariable} from "../../../Components/ButtonFilter";
import {numberWithCommasAndFixedDecimals} from "../../../utils/numberWithCommasAndFixedDecimals";
import {toIncludes} from "../../../utils/request";
import {tagColorOrderStatus} from "../../../utils/tagColorOrderStatus";
import {TableFilters} from "../clients/components/TableFilters";
import {OrderRowsTable} from "./OrderRowsTable";
import {format} from "date-fns";
import {CreditCardFilled, DownOutlined, SettingOutlined, UnorderedListOutlined, UpOutlined} from "@ant-design/icons"
import {it} from "date-fns/locale";
import status = GetOrderResponseDto.status;
import {useUserPermissions} from "../../../utils/hooks/useUserPermissions";
import {AuthContext} from "../../../contexts/authContext";

interface DataType {
  key: any;
  number: string;
  client: string;
  date: string;
  status: any;
  total: number;
  discount: number;
}

interface OrderStatusEnum {
  [key: string]: string;
}

export const Orders = () => {
  const [orders, setOrders] = useState([] as Array<OrderBaseDto>);
  const {t} = useTranslation();
  const [pages, setPages] = useState([0, 10]);
  const [totalValues, setTotalValues] = useState<number>(0);
  const [countFilter, setCountFilter] = useState(0);
  const [filtersValue, setFiltersValue] = useState({});
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [statusChecked, setStatusChecked] = useState<CheckboxValueType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<GetOrderResponseDto>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { permissions, currentUser } = useContext(AuthContext);
  const { canUser }= useUserPermissions(permissions, currentUser)

  const refreshTable = (orders: OrderBaseDto[]) => {
    let list: DataType[] = [];
    orders.map((item: OrderBaseDto) => {
      list.push({
        key: item.id,
        number: item.number!,
        client: item.user.email,
        date: dayjs(item.createdAt).toString() ?? "",
        status: item.status,
        total: item.total,
        discount: item.discount,
      });
    });
    setTableData(list);
  }

  const getOrders = (filters?: any) => {
    OrderService.findAllOrderController(
      pages[0],
      pages[1],
      filters ? JSON.stringify(filters) : undefined,
      undefined,
      undefined,
      toIncludes<GetOrderResponseDto>({
        user: true,
      })
    ).then((res) => {
      setOrders(res.data);
      setTotalValues(res.totalCount);
      refreshTable(res.data);
    });
  };

  useEffect(() => {
    getOrders();
  }, [pages]);

  const setOrderStatus = (id: number, status: GetOrderResponseDto.status) => {
    const data = orders!.map(
      d => {
        return {
          ...d,
          status: d.id === id ? status: d.status
        }
      }
    )
    setOrders(
      data
    )
    refreshTable(data);
  }
  const getSelectedOrder = async (id: string) => {
    try {

      setSelectedOrder(undefined);

      const res = await OrderService.findOneOrderController(
        id,
        undefined,
        toIncludes<GetOrderResponseDto>({
          rows: {
            variant: {
              product: true,
            },
          },
          transaction: true
        })
      );

      if (res.data) setSelectedOrder(res.data);
    } catch {

    }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t("order.orderNumber"),
      dataIndex: "number",
      key: "number",
      width: 200,
      render: (text: string) => {
        return (
          <div className="text-[#5C59E8] font-[Inter] text-sm font-semibold leading-5">
            {text}
          </div>
        );
      },
    },
    {
      title: t("order.client"),
      dataIndex: "client",
      key: "client",
    },
    {
      title: t("common.date"),
      dataIndex: "date",
      key: "date",
      render: (data: any) => format(new Date(data), 'dd-MM-yyyy')
    },
    {
      title: t("common.status"),
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        return (
          <Tag
            color={tagColorOrderStatus(text)?.tag}
            style={{
              borderRadius: 50,
              borderWidth: 0,
              fontWeight: "bold",
              color: tagColorOrderStatus(text)?.text,
            }}
            key={text}
          >
            <div className="capitalize">{text.toLocaleLowerCase()}</div>
          </Tag>
        );
      },
    },
    {
      title: t("common.total"),
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return (
          <>
            {record.discount > 0 &&
            <div className="flex justify-center">
              <div className="text-[10px] font-normal leading-[18px] tracking-[0.05px] line-through">
                {numberWithCommasAndFixedDecimals(text + record.discount)}
              </div>
              <div className="text-[#AE0F0A] text-[10px] ml-2 font-semibold leading-[18px] tracking-[0.05px]">
                -{numberWithCommasAndFixedDecimals(record.discount)}
              </div>
            </div>
            }
            <div
              className="bg-blue-50 text-blue-400 rounded-xl text-center text-xs leading-4 tracking-[0.06px] font-semibold p-1">
              {numberWithCommasAndFixedDecimals(text)}€
            </div>
          </>
        );
      },
    },
    // {
    //   title: t("common.actions"),
    //   render: (text, record) => {
    //     return (
    //       <Button
    //         onClick={() => {
    //           OrderService.findOneOrderController(
    //             record.key,
    //             undefined,
    //             toIncludes<GetOrderResponseDto>({
    //               rows: {
    //                 variant: {
    //                   product: true,
    //                 },
    //               },
    //             })
    //           ).then((res) => {
    //             setOrderSelected(res.data);
    //             if (res.data) {
    //               setShowModal(true);
    //             }
    //           });
    //         }}
    //       >
    //         {t("order.orderDetails")}
    //       </Button>
    //     );
    //   },
    // },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    getOrders(filters);
    setPages([pagination.current - 1, pagination.pageSize]);
  };

  const onChangeInput = (column: string, value: any) => {
    let filters: OriginalVariable = filtersValue;
    setCountFilter(countFilter > 0 ? -1 : 0);
    if (value.length > 0) {
      setCountFilter(countFilter + 1);
      if (value.length >= 3 && column === 'email') {
        filters["user"] = {email: {$containsIgnore: value}};
      }
      else {
        filters[column] = {$containsIgnore: value}
      }
    } else {
      setCountFilter(countFilter - 1);
      delete filters[column];
    }
    getOrders(filters);
    setFiltersValue(filters);
  };
  const onChangeRangeDate = (column: string, value: any) => {
    let filters: OriginalVariable = filtersValue;
    setCountFilter(countFilter > 0 ? -1 : 0);
    if (value && value.length > 0) {
      setCountFilter(countFilter + 1);
      if (value && value.length > 1) {
        filters[column] = {
          $between: [
            dayjs(value[0]).toISOString(),
            dayjs(value[1]).toISOString(),
          ],
        };
      }
    } else {
      setCountFilter(countFilter - 1);
      delete filters[column];
    }
    getOrders(filters);
    setFiltersValue(filters);
  };
  const onChangeRangeNumber = (
    column: string,
    type: "min" | "max",
    value: any
  ) => {
    let updatedFilters: OriginalVariable = filtersValue;

    const updatedCountFilter =
      value > 0 ? countFilter + 1 : countFilter > 0 ? countFilter - 1 : 0;

    const oppositeValue =
      type === "min"
        ? updatedFilters[column]?.$lte
        : updatedFilters[column]?.$gte;

    if (value > 0) {
      updatedFilters[column] = {
        $gte: type === "min" ? value : oppositeValue || 0,
        $lte: type === "max" ? value : oppositeValue || 0,
      };
    } else {
      setCountFilter(countFilter - 1);
      delete updatedFilters[column];
    }

    getOrders(updatedFilters);
    setFiltersValue(updatedFilters);
    setCountFilter(updatedCountFilter);
  };

  const onChangeCheckbox = (
    column: string,
    checkedValues: CheckboxValueType[]
  ) => {
    setStatusChecked(checkedValues);
    let filters: OriginalVariable = filtersValue;
    setCountFilter(countFilter > 0 ? -1 : 0);
    if (checkedValues.length > 0) {
      setCountFilter(countFilter + 1);
      filters[column] = {$in: checkedValues};
    } else {
      setCountFilter(countFilter - 1);
      delete filters[column];
    }
    getOrders(filters);
    setFiltersValue(filters);
  };

  const orderExpandedRowContent = useMemo(() => {

    const orderTransaction = selectedOrder?.transaction;

    if (!selectedOrder) return (
      <div className={"size-full p-3 flex flex-col space-y-5 items-center justify-center"}>
        <Spin/>
        <p className={"font-bold text-md"}>{t("dashboard.orders.loadingOrderInfoMessage")}</p>
      </div>
    )

    const tabItems = [
      {
        key: `${selectedOrder.id}_products`,
        label: t("dashboard.orders.orderInfoProductsTab"),
        children: <OrderRowsTable order={selectedOrder}/>,
        icon: <UnorderedListOutlined/>,
      },
    ]

    const rejectOrder =               <Button onClick={(e) => {
      let reason = prompt("Attenzione specificare una motivazione");
      while(reason === "")
        reason = prompt("Attenzione specificare una motivazione");

      if (reason)
        OrderService.rejectOrderController(selectedOrder?.uuid!, reason!).then(
          d => {
            setSelectedOrder({
              ...selectedOrder,
              status: status.REJECTED
            })
            setOrderStatus(selectedOrder.id!, status.REJECTED);
          }
        )
    }} size={"middle"} type={"dashed"} style={{fontSize: 12}}>
      Annulla Ordine
    </Button>;

    const confirmOrder = (text: string) => <Popconfirm
      title={t("dashboard.orders.sureToConfirm")}
      className="cursor-pointer"
      onConfirm={() => {
        OrderService.confirmOrderController(selectedOrder?.uuid!).then(
          d => {
            setSelectedOrder({
              ...selectedOrder,
              status: status.CONFIRMED
            })
            setOrderStatus(selectedOrder.id!, status.CONFIRMED);
          }
        )
      }}
    >
      <Button size={"middle"} type={"primary"} style={{fontSize: 12}}>
        {text}
      </Button>
    </Popconfirm>

    if (orderTransaction) tabItems.push({
      key: `${selectedOrder.id}_transaction`,
      label: t("dashboard.orders.orderInfoTransactionTab"),
      children: (
        <div className={"grid grid-cols-1 md:grid-cols-4 gap-3"}>
          <div className={"flex flex-col space-y-1"}>
            <span className={"font-bold text-md"}>{t("dashboard.orders.transactionId")}</span>
            <span>{orderTransaction.transactionId}</span>
          </div>
          {orderTransaction.createdAt && (
            <div className={"flex flex-col space-y-1"}>
              <span className={"font-bold text-md"}>{t("dashboard.orders.transactionDate")}</span>
              <span>{format(new Date(orderTransaction.createdAt), "dd/MM/yyyy", {locale: it})}</span>
            </div>
          )}
          {orderTransaction.amount && (
            <div className={"flex flex-col space-y-1"}>
              <span className={"font-bold text-md"}>{t("dashboard.orders.transactionPrice")}</span>
              <span>{numberWithCommasAndFixedDecimals(orderTransaction.amount)} €</span>
            </div>
          )}
          {orderTransaction.paymentMethod && (
            <div className={"flex flex-col space-y-1"}>
              <span className={"font-bold text-md"}>{t("dashboard.orders.transactionPaymentMethod")}</span>
              <span>{numberWithCommasAndFixedDecimals(orderTransaction.paymentMethod)}</span>
            </div>
          )}
        </div>
      ),
      icon: <CreditCardFilled/>,
    });

    const showConfirm = selectedOrder.status !== 'CONFIRMED' && selectedOrder.status !== 'REJECTED';
    const showRejected = selectedOrder.status !== 'SHIPPED' && selectedOrder.status !== 'REJECTED';
    if(canUser(UserSectionPermissionBaseDto.permission.UPDATE, 'order'))
    tabItems.push({
      key: Math.random().toString(32).split('.').pop()!,
      children:
        selectedOrder.status === 'REJECTED' ? <div className={'flex flex-col gap-3'}>
          <div>
            L'ordine é stato annullato per la seguente motivazione:
          </div>
         <div>
           {(selectedOrder as any)['declineReason']}
         </div>
          </div> :
        <div>
        {selectedOrder.status === 'SHIPPED' && <div className={'flex flex-col gap-3'}>
            <div>
                Ordine spedito in data {format(new Date(selectedOrder.shippingDate as any), 'dd-MM-yyyy HH:mm')}
            </div>
            <div>
                C'é stato un errore?
            </div>
            <div className={'flex gap-3'}>
              {rejectOrder}
              {confirmOrder('Annulla spedizione')}
            </div>
        </div>}
        {(showRejected) && <div className={'flex gap-3'}>
          {
            rejectOrder
          }

          {
            showConfirm &&
              confirmOrder('Conferma')
          }
          {
            selectedOrder.status === 'CONFIRMED' &&
              <Popconfirm
                  title={t("dashboard.orders.sureToConfirm")}
                  className="cursor-pointer"
                  onConfirm={() => {
                    OrderService.shippedOrderController(selectedOrder?.uuid!).then(
                      d => {
                        setSelectedOrder({
                          ...selectedOrder,
                          status: status.SHIPPED,
                          shippingDate: d.shippingDate
                        })
                        setOrderStatus(selectedOrder.id!, status.SHIPPED);
                      }
                    )
                  }}
              >
                  <Button onClick={(e) => {

                  }} size={"middle"} type={"primary"} style={{fontSize: 12}}>
                      Spedito
                  </Button>
              </Popconfirm>

          }
        </div>}

      </div>,
      label: t('dashboard.orders.handle'),
      icon: <SettingOutlined/>
    })

    return (
      <div className={"bg-white px-5 py-3 rounded-md"}>
        <Tabs defaultActiveKey={`${selectedOrder.id}_products`} items={tabItems}/>
      </div>
    )
  }, [selectedOrder])

  return (
    <div>
      <div className="text-[#333843] text-base font-semibold leading-6 tracking-[0.08px] mb-4">
        {t("menu.orders")}
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: " 1px solid var(--neutral-gray-gray-100, #E0E2E7)",
          background: "var(--neutral-white, #FFF)",
          boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.10)",
          padding: 24,
          height: "100%",
        }}
      >
        <div className="mb-4">
          <TableFilters
            countFilter={countFilter}
            reset={() => {
              setCountFilter(0);
              setFiltersValue({});
            }}
            filters={[
              {
                label: t("order.orderNumber"),
                type: "input",
                onChangeInput: (e) => onChangeInput("number", e.target.value),
              },
              {
                label: t("order.client"),
                type: "input",
                onChangeInput: (e) => onChangeInput("email", e.target.value),
              },
              {
                label: t("common.date"),
                type: "rangeDate",
                onChangeRangeDate: (values) =>
                  onChangeRangeDate("createdAt", values),
              },
              {
                label: t("common.status"),
                type: "checkbox",
                items: Object.keys(OrderBaseDto.status) as Array<keyof OrderStatusEnum>,
                itemChecked: statusChecked,
                onChangeCheckbox: (value) => onChangeCheckbox("status", value),
              },
              {
                label: t("common.total"),
                type: "rangeNumber",
                onChangeRangeNumber: (type, value) =>
                  onChangeRangeNumber("total", type, value),
              },
            ]}
          ></TableFilters>
        </div>
        <Table
          columns={columns}
          onChange={handleChange}
          dataSource={tableData}
          pagination={{
            current: pages[0] + 1,
            pageSize: pages[1],
            total: totalValues,
          }}
          expandable={{
            expandedRowRender: (record) => orderExpandedRowContent,
            expandedRowKeys: expandedRowKeys,
            rowExpandable: (record) => true,
            onExpand: (expanded, record) => setExpandedRowKeys(expanded ? [record.key] : []),
            expandIcon: ({expanded, onExpand, record}) => {
              return (
                <Button onClick={(e) => {
                  getSelectedOrder(record.key);
                  onExpand(record, e);
                }} size={"middle"} type={"primary"} style={{fontSize: 12}} icon={expanded ? <UpOutlined/> :
                  <DownOutlined/>}>{expanded ? t("dashboard.orders.hideInfo") : t("dashboard.orders.showInfo")}</Button>
              )
            },
            expandIconColumnIndex: 10
          }}
        ></Table>
        <Modal
          open={showModal}
          width={1000}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <OrderRowsTable order={selectedOrder}></OrderRowsTable>
        </Modal>
      </div>
    </div>
  );
};
